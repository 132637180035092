import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import React, { createRef, useContext, useEffect } from "react";
import { UserContext } from "../../App";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {getRequest, put, remove } from "../../services/api.request";
import ExcudePriorQuarterParts from "./ExcludePriorQuarterParts";
import './DeleteCostSubmissionToAssociatedParts.css'
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";

const limit = 10;
const initialDataState = {
    skip: 0
};
const DeleteCostSubmissionToAssociatedParts = ({addToggleDialog,hmid, ccId,reloadGrid,setGetHeirachyName}) =>{
    const [loading, setLoading] = React.useState(false);
    const [dataState, setDataState] = React.useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const userContext = useContext(UserContext);
    const user_id = userContext.user_id;
    const curr_qrtr = userContext.curr_qrtr;
    const [openExcludePartsDialog, setOpenExcludePartsDialog] = React.useState(false);
    const [exportDownloadData, setExportDownloadedData] = React.useState([]);
    const [deleteCSIDs,setDeleteCSIds] = React.useState();
    const createExporter = createRef();    
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [disableNextButton, setDisableNextButton] = React.useState(true);

    const getDeletedCSParts = ({data})=>{
        setDataState(data.items);
        setLoading(false);      
    }
    const downloadExcel = ({data})=>{
      setExportDownloadedData(data.items);
      let res = data.items;
        if (res.length > 0) {
            setTimeout(() => {
                setAlertDialog(true);
            }, 3000);
        }
        else {
            setDisableNextButton(false)
        }
      setLoading(false);
    }
    useEffect(() => {
       setLoading(true);
       getRequest(`/hierarchy_cs_delete?user_id=${user_id}&hmid=${hmid}&ccid=${ccId}&current_quarter=${curr_qrtr}&offset=0&limit=${limit}`, getDeletedCSParts);
       getRequest(`/hierarchy_cs_delete_dwnld?user_id=${user_id}&hmid=${hmid}&ccid=${ccId}&current_quarter=${curr_qrtr}`,downloadExcel)
    }, []);
    useEffect(() => {
        let deleteArry=[];
        if(exportDownloadData.length > 0 ){          
         handleSaveExcel();
         exportDownloadData.map((item)=>{
            return deleteArry.push({
                csid : item.csid,
            });
         });   
        }
        setDeleteCSIds(deleteArry);
    },[exportDownloadData])
    const handleSaveExcel = () => {
        if (createExporter.current) {
            createExporter.current.save();
        }        
      };
    const deleteRecords =({data}) =>{
    }
    const onOKClick = ()=>{
        setAlertDialog(false) 
        setDisableNextButton(false)
    }
    const onNextExcludeParts = () =>{
        put(`cs_delete_multiple?p_user_id=${user_id}`, {"cost_lines":deleteCSIDs}, deleteRecords)
        setOpenExcludePartsDialog(!openExcludePartsDialog);
    }
    const onPageChange = (e) =>{
        setLoading(true)
        getRequest(`/hierarchy_cs_delete?user_id=${user_id}&hmid=${hmid}&ccid=${ccId}&current_quarter=${curr_qrtr}&offset=${e.page.skip}&limit=${limit}`, getDeletedCSParts);
        setTimeout(()=>{
            setPage(e.page);
        },100); 
        }
    return (
        <div className="window-container">
            <Window title = {"Current Quarter(Q0) Cost Submissions"}
                minimizeButton={() => null}
                maximizeButton={() => null}
                initialHeight={window.innerHeight * 0.95}
                closeButton={() => null}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={1200} >
                <div style={{ marginTop: -15 }}>{loading ? (
                    <ProgressBar />
                ) : ""}
                </div>
                <div className="wizard-page-hedding">
                    <p style={{ width: '150px'}}>Quarter : {curr_qrtr}</p>
                    <span className="spacer"></span>
                    <p className="over-flow2">{setGetHeirachyName.p_hierarchy_name}</p>
                    <span className="spacer"></span>
                    <p className="next-steps"> Step 2 of 3 
                        <Button size="small" style={{ backgroundColor: "#228dff", color: "white" ,marginBottom:'3px',marginLeft:'5px'}}
                            onClick={onNextExcludeParts} disabled = {disableNextButton}>
                            <span className="k-icon k-i-arrow-chevron-right"></span>
                            <span >Next</span>
                        </Button>
                    </p>
                </div>
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <Grid className="uploadStatus" 
                    onPageChange={(e) => { onPageChange(e) }}
                    total={dataState ? dataState.length > 0 ? dataState[0]?.resultset_size : 0 : 0}
                    sortable={true} pageable={true} skip={page.skip} pageSize={limit} data={dataState} rowHeight={window.innerHeight * 0.065}>
                    <GridNoRecords> No Submission entries found for deletion </GridNoRecords>
                    <GridColumn className="col" headerClassName="headerClass" field="part" title="Parts" width={140} cell={(e) => GridCellToolTip(e, 'part')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="supplier_common_name" title="Supplier" cell={(e) => GridCellToolTip(e, 'supplier_common_name')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="manufacturer_name" width={170} title="Manufacturer" cell={(e) => GridCellToolTip(e, 'manufacturer_name')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="commodity_code" title="Commodity Code" width={150} cell={(e) => GridCellToolTip(e, 'commodity_code')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="mpn" title="MPN" cell={(e) => GridCellToolTip(e, 'mpn')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q1_price" title="Q+1 ($)" cell={(e) => GridCellToolTip(e, 'q1_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q2_price" title="Q+2 ($)" cell={(e) => GridCellToolTip(e, 'q2_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q3_price" title="Q+3 ($)" cell={(e) => GridCellToolTip(e, 'q3_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q4_price" title="Q+4 ($)" cell={(e) => GridCellToolTip(e, 'q4_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q5_price" title="Q+5 ($)" cell={(e) => GridCellToolTip(e, 'q5_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q6_price" title="Q+6 ($)" cell={(e) => GridCellToolTip(e, 'q6_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q7_price" title="Q+7 ($)" cell={(e) => GridCellToolTip(e, 'q7_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q8_price" title="Q+8 ($)" cell={(e) => GridCellToolTip(e, 'q8_price')}/>
                </Grid>
                </Tooltip>
         <ExcelExport data={exportDownloadData} fileName={`CMATXWD_CS_DELETED_PARTS_TEMPLATE.xlsx`} ref={createExporter}>
         <ExcelExportColumn field="part" headerCellOptions={{ background: '#808080'}}  title="Part" width={150} />
         <ExcelExportColumn field="supplier_common_name" headerCellOptions={{ background: '#808080' }}  title="Supplier" width={150} />
         <ExcelExportColumn field="manufacturer_name"  headerCellOptions={{ background: '#808080' }} title="Manufacturer" width={150} />
         <ExcelExportColumn field="commodity_code" headerCellOptions={{ background: '#808080' }} title="Commodity Code" width={130} />
         <ExcelExportColumn field="commodity_group"  headerCellOptions={{ background: '#808080' }} title="Commodity Group" width={150} />
         <ExcelExportColumn field="mpn" headerCellOptions={{ background: '#808080' }} title="MPN" width={150} />
         <ExcelExportColumn field="q1_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+1 ($)" cellOptions={{format: "#,###"}} width={80} />
         <ExcelExportColumn field="q2_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+2 ($)" cellOptions={{format: "#,###"}} width={80} />
         <ExcelExportColumn field="q3_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+3 ($)" cellOptions={{format: "#,###"}} width={80} />
         <ExcelExportColumn field="q4_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+4 ($)" cellOptions={{format: "#,###"}} width={80} />
         <ExcelExportColumn field="q5_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+5 ($)" cellOptions={{format: "#,###"}} width={80} /> 
         <ExcelExportColumn field="q6_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+6 ($)" cellOptions={{format: "#,###"}} width={80} /> 
         <ExcelExportColumn field="q7_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+7 ($)" cellOptions={{format: "#,###"}} width={80} /> 
         <ExcelExportColumn field="q8_price" headerCellOptions={{background:'#808080'}}  className="numberFields" title="Q+8 ($)" cellOptions={{format: "#,###"}} width={80} /> 
      </ExcelExport>
      {
                alertDialog &&  ( <Dialog height='150px' ><p
                  style={{
                    textAlign: "center",
                    width: "400px"
                  }}
                >
                 These Cost Submission entries will be deleted.
                </p>
                <DialogActionsBar>
                  <button style={{ backgroundColor: "#228dff", color: "white",width:'100%'}}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onOKClick}
                  >
                    OK
                  </button>
                </DialogActionsBar>
              </Dialog>)
            }
            </Window>
            {
                openExcludePartsDialog && <ExcudePriorQuarterParts onNextExcludeParts={onNextExcludeParts} 
                ccId = {ccId} hmid = {hmid}  addToggleDialog = {addToggleDialog} reloadGrid ={reloadGrid}
                setGetHeirachyName = {setGetHeirachyName}></ExcudePriorQuarterParts>
            }
     
        </div>
    )
}
export default DeleteCostSubmissionToAssociatedParts;