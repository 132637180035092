import React, { useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import "./CostSubmissionUpload.css";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { UserContext } from "../../../App";
import { getRequest, post } from "../../../services/api.request";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FileUploader from "../../../components/FileUploader/FileUploader";
import { debounce, set } from "lodash";
import privateRequest from "../../../services/api.config";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { Switch } from "@progress/kendo-react-inputs";

const CostSubmissionUpload = ({ toggleWizard, setLoading }) => {
  const [manufacturer, setManufacturer] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [saveFlag, setSaveFlag] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [manufacturerLoading, setManufacturerLoading] = useState(false);
  const [uploadMessageDialog, setUploadMessageDialog] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [override, setOverride] = useState(false);
  const [clearFileUploadForm, setClearFileUploadForm] = React.useState(true);
  const [multiSupFlag, setmultiSupFlag] = useState("N")
  const userContext = useContext(UserContext);

  const readUploadFile = (e) => {
    var fileInput = document.getElementById("upload");
    var filePath = e.target.value;
    var allowedExtensions = /(\.xlsx|\.xls)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type");
      fileInput.value = "";
      return false;
    } else {
      e.preventDefault();
      if (e.target.files && e.target.files?.length > 0) {
        let fileName = e.target.files[0].name;
        setFile(e.target.files[0]);
        setFileName(
          fileName.substring(0, fileName.lastIndexOf(".")) || fileName
        );
        setClearFileUploadForm(false)
      }
      return true;
    }
  };

  const savedUploadedFile = ({ data }) => {
    if (data.p_out_status === "Success") {
      alert("File Upload started with id:" + data.image_id);
      setDisabledBtn(false);
      toggleWizard();
    } else alert(data.p_out_err_msg);
    setSaveFlag(!saveFlag);
    setLoading(false);
    setOverride(false);
    setmultiSupFlag("N")
  };

  const getUploadMessage = ({ data }) => {
    if (data?.allow_upload === "N") {
      setUploadMessageDialog(true);
    } else {
      setLoading(true);
      setSaveFlag(!saveFlag);
      const formData = new FormData();
      formData.append("body", file);
      let url = "";
      if (manufacturer.lookup_value === undefined) {
        if(supplier.lookup_code === undefined){
          url = `/media/?file_name=${fileName}&source_name=COST SUBMISSION&user_id=${userContext.user_id}&multi_supp_flag=${multiSupFlag}&current_qtr=${userContext.curr_qrtr}`;
        }
        else
        url = `/media/?file_name=${fileName}&source_name=COST SUBMISSION&user_id=${userContext.user_id}&attribute1_num=${supplier.lookup_code}&multi_supp_flag=${multiSupFlag}&current_qtr=${userContext.curr_qrtr}`;
      } else {
        url = `/media/?file_name=${fileName}&source_name=COST SUBMISSION&user_id=${userContext.user_id}&attribute1_num=${supplier.lookup_code}&multi_supp_flag=${multiSupFlag}&current_qtr=${userContext.curr_qrtr}&manufacturer_name=${manufacturer.lookup_value}`;
      }
      post(url, formData, savedUploadedFile);
    }
    setuploadLoading(false);
  };

  const saveCostSubmissionUpload = () => {
    setuploadLoading(true);
    setDisabledBtn(true);
    getRequest(
      `upload_allow_yn?user_id=${userContext?.user_id}&source_name=COST SUBMISSION`,
      getUploadMessage
    );
  };
 const clearCostSubmissionUpload = () =>{
  setOverride(false);
  setmultiSupFlag("N");
  setSupplier("");
  setManufacturer({});
  setFileName("");
  setFile(null);
  setClearFileUploadForm(true);
 }
  const handleRequest = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value });
      });
    }
    if (field) {
      setSuppliers({ ...suppliers, [field]: items });
    }
    setFilterDataLoading(false);
  };

  const filterChange = debounce(({ filter }, param) => {
    setFilterDataLoading(true);
    let url = `generic_lov_service/suppliers/?search_for=${filter.value}&user_id=${userContext?.user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        handleRequest(data, "suppliers");
      })
      .catch((err) => {
        setFilterDataLoading(false);
      });
  }, 1000);

  const filterSupplierOnChange = (event) => {
    let obj = event.value;
    setSupplier(obj);
    setClearFileUploadForm(false);
    setDisabledBtn(false);
  };
  const filterManufacturer = debounce(({ filter }, param) => {
    setManufacturerLoading(true);
    if(filter.value){
 let url = `generic_lov_service/manufacturer/?search_for=${filter.value}&user_id=${userContext?.user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        filterManuDataChng(data, "manufacturer");
      })
      .catch((err) => {
        setManufacturerLoading(false);
      });
    }
else{
  setManufacturers({...manufacturers, ["manufacturer"]:[]});
  setManufacturerLoading(false);
} 
   
  }, 1000);

  const filterManuDataChng = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value });
      });
    }
    if (field) {
      setManufacturers({ ...manufacturers, [field]: items });
    }
    setManufacturerLoading(false);
  };

  const filterManufactOnChange = (event) => {
    let obj = event.value;
    setManufacturer(obj);
    setClearFileUploadForm(false);
  };

  const toggleConfirmUpload = () => {
    setUploadMessageDialog(false);
  };

  const handleConfirmUpload = () => {
    setUploadMessageDialog(false);
    toggleWizard();
  };
const onSwitchChangeForMultiSupplier = (e)=>{
  if (e.value === true) {
    setOverride(e.value);
    setmultiSupFlag("Y");
    setClearFileUploadForm(false);
    setSupplier("");
    setManufacturer({});
    setFileName("")
  }
  else {
    setOverride(e.value);
    setmultiSupFlag("N");
    setClearFileUploadForm(true);
  }
}
  return (
    <div>
      <div className="window-container">
        <Window
          width={486}
          height={350}
          minimizeButton={() => null}
          maximizeButton={() => null}
          title={
            <WindowTitle
              title="Cost Submission - Upload"
              icon="k-i-table-cell"
            />
          }
          onClose={toggleWizard}
        >
          <div
            style={{
              margin: "25px 12px 12px 15px",
            }}
          >
            <div style={{ marginTop: "-39px", marginBottom: "9px" }}>
              {uploadLoading ? <ProgressBar /> : ""}
            </div>
            <FormElement>
            <div className="form-element">
            <label className="input-header-cell">Upload Multiple Suppliers:</label>
                  <Switch
                    className="switch"
                    width={200}
                    onChange={onSwitchChangeForMultiSupplier}
                    defaultChecked={override}
                    checked = {override}
                    onLabel={"Enabled"}
                    offLabel={"Disabled"}
                    disabled = {supplier?.lookup_code !== undefined || null ?true:false }
                  />
                </div>
              <div className="form-element">
                <label className="input-header-cell">
                  Supplier Common Name* :
                </label>
                <DropDownList
                  style={{
                    width: "200px",
                  }}
                  data={
                    Object.keys(suppliers).length > 0
                      ? suppliers["suppliers"]
                      : []
                  }
                  textField="lookup_value"
                  onChange={filterSupplierOnChange}
                  filterable={true}
                  value={supplier}
                  onFilterChange={filterChange}
                  loading={filterDataLoading}
                  disabled = {override}
                />
              </div>     
              <div className="form-element">
                <label className="input-header-cell">Manufacturer Name:</label>
                <DropDownList
                  style={{
                    width: "200px",
                  }}
                  data={
                    Object.keys(manufacturers).length > 0
                      ? manufacturers["manufacturer"]
                      : []
                  }
                  textField="lookup_value"
                  onChange={filterManufactOnChange}
                  filterable={true}
                  value={manufacturer}
                  onFilterChange={filterManufacturer}
                  loading={manufacturerLoading}
                  disabled = {override}
                />
              </div>
              <div className="form-element">
                <label className="input-header-cell">Upload File*:</label>
                <FileUploader onUpload={readUploadFile} />
              </div>
              <span className="selectedFileName">
                {fileName ? file.name : "No file selected"}
              </span>
            </FormElement>
          </div>
          <div style={{ marginTop: 15, width: "100%"}}>
            <div style={{ display: 'flex', float: 'right' }}>
            <Button  onClick={saveCostSubmissionUpload}
              className="save-button"
              disabled={!file || disabledBtn || saveFlag === true || (!supplier && override === false)?true:false}
              >
              <span className="k-icon k-i-upload"> </span> Upload
            </Button>
            </div>
            <div style={{ display: 'flex', float: 'left' }}>
            <Button  onClick={clearCostSubmissionUpload}
            disabled = {clearFileUploadForm}
              className="save-button"> Clear
            </Button>
            </div>
          </div>
        </Window>
      </div>
      {uploadMessageDialog && (
        <Dialog
          title={"Cost Submission - Upload"}
          onClose={toggleConfirmUpload}
          width={390}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your Existing uploads awaiting import. Please import and try to
            upload again.
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmUpload}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default CostSubmissionUpload;
