import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {formatDates} from '../../../utils/formatDateUtil';
import { getRequest } from '../../../services/api.request';
import { Tooltip } from '@progress/kendo-react-tooltip';
import GridCellToolTip from '../../../components/GridCellToolTip/GridCellToolTip';
const initialDataState = {
    skip: 0
  };

  const gridWidth = window.innerWidth * 0.85;
  const setPercentage = (percentage) => {
      return Math.round(gridWidth / 100) * percentage;
    };

  const columnDef= [
    {
        field: 'commodity_code',
        title: 'Commodity Code',
        width: setPercentage(15)
    },
    {
        field: 'gcc_start_active',
        title: 'Start Date Active',
        width: setPercentage(15)
    },
    {
        field: 'gcc_end_active',
        title: 'End Date Active',
        width: setPercentage(15)
    }
];

const MappedCommodityCodes = ({commodityGroupCode, setLoading}) => {
    const limit =10;
    const [dataState, setDataState] = React.useState([]);
    const [sort, setSort]= useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const getMappedCommodityCodes = ({ data },type)=>{
        setDataState(
            data.items.map(dataItem => Object.assign({}, formatDates(["gcc_start_active","gcc_end_active"],dataItem)))
        );  
        setLoading(false);
    };

    const fetchCommodityCodes =React.useCallback(
        () => {
            if(commodityGroupCode)
                getRequest('/gcm_group_ccode/'+commodityGroupCode+'?offset=0&limit='+limit,getMappedCommodityCodes);
            else
                setDataState([]);
            setSort([]);
            setPage(initialDataState);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commodityGroupCode]
      );
    
    useEffect(()=>{
        fetchCommodityCodes();
    },[fetchCommodityCodes]);

    const handlePageChange=(e)=>{
        setLoading(true);
        if(sort.length===0){
            let c= 'gcm_group_ccode/'+commodityGroupCode+'?offset='+e.page.skip+"&limit="+limit;
            getRequest(c, getMappedCommodityCodes);
            setTimeout(()=>{
                setPage(e.page);
            },100); 
        } else{
            const queryString='{"$orderby": {"'+sort[0].field+'":"'+sort[0].dir+'"}}';
            let c= 'gcm_group_ccode/'+commodityGroupCode+'?offset='+e.page.skip+"&limit="+limit+'&q='+encodeURIComponent(queryString);
            getRequest(c, getMappedCommodityCodes);
            setTimeout(()=>{
                setPage(e.page);
            },100); 
        }
         
    };
    const handleSortChange=(e)=>{
        if(e.sort && e.sort.length>0){
            let field = e.sort[0].field;
            let sortingOrder = e.sort[0].dir;
            const queryString='{"$orderby": {"'+field+'":"'+sortingOrder+'"}}';
            getRequest('/gcm_group_ccode/'+commodityGroupCode+'?q='+encodeURIComponent(queryString),getMappedCommodityCodes);
            setSort(e.sort);
            setPage(initialDataState);
        } else{
            let dir= "asc";
            if(sort[0].dir==='asc'){
                dir="desc";
            }
            const queryString='{"$orderby": {"'+sort[0].field+'":"'+dir+'"}}';
            getRequest('/gcm_group_ccode/'+commodityGroupCode+'?q='+encodeURIComponent(queryString),getMappedCommodityCodes);
            setSort([{"field": sort[0].field, "dir":dir}]);
            setPage(initialDataState);
        }
    };
    return (
        <div>
            <Tooltip openDelay={100} position="bottom" anchorElement="target">
             <Grid 
                        pageable={true}
                        skip={page.skip}
                        pageSize={limit}
                        total={dataState ? dataState.length > 0 ? dataState[0].resultset_size : 0 : 0}
                        data={dataState}
                        onPageChange={(e)=> { handlePageChange(e)}}
                        rowHeight={window.innerHeight * 0.065}>           
                            {columnDef.map(colDef=>
                                <GridColumn headerClassName="headerClass"  key={colDef.field} field={colDef.field} title={colDef.title}  cell={(e) => GridCellToolTip(e, colDef.field)}/>
                            )}
                    </Grid>
            </Tooltip>    
        </div>
    );
};

export default MappedCommodityCodes;