import { Button } from "@progress/kendo-react-buttons";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import privateRequest from "../../../services/api.config";
import { getRequest, put } from "../../../services/api.request";
import "../costSubmission.css";

const CostSubmissionDelete = ({
  setDeleteSearchWindow,
  user_id,
  currentQuarter,
}) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [commCodesList, setCommCodesList] = useState([]);
  const [commCodeLoading, setCommCodeLoading] = useState(false);
  const [partList, setPartList] = useState([]);
  const [partsLoading, setPartsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [deleteTableLoading, setDeleteTableLoading] = useState(false);
  const [deleteTableData, setDeleteTableData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [deleteParams, setDeleteParams] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const getGCMCommCodes = debounce(({ filter }) => {
    setCommCodeLoading(true);
    let url = `/generic_lov_service/added_ccode_in_cs/?search_for=${filter.value}&user_id=${user_id}&quarter=${currentQuarter}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        setCommCodesList(data.items);
        setCommCodeLoading(false);
      })
      .catch((err) => {
        setCommCodeLoading(false);
      });
  }, 1000);

  const getPartNumber = debounce(({ filter }, param) => {
    setPartsLoading(true);
    let url = `generic_lov_service/added_prefix_part_in_cs/?search_for=${filter.value}&user_id=${user_id}&quarter=${currentQuarter}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        data && setPartList(data.items);
        setPartsLoading(false);
      })
      .catch((err) => {
        setPartsLoading(false);
      });
  }, 1000);

  const getSupplierName = debounce(({ filter }, param) => {
    setSupplierLoading(true);
    let url = `generic_lov_service/added_supp_in_cs/?search_for=${filter.value}&user_id=${user_id}&quarter=${currentQuarter}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        data && setSupplierList(data.items);
        setSupplierLoading(false);
      })
      .catch((err) => {
        setSupplierLoading(false);
      });
  }, 1000);

  const handleValues = (e) => {
    switch (e.value.lookup_type) {
      case "added_ccode_in_cs":
        setSelectedValue({ ...selectedValue, gcmcommcodes: e.value });
        break;
      case "added_prefix_part_in_cs":
        setSelectedValue({ ...selectedValue, gcmparts_hierarchy: e.value });
        break;
      case "added_supp_in_cs":
        setSelectedValue({ ...selectedValue, suppliers: e.value });
        break;
      default:
        break;
    }
  };

  const handleReload = () => {
    setDeleteTableLoading(true);
    setChecked(false);
    setDeleteParams([]);
    handleSearch();
  };

  const handleSearch = () => {
    setSearchLoading(true);
    setDeleteWindow(true);
    setDeleteTableLoading(true);
    getRequest(
      `cs_del_search_get?user_id=${user_id}&current_quarter=${currentQuarter}&ccid=${
        selectedValue.gcmcommcodes ? selectedValue.gcmcommcodes.lookup_code : ""
      }&part=${
        selectedValue.gcmparts_hierarchy
          ? selectedValue.gcmparts_hierarchy.lookup_value
          : ""
      }&supplier_id=${
        selectedValue.suppliers ? selectedValue.suppliers.lookup_code : ""
      }`,
      handleProceed
    );
  };

  const handleProceed = ({ data }) => {
    if (data.items.length > 0) {
      let results = [...data.items];
      results.forEach((item, i) => {
        results[i]["checked"] = "N";
      });
      setDeleteTableData(results);
    } else {
      setDeleteTableData([]);
      alert("There are no submissions.");
    }
    setSearchLoading(false);
    setDeleteTableLoading(false);
  };

  const handleClear = () => {
    setSelectedValue({
      gcmcommcodes: null,
      gcmparts_hierarchy: null,
      suppliers: null,
    });
  };

  useEffect(() => {
    let deletedRecords = [];
    [...deleteTableData].map((item) => {
      if (item.checked === "Y") {
        return deletedRecords.push({
          csid: item.csid,
        });
      }
    });
    setDeleteParams(deletedRecords);
  }, [deleteTableData]);

  const handleCheckBox = (e, index, Object) => {
    const list = [...deleteTableData];
    if (e.value === true) {
      list[index]["checked"] = "Y";
    } else {
      list[index]["checked"] = "N";
    }
    setChecked(list.every((item) => item.checked === "Y"));
    setDeleteTableData(list);
  };

  const handleChangeAll = (e) => {
    setChecked(e.value);
    let list = [...deleteTableData];
    if (e.value === true) {
      list.forEach((item, i) => {
        list[i]["checked"] = "Y";
      });
      setDeleteTableData(list);
    } else {
      list.forEach((item, i) => {
        list[i]["checked"] = "N";
      });
      setDeleteTableData(list);
    }
  };

  const handleDltProceed = () => {
    setDeleteAlert(false);
    setDeleteTableLoading(true);
    put(
      `cs_delete_multiple?p_user_id=${user_id}`,
      {
        cost_lines: deleteParams,
      },
      deleteMultiRecords
    );
  };

  const deleteMultiRecords = ({ data }) => {
    if (data.p_out_status === "success") {
      alert(data.p_out_err_msg);
      handleReload();
    } else {
      alert(data.p_out_err_msg);
      setDeleteTableLoading(false);
    }
  };

  return (
    <div>
      <div className="window-container">
        <Window
          style={{ zIndex: "1", height: "295px", width: "300px" }}
          title={<WindowTitle title="Search" icon="k-i-zoom k-i-search" />}
          onClose={() => setDeleteSearchWindow(false)}
          minimizeButton={() => null}
          maximizeButton={() => null}
        >
          <React.Fragment>
            <div style={{ marginTop: "-15px" }}>
              {searchLoading ? <ProgressBar /> : ""}
            </div>
            <div className="searchWindow">
              <div>
                <label className="input-header-cell">Commodity Code:</label>
                <DropDownList
                  data={commCodesList}
                  filterable={true}
                  textField="lookup_value"
                  dataItemKey="lookup_value"
                  onFilterChange={getGCMCommCodes}
                  loading={commCodeLoading}
                  value={selectedValue.gcmcommcodes}
                  onChange={handleValues}
                />
              </div>
              <div>
                <label className="input-header-cell">Part Number:</label>
                <DropDownList
                  data={partList}
                  filterable={true}
                  textField="lookup_value"
                  onFilterChange={getPartNumber}
                  loading={partsLoading}
                  value={selectedValue.gcmparts_hierarchy}
                  onChange={handleValues}
                />
              </div>
              <div>
                <label className="input-header-cell">
                  Supplier Common Name:
                </label>
                <DropDownList
                  data={supplierList}
                  filterable={true}
                  textField="lookup_value"
                  onFilterChange={getSupplierName}
                  loading={supplierLoading}
                  value={selectedValue.suppliers}
                  onChange={handleValues}
                />
              </div>
            </div>
            <Button
              style={{ bottom: "-15px" }}
              size="small"
              title="Proceed"
              className="save-button"
              onClick={handleSearch}
              icon="k-i-zoom k-i-search"
              disabled={
                Object.values(selectedValue).length > 0 &&
                Object.values(selectedValue).filter(Boolean).length > 0
                  ? false
                  : true
              }
            >
              Search
            </Button>
            <Button
              style={{ bottom: "-15px" }}
              size="small"
              title="Clear"
              className="save-button"
              onClick={handleClear}
              disabled={
                Object.values(selectedValue).length > 0 &&
                Object.values(selectedValue).filter(Boolean).length > 0
                  ? false
                  : true
              }
            >
              Clear
            </Button>
          </React.Fragment>
        </Window>
      </div>
      {deleteWindow && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle
                title="Delete Multiple Submissions"
                icon="k-icon k-i-delete"
              />
            }
            initialHeight={455}
            initialWidth={835}
            style={{ zIndex: "1" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
            onClose={() => {
              setDeleteTableData([]);
              setChecked(false);
              setDeleteWindow(false);
            }}
          >
            <div style={{ marginTop: "-15px" }}>
              {deleteTableLoading ? <ProgressBar /> : ""}
            </div>
            <Button
              size="small"
              onClick={handleReload}
              className="app-button"
              title="Refresh"
              iconClass="k-icon k-i-reload"
            ></Button>
            <div>
              <div className="deleteTableHead">
                <div></div>
                <div>Commodity Code</div>
                <div>Part Number</div>
                <div>Supplier Name</div>
                <div>Manufacturer Name</div>
              </div>
            </div>
            <div className="excludeTableBody">
              {deleteTableData.length > 0 &&
                [...deleteTableData].map((item, i) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        className="example-col"
                      >
                        <Checkbox
                          checked={item.checked === "Y" ? true : false}
                          style={{
                            borderColor: "#666666",
                            pointerEvents: "auto",
                            marginLeft: "14px",
                            justifyContent: "center",
                          }}
                          defaultChecked={item.checked === "Y" ? true : false}
                          value={item.checked}
                          onChange={(e) => handleCheckBox(e, i, item)}
                          id={i}
                        />
                        <label
                          style={{
                            width: "193px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.commodity_code}
                        </label>
                        <label
                          style={{
                            width: "170px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.part}
                        </label>
                        <label
                          style={{
                            width: "230px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.supplier_common_name}
                        </label>
                        <label
                          style={{
                            width: "180px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.manufacturer}
                        </label>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                checked={checked}
                onChange={handleChangeAll}
                label="Delete All Records"
                style={{ fontWeight: "500", borderColor: "#666666" }}
                disabled={deleteTableData.length <= 0}
              />
              <Button
                size="small"
                title="Proceed"
                onClick={() => setDeleteAlert(true)}
                icon="delete"
                style={{ backgroundColor: "#228dff", color: "#fff" }}
                disabled={deleteParams.length <= 0}
              >
                Delete
              </Button>
            </div>
          </Window>
        </div>
      )}
      {deleteAlert && (
        <Dialog title="Delete Records" onClose={() => setDeleteAlert(false)}>
          <p
            style={{
              textAlign: "center",
              width: "464px",
            }}
          >
            Cost Submission entry and the associated Cost Allocations will be
            deleted. Do you want to proceed?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setDeleteAlert(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleDltProceed}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
export default CostSubmissionDelete;
