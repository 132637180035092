import React, { useEffect, useState, useContext } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { UserContext } from "../../../App";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { Hint } from "@progress/kendo-react-labels";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { debounce } from "lodash";
import "./ReductionInitAddPopUp.css";
import { getRequest, put, post } from "../../../services/api.request";
import privateRequest from "../../../services/api.config";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { getSelectedState } from "@progress/kendo-react-grid";

import LovDataPopUp from "../../../components/LovDataPopUp/LovDataPopUp";
const DATA_ITEM_KEY = "part";

const initialDataState = {
  skip: 0,
  take: 10,
};

const ReductionInitPopUp = ({
  toggleDialog,
  mode,
  selectedData,
  dataLabel,
  reloadGrid,
  initsData1,
}) => {
  const limit = 10;

  const [page, setPage] = React.useState(initialDataState);

  const [chk, setChk] = React.useState([]);
  const [ItemDes, setItemDes] = React.useState("");
  const [saveBtn, setSaveBtn] = useState(true);
  const [ProposedChg, setProposedChg] = React.useState("");
  const [Remarks, setRemarks] = React.useState("");
  const [ItemVal, setItemVal] = React.useState("");
  const [qtrVal1, setQtrVal1] = React.useState(null);
  const [qtrVal2, setQtrVal2] = React.useState(null);
  const [qtrVal3, setQtrVal3] = React.useState(null);
  const [qtrVal4, setQtrVal4] = React.useState(null);
  const [qtrVal5, setQtrVal5] = React.useState(null);
  const [qtrVal6, setQtrVal6] = React.useState(null);
  const [qtrVal7, setQtrVal7] = React.useState(null);
  const [qtrVal8, setQtrVal8] = React.useState(null);
  const [SavingType, setSavingType] = React.useState("");
  const [payload, setPayload] = React.useState({});
  const [payloadInit, setPayloadInit] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitLoading, setIsInitLoading] = React.useState(false);
  const [payloadItem, setPayloadItem] = React.useState({});
  const [isItemLoading, setIsItemLoading] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState({});
  const [filterValue, setFilterValue] = React.useState({});

  const [filterInit, setFilterInit] = React.useState({});
  const [statType, setStatType] = React.useState("Not Started");
  const [probLevel, setprobLevel] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [popUpData, setPopUpData] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [disCommCode, setDisCommCode] = useState(false);
  const [typeValue, setTypeValue] = useState();
  const [loading2, setLoading2] = useState(false);
  const [buVal, setBuVal] = React.useState("");

  const [commCodesList, setCommCodesList] = useState([]);
  const [commCodeLoading, setCommCodeLoading] = useState(false);
  const [selectedCommCode, setSelectedCommCode] = useState();
  const [selectedCommCodeID, setSelectedCommCodeID] = useState(null);

  const userContext = useContext(UserContext);
  const statusPresent = [
    "Not Started",
    "Committed",
    "Completed",
    "Rejected",
    "Ongoing",
    "Cancelled",
  ];
  const probs = ["High", "Low", "Medium"];
  const bu = ["HDD", "Flash"];
  useEffect(() => {
    if (selectedData != null) {
      setItemVal(selectedData.item);
      setItemDes(selectedData.idea_name);
      setBuVal(selectedData.bu);
      setFilterValue({ lookup_value: selectedData.product_name });
      setFilterInit({ lookup_value: selectedData.initiative_type });
      setFilterItems({ wd_part_number: selectedData.item });
      setSearchVal(selectedData.item);
      setSavingType(selectedData.saving_type);
      setprobLevel(selectedData.probability_level);
      setStatType(selectedData.status_level);
      setProposedChg(selectedData.proposed_change);
      setSelectedCommCode({ lookup_value: selectedData.commodity_code });
      if (selectedData.saving_type === "Run Rate Dollar Saving") {
        setQtrVal1(selectedData.q1_rr);
        setQtrVal2(selectedData.q2_rr);
        setQtrVal3(selectedData.q3_rr);
        setQtrVal4(selectedData.q4_rr);
        setQtrVal5(selectedData.q5_rr);
        setQtrVal6(selectedData.q6_rr);
        setQtrVal7(selectedData.q7_rr);
        setQtrVal8(selectedData.q8_rr);
      }
      if (selectedData.saving_type === "Run Rate Unit Saving") {
        setQtrVal1(selectedData.q1_us);
        setQtrVal2(selectedData.q2_us);
        setQtrVal3(selectedData.q3_us);
        setQtrVal4(selectedData.q4_us);
        setQtrVal5(selectedData.q5_us);
        setQtrVal6(selectedData.q6_us);
        setQtrVal7(selectedData.q7_us);
        setQtrVal8(selectedData.q8_us);
      }
      setRemarks(selectedData.remarks);
    }
  }, [selectedData]);
  useEffect(() => {
    let arr = [];
    initsData1?.forEach((element) => {
      if (element.lookup_value != null) {
        arr.push({
          lookup_value: element.lookup_value,
          lookup_code: element.lookup_code,
        });
      }
    });
    setChk(arr);
    if (mode === "Add") {
      setItemVal("");
      setItemDes("");
      setFilterInit({ lookup_value: "" });
      setFilterItems({ wd_part_number: "" });
      //setFilterValue({ lookup_value: '' })
      setprobLevel("");
      //setSavingType('')
      setProposedChg("");
      setStatType("");
      setBuVal("");
      setQtrVal1(null);
      setQtrVal2(null);
      setQtrVal3(null);
      setQtrVal4(null);
      setQtrVal5(null);
      setQtrVal6(null);
      setQtrVal7(null);
      setQtrVal8(null);
      setRemarks("");
    }
  }, [mode, initsData1, searchVal]);

  /* --------------------------------------- */
  const dataPopUp = debounce((e) => {
    var myString = e.target.value;
    var stringLength = myString.length;
    if (stringLength >= 4) {
      let url =
        selectedCommCodeID === null
          ? `/itemlov_ro?search_for=${myString}&offset=0&limit=${limit}`
          : `/item_lov_cc_ro?search_for=${myString}&ccid=${selectedCommCodeID}&offset=0&limit=${limit}`;
      getRequest(url, itemsPop);
      setTypeValue(myString);
      setPage(initialDataState);
      setLoading2(true);
    }
    setDisCommCode(stringLength >= 4);
  }, 1000);
  const itemsPop = ({ data }, type) => {
    setPopUpData(data.items);
    setLoading2(false);
  };
  const [selectedState, setSelectedState] = React.useState({});
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const handleRowClick = (e) => {
    setSavingType("Run Rate Unit Saving");

    setFilterValue("");
    if (e.dataItem.part) {
      setSearchVal(e.dataItem.part);
      setTimeout(() => {
        setDisCommCode(false);
      }, 1000);
      setLoading2(true);
    }
  };
  const clearItemInput = () => {
    setSearchVal();
    setDisCommCode(true);
    setLoading2(false);
  };

  const handlePageChange = (e) => {
    let url =
      selectedCommCodeID === null
        ? `/itemlov_ro?search_for=${typeValue}&offset=${e.page.skip}&limit=${limit}`
        : `/item_lov_cc_ro?search_for=${typeValue}&ccid=${selectedCommCodeID}&offset=${e.page.skip}&limit=${limit}`;
    getRequest(url, itemsPop);
    setLoading2(true);
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  /* --------------------------------------- */

  const handleFilterRequest = (data) => {
    setPayload(data.items);
    setIsLoading(false);
  };
  const handleFilterRequestInit = (data) => {
    let arr = [];
    data.items.forEach((element) => {
      if (element.lookup_value != null) {
        arr.push({
          lookup_value: element.lookup_value,
          lookup_code: element.lookup_code,
        });
      }
    });
    setPayloadInit(arr);
    setIsInitLoading(false);
  };

  const handleFilterRequestItems = (data) => {
    setPayloadItem(data.items);
    setIsItemLoading(false);
  };
  const clearAll = () => {
    setItemVal("");
    setSelectedCommCodeID(null);
    setSelectedCommCode({ lookup_value: "" });
    setItemDes("");
    setFilterInit({ lookup_value: "" });
    setFilterValue({ lookup_value: "" });
    setFilterItems({ wd_part_number: "" });
    setSavingType("");
    setProposedChg("");
    setBuVal("");
    setStatType("");
    setQtrVal1("");
    setQtrVal2("");
    setQtrVal3("");
    setQtrVal4("");
    setQtrVal5("");
    setQtrVal6("");
    setQtrVal7("");
    setQtrVal8("");
  };
  const filterChange = debounce(({ filter }) => {
    setIsLoading(true);
    let url = `/generic_lov_service/productname/?search_for=${filter.value}&lookup_type=productname`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        handleFilterRequest(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, 1000);

  const ProdChg = (e) => {
    setFilterValue(e.target.value);
    setSavingType("Run Rate Dollar Saving");
    setItemVal("");
   // setSelectedCommCode("");
    setDisCommCode(false);
    setSearchVal();
  };

  const InitChange = debounce(({ filter }) => {
    setIsInitLoading(true);
    let url = `/generic_lov_two?lookup_type=RO_INIT_TYPE&search_for=${filter.value}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        handleFilterRequestInit(data);
      })
      .catch((err) => {
        setIsInitLoading(false);
      });
  }, 1000);
  const saveInitiative = () => {
    setLoading(true);
    setSaveBtn(false);
    let payload;
    if (mode === "Add") {
      if (SavingType === "Run Rate Dollar Saving") {
        payload = {
          gcm: userContext.user_name,
          gcm_id: userContext.user_id,
          product_name: filterValue.lookup_value,
          item: searchVal,
          idea_name: ItemDes,
          bu: buVal,
          proposed_change: ProposedChg,
          initiative_type: filterInit.lookup_value,
          saving_type: SavingType,
          probability_level: probLevel,
          status_level: statType,
          initiated_quarter: userContext.curr_qrtr,
          remarks: Remarks,
          q1_rr: qtrVal1,
          q2_rr: qtrVal2,
          q3_rr: qtrVal3,
          q4_rr: qtrVal4,
          q5_rr: qtrVal5,
          q6_rr: qtrVal6,
          q7_rr: qtrVal7,
          q8_rr: qtrVal8,
          ccid: selectedCommCodeID,
          commodity_code: selectedCommCode && selectedCommCode.lookup_value
        };
      } else if (SavingType === "Run Rate Unit Saving") {
        payload = {
          gcm: userContext.user_name,
          gcm_id: userContext.user_id,
          product_name: filterValue.lookup_value,
          item: searchVal,
          bu: buVal,
          idea_name: ItemDes,
          proposed_change: ProposedChg,
          initiative_type: filterInit.lookup_value,
          saving_type: SavingType,
          probability_level: probLevel,
          status_level: statType,
          initiated_quarter: userContext.curr_qrtr,
          remarks: Remarks,
          q1_us: qtrVal1,
          q2_us: qtrVal2,
          q3_us: qtrVal3,
          q4_us: qtrVal4,
          q5_us: qtrVal5,
          q6_us: qtrVal6,
          q7_us: qtrVal7,
          q8_us: qtrVal8,
          ccid: selectedCommCodeID,
          commodity_code:selectedCommCode && selectedCommCode.lookup_value
        };
      }
      post(`/ro_cogs_post_put `, payload, ({ data }) => {
        if (data.hasOwnProperty("p_out_err_msg")) {
          alert(data.p_out_err_msg);
          setLoading(false);
          toggleDialog();
          reloadGrid();
        }
      });
    } else if (mode === "Edit") {
      if (SavingType === "Run Rate Dollar Saving") {
        payload = {
          roid: selectedData.roid,
          status_level: statType,
          initiated_quarter: selectedData.initiated_quarter,
          remarks: Remarks,
          bu: buVal,
          saving_type: SavingType,
          probability_level: probLevel,
          initiative_type: filterInit.lookup_value,
          q1_rr: qtrVal1,
          q2_rr: qtrVal2,
          q3_rr: qtrVal3,
          q4_rr: qtrVal4,
          q5_rr: qtrVal5,
          q6_rr: qtrVal6,
          q7_rr: qtrVal7,
          q8_rr: qtrVal8,
          selectedCommCode: selectedData.commodity_code,
        };
      } else if (SavingType === "Run Rate Unit Saving") {
        payload = {
          roid: selectedData.roid,
          status_level: statType,
          initiated_quarter: selectedData.initiated_quarter,
          remarks: Remarks,
          bu: buVal,
          saving_type: SavingType,
          probability_level: probLevel,
          initiative_type: filterInit.lookup_value,
          q1_us: qtrVal1,
          q2_us: qtrVal2,
          q3_us: qtrVal3,
          q4_us: qtrVal4,
          q5_us: qtrVal5,
          q6_us: qtrVal6,
          q7_us: qtrVal7,
          q8_us: qtrVal8,
          selectedCommCode: selectedData.commodity_code,
        };
      }
      put(`/ro_cogs_post_put `, payload, ({ data }) => {
        if (data.hasOwnProperty("p_out_err_msg")) {
          alert(data.p_out_err_msg);
          setLoading(false);
          toggleDialog();
          reloadGrid();
        }
      });
    }
  };
  const filterCommodityCodeChng = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value });
      });
    }
    if (field) {
      setCommCodesList({ ...commCodesList, [field]: items });
    }
    setCommCodeLoading(false);
  };
  const getGCMCommCodes = debounce(({ filter }) => {
    setCommCodeLoading(true);
    let url = `/generic_lov_service/commoditycode/?search_for=${filter.value}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        filterCommodityCodeChng(data, "commoditycode");
      })
      .catch((err) => {
        setCommCodeLoading(false);
      });
  }, 1000);
  const handleCommCode = (e) => {
    let obj = e.value;
    setSelectedCommCode(e.value);
    setSelectedCommCodeID(obj.lookup_code);
     if(searchVal !== undefined){
      setSearchVal();
        setTypeValue("")
     }
  };

  return (
    <div className="window-container">
      <Window
        title={
          <WindowTitle title={mode + " Initiative"} icon="k-i-table-cell" />
        }
        initialHeight={570}
        initialWidth={956}
        onClose={() => toggleDialog()}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        {loading ? (
          <ProgressBar style={{ marginTop: "-20px !important" }} />
        ) : (
          ""
        )}
        <Form
          render={() => (
            <FormElement>
              <div className="cont" style={{ marginTop: "-20px" }}>
                <div>
                  <Hint
                    direction={"start"}
                    style={{
                      position: "absolute",
                      left: 16,
                      top: 0,
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Select any one from Product Name or Item{" "}
                    <sup style={{ color: "#f31700" }}>*</sup>
                  </Hint>
                  <div
                    style={{
                      border: "1px solid",
                      marginTop: "25px",
                      display: "grid",
                      gridTemplateColumns: "50px 50px",
                      columnGap: "50%",
                    }}
                  >
                    <FieldWrapper>
                      <div
                        className="input-container input-first-cell"
                        style={{
                          marginTop: "-10px",
                          marginLeft: "10px",
                          marginBottom: "-10px",
                        }}
                      >
                        <div className="input-first">
                          <label className="input-header-cell">
                            Commodity Code:
                          </label>
                        </div>
                        <div className="input-second">
                          <DropDownList
                            style={{ minWidth: "170px" }}
                            data={
                              Object.keys(commCodesList).length > 0
                                ? commCodesList["commoditycode"]
                                : []
                            }
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={getGCMCommCodes}
                            loading={commCodeLoading}
                            value={selectedCommCode}
                            onChange={handleCommCode}
                            disabled={
                              mode === "Edit"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </FieldWrapper>
                    <FieldWrapper>
                      <div
                        className="input-container input-first-cell"
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      >
                        <div className="input-first">
                          <label className="input-header-cell">
                            Product Name:
                          </label>
                        </div>
                        <div className="input-second">
                          <DropDownList
                            style={{ minWidth: "170px" }}
                            data={
                              Object.keys(payload).length > 0 ? payload : []
                            }
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={filterChange}
                            loading={isLoading}
                            value={filterValue}
                            onChange={(e) => ProdChg(e)}
                            disabled={mode === "Edit" || searchVal !== undefined}
                          />
                        </div>
                      </div>
                    </FieldWrapper>
                    <FieldWrapper>
                      <div
                        className="input-container input-second-cell"
                        style={{ marginBottom: "5px", marginLeft: "10px" }}
                      >
                        <div className="input-first">
                          <label className="input-header-cell">
                            {" "}
                            Saving Type:{" "}
                          </label>{" "}
                        </div>
                        <div className="input-second">
                          <Input
                            style={{ minWidth: "170px" }}
                            id="savingType"
                            name="SavingType"
                            disabled={mode === "Edit"}
                            readOnly={true}
                            value={SavingType}
                            validationMessage={"This field is required"}
                          />
                        </div>
                      </div>
                    </FieldWrapper>
                    <FieldWrapper>
                      <div className="input-container input-second-cell">
                        <div className="input-first">
                          <label className="input-header-cell">Item:</label>
                        </div>
                        <div
                          className="input-second"
                          style={{ display: "flex" }}
                        >
                          <Input
                            style={{ minWidth: "170px" }}
                            textField="wd_part_number"
                            dataItemKey="wd_part_number"
                            placeholder="Search..."
                            value={searchVal}
                            onChange={(e) => {
                              dataPopUp(e);
                            }}
                            disabled={
                              mode === "Edit" || Object.values(filterValue).length > 0
                                ? true
                                : false
                            }
                          />
                          {searchVal === undefined ? (
                            <div
                              style={{
                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                padding: "5px 2px",
                                marginleft: "-3px",
                              }}
                              title="List Of Item"
                              class="k-icon k-i-list-unordered"
                            ></div>
                          ) : (
                            <Button
                              class="k-icon k-i-close"
                              title="Change The Selected Value"
                              disabled={mode === "Edit"}
                              style={{ marginLeft: "-3px" }}
                              onClick={clearItemInput}
                            >
                              &#10006;
                            </Button>
                          )}
                          <Hint>{"At least type 4 characters"}</Hint>
                        </div>
                      </div>
                    </FieldWrapper>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "25px",
                    marginLeft: "-10px",
                    marginTop: "13px",
                  }}
                >
                  <FieldWrapper
                    style={{
                      width: "76%",
                      marginLeft: "61px",
                      marginTop: "-7px",
                      marginBottom: "13px",
                    }}
                  >
                    <label
                      className="input-header-cell"
                      style={{ marginLeft: "-35px" }}
                    >
                      Idea Name<sup style={{ color: "#f31700" }}>*</sup>:
                    </label>
                    <Input
                      style={{ width: "560px", marginLeft: "3px" }}
                      id="itemDescription"
                      name="itemDescription"
                      maxlength={120}
                      onChange={(e) => {
                        setItemDes(e.target.value);
                      }}
                      disabled={mode === "Edit"}
                      value={ItemDes}
                      validationMessage={"This field is required"}
                    />
                    <Hint style={{ position: "absolute", right: "306px" }}>
                      {ItemDes != null ? ItemDes.length : 0} /{"120"}
                    </Hint>
                  </FieldWrapper>
                  <FieldWrapper
                    style={{
                      width: "24%",
                      marginLeft: "9px",
                      marginTop: "-7px",
                      marginBottom: "13px",
                    }}
                  >
                    <label
                      className="input-header-cell"
                      style={{ marginLeft: "-29px" }}
                    >
                      BU:
                    </label>
                    <DropDownList
                      style={{ width: "150px", marginLeft: "3px" }}
                      data={bu}
                      value={buVal}
                      onChange={(e) => setBuVal(e.target.value)}
                    />
                  </FieldWrapper>
                </div>
                <div
                  className="input-container  input-first-cell "
                  style={{ marginTop: "-1px" }}
                >
                  <div className="input-first" style={{ marginLeft: "-34px" }}>
                    <label
                      className="input-header-cell"
                      style={{ marginRight: 30, marginLeft: 25 }}
                    >
                      Proposed Change<sup style={{ color: "#f31700" }}>*</sup>:
                    </label>
                  </div>
                  <div className="input-second" style={{ marginLeft: "-35px" }}>
                    <Input
                      style={{ width: "445%" }}
                      id="ProposedChg"
                      name="ProposedChg"
                      maxlength={300}
                      onChange={(e) => {
                        setProposedChg(e.target.value);
                      }}
                      disabled={mode === "Edit"}
                      value={ProposedChg}
                      validationMessage={"This field is required"}
                    />
                    <Hint style={{ position: "absolute", right: "1px" }}>
                      {ProposedChg != null ? ProposedChg.length : 0} /{"300"}
                    </Hint>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    marginLeft: "-1px",
                    marginTop: "-3px",
                  }}
                >
                  <FieldWrapper style={{ width: "30%", marginLeft: "16px" }}>
                    <label className="input-header-cell">
                      Initiative Type<sup style={{ color: "#f31700" }}>*</sup>:
                    </label>
                    <DropDownList
                      style={{
                        width: "178px",
                        marginLeft: "101px",
                        marginTop: "-37px",
                      }}
                      data={
                        Object.keys(payloadInit).length > 0 ? payloadInit : chk
                      }
                      filterable={true}
                      textField="lookup_value"
                      dataItemKey="lookup_value"
                      onFilterChange={InitChange}
                      loading={isInitLoading}
                      value={filterInit}
                      onChange={(e) => setFilterInit(e.target.value)}
                      required={true}
                    />
                  </FieldWrapper>
                  <FieldWrapper style={{ width: "30%", marginLeft: "35px" }}>
                    <label
                      className="input-header-cell"
                      style={{ marginLeft: "-29px" }}
                    >
                      Probability Level<sup style={{ color: "#f31700" }}>*</sup>
                      :
                    </label>
                    <DropDownList
                      style={{ width: "178px", marginLeft: "3px" }}
                      data={probs}
                      value={probLevel}
                      onChange={(e) => setprobLevel(e.target.value)}
                    />
                  </FieldWrapper>
                  <FieldWrapper style={{ width: "30%" }}>
                    <label className="input-header-cell">
                      Status Type<sup style={{ color: "#f31700" }}>*</sup>:
                    </label>
                    <DropDownList
                      data={statusPresent}
                      name="statusPresent"
                      id="statusPresent"
                      value={statType}
                      onChange={(e) => setStatType(e.target.value)}
                      style={{ width: "178px", marginLeft: "3px" }}
                    />
                  </FieldWrapper>
                </div>
              </div>
            </FormElement>
          )}
        />
        <br></br>
        <div
          className="container"
          style={{ marginTop: "-25px", marginLeft: "29px" }}
        >
          <div
            className="input-container input-first-cell "
            style={{ gridRowGap: "2px", marginLEft: "22px" }}
          >
            <div className="input-first " style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[0].quarter_name
                    : ""
                  : selectedData.q1}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr1"
                min={-999999999}
                max={999999999}
                value={qtrVal1}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal1(e.target.value);
                  } else {
                    setQtrVal1(null);
                  }
                }}
              />
            </div>
            <div className="input-first " style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[1].quarter_name
                    : ""
                  : selectedData.q2}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr2"
                min={-999999999}
                max={999999999}
                value={qtrVal2}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal2(e.target.value);
                  } else {
                    setQtrVal2(null);
                  }
                }}
              />
            </div>
            <div className="input-first " style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[2].quarter_name
                    : ""
                  : selectedData.q3}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr3"
                max={999999999}
                min={-999999999}
                value={qtrVal3}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal3(e.target.value);
                  } else {
                    setQtrVal3(null);
                  }
                }}
              />
            </div>
            <div className="input-first" style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[3].quarter_name
                    : ""
                  : selectedData.q4}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                max={999999999}
                min={-999999999}
                name="qtr4"
                value={qtrVal4}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal4(e.target.value);
                  } else {
                    setQtrVal4(null);
                  }
                }}
              />{" "}
            </div>
            <div className="input-first" style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[4].quarter_name
                    : ""
                  : selectedData.q5}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr5"
                max={999999999}
                min={-999999999}
                value={qtrVal5}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal5(e.target.value);
                  } else {
                    setQtrVal5(null);
                  }
                }}
              />{" "}
            </div>
            <div className="input-first" style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[5].quarter_name
                    : ""
                  : selectedData.q6}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr6"
                max={999999999}
                min={-999999999}
                value={qtrVal6}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal6(e.target.value);
                  } else {
                    setQtrVal6(null);
                  }
                }}
              />
            </div>
            <div className="input-first" style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[6].quarter_name
                    : ""
                  : selectedData.q7}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr7"
                max={999999999}
                min={-999999999}
                value={qtrVal7}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal7(e.target.value);
                  } else {
                    setQtrVal7(null);
                  }
                }}
              />
            </div>
            <div className="input-first" style={{ marginTop: "5px" }}>
              <label className="input-header-cell">
                {mode === "Add"
                  ? dataLabel
                    ? dataLabel[7].quarter_name
                    : ""
                  : selectedData.q8}
              </label>
            </div>
            <div className="input-second">
              <NumericTextBox
                format={"n4"}
                name="qtr8"
                min={-999999999}
                max={999999999}
                value={qtrVal8}
                onChange={(e) => {
                  if (
                    e.target.value > -999999999 &&
                    e.target.value <= 999999999
                  ) {
                    setQtrVal8(e.target.value);
                  } else {
                    setQtrVal8(null);
                  }
                }}
              />{" "}
            </div>
          </div>
          <div
            className="input-container  input-second-cell inputCont"
            style={{ gridRowGap: "4px" }}
          >
            <div className="input-first">
              <label
                className="input-header-cell"
                style={{ marginLeft: "-35px" }}
              >
                Remarks:
              </label>
            </div>
            <div className="input-second" style={{ marginLeft: "-2px" }}>
              <TextArea
                style={{ width: "488px", height: "190px" }}
                maxlength={300}
                id="remarks"
                name="remarks"
                onChange={(e) => setRemarks(e.value)}
                value={Remarks}
              />
              <Hint
                direction={"end"}
                style={{
                  position: "absolute",
                  right: 33,
                }}
              >
                {Remarks != null ? Remarks.length : 0} /{"300"}
              </Hint>
            </div>
          </div>
        </div>
        <div
          style={{ float: "right", marginRight: "36px", marginTop: "-15px" }}
        >
          <Button
            onClick={saveInitiative}
            icon="save"
            disabled={
              (filterValue.lookup_value === "" && ItemVal === "") ||
              filterInit.lookup_value === "" ||
              probLevel === "" ||
              ItemDes === "" ||
              SavingType === "" ||
              ProposedChg === "" ||
              statType === "" ||
              saveBtn === false
                ? true
                : false
            }
            className="save-button"
          >
            Save
          </Button>
          {mode === "Add" ? (
            <Button onClick={clearAll} icon="close" className="close">
              Clear
            </Button>
          ) : (
            ""
          )}
        </div>

        {/* ----------------Items LOV PopUp-------------------- */}

        {disCommCode === true ? (
          <LovDataPopUp
            resultSet={popUpData}
            handlePage={(e) => handlePageChange(e)}
            onSelectionChange={onSelectionChange}
            onRowClick={(e) => handleRowClick(e)}
            page={page}
            limit={limit}
            selectedState={selectedState}
            dataKey={DATA_ITEM_KEY}
          />
        ) : (
          ""
        )}
      </Window>
    </div>
  );
};

export default ReductionInitPopUp;
