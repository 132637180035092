import React, { useContext, useState } from "react";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs"
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { FormElement } from "@progress/kendo-react-form";
import FileUploader from "../../../components/FileUploader/FileUploader";
import { Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
 import "./UploadReductionInitiative.css"
import UpdateCOGSInitiativeUpload from "./UpdateCOGSInitiativeUpload";
import { UserContext } from "../../../App";
import { getRequest, post } from "../../../services/api.request";

const UploadReductionInitiative= ({  toggleWizard, setLoading } )=>{
    const userContext = useContext(UserContext);
    const [uploadLoading, setuploadLoading] = useState(false);
    const [uploadMessageDialog, setUploadMessageDialog] = useState(false);
    const [override, setOverride] = useState(true);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [clearFileUploadForm, setClearFileUploadForm] = React.useState(true);
    const [viewUploadStatus,setViewUploadStatus] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);

   const onSwitchChangeForUploadInitiative = (e)=>{
   if (e.value === false){
    setOverride(e.value);
    setViewUploadStatus(true);
   }
   setOverride(e.value);
   }

   const readUploadFile = (e) => {
    var fileInput = document.getElementById("upload");
    var filePath = e.target.value;
    var allowedExtensions = /(\.xlsx|\.xls)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type");
      fileInput.value = "";
      return false;
    } else {
      e.preventDefault();
      if (e.target.files && e.target.files?.length > 0) {
        let fileName = e.target.files[0].name;
        setFile(e.target.files[0]);
        setFileName(
          fileName.substring(0, fileName.lastIndexOf(".")) || fileName
        );
        setClearFileUploadForm(false)
      }
      return true;
    }
  };

  const clearUploadReductionInitiative =()=>{
    setOverride(true);
    setFileName("");
    setFile(null);
    setClearFileUploadForm(true);
    
}
const checkInitiativeUpload = () => {
  setuploadLoading(true);
  setDisabledBtn(true);
  getRequest(
    `upload_allow_yn?user_id=${userContext?.user_id}&source_name=RO COGS REDUCTION`,
    saveInitiativeUpload
  );
};
const saveInitiativeUpload =({data})=>{
  if (data?.allow_upload === "N") {
    setUploadMessageDialog(true);
  } else {
    // setSaveFlag(!saveFlag);
    setLoading(true)
    const formData = new FormData();
    formData.append("body", file);
    toggleWizard();
    let url = `media/?file_name=${fileName}&source_name=${"RO COGS REDUCTION"}&user_id=${userContext.user_id}&current_qtr=${userContext.curr_qrtr}&upload_initiative=${'N'}`
    post(url,formData,({ data }) => {
      if(data.p_out_status === "Success"){
        alert("File Upload started with id:" + data.image_id);
        setDisabledBtn(false);
      }
      else{
        alert(data.p_out_err_msg);
      }
        setLoading(false);
    });
}
}
   const toggleForViewDialog = ()=>{
    setViewUploadStatus(!viewUploadStatus);
  }
  const labelOverride = () =>{
    setOverride(!override);
  }
  const toggleConfirmUpload = () => {
    setUploadMessageDialog(false);
  };
  const handleConfirmUpload = () => {
    setUploadMessageDialog(false);
    toggleWizard();
  };
    return (
            <div>
                <div className="window-container">
                    <Window
                        width={450}
                        height={300}
                        minimizeButton={() => null}
                        maximizeButton={() => null}
                        title="Upload Initiative"
                        onClose={ () => toggleWizard()}>
             <div  style={{   margin: "30px 12px 30px 15px"}} >
            <div style={{ marginTop: "-39px", marginBottom: "9px" }}>
              {uploadLoading ? <ProgressBar /> : ""}
            </div>
            <FormElement>
            <div className="form-element" >
            <label className="input-header-cell">Upload Initiative Type:</label>
                  <Switch
                    className="switch"
                    width={200}
                    onChange={onSwitchChangeForUploadInitiative}
                    defaultChecked={override}
                    checked = {override}
                    onLabel={"New"}
                    offLabel={"Update"}
                  />
                </div>
              <div className="form-element" style={{marginTop:'30px'}}>
                <label className="input-header-cell">Upload File*:</label>
                <FileUploader
                onUpload={readUploadFile} 
                />
              </div>
              <span className="selectedFileName"
            style={{marginTop:'18px'}}> 
                {fileName ? file.name : "No file selected"}
              </span>
            </FormElement>
          </div>
          <div style={{ marginTop: 15, width: "100%"}}>
            <div style={{ display: 'flex', float: 'right' }}>
            <Button
            onClick={checkInitiativeUpload}
              className="save-button"
              disabled={!file ||disabledBtn } >
              <span className="k-icon k-i-upload"> </span> Upload
            </Button>
            </div>
            <div style={{ display: 'flex', float: 'left' }}>
            <Button 
             onClick={clearUploadReductionInitiative}
            disabled = {!file && override} 
              className="save-button"> Clear
            </Button>
            </div>
          </div>
                    </Window>
                </div>
                {
                    viewUploadStatus && (
                        <UpdateCOGSInitiativeUpload 
                        toggleForViewDialog = {toggleForViewDialog} labelOverride = {labelOverride} view = {true}>
                        </UpdateCOGSInitiativeUpload>
                    )
                }
                  {uploadMessageDialog && (
        <Dialog
          title={"COGS Reduction - Upload"}
          onClose={toggleConfirmUpload}
          width={390}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your Existing uploads awaiting import. Please import and try to
            upload again.
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmUpload}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
            </div>
    )
}

export default UploadReductionInitiative