import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useRef, useState } from "react";
import FilterPopUp from "../../../components/FilterPopUp/FilterPopUp";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { getRequest, post } from "../../../services/api.request";

const paginationData = {
  skip: 0,
  take: 10,
};

const ImportComponent = ({
  setShowImportTable,
  quarterNames,
  media_ID,
  showImportTable,
  user_id,
  setImportedFlag,
  donwload_ID,
}) => {
  const limit = 10;
  const FILTER_PARAMS = [
    {
      paramName: "gcmcommcodes",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
    },
    {
      paramName: "gpn",
      displayValue: "Group Part Number",
      id_field: "gpn",
      api_field: "gpn",
    },
    {
      paramName: "gcmparts_hierarchy",
      displayValue: "Part Number",
      id_field: "part",
      api_field: "part",
    },
    {
      paramName: "loadstatus",
      displayValue: "Status",
      id_field: "load_status",
      api_field: "load_status",
      filterable: false,
    },
  ];

  const [importDetailsTable, setImportDetailsTable] = useState([]);
  const [importTableLoading, setImportTableLoading] = useState(false);
  const [page, setPage] = useState(paginationData);
  const [show, setShow] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [sort, setSort] = useState([]);
  const anchor = useRef(null);
  const getValidStatus = useRef(false);

  const gridWidth = window.innerWidth * 0.825;
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };

  const fetchImportDetails = () => {
    setImportTableLoading(true);
    getRequest(
      `get_ca_upload_filter?media_id=${media_ID}` +
        "&offset=" +
        page.skip +
        "&limit=" +
        limit,
      getImportTableData
    );
  };

  useEffect(() => {
    fetchImportDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showImportTable]);

  const getImportTableData = ({ data }) => {
    data && setImportDetailsTable(data.items);
    getValidStatus.current = data.items.some(
      (item) => item.load_status === "Valid" || item.load_status === "Existing"
    );
    setImportTableLoading(false);
  };

  const reloadImportTable = () => {
    fetchImportDetails();
    setIsFilter(false);
    setSort([]);
  };

  const handlePageChange = (e) => {
    setImportTableLoading(true);
    let url = `get_ca_upload_filter?media_id=${media_ID}&offset=`;
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (isFilter === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? url + e.page.skip + "&limit=" + e.page.take
          : url +
            e.page.skip +
            "&q=" +
            encodeURIComponent(queryString) +
            "&limit=" +
            e.page.take;
      getRequest(urlWithoutFilter, getImportTableData);
    }
    if (isFilter === true) {
      let urlWithFilter;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "gcmparts_hierarchy") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gcmcommcodes") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gpn") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else {
            let urlAppendCharacter = url.includes("?") ? `&` : `?`;
            url +=
              filterValue[param.id_field][param.id_field] === "All"
                ? `${urlAppendCharacter}${param.api_field}=`
                : `${urlAppendCharacter}${param.api_field}=${
                    filterValue[param.id_field][param.id_field]
                  }`;
          }
        }
      });
      urlWithFilter =
        sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
        url = urlWithFilter + `&offset=${e.page.skip}&limit=${e.page.take}`;
      } else {
        url = urlWithFilter + `?offset=${e.page.skip}&limit=${e.page.take}`;
      }
      getRequest(url, getImportTableData);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const openPopover = (event) => {
    setShow(true);
  };

  const onSubmit = () => {
    setPage(paginationData);
    setImportTableLoading(true);
    let url = `/get_ca_upload_filter?media_id=${media_ID}`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        if (param.paramName === "gcmparts_hierarchy") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        } else if (param.paramName === "gcmcommcodes") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        } else if (param.paramName === "gpn") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        } else {
          let urlAppendCharacter = url.includes("?") ? `&` : `?`;
          url +=
            filterValue[param.id_field][param.id_field] === "All"
              ? `${urlAppendCharacter}${param.api_field}=`
              : `${urlAppendCharacter}${param.api_field}=${
                  filterValue[param.id_field][param.id_field]
                }`;
        }
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getImportTableData);
    Object.keys(filterValue).length > 0
      ? setIsFilter(true)
      : setIsFilter(false);
    setShow(false);
  };

  const importTableData = () => {
    setImportTableLoading(true);
    post(`costalloc_importupload/${media_ID}/${user_id}`, {}, handleSave);
  };

  const handleSave = ({ data }) => {
    if (data.p_out_status === "Success") {
      setImportedFlag(true);
      alert(data.p_out_err_msg);
    } else alert(data.p_out_err_msg);
    setImportTableLoading(false);
    setShowImportTable(false);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    setIsFilter(false);
    setShow(false);
  };

  const handleSortChange = (e) => {
    setImportTableLoading(true);
    let fetchURL = `get_ca_upload_filter?media_id=${media_ID}`;
    if (isFilter === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          fetchURL +
            "&q=" +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getImportTableData
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          fetchURL +
            "&q=" +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getImportTableData
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else if (isFilter === true) {
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "gcmparts_hierarchy") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gcmcommcodes") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gpn") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else {
            let urlAppendCharacter = url.includes("?") ? `&` : `?`;
            url +=
              filterValue[param.id_field][param.id_field] === "All"
                ? `${urlAppendCharacter}${param.api_field}=`
                : `${urlAppendCharacter}${param.api_field}=${
                    filterValue[param.id_field][param.id_field]
                  }`;
          }
        }
      });
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          fetchURL +
            "&q=" +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getImportTableData
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          fetchURL +
            "&q=" +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getImportTableData
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else {
      setSort(e.sort);
    }
  };

  return (
    <div className="window-shouldCostUpload">
      <Window
        title={
          <WindowTitle
            title={`Cost Allocation - Mass Upload(${donwload_ID})`}
            icon="k-i-calculator"
          />
        }
        initialHeight={window.innerHeight * 0.96}
        initialWidth={window.innerWidth * 0.86}
        minimizeButton={() => null}
        maximizeButton={() => null}
        onClose={() => setShowImportTable(false)}
      >
        <div style={{ marginTop: -16 }}>
          {importTableLoading ? <ProgressBar /> : ""}
        </div>
        <div style={{ marginRight: 10, marginBottom: 5 }}>
          <Button
            size="small"
            title="Refresh"
            onClick={reloadImportTable}
            className="app-button"
            iconClass="k-icon k-i-reload"
          ></Button>
          <span title="Import">
            <Button
              size="small"
              style={{ marginLeft: 5 }}
              className="app-button"
              disabled={getValidStatus.current === false ? true : false}
              onClick={importTableData}
              iconClass="k-icon k-i-data"
            ></Button>
          </span>
          <Button
            size="small"
            onClick={openPopover}
            title="Filter By"
            className="app-button"
            disabled={importDetailsTable.length < 0 ? true : false}
            ref={anchor}
          >
            <span
              className={
                isFilter === true
                  ? "k-icon k-i-filter-clear"
                  : "k-icon k-i-filter"
              }
            ></span>
          </Button>
          {show && (
            <div>
              <FilterPopUp
                params={FILTER_PARAMS}
                handleClose={onCancelClick}
                show={show}
                elementRef={anchor.current && anchor.current.element}
                handleFilterSubmit={onSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                mediaId={media_ID}
                staticLOVFlag="CA"
                userId={user_id}
              />
            </div>
          )}
        </div>
        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <Grid
            data={orderBy(importDetailsTable, sort)}
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            onPageChange={(e) => handlePageChange(e)}
            onSortChange={(e) => handleSortChange(e)}
            total={
              importDetailsTable
                ? importDetailsTable?.length > 0
                  ? importDetailsTable[0]?.resultset_size
                  : 0
                : 0
            }
            rowHeight={window.innerHeight * 0.065}
            sortable={true}
            sort={sort}
          >
            <GridColumn
              headerClassName="headerClass"
              title="Commodity Code"
              field="commodity_code"
              width={setPercentage(13)}
              cell={(e) => GridCellToolTip(e, "commodity_code")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="GPN"
              field="gpn"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "gpn")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Part Number"
              field="part_number"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "part_number")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Supplier Name"
              field="scn"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "scn")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Manufacturer Name"
              field="manufacturer_name"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "manufacturer_name")}
            />
            {quarterNames?.length > 0 &&
              [...quarterNames]?.map((name, i) => {
                return (
                  <GridColumn
                    headerClassName="headerClass"
                    className="numberFields"
                    title={name.quarter_name + " (%)"}
                    field={"q" + (i + 1) + "_alloc"}
                    cellOptions={{
                      format: "#,###",
                    }}
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "q" + (i + 1) + "_alloc")}
                  />
                );
              })}
            <GridColumn
              headerClassName="headerClass"
              title="Status"
              field="load_status"
              width={setPercentage(9)}
              cell={(e) => GridCellToolTip(e, "load_status")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Reason"
              field="error_message"
              width={setPercentage(25)}
              cell={(e) => GridCellToolTip(e, "error_message")}
            />
          </Grid>
        </Tooltip>
      </Window>
    </div>
  );
};

export default ImportComponent;
