import react, { useState, useEffect, useContext,createRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import "./DownloadCOGSMassUpload.css";
import COGSInitiativeTemplate from "../../../assets/templates/COGSNewInitiativeTemplate.xlsx"
import { put, getRequest } from "../../../services/api.request";
import { UserContext } from "../../../App";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
const tasks = [
    { label: 'New Initiative', value: 'New', },
    { label: 'Update Initiative', value: 'Update', },
];

const DownloadCOGSMassUpload = ({ user_id,nextEightQuarters }) => {
    const userContext = useContext(UserContext);
    const currentQuarter = userContext.curr_qrtr;
    const [downloadCOGSType, setDownloadCOGSType] = useState(tasks[0].value);
    const [includeComplted, setIncludeCompleted] = useState('N');
    const [includeCancelled, setIncludeCancelled] = useState('N');
    const [loading, setLoading] = useState(false);
    const [exportDownloadData, setExportDownloadedData] = useState([]);
    const [downloadedID, setDownloadedID] = useState(null);
    const [COGSTemplate, setCOGSTemplate] = useState(false);
    const createExporter = createRef();

    const handleCategoryChange = (e) => {
        setDownloadCOGSType(e.value);
        if (e.value == "New") {
            setIncludeCompleted(false);
            setIncludeCancelled(false);
        }
    };
    const handleCompletedCheckbox = (event) => {
        let status = event.target.value;
        if(status == true){
        setIncludeCompleted("Y");
        }
        else{
            setIncludeCompleted("N");
        }
    }
    const handleCancelledCheckbox = (event) => {
        let status = event.target.value;
        if(status == true){
            setIncludeCancelled("Y");
            }
            else{
                setIncludeCancelled("N");
            }
     
    }
    const downloadCOGSInitiative = () => {
        setLoading(true);
        if(downloadCOGSType == "Update"){
            getRequest(`ro_download_allow_yn?source=RO COGS REDUCTION&user_id=${user_id}`, ({data})=>{
              if(data.allow_download === "N"){
                alert("Your existing download awaits for upload. Please upload the downloaded file.");
                setLoading(false);
              }
              else {
                put(`/ro_cogs_dwnld?p_user_id=${user_id}&p_cancel_flg=${includeCancelled}&p_complete_flg=${includeComplted}`, "", ({ data }) => {
                    if (data.hasOwnProperty("p_out_status")) {                        
                        if(data.p_out_status == "Success"){
                            setDownloadedID(data.p_out_id);
                        getRequest(`/ro_cogs_dwnld_extract/${data.p_out_id}`, ({ data }) => {
                            setExportDownloadedData(data.items);
                            setLoading(false);
                            setDownloadCOGSType(tasks[0].value);
                            setIncludeCancelled(false);
                            setIncludeCompleted(false);
                        });
                    }
                    else {
                        alert(data.p_out_err_msg);
                        setLoading(false);
                        setDownloadCOGSType(tasks[0].value);
                        setIncludeCancelled(false);
                        setIncludeCompleted(false);
                    }
                    }
                    else {
                        alert(data.p_out_err_msg);
                        setLoading(false);
                        setDownloadCOGSType(tasks[0].value);
                        setIncludeCancelled(false);
                        setIncludeCompleted(false);
                    }
                })
              }
            })
    }
    else{
        setLoading(false);
    }
    }
    const handleSaveExcel = () => {
        if (createExporter.current) {
            createExporter.current.save();
        }
      };
    useEffect(() => {
        // setLoading(true);
        exportDownloadData.length > 0 && handleSaveExcel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [exportDownloadData]);
   
    return (
        <div>
             <div style={{ marginTop: "-8px" }}>{loading ? <ProgressBar /> : ""}</div>
             <div>
            <RadioGroup style={{ display: 'flex', gap: '22%', marginLeft: '30px', marginTop:'20px' }} data={tasks}
                value={downloadCOGSType}
                layout={'horizontal'} onChange={handleCategoryChange} />

            <div style={{ display: 'inline-grid', marginTop: '20px', marginLeft: '220px', gap: '15px' }}>
                <Checkbox
                    value={includeComplted == "Y"?true:false}
                    label={"Include Completed"}
                    onChange={handleCompletedCheckbox}
                    disabled={downloadCOGSType == 'New' ? true : false}
                />
                <Checkbox
                    value={includeCancelled == "Y"?true: false}
                    label={"Include Cancelled"}
                    onChange={handleCancelledCheckbox}
                    disabled={downloadCOGSType == 'New' ? true : false}
                />
            </div>
            <div style={{ marginTop: 25, width: "100%", textAlign: "right" }}>
                {
                    downloadCOGSType == "New"?  <Button
                    size="small"
                    title="Download"
                    className="save-button"
                     onClick={downloadCOGSInitiative}
                     disabled={loading ? true : false} >
                     <a
                         style={{ color: "white", textDecoration: "none" }}
                         href={COGSInitiativeTemplate}
                         download="CMATXWD_COGS_NEW_INITIATIVE_TEMPLATE.xlsx">
                          <span className="k-icon k-i-download"> </span> Download             
                      </a> </Button>:
                      <Button
                    size="small"
                    iconClass="k-icon k-i-download"
                    title="Download"
                    className="save-button"
                     onClick={downloadCOGSInitiative}
                     disabled={loading ? true : false} >Download </Button>
                }
            </div>
        </div>
        <ExcelExport
        data={exportDownloadData}
        fileName={
        `CMATXWD_COGS_UPDATE_INITATIVE_TEMPLATE_${downloadedID}.xlsx`}
        ref={createExporter}>
         <ExcelExportColumn field="product_name" headerCellOptions={{ background: '#808080'}}  title="PRODUCT_NAME" width={150} />
         <ExcelExportColumn field="saving_type" headerCellOptions={{ background: '#808080' }}  title="SAVING_TYPE" width={150} />
         <ExcelExportColumn field="commodity_code"  headerCellOptions={{ background: '#808080' }} title="COMMODITY_CODE" width={150} />
         <ExcelExportColumn field="item" headerCellOptions={{ background: '#808080' }} title="ITEM_NAME" width={130} />
         <ExcelExportColumn field="idea_name"  headerCellOptions={{ background: '#808080' }} title="IDEA_NAME" width={150} />
         <ExcelExportColumn field="proposed_change"  headerCellOptions={{ background: '#808080' }} title="PROPOSED_CHANGE" width={150} />
         <ExcelExportColumn field="bu"  headerCellOptions={{ background: '#2E8B57' }}  title="BU" width={150} />
         <ExcelExportColumn field="initiative_type" headerCellOptions={{background:'#2E8B57'}} title="INITIATIVE_TYPE" width={150} />
         <ExcelExportColumn field="probability_level" headerCellOptions={{background:'#2E8B57'}} title="PROBABILITY_LEVEL" width={150} />
         <ExcelExportColumn field="status_level" headerCellOptions={{background:'#2E8B57'}} title="STATUS_TYPE" width={150} />
         <ExcelExportColumn field="remarks" headerCellOptions={{background:'#2E8B57'}} title="REMARKS" width={150} /> 
        {nextEightQuarters.length > 0 &&
          [...nextEightQuarters]?.map((name, i) => {
            return (
              <ExcelExportColumn headerCellOptions={{background:'#2E8B57'}}
                className="numberFields"
                title={"Q" + (i + 1) + "_SAVINGS"}
                field={"q" + (i + 1) + "_savings"}
                cellOptions={{
                  format: "#,###",
                }}
                width={80}
              />
            );
          })}
      </ExcelExport>
        </div>
    )
}


export default DownloadCOGSMassUpload;