import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { createRef, useEffect, useState } from "react";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { getRequest } from "../../../services/api.request";

const records = [
  { label: "All Records", value: "A" },
  { label: "Error Records", value: "E" },
];

const DownloadFileExtraction = ({
  setDownloadExcel,
  source,
  mediaId,
  futureQuarters,
}) => {
  const [recordType, setRecordType] = useState(records[0].value);
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const _exporter = createRef();

  const handleOnChange = (e) => {
    setRecordType(e.value);
  };

  const downloadExcelRecords = () => {
    setLoading(true);
    let params =
      recordType === "A"
        ? `media_id=${mediaId}&load_status=`
        : `media_id=${mediaId}&load_status=Error`;
    let url =
      source === "CS"
        ? `costsubmission_upload_view_extract?${params}`
        : `shouldcost_uploadview_extract?${params}`;
    getRequest(url, getRecords);
  };

  const getRecords = ({ data }) => {
    data.items.length > 0
      ? setExportData(data.items)
      : alert("No data to Download.");
    setLoading(false);
  };

  useEffect(() => {
    exportData.length > 0 && handleSaveExcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportData]);

  const handleSaveExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  return (
    <div className="window-container">
      <Dialog
        width={"350px"}
        height={"200px"}
        title={<WindowTitle title={"Download By"} icon="k-i-download" />}
        onClose={() => setDownloadExcel(false)}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <div style={{ marginTop: "-15px" }}>
          {loading ? <ProgressBar /> : ""}
        </div>
        <RadioGroup
          layout={"vertical"}
          style={{
            display: "flex",
            gap: "20px",
            marginLeft: "25px",
            marginTop: "10px",
          }}
          data={records}
          value={recordType}
          onChange={handleOnChange}
        />
        <Button
          size="small"
          iconClass="k-icon k-i-download"
          title="Download"
          className="save-button"
          onClick={downloadExcelRecords}
          disabled={loading ? true : false}
        >
          Download
        </Button>
        {source === "CS" ? (
          <ExcelExport
            data={exportData}
            fileName={`CMATXWD_COST_SUBMISSION_${mediaId}.xlsx`}
            ref={_exporter}
          >
            <ExcelExportColumn
              field="wd_part"
              title="Part Numbers"
              width={150}
            />
            {futureQuarters.length > 0 &&
              [...futureQuarters]?.map((name, i) => {
                return (
                  <ExcelExportColumn
                    className="numberFields"
                    title={name.quarter_name + " (" + name.quarter_label + ")"}
                    field={"q" + (i + 1)}
                    cellOptions={{ format: "#,###" }}
                    width={120}
                  />
                );
              })}
            <ExcelExportColumn field="load_status" title="Status" width={150} />
            <ExcelExportColumn
              field="error_message"
              title="Reason"
              width={350}
            />
            <ExcelExportColumn
              field="commodity_group"
              title="Commodity Group"
              width={160}
            />
            <ExcelExportColumn
              field="commodity_code"
              title="Commodity Code"
              width={150}
            />
            <ExcelExportColumn
              field="supplier_common_name"
              title="Supplier Common Name"
              width={190}
            />
            <ExcelExportColumn
              field="manufacturer_name"
              title="Manufacturer Name"
              width={160}
            />
          </ExcelExport>
        ) : (
          <ExcelExport
            data={exportData}
            fileName={`CMATXWD_SHOULD_COST_${mediaId}.xlsx`}
            ref={_exporter}
          >
            <ExcelExportColumn
              field="wd_part"
              title="WD_PART_NUMBER"
              width={150}
            />
            <ExcelExportColumn title="Quarter" field="quarter" width={90} />
            <ExcelExportColumn
              title="SHOULD_COST"
              field="should_cost"
              width={110}
            />
            <ExcelExportColumn
              title="COMMODITY_CODE"
              field="commodity_code"
              width={150}
            />
            <ExcelExportColumn
              title="COMMODITY_GROUP"
              field="commodity_group"
              width={150}
            />
            <ExcelExportColumn
              title="QTR_STATE"
              field="quarter_state"
              width={90}
            />
            <ExcelExportColumn title="STATUS" field="load_status" width={130} />
            <ExcelExportColumn
              title="REASON"
              field="error_message"
              width={500}
            />
          </ExcelExport>
        )}
      </Dialog>
    </div>
  );
};
export default DownloadFileExtraction;
