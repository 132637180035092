import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../App";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import { getRequest } from "../../services/api.request";
import { formatDates } from "../../utils/formatDateUtil";

const PartAssociation = ({heirarchyCode, setLoading}) => {
    const limit = 10;
    const initialDataState = {
        skip: 0
    };
    const [dataState, setDataState] = React.useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const anchor = React.useRef(null);
    const [submit, setSubmit] = React.useState(true);
    const [filterValue, setFilterValue] = React.useState({});
    const userContext = useContext(UserContext);
    const user_id = userContext.user_id;
    
    const FILTER_PARAMS = [
        {
          paramName: "hierarchyparts",
          displayValue: "Part",
          id_field: "part",
          api_field: "part_number",
        }
    ]
    const getAssociatedParts = ({data})=>{
        setLoading(false);
        setDataState(data.items.map(dataItem => Object.assign({}, formatDates(["end_date", "start_date"], dataItem))));
        setLoading(false);
        setSubmit(true);
        let res = data.items;
        if(res.length > 0){
          setSubmit(false);
        }
    }
    const fetchAssociatedParts =React.useCallback(
        () => {
            setLoading(false);
            if(heirarchyCode){
            setLoading(true);
                getRequest(`/hierarchy_right_vw/${heirarchyCode}?part_number=${''}&offset=0&limit=${limit}`, getAssociatedParts);
            }
            else
                setDataState([]);
            setSort([]);
            setPage(initialDataState);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [heirarchyCode]
      );
    const reloadGrid = () =>{
        setLoading(false);
        if(heirarchyCode){
            setPage({ skip: 0 })
        setLoading(true);
            getRequest(`/hierarchy_right_vw/${heirarchyCode}?part_number=${''}&offset=0&limit=${limit}`, getAssociatedParts);
        }
    }
    useEffect(()=>{
        setLoading(false);  
        fetchAssociatedParts();
        setSubmit(true);
    },[fetchAssociatedParts]);
    const onPageChange = (e) => {
        setLoading(true)
        getRequest(`/hierarchy_right_vw/${heirarchyCode}?part_number=${''}&offset=${e.page.skip}&limit=${limit}`, getAssociatedParts);
        setTimeout(() => {
            setPage(e.page);
        }, 100);
    }
    const openPopover = (event) => {
        setShow(!show);
    };
    const onFilterSubmit = () => {
        setPage({ skip: 0, take: 10 });
        setLoading(true);
        let url =`hierarchy_right_vw/${heirarchyCode}`;
        FILTER_PARAMS.forEach((param) => {
            if (filterValue.hasOwnProperty(param.id_field)) {
              url +=`?${param.api_field}=${filterValue[param.id_field][param.id_field]}`; 
            }
          });
          if (url.includes("?")) {
            url += `&offset=0&limit=${limit}`;
          } else {
            url += `?offset=0&limit=${limit}`;
          }
        getRequest(url,getAssociatedParts);
        setShow(false);
    }
    return (
        <div>
              <span>
            <Button size="small" className="app-button" onClick={reloadGrid} title="Refresh">
              <span className="k-icon k-i-reload"></span>
            </Button>
            <Button size="small" className="app-button" ref={anchor} title="Filter By" onClick={openPopover}>
              <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
            </Button>
            {show && <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={openPopover}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onFilterSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              hmid = {heirarchyCode}
              userId = {user_id}
            />}
            </span>
            <Grid  
          onPageChange={(e) => { onPageChange(e) }}
             pageable={{
                buttonCount: 5,
              }}
                total={dataState ? dataState.length > 0 ? dataState[0]?.resultset_size : 0 : 0}
                sortable={true} skip={page.skip} pageSize={limit} data={dataState} rowHeight={window.innerHeight * 0.065}>
                <GridColumn className="col" headerClassName="headerClass" field="part_number" key="part" title="Parts" />
                <GridColumn className="col" headerClassName="headerClass" key="start_date_active" field="start_date" title="Start Date Active" />
                <GridColumn className="col" headerClassName="headerClass" key="end_date_active" field="end_date" title="End Date Active" />
            </Grid>
        </div>
    )
}
export default PartAssociation;