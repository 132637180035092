import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import React, { useContext, useEffect } from 'react'
import { debounce } from 'lodash';
import { v4 as uuid } from 'uuid';

import WindowTitle from '../../../components/WindowTitle/WindowTitle';
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { UserContext } from '../../../App';
import privateRequest from '../../../services/api.config';
import DatePicker from 'react-date-picker';
import { formatLongDateToString, setLocal } from '../../../utils/formatDateUtil';
import { Button } from '@progress/kendo-react-buttons';
import { getRequest, put } from '../../../services/api.request';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';


export default function GroupPartDialog({ toggleDialog, mode, selectedData, reloadGrid, dataLimit }) {
    const [gpnNumber, setGpnNumber] = React.useState("");
    const [payload, setPayload] = React.useState({});
    const [enddatecheckbox, setEnddatacheckbox] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSave, setIsSave] = React.useState(false);
    const [isGpnID, setisGpnID] = React.useState(false);
    const [startActiveDate, setStartActiveDate] = React.useState(setLocal(new Date()));
    const [endActiveDate, setEndActiveDate] = React.useState(null);
    const [partNumbers, setPartNumbers] = React.useState([]);
    const [isGpnExists, setIsGpnExists] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [partFilterPayload, setPartFilterPayload] = React.useState({});
    //const [ partFilterValue, setPartFilterValue] = React.useState({});
    const [supplierData, setSupplierData] = React.useState([]);
    const [deleteFlag, setDeleteFlag] = React.useState(false);
    const userContext = useContext(UserContext);
    const user_id = userContext.user_id;
    const currentQuarter = userContext.curr_qrtr;
    const gcm_append_yn = userContext.gcm_append_yn;

    const initializePartNumbers = () => {
        setLoading(true)
        getRequest(`/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?limit=${dataLimit || 1000}`, ({ data }) => {
            setPartNumbers(data.items);
            setPartNumbers(data.items.map(dataItem => Object.assign({},{isSupplierExists:true,isDescriptionExistis:true}, dataItem)))
            setLoading(false);
        })
    }

    useEffect(() => {
        if (selectedData !== null) {
            setGpnNumber(selectedData.gpn_number)
            setFilterValue({ lookup_value: selectedData.commodity_code })
            setStartActiveDate(new Date(selectedData.start_date_active))
            setEndActiveDate(selectedData.end_date_active == null ? null : new Date(selectedData.end_date_active))
            if (mode === 'Edit') {
                initializePartNumbers()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData])

    useEffect(() => {
        if (mode === 'Add') {
            setGpnNumber('')
            setFilterValue({})
            setStartActiveDate(setLocal(new Date()))
            setEndActiveDate(null);
            setPartNumbers([])
        }
    }, [mode])

    const handleFilterRequest = (data) => {
        setPayload(data.items);
        setIsLoading(false);
    }

    const filterChange = debounce(({ filter }) => {
        setIsLoading(true)
        let url = `/generic_lov_service/gcmcommcodes/?search_for=${filter.value}&user_id=${user_id}`

        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest(data)
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, 1000)

    const handlePartAdd = () => {
        setPartNumbers([...partNumbers, { part_id: uuid(), part: '', part_desc: '',supplier:'' ,cost_allocation_status: '', cost_submission_status: '', flag: 'A',  isSupplierExists:true,
        isDescriptionExistis:true }])
    }
    const handlePartChange = ({ value }, partNumber) => {
        let { lookup_code, lookup_value ,attribute3} = value;
        if(attribute3 === "Active_Part"){
        let checkForDuplicate = partNumbers.filter((p) => p.part === lookup_value);
        if (checkForDuplicate.length > 0) {
            alert("Duplicated part numbers are not allowed!!")
        }
        else {
            // setIsSupplierExists(true);
            let url = `/gpn_part_desc/${lookup_code}?current_quarter=${userContext.curr_qrtr}`;
            setLoading(true);
            getRequest(url, ({ data }) => {
                let { part_id, part_desc, cost_submission_status, cost_allocation_status, commodity_group, commodity_code } = data.items[0];
                let selectedPartNumber = partNumbers.filter((p) => partNumber.part_id === p.part_id)[0];
                let selectedPartNo = JSON.parse(JSON.stringify(selectedPartNumber));
                let index = partNumbers.indexOf(selectedPartNumber);

                selectedPartNo = {
                    part_id,
                    part: lookup_value,
                    part_desc,
                    cost_submission_status,
                    cost_allocation_status,
                    commodity_group,
                    commodity_code,
                    flag: partNumber.flag,
                    isSupplierExists:true,
                    isDescriptionExistis:false
                }

                partNumbers.splice(index, 1, selectedPartNo);
                setPartNumbers(JSON.parse(JSON.stringify(partNumbers)))
                setLoading(false)
            })
        }
    }   
    else {
        setLoading(true);
            let selectedPartNumber = partNumbers.filter((p) => partNumber.part_id === p.part_id)[0];
            let selectedPartNo = JSON.parse(JSON.stringify(selectedPartNumber));
            let index = partNumbers.indexOf(selectedPartNumber);
            selectedPartNo = {
                part: lookup_value,
                flag: partNumber.flag,
                isDescriptionExistis:true
            }
            partNumbers.splice(index, 1, selectedPartNo);
            setPartNumbers(JSON.parse(JSON.stringify(partNumbers)));
            setLoading(false);
        // });
        getRequest(`gpn_part_prefix?part=${lookup_value}&ccid=${filterValue.attribute2}&current_quarter=${currentQuarter}`,({data})=>{
            setLoading(false);
            setSupplierData(data.items);
            // setIsSupplierExists(false)
        });
    }
    }
    const handleSupplierChange = ({ value }, partNumber)=>{
        let obj = value;
        let checkForDuplicate = partNumbers.filter((p) => p.supplier === obj.supplier_name);
        if (checkForDuplicate.length > 0) {
            alert("Duplicated part & supplier are not allowed!!");
        }
        else {
            setLoading(true);
                let newArray = supplierData.filter((s)=>s.supplier_name === obj.supplier_name);
                let { part_id,cost_submission_status, cost_allocation_status ,commodity_code,commodity_group} = newArray[0];
                let selectedPartNumber = partNumbers.filter((p) => partNumber.part_id === p.part_id)[0];
                let selectedPartNo = JSON.parse(JSON.stringify(selectedPartNumber));
                let index = partNumbers.indexOf(selectedPartNumber);
                selectedPartNo = {
                    part_id,
                    part:partNumber.part,
                    supplier:obj.supplier_name,
                    cost_submission_status,
                    cost_allocation_status,
                    commodity_code,
                    commodity_group,
                    flag: partNumber.flag, 
                     isSupplierExists:false,
                    isDescriptionExistis:true
                }
                partNumbers.splice(index, 1, selectedPartNo);
                setPartNumbers(JSON.parse(JSON.stringify(partNumbers)))
                setLoading(false);
        } 
    }
    const handleDescriptionChange = (e, partNumber) => {
    }
    const commCodeChg = (e) => {
        if (Object.keys(filterValue).length === 0) {
            setFilterValue(e.target.value)
        }
        else {
            if (partNumbers.length > 0) {
                let confirmation = window.confirm("Selected Part Numbers will be wiped out. Do you want to continue?")
                if (confirmation) {
                    setPartNumbers([])
                    setPartFilterPayload({})
                    setFilterValue(e.target.value)
                }
            }
            else {
                setPartFilterPayload({})
                setFilterValue(e.target.value)
            }
        }
    }


    const partFilterChange = debounce(({ filter }) => {
        let commcode_id;
        if (mode === "Add") {
            if (Object.keys(filterValue).length > 0) {
                commcode_id = filterValue.attribute2
            } else {
                alert("Please select commodity code first");
            }
        } else {
            if (selectedData && Object.keys(selectedData).length > 0) {
                commcode_id = selectedData.ccid
            }
        }
        if (commcode_id) {
            //setIsPartLoading(true);
            setLoading(true)
            let url = `/generic_lov_service/gpnparts_hierarchy/?search_for=${filter.value}&ccid=${commcode_id}`
            return privateRequest
                .get(url)
                .then(({ data }) => {
                    // setIsPartLoading(false);
                    setLoading(false)
                    setPartFilterPayload(data.items)
                })
                .catch((err) => {
                    setLoading(false)
                    //setIsPartLoading(false);
                });
        }
    }, 1000)

    const onGpnSave = () => {
        let isSaveEnabled = false;
        let isEmptyPart = false;
        let gpn_id = null;
        let commcode_id;
        if (mode === 'Add') {
            commcode_id = filterValue.attribute2;
            if (isSave === true) {
                gpn_id = isGpnID;
            }
        } else {
            gpn_id = selectedData.gpn_id;
            commcode_id = selectedData.ccid
        }

        partNumbers.forEach((partNumber) => {
            partNumber["commcode_id"] = commcode_id
        })

        if (enddatecheckbox) {
            partNumbers.forEach((partNumber) => partNumber["flag"] = 'D');
        }
        let payload = {
            gpn_id,
            start_date_active: formatLongDateToString(startActiveDate),
            end_date_active: endActiveDate ? formatLongDateToString(endActiveDate) : endActiveDate,
            gpn_description: "",
            user_id,
            gpn_number: gpnNumber,
            commodity_code: filterValue.lookup_value,
            commcode_id,
            part: partNumbers
        }

        let deletedParts = partNumbers.filter(partNumber => partNumber.flag === 'D');
        let partLength = partNumbers.length - deletedParts.length;
        if (!enddatecheckbox) {
            if (partLength < 2) {
                if (mode === 'Add') {
                    window.alert("GPN should have two or more active components.")
                } else {
                    let confirmation = window.confirm("GPN will be disabled. Do you still want to continue?")
                    if (confirmation) {
                        isSaveEnabled = true
                    }
                }
            } else {
                isSaveEnabled = true
            }
        } else {
            isSaveEnabled = true
        }
        //checking if any part number is empty
        partNumbers.forEach(element => {
            if (element.part === "") {
                isSaveEnabled = false;
                isEmptyPart = true;
            }
        });

        if (isSaveEnabled) {
            setLoading(true)
            put(`/gpn_post`, payload, ({ data }) => {
                if (data.hasOwnProperty("p_out_err_msg")) {
                    alert(data.p_out_err_msg)
                    setisGpnID(data.p_gpn_id);
                    setLoading(false)
                    setIsSave(true)
                    partNumbers.forEach((partNumber) => {
                        partNumber["flag"] = ''
                    })
                } else {
                    alert(`Successfully ${mode === 'Edit' ? 'Updated.' : "Added."}`)
                }
                toggleDialog()//clsing dialog box
                reloadGrid()
            })
        }
        else {
            if (isEmptyPart) {
                alert("Empty Parts are not allowed");
            }
        }
    }

    const onPartDelete = (partNumber) => {
        let confirmation;
        if (partNumber.cost_allocation_status === "Y" && partNumber.cost_submission_status === "Y") {
            confirmation = window.confirm("Part Cost Submission and Cost Allocation is already done. Cost Allocation will be deleted. Do you want to continue ?")
            if (confirmation) {
                alert("Please revisit allocation for GPN in Cost Allocation screen.")
            }
        }
        else {
            confirmation = window.confirm("Are you sure you want to delete?")
        }
        if (confirmation) {
            let selectedPartNumber = partNumbers.filter((p) => partNumber.part_id === p.part_id)[0];
            let index = partNumbers.indexOf(selectedPartNumber);
            if (partNumber.hasOwnProperty("resultset_size")) {
                let deletedParts = partNumbers.filter(partNumber => partNumber.flag === 'D');
                let partLength = partNumbers.length - deletedParts.length;
                if (partLength < 3) {
                    let confirmation = window.confirm(`GPN should have two or more active components.GPN will be disabled. Do you still want to continue?\n 1. Cost Allocation Status - ${partNumber.cost_allocation_status} \n 2. Cost Submission Status - ${partNumber.cost_submission_status}`)
                    if (confirmation) {
                        partNumbers.forEach(object => {
                            object.flag = "D";
                        });
                        setEndActiveDate(setLocal(new Date()));
                    }
                } else {
                    let selectedPartNo = JSON.parse(JSON.stringify(selectedPartNumber));
                    selectedPartNo["flag"] = "D";
                    partNumbers.splice(index, 1, selectedPartNo);
                }
            } else {
                partNumbers.splice(index, 1,);
            }
            setPartNumbers(JSON.parse(JSON.stringify(partNumbers)))
        }
    }

    const checkGpnExists = (e) => {
        let searchValue = e.target.value;
        if (searchValue.length > 0) {
            let url = `/generic_lov_service/gpnexists_yn/?isexistinggpn=${searchValue}`
            setLoading(true)
            getRequest(url, ({ data }) => {
                if (data.items && data.items.length > 0) {
                    let value = data.items[0].lookup_code;
                    if (value === 'Y') {
                        setIsGpnExists(true);
                    } else {
                        setIsGpnExists(false);
                    }
                }
                setLoading(false)
            })
        }
    }

    const handleMarkedDate = (event) => {
        let status = event.target.value;
        setEnddatacheckbox(status);
        if (status) {
            setEndActiveDate(setLocal(new Date()));
            setDeleteFlag(true);
        } else {
            setEndActiveDate(null);
        }
    }
    const deleteWindow = (e) => {
        if (e === "YES") {
            setEndActiveDate(setLocal(new Date()));
        }
        else {
            setEndActiveDate(null);
            setEnddatacheckbox(false);
        }
        setDeleteFlag(!deleteFlag);
    }

    return (
        <div className="window-container">
            <Window
                title={
                    <WindowTitle title={mode + " Group Part Number"} icon="k-i-ungroup" />
                }
                minimizeButton={() => null}
                maximizeButton={() => null}
                onClose={toggleDialog}
                initialHeight={530}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={700}
            >
                <div style={{ marginTop: -15 }}>{loading ? (
                    <ProgressBar />
                ) : ""}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: "10px" }}>
                    <FieldWrapper style={{ width: '40%' }}>
                        <Label editorId={"gpn_number"} optional={false}>Group Part Number:</Label>
                        <div className={"k-form-field-wrap"}>
                            <Input
                                style={{ width: '87%' }}
                                id="gpn_number"
                                onChange={(e) => { setGpnNumber(e.target.value) }}
                                disabled={mode === 'Edit'}
                                name="gnp"
                                value={gpnNumber}
                                required={true}
                                validationMessage={"This field is required"}
                                onBlur={checkGpnExists}
                            />
                            {isGpnExists && <Error style={{ display: 'block' }} direction='end'>GPN already exists.</Error>}
                        </div>
                    </FieldWrapper>
                    <FieldWrapper style={{ width: '40%' }}>
                        <Label editorId={"commodity_code"} optional={false}>Commodity Code:</Label>
                        <DropDownList
                            style={{ width: '87%' }}
                            data={Object.keys(payload).length > 0 ? payload : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={filterChange}
                            loading={isLoading}
                            value={filterValue}
                            onChange={commCodeChg}
                            required={true}
                            disabled={mode === 'Edit'}
                        />
                    </FieldWrapper>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '1rem' }}>
                    <FieldWrapper style={{ width: '40%' }}>
                        <Label editorId={"gpn_number"} optional={false}>Start Date Active:</Label>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <DatePicker
                                name="startDate"
                                onChange={(e) => setStartActiveDate(e)}
                                format="MM/dd/y"
                                style={{ width: '110%' }}
                                value={startActiveDate}
                                minDate={mode === 'Add' && startActiveDate}
                                required={true}
                                disabled={true}
                                dayPlaceholder='dd'
                                monthPlaceholder='mm'
                                yearPlaceholder='yyyy'
                            />
                            <span><Hint>(PST)</Hint></span>
                        </div>
                    </FieldWrapper>
                    {mode === 'Edit' && selectedData.end_date_active === null && <FieldWrapper style={{ width: '40%' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                            <Checkbox
                                value={enddatecheckbox}
                                label={"Mark as End dated"}
                                onChange={handleMarkedDate} />
                        </div>
                    </FieldWrapper>}
                </div>
                {/* Part number data grid */}
                <div style={{ marginTop: '0.2rem', borderTop: '1px solid lightgrey' }}>
                    <Button onClick={handlePartAdd} size="small" className="app-button" title="Add" disabled={gcm_append_yn === "N" ? true : false}>
                        <span className="k-icon k-i-plus"  ></span>
                    </Button>
                    <div style={{ height: "300px", overflow: 'auto' }}>
                        <table style={{ width: '100%', border: '1px solid lightgrey' }}>
                            <thead style={{ backgroundColor: '#545454', color: '#fff' }}>
                                <tr>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal' }}>...</th>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal' }}>Part Number</th>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal' }}>Part Description</th>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal' }}>Supplier</th>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal', width: "10%" }}>Cost Submission</th>
                                    <th style={{ padding: '0.5rem', fontWeight: 'normal', width: "10%" }}>Cost Allocation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partNumbers.map((partNumber) => {
                                    return <tr style={{ backgroundColor: partNumber.flag && partNumber.flag === 'D' && 'red' }} key={partNumber.part_id}>
                                        <td onClick={() => onPartDelete(partNumber)}>
                                            <span style={{ cursor: "pointer" }} className="k-icon k-i-delete"></span>
                                        </td>
                                        <td>
                                            <DropDownList
                                                style={{ width: '100%' }}
                                                title={partNumber.part}
                                                value={{ lookup_value: partNumber.part }}
                                                onChange={(e) => handlePartChange(e, partNumber)}
                                                data={Object.keys(partFilterPayload).length > 0 ? partFilterPayload : []}
                                                filterable={true}
                                                textField="lookup_value"
                                                dataItemKey="lookup_value"
                                                onFilterChange={partFilterChange}
                                                disabled={!!partNumber.resultset_size}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                style={{ width: '100%' }}
                                                id="part_desc"
                                                onChange={(e) => handleDescriptionChange(e, partNumber)}
                                                name="part_desc"
                                                // disabled={partNumber.resultset_size}
                                                disabled={partNumber.isDescriptionExistis}
                                                value={partNumber.part_desc}
                                            />
                                        </td>
                                        <td>
                                        <DropDownList
                                                style={{ width: '100%' }}
                                                title={partNumber.part}
                                                value ={{supplier_name:partNumber.supplier}}
                                                onChange={(e) => handleSupplierChange(e, partNumber)}
                                                data = { supplierData.length > 0
                                                    ? supplierData
                                                    : []}
                                                textField="supplier_name"
                                                dataItemKey="supplier_name"
                                                // onFilterChange={supplierFilterChange}
                                                disabled={partNumber.isSupplierExists}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                style={{ width: '100%' }}
                                                id="cost_submission_status"
                                                disabled={true}
                                                name="cost_submission_status"
                                                value={partNumber.cost_submission_status}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                style={{ width: '100%' }}
                                                id="cost_allocation_status"
                                                disabled={true}
                                                name="cost_allocation_status"
                                                value={partNumber.cost_allocation_status}
                                            />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Button
                    size="small"
                    className='save-button'
                    style={{ position: 'absolute', right: 0, bottom: 0, margin: '1rem', marginBottom: '6px' }}
                    disabled={isGpnExists || !gpnNumber}
                    onClick={onGpnSave}
                >
                    <span className="k-icon k-i-save"></span>
                    <span style={{ marginLeft: '0.4rem' }}>Save</span>
                </Button>
            </Window>
            {deleteFlag && (
                <Dialog title={"Please confirm "} onClose={deleteWindow}>
                    <p
                        style={{
                            margin: "25px",
                            textAlign: "center",
                            width: 500,
                            height: 40
                        }}
                    >
                        {"All the parts under this GPN will be end-dated, do you want to continue?"}
                    </p>
                    <DialogActionsBar>
                        <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            value="NO"
                            onClick={(e) => deleteWindow(e.target.value)}
                        >
                            No
                        </button>
                        <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            value="YES"
                            onClick={(e) => deleteWindow(e.target.value)}
                        >
                            Yes
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    )
}
