import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { getRequest, put } from "../../../services/api.request";
import { UserContext } from "../../../App";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";

const initialDataState = {
  skip: 0,
  take: 10,
};

const PreviousSubmissionView = ({
  componentCode,
  setPrevDialog,
  prevDialog,
  reloadRightGrid,
  copyStatus,
}) => {
  const gridWidth = window.innerWidth * 0.85;
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };

  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const limit = 10;
  const DATA_ITEM_KEY = "cam_id";
  const SELECTED_FIELD = "selected";
  const fetchDetails =
    componentCode.gpn_id !== null
      ? `cost_alloc_prev_data?current_quarter=${currentQuarter}&user_id=${user_id}&gpn_id=${componentCode.gpn_id}`
      : `cost_alloc_prev_data?current_quarter=${currentQuarter}&user_id=${user_id}&part_id=${componentCode.part_id}`;
  const idGetter = getter(DATA_ITEM_KEY);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState(initialDataState);
  const [submissionDetails, setSubmissionDetails] = useState([]);
  const [copyAllocation, setCopyAllocation] = useState({});
  const [viewLoading, setViewLoading] = useState(false);
  const quarterLabels = useRef({});

  const getSubmissionDetails = ({ data }, type) => {
    setSubmissionDetails(data.items);
    getQuarterLabels(data.items[0]);
    let costLineArray = [];
    data?.items.map((item) => {
      return costLineArray.push({
        cad_id_curr: item?.cad_id_curr,
        cad_id_prev: item?.cad_id_prev,
        part_id: item?.part_id,
        supplier_id: item?.supplier_id,
        mpn_id: item?.mpn_id,
        q1_alloc: item?.q1_alloc,
        q2_alloc: item?.q2_alloc,
        q3_alloc: item?.q3_alloc,
        q4_alloc: item?.q4_alloc,
        q5_alloc: item?.q5_alloc,
        q6_alloc: item?.q6_alloc,
        q7_alloc: item?.q7_alloc,
        q8_alloc: item?.q8_alloc,
      });
    });
    data?.items &&
      setCopyAllocation({
        ...copyAllocation,
        user_id: user_id,
        current_quarter: currentQuarter,
        cost_lines: costLineArray,
      });
    setViewLoading(false);
  };

  const fetchCommodityCodes = useCallback(() => {
    if (componentCode.part_id)
      getRequest(
        fetchDetails + "&offset=" + page.skip + "&limit=" + limit,
        getSubmissionDetails
      );
    else setSubmissionDetails([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentCode.part_id]);

  useEffect(() => {
    setViewLoading(true);
    prevDialog === true && fetchCommodityCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommodityCodes]);

  const getQuarterLabels = (item) => {
    if (item?.length > 0) {
      let col = [];
      for (let key in item) {
        if (key.toString().charAt(0) === "q" && key.length === 2) {
          col.push(item[key]);
        }
      }
      quarterLabels.current = col;
    } else
      quarterLabels.current = ["q1", "q2", "q3", "q4", "q5", "q6", "q7", "q8"];
  };

  const handleSortChange = (e) => {
    if (e.sort && e.sort.length > 0) {
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString =
        '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
      getRequest(
        fetchDetails +
          "&q=" +
          encodeURIComponent(queryString) +
          "&offset=" +
          page.skip +
          "&limit=" +
          limit,
        getSubmissionDetails
      );
      setSort(e.sort);
    } else {
      let dir = "asc";
      if (sort[0].dir === "asc") {
        dir = "desc";
      }
      const queryString =
        '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
      getRequest(
        fetchDetails +
          "&q=" +
          encodeURIComponent(queryString) +
          "&offset=" +
          page.skip +
          "&limit=" +
          limit,
        getSubmissionDetails
      );
      setSort([{ field: sort[0].field, dir: dir }]);
    }
  };

  const handlePageChange = (e) => {
    setViewLoading(true);
    if (sort.length === 0) {
      getRequest(
        fetchDetails + "&offset=" + e.page.skip + "&limit=" + limit,
        getSubmissionDetails
      );
      setTimeout(() => {
        setPage(e.page);
      }, 100);
    } else {
      const queryString =
        '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
      getRequest(
        fetchDetails +
          "&q=" +
          encodeURIComponent(queryString) +
          "&offset=" +
          e.page.skip +
          "&limit=" +
          limit,
        getSubmissionDetails
      );
      setTimeout(() => {
        setPage(e.page);
      }, 100);
    }
  };

  const togglePrevDialog = () => {
    setPrevDialog(!prevDialog);
  };

  const getCopiedAllocation = ({ data }, type) => {
    if (data.p_out_status === "Success") {
      alert("Copied Successfully");
      setPrevDialog(false);
      setSort([]);
      reloadRightGrid();
    } else alert(data.p_out_err_msg);
    setViewLoading(false);
  };

  const handleCopyAlloc = () => {
    setViewLoading(true);
    put("/copy_cost_alloc_details", copyAllocation, getCopiedAllocation);
  };

  return (
    <div className="window-costAllocation">
      <Window
        initialHeight={window.innerHeight * 0.955}
        initialWidth={window.innerWidth * 0.9}
        style={{ zIndex: "1" }}
        title={
          <WindowTitle
            title="Allocation details from Previous Submission"
            icon="k-i-table k-i-table-unmerge"
          />
        }
        onClose={togglePrevDialog}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <div style={{ marginTop: "-15px" }}>
          {viewLoading ? <ProgressBar /> : ""}
          <Button
            size="small"
            onClick={handleCopyAlloc}
            className="app-button"
            title="Copy Allocation"
            disabled={copyStatus === true ?? false}
          >
            <span className="k-icon k-i-copy k-i-files"></span>
          </Button>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              sort={sort}
              data={orderBy(
                submissionDetails?.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: [idGetter(item)],
                })),
                sort
              )}
              total={
                submissionDetails
                  ? submissionDetails.length > 0
                    ? submissionDetails[0]?.resultsetsize
                    : 0
                  : 0
              }
              onSortChange={(e) => {
                handleSortChange(e);
              }}
              onPageChange={(e) => {
                handlePageChange(e);
              }}
            >
              <GridColumn
                headerClassName="headerClass"
                field="part"
                title="Part Number"
                width={setPercentage(15)}
                cell={(e) => GridCellToolTip(e, "part")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="supplier_info"
                title="Supplier Info"
                width={setPercentage(15)}
                cell={(e) => GridCellToolTip(e, "supplier_info")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="manufacturer_name"
                title="Manufacturer Name"
                width={setPercentage(15)}
                cell={(e) => GridCellToolTip(e, "manufacturer_name")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="mpn_id"
                title="MPN"
                width={setPercentage(10)}
                cell={(e) => GridCellToolTip(e, "mpn_id")}
              />
              {quarterLabels.current.length > 0 &&
                quarterLabels.current.map((name, i) => {
                  return (
                    <GridColumn
                      headerClassName="headerClass"
                      className="numberFields"
                      title={name + " (Q+" + (i + 1) + ")(%)"}
                      width={setPercentage(9)}
                      field={"q" + (i + 1) + "_alloc"}
                      cell={(e) => GridCellToolTip(e, "q" + (i + 1) + "_alloc")}
                    />
                  );
                })}
            </Grid>
          </Tooltip>
        </div>
      </Window>
    </div>
  );
};

export default PreviousSubmissionView;
