import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Avatar,
} from "@progress/kendo-react-layout";
// import { Input } from "@progress/kendo-react-inputs";
// import { FloatingLabel } from "@progress/kendo-react-labels";
import "./Home.css";
import { Link} from "react-router-dom";
import { SCREEN_METADATA } from "../../configs/screenMetaData";
import { UserContext } from "../../App";
import * as _ from 'lodash'

export const Home = () => {
  const initialServiceList = SCREEN_METADATA;
  const [filteredList, setFilteredList] = new useState(initialServiceList);
  //const [value, setValue] = React.useState("");
  const user = useContext(UserContext);

  //const max = 20;

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  //   const query = e.target.value;
  //   let updatedList = initialServiceList.filter(({ text }) => {
  //     return text.toLowerCase().includes(query.toLowerCase())
  //   })
  //   setFilteredList(updatedList);
  // };

  useEffect(()=>{
    let filteredItem = [];
    initialServiceList.forEach((item)=>{
      if(user[`scr_${item.title.toLowerCase()}`] === "N") {
        return
      }
      if(item.hasOwnProperty("roles_allowed")){
        let roles_allowed = item["roles_allowed"].split(",");
        roles_allowed.forEach((role)=>{
          if(user.hasOwnProperty(role) && (user[role] === "Y")){
            filteredItem.push(item)
          }
        })
      }else{
        filteredItem.push(item)
      }
    })
    setFilteredList(_.uniq(filteredItem))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])

  
  return (
    <div className="home-container">
      {/* <div className="home-filter-container">
        <FloatingLabel 
           label={"Search"}
           editorId={"search"}
           editorValue={value}
        >
          <Input
            id="search"
            className="home-search-input"
            maxLength={max}
            value={value}
            onChange={handleChange}
          />
        </FloatingLabel>
      </div> */}
      <div className="home-card-container">
      
            {filteredList.map((service, index) => {
    return (
      <Link key={service.text} to={service.route} style={{ textDecoration:'none' }}>
        <Card className="home-card" key={index}>
          <CardBody className="card-header">
            <Avatar style={{background:'#53A5FF'}} type="icon" size="small">
              <span className={"k-icon " + service.icon} />
            </Avatar>
            <CardTitle className="card-title">
              {service.text}
            </CardTitle>
          </CardBody>
        </Card>
      </Link>
    );
  })}   
      </div>
    </div>
  );
};

export default Home;


