import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import "./ShouldBeCostUploadWizard.css";
import { Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { getRequest, post } from "../../../services/api.request";
import { UserContext } from "../../../App";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import FileUploader from "../../../components/FileUploader/FileUploader";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

const ShouldBeCostUploadWizard = (props) => {
  const userContext = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [override, setOverride] = useState("Y");
  const [fileName, setFileName] = useState("");
  const pageName = "upload";
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [uploadMessageDialog, setUploadMessageDialog] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);

  // const exportColumns = [
  //   {
  //     field: "a",
  //     title: "WD Part Number",
  //   },
  //   {
  //     field: "b",
  //     title: "Quarter(1-4) QFY(YY)",
  //   },
  //   {
  //     field: "c",
  //     title: "Should-be Cost",
  //   },
  // ];
  // const excelExport = () => {
  //   if (_exporter.current) {
  //     _exporter.current.save();
  //   }
  // };

  const readUploadFile = (e) => {
    var filePath = e.target.value;
    var allowedExtensions = /(\.xlsx|\.xls)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type");
      return false;
    } else {
      e.preventDefault();
      if (e.target.files && e.target.files?.length > 0) {
        let fileName = e.target.files[0].name;
        setFile(e.target.files[0]);
        setFileName(
          fileName.substring(0, fileName.lastIndexOf(".")) || fileName
        );
      }
      return true;
    }
  };

  const savedUploadedFile = ({ data }) => {
    alert("File Upload started with id:" + data.image_id);
    setSaveFlag(false);
    setDisabledBtn(false);
    props.toggleWizard();
  };

  const onSwitchChange = (e) => {
    if (e.value === true) setOverride("Y");
    else setOverride("N");
  };

  const getUploadMessage = ({ data }) => {
    if (data?.allow_upload === "N") {
      setUploadMessageDialog(true);
    } else {
      setSaveFlag(true);
      const formData = new FormData();
      formData.append("body", file);
      setDisabledBtn(true);
      post(
        "media/?file_name=" +
          fileName +
          "&source_name=SHOULD COST&override_yn=" +
          override +
          "&user_id=" +
          userContext.user_id,
        formData,
        savedUploadedFile
      );
    }
    setuploadLoading(true);
  };

  const storeUploadedFile = () => {
    setuploadLoading(true);
    getRequest(
      `upload_allow_yn?user_id=${userContext?.user_id}&source_name=SHOULD COST`,
      getUploadMessage
    );
  };

  const toggleConfirmUpload = () => {
    setUploadMessageDialog(false);
  };

  const handleConfirmUpload = () => {
    setUploadMessageDialog(false);
    props.toggleWizard();
  };

  return (
    <>
      <div className="window-shouldCostUpload">
        <Window
          initialHeight={263}
          initialWidth={400}
          title={
            <WindowTitle title="Should Cost Upload" icon="k-i-calculator" />
          }
          onClose={props.toggleWizard}
          minimizeButton={() => null}
          maximizeButton={() => null}
        >
          <div
            style={{
              margin: "10px",
            }}
          >
            <div style={{ marginTop: "-25px" }}>
              {uploadLoading ? <ProgressBar /> : ""}
            </div>
            {pageName === "upload" && (
              <>
                <div className="form-element">
                  <label className="input-header-cell">Upload File:</label>
                  <FileUploader onUpload={readUploadFile} />
                </div>
                <span className="selectedFileName">
                  {fileName ? fileName : "No file selected"}
                </span>
                <div className="form-element">
                  <label className="input-header-cell">Override:</label>
                  <Switch
                    className="switch"
                    width={200}
                    onChange={onSwitchChange}
                    defaultChecked={override}
                    onLabel={"Enabled"}
                    offLabel={"Disabled"}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: 15, width: "100%", textAlign: "right" }}>
            <Button
              onClick={storeUploadedFile}
              disabled={!file || disabledBtn || saveFlag === true}
              className="save-button"
            >
              <span className="k-icon k-i-upload"> </span> Upload
            </Button>
          </div>
        </Window>
      </div>
      {uploadMessageDialog && (
        <Dialog
          title={"Cost Submission - Upload"}
          onClose={toggleConfirmUpload}
          width={390}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your Existing uploads awaiting import. Please import and try to
            upload again.
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmUpload}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default ShouldBeCostUploadWizard;
