export const ONETIMECHARGE_STATUS = [
    {
        lookup_value: "All",
        lookup_code: "All"
    },
    {
        lookup_value: "Open",
        lookup_code: "Open"
    },
    {
        lookup_value: "Closed",
        lookup_code: "Closed"
    },
    {
        lookup_value: "Ongoing",
        lookup_code: "Ongoing"
    },
    {
        lookup_value: "Cancelled",
        lookup_code: "Cancelled"
    },
    {
        lookup_value: "Manually Closed",
        lookup_code: "Manually Closed"
    }]
export const RO_STATUS = [
    {
        lookup_value: "All",
        lookup_code: "All"
    },
    {
        lookup_value: "Completed",
        lookup_code: "Completed"
    },
    {
        lookup_value: "Committed",
        lookup_code: "Committed"
    },
    {
        lookup_value: "Cancelled",
        lookup_code: "Cancelled"
    },
    {
        lookup_value: "Not Started",
        lookup_code: "Not Started"
    },
    {
        lookup_value: "Ongoing",
        lookup_code: "Ongoing"
    },
    {
        lookup_value: "Rejected",
        lookup_code: "Rejected"
    }
]
export const PBL_STATUS = [
    {
        lookup_value: "High",
        lookup_code: "High"
    },
    {
        lookup_value: "Medium",
        lookup_code: "Medium"
    },
    {
        lookup_value: "Low",
        lookup_code: "Low"
    }
]
export const SHOULDCOST_STATUS = [
    {
        lookup_value:"All",
        lookup_code:"All"
    },
    {
        lookup_value: "Valid",
        lookup_code: "Valid"
    },
    {
        lookup_value: "Error",
        lookup_code: "Error"
    },
    {
        lookup_value: "Imported - Existing",
        lookup_code: "Imported - Existing"
    },
    {
        lookup_value: "Not Imported",
        lookup_code: "Not Imported"
    },
    {
        lookup_value: "Imported",
        lookup_code: "Imported"
    }    
]
export const COSTSUBMISSION_STATUS = [
    {
     lookup_value:"All",
     lookup_code:"All"
    },
    {
        lookup_value: "Valid",
        lookup_code: "Valid"
    },
    {
        lookup_value: "Error",
        lookup_code: "Error"
    },
    {
        lookup_value: "Existing",
        lookup_code: "Existing" 
    },
    {
        lookup_value: "Imported",
        lookup_code: "Imported"
    }
]
export const COSTALLOCATION_STATUS = [
    {
      lookup_value: "All",
      lookup_code: "All",
    },
    {
      lookup_value: "Valid",
      lookup_code: "Valid",
    },
    {
      lookup_value: "Error",
      lookup_code: "Error",
    },
    {
      lookup_value: "Existing",
      lookup_code: "Existing",
    },
    {
      lookup_value: "Imported",
      lookup_code: "Imported",
    },
  ];
  export const ROCOGS_STATUS = [
    {
        lookup_code:"HDD",
        lookup_value:"HDD"
    },
    {
        lookup_code:"Flash",
        lookup_value:"Flash"
    }
  ]