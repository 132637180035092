import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { getRequest } from "./../../../services/api.request";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { formatDates } from "../../../utils/formatDateUtil";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
const InvoiceDetails = ({ OneTimeChargeId, oneTimeChargeType, setLoading }) => {
    const initialDataState = {
        skip: 0,
        take: 1
    };
    const limit = 10;
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = useState([]);
    const [dataIdState, setDataIdState] = React.useState();
    const fetchInvoiceDetails = React.useCallback(
        () => {
            setLoading(false);
            if (OneTimeChargeId) {
                if (oneTimeChargeType === 'Debit Note') {
                    setLoading(true);
                    getRequest(`otc_right_view?po_number=${OneTimeChargeId}&offset=${page.skip}&limit=${limit}`, getinvoiceDetails);
                }
                else {
                    setLoading(true);
                    getRequest(`otc_right_view?invoice_number=${OneTimeChargeId}&offset=${page.skip}&limit=${limit}`, getinvoiceDetails);
                }
            }
            else
                setDataIdState([]);
            setSort([]);
            setPage(initialDataState);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [OneTimeChargeId]
    );
    const getinvoiceDetails = ({ data }, type) => {
        setLoading(false);
        setDataIdState(
            data.items.map(dataItem => Object.assign({}, formatDates(["invoice_date"], dataItem)))
        );
        setLoading(false);
    };
    useEffect(() => {
        setLoading(false);
        fetchInvoiceDetails();
    }, [fetchInvoiceDetails]);
    
    const handlePageChange = (e) => {
        let c = '';
        if (oneTimeChargeType === 'Debit Note') {
            c = `otc_right_view?po_number=${OneTimeChargeId}&offset=${e.page.skip}&limit=${limit}`;
        }
        else {
            c = `otc_right_view?invoice_number=${OneTimeChargeId}&offset=${e.page.skip}&limit=${limit}`;
        }
        if (sort.length === 0) {
            getRequest(c, getinvoiceDetails);
            setTimeout(() => {
                setPage(e.page);
            }, 100);
        } else {
            const queryString = '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
            let url = c + '&q=' + encodeURIComponent(queryString);
            getRequest(url, getinvoiceDetails);
            setTimeout(() => {
                setPage(e.page);
            }, 100);
        }
    };
    const handleSortChange = (e) => {
        let url = '';
        if (oneTimeChargeType === "Debit Note") {
            url = `otc_right_view?po_number=${OneTimeChargeId}`
        }
        else {
            url = `otc_right_view?invoice_number=${OneTimeChargeId}`
        }
        if (e.sort && e.sort.length > 0) {
            let field = e.sort[0].field;
            let sortingOrder = e.sort[0].dir;
            const queryString = '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
            getRequest(url + '?q=' + encodeURIComponent(queryString), getinvoiceDetails);
            setSort(e.sort);
            setPage(initialDataState);
        } else {
            let dir = "asc";
            if (sort[0].dir === 'asc') {
                dir = "desc";
            }
            const queryString = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
            getRequest(url + '?q=' + encodeURIComponent(queryString), getinvoiceDetails);
            setSort([{ "field": sort[0].field, "dir": dir }]);
            setPage(initialDataState);
        }
    };
    const reloadGrid = () => {
        setLoading(true);
        setPage({ skip: 0 });
        let c = '';
        if (oneTimeChargeType === 'Debit Note') {
            c = `otc_right_view?po_number=${OneTimeChargeId}&offset=0&limit=${limit}`;
        }
        else {
            c = `otc_right_view?invoice_number=${OneTimeChargeId}&offset=0&limit=${limit}`;
        }
        getRequest(c, getinvoiceDetails);
        setSort([]);
      }
    return (
        <div>
            <div className="pane-content">
                <span className="fil">
                    <Button className="app-button" size="small" title="Refresh" onClick={reloadGrid} >
                        <span className="k-icon k-i-reload"></span>
                    </Button>
                    <div className="commoditygroup">
                        <strong>
                            <span>Invoice Details</span> &nbsp;{" "}
                            <span></span>
                        </strong>
                    </div>
                </span>
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                    {<Grid total={dataIdState ? dataIdState.length > 0 ? dataIdState[0]?.resultset_size : 0 : 0}
                        data={orderBy(dataIdState, sort)} sortable={true} sort={sort} pageable={true}
                        skip={page.skip}
                        pageSize={limit}
                        rowHeight={window.innerHeight * 0.067}
                        onPageChange={(e) => { handlePageChange(e) }}
                        onSortChange={(e) => { handleSortChange(e) }}
                    >
                        <GridColumn title={oneTimeChargeType === "Debit Note" ? "Purchase Order#" : "Invoice #"}>
                            <GridColumn headerClassName="headerClass" field="invoice_number" title="Invoice No" cell={(e) => GridCellToolTip(e, 'invoice_number')} />
                        </GridColumn>
                        <GridColumn title={OneTimeChargeId}>
                            <GridColumn headerClassName="headerClass" field="invoice_amount" title="Invoice Amount " cell={(e) => GridCellToolTip(e, 'invoice_amount')} />
                            <GridColumn headerClassName="headerClass" field="invoice_date" title="Invoice Date" />
                        </GridColumn>
                    </Grid>}
                </Tooltip>
            </div>
        </div>
    );

}
export default InvoiceDetails;