
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { getRequest } from "../../services/api.request";
import { formatDates } from "../../utils/formatDateUtil";
import DeleteCostSubmissionToAssociatedParts from "./DeleteCostSubmissionToAssociatedParts";
import './AddParts.css'

const limit = 10;
const initialDataState = {
    skip: 0
};
const AddParts = ({ setGetHeirachyName,addToggleDialog,hmid,ccId, reloadGrid}) => {
    const [loading, setLoading] = React.useState(false);
    const [dataState, setDataState] = React.useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const [openDeleteCSDialog, setOpenDeleteCSDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [disableNextButton, setDisableNextButton] = React.useState(true);
    const [isClicked, setIsClicked] = useState(true);

    const getAssociatedParts = ({data})=>{
        setDataState(data.items.map(dataItem => Object.assign({}, formatDates(["end_date", "start_date"], dataItem))));
        setLoading(false);
        let res = data.items;
        if(res.length > 0){
            isClicked === true &&
            setTimeout(()=>{
                setAlertDialog(true);
            },1000);            
        }
        else {
           setDisableNextButton(false);
        }
    }
    useEffect(() => {
        setLoading(true);
       getRequest(`/hierarchy_right_vw/${setGetHeirachyName.p_hmid}?part_number=${''}&offset=0&limit=${limit}`, getAssociatedParts);
    }, []);
    const onNextDeleteCSParts = () =>{
        setOpenDeleteCSDialog(!openDeleteCSDialog);
    }
   const onPageChange = (e) =>{
    setIsClicked(false);
    setLoading(true)
    getRequest(`/hierarchy_right_vw/${setGetHeirachyName.p_hmid}?part_number=${''}&offset=${e.page.skip}&limit=${limit}`, getAssociatedParts);
    setTimeout(()=>{
        setPage(e.page);
    },100); 
    }
    const onOKClick = ()=>{
        setAlertDialog(false) 
        setDisableNextButton(false)
    }
    return (
        <div className="window-container">
            <Window title = {"Heirarchy Parts"}
                closeButton={() => null}
                draggable = {false}
                minimizeButton={() => null}
                maximizeButton={() => null}
                initialHeight={window.innerHeight * 0.95}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={600} >
                <div style={{ marginTop: -15 }}>{loading ? (
                    <ProgressBar />
                ) : ""}
                </div>
                <div className="wizard-page-hedding">
                    <p className="over-flow">{setGetHeirachyName.p_hierarchy_name}</p>
                    <span className="spacer"></span>
                    <p className="next-steps"> Step 1 of 3 <Button size="small"
                        style={{ backgroundColor: "#228dff", color: "white" }}
                        onClick={onNextDeleteCSParts}
                        disabled={disableNextButton}>
                        <span className="k-icon k-i-arrow-chevron-right"></span>
                        <span >Next</span>
                    </Button>
                    </p>
                </div>
                <Grid onPageChange={(e) => { onPageChange(e) }} pageable={true}
                    total={dataState ? dataState.length > 0 ? dataState[0]?.resultset_size : 0 : 0}
                    sortable={true} skip={page.skip} pageSize={limit} data={dataState} rowHeight={window.innerHeight * 0.065}>
                    <GridColumn className="col" headerClassName="headerClass" field="part_number" key="part" title="Parts" />
                    <GridColumn className="col" headerClassName="headerClass" key="start_date_active" field="start_date" title="Start Date Active" />
                    <GridColumn className="col" headerClassName="headerClass" key="end_date_active" field="end_date" title="End Date Active" />
                </Grid>
            </Window>
            {
                openDeleteCSDialog && <DeleteCostSubmissionToAssociatedParts onNextDeleteCSParts={onNextDeleteCSParts}
                addToggleDialog = {addToggleDialog}  hmid = {hmid} ccId = {ccId} reloadGrid ={reloadGrid}
                setGetHeirachyName = {setGetHeirachyName}>
                </DeleteCostSubmissionToAssociatedParts>
            }
            {
                alertDialog &&  ( <Dialog height='150px' ><p
                  style={{
                    textAlign: "center",
                    width: "400px"
                  }}
                >
                  Listed are the Parts Associated to Hierarchy ({setGetHeirachyName.p_hierarchy_name})
                </p>
                <DialogActionsBar>
                  <button style={{ backgroundColor: "#228dff", color: "white",width:'100%'}}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onOKClick}
                  >
                    Ok
                  </button>
                </DialogActionsBar>
              </Dialog>)
            }
        </div>
    )
}
export default AddParts;

