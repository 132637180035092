import React, { useContext, useEffect, useRef, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { orderBy } from "@progress/kendo-data-query";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { getRequest, post } from "../../../services/api.request";
import { UserContext } from "../../../App";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import FilterPopUp from "../../../components/FilterPopUp/FilterPopUp";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import "./UploadFileStatus.css";
import DownloadFileExtraction from "../../CostSubmissionView/components/DownloadFileExtraction";

const initialDataState = {
  skip: 0,
  take: 10,
};

const UploadDetails = (props) => {
  const FILTER_PARAMS = [
    {
      paramName: "scuploadparts",
      displayValue: "WD Part Number",
      id_field: "wd_part",
      api_field: "wd_part",
      filterable: true,
    },
    {
      paramName: "scuploadstatus",
      displayValue: "Status",
      id_field: "load_status",
      api_field: "load_status",
      filterable: false,
    },
  ];
  const dataId = props.params;
  const gridWidth = window.innerWidth * 0.85;
  const limit = 10;

  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };
  const [filterValue, setFilterValue] = useState({});
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [sort, setSort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(initialDataState);
  const [submit, setSubmit] = useState(false);
  const userContext = useContext(UserContext);
  const user_id = userContext?.user_id;
  const [width, setWidth] = useState(212);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const anchor = useRef(null);

  const reloadGrid = () => {
    setLoading(true);
    setPage({ skip: 0 });
    let { media_id } = props.params;
    getRequest(
      `/shouldcost_uploadview/${media_id}/?offset=0&limit=` + limit,
      handleRequest
    );
    setSubmit(false);
  };

  let handleRequest = ({ data }) => {
    setData(data.items);
    setLoading(false);
  };

  let handleSave = ({ data }) => {
    alert("Import Initiated");
    props.toggleWizard();
  };

  useEffect(() => {
    reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let saveChanges = () => {
    setLoading(true);
    reloadGrid();
    setDisable(true);
    let { media_id } = props.params;
    post(`/shouldcost_importupload/${media_id}/${user_id}`, {}, handleSave);
  };

  const handlePageChange = (e) => {
    setLoading(true);
    /*  if (sort.length === 0) {
          let { media_id } = props.params;
          let c = `/shouldcost_uploadview/${media_id}/?offset=`+ page.skip + "&limit=" + limit;
          getRequest(c,  handleRequest);
          setTimeout(() => {
            setPage(e.page);
          }, 100);
        } else {
          const queryString =
            '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
            let { media_id } = props.params;
            let c = `/shouldcost_uploadview/${media_id}/?offset=`+ 
            page.skip + 
            "&limit=" + limit+
            "&q=" +
            encodeURIComponent(queryString);
          getRequest(c, handleRequest);
          setTimeout(() => {
            setPage(e.page);
          }, 100);
        } */

    //submit true filter value
    if (submit === false) {
      let { media_id } = props.params;
      let c =
        `/shouldcost_uploadview/${media_id}/?offset=` +
        e.page.skip +
        "&limit=" +
        limit;
      getRequest(c, handleRequest);
    }
    if (submit === true) {
      let { media_id } = props.params;
      let url = `/should_cost_wd_part_filter?media_id=${media_id}&`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (url.includes("?")) {
            url += `&${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          } else {
            url += `?${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          }
        }
      });
      if (url.includes("?")) {
        url += `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, handleRequest);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const onSubmit = (event) => {
    setPage({ skip: 0 });
    setLoading(true);
    let { media_id } = props.params;
    let url = `/should_cost_wd_part_filter?media_id=${media_id}&`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        let urlAppendCharacter = url.includes("?") ? `&` : `?`;
        url +=
          filterValue[param.id_field][param.id_field] === "All"
            ? `${urlAppendCharacter}${param.api_field}=`
            : `${urlAppendCharacter}${param.api_field}=${
                filterValue[param.id_field][param.id_field]
              }`;
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, handleRequest);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setSubmit(!submit);
    setShow(false);
  };

  const handleMove = (e) => {
    if (e.end) {
      setWidth(e.left);
    }
  };

  const handleDownload = () => {
    setDownloadExcel(true);
  };

  return (
    <div className="window-shouldCostUpload">
      <Window
        title={
          <WindowTitle
            title={`Should Cost - Upload (${props.params.media_id})`}
            icon="k-i-calculator"
          />
        }
        initialHeight={window.innerHeight * 0.96}
        initialWidth={window.innerWidth * 0.75}
        minimizeButton={() => null}
        maximizeButton={() => null}
        onMove={handleMove}
        onResize={handleMove}
        onClose={props.toggleWizard}
      >
        <div style={{ marginTop: -16 }}>{loading ? <ProgressBar /> : ""}</div>
        <div style={{ marginRight: 10, marginBottom: 5 }}>
          <Button
            size="small"
            style={{ marginLeft: 5 }}
            title="Refresh"
            onClick={reloadGrid}
            className="app-button"
            ic
          >
            <span
              style={{ paddingRight: 5 }}
              className="k-icon k-i-reload"
            ></span>
          </Button>
          <span title="Import">
            <Button
              size="small"
              style={{ marginLeft: 5 }}
              className="app-button"
              disabled={dataId.import_status != null}
              onClick={saveChanges}
            >
              <span
                style={{ paddingRight: 5 }}
                className="k-icon k-i-data"
              ></span>
            </Button>
          </span>
          <Button
            size="small"
            onClick={openPopover}
            title="Filter By"
            className="app-button"
            ref={anchor}
          >
            <span
              className={
                submit === true
                  ? "k-icon k-i-filter-clear"
                  : "k-icon k-i-filter"
              }
            ></span>
          </Button>
          <Button
            size="small"
            title="Download By"
            className="app-button"
            onClick={handleDownload}
            iconClass="k-icon k-i-download"
            disabled={data.length > 0 ? false : true}
          ></Button>
          {show && (
            <div>
              <FilterPopUp
                params={FILTER_PARAMS}
                handleClose={openPopover}
                show={show}
                elementRef={anchor.current && anchor.current.element}
                handleFilterSubmit={onSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                mediaId={dataId.media_id}
                staticLOVFlag="SC"
              />
            </div>
          )}
        </div>

        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <Grid
            style={{ width: "100%", height: "350px" }}
            data={orderBy(data, sort)}
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            onPageChange={(e) => {
              handlePageChange(e);
            }}
            total={data ? (data.length > 0 ? data[0]?.resultset_size : 0) : 0}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            rowHeight={window.innerHeight * 0.065}
          >
            <GridColumn
              headerClassName="headerClass"
              title="WD Part Number"
              field="wd_part"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "wd_part")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Quarter"
              field="quarter"
              width={setPercentage(8)}
              cell={(e) => GridCellToolTip(e, "quarter")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Should Cost"
              field="should_cost"
              width={setPercentage(10)}
              cell={(e) => GridCellToolTip(e, "should_cost")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Commodity Code"
              field="commodity_code"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "commodity_code")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Commodity Group"
              field="commodity_group"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "commodity_group")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Qtr State"
              field="quarter_state"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "quarter_state")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Status"
              field="load_status"
              width={setPercentage(10)}
              cell={(e) => GridCellToolTip(e, "load_status")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Reason"
              field="error_message"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "error_message")}
            />
          </Grid>
        </Tooltip>
      </Window>
      {downloadExcel && (
        <DownloadFileExtraction
          source={"SC"}
          mediaId={props.params.media_id}
          setDownloadExcel={setDownloadExcel}
        ></DownloadFileExtraction>
      )}
    </div>
  );
};
export default UploadDetails;
