import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from '../App';
import { SCREEN_METADATA } from '../configs/screenMetaData';

export default function PrivateRoute({component, path, ...rest}) {
    const [authorized, setAuthorized] = useState(false);
    const user = useContext(UserContext);

    useEffect(()=>{
        let metadata = SCREEN_METADATA;
        let selectedMetadata = metadata.filter(meta => meta.route === path)[0];
          if(selectedMetadata.hasOwnProperty("roles_allowed")){
            let roles_allowed = selectedMetadata["roles_allowed"].split(",");
            roles_allowed.forEach((role)=>{
              if(!authorized && user.hasOwnProperty(role) && (user[role] === "Y")){
                setAuthorized(true);
              }
            })
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user])


    return (
        authorized ?
        <Route
            exact={true}
            path={path}
            component={component}
        />
        :
        <Redirect to={"/"}/>
    );
}
