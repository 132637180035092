import { base64Client, tokenUrl } from "../environment/environment.stage";
import { publicRequest } from "./api.config";

class TokenService {
   
    getLocalAccessToken() {
      const token = JSON.parse(localStorage.getItem("token"));
      return token;
    }


    updateLocalAccessToken() {
        const params = new URLSearchParams();
        params.append("grant_type", "client_credentials")
        return publicRequest 
        .post(tokenUrl, params, {
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${base64Client}`
            }
        })
    }
  }
  export default new TokenService();

  // const params = new URLSearchParams();
        // params.append("grant_type", "client_credentials")
        // return publicRequest 
        // .post(tokenUrl, params, {
        //     headers:{
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'Authorization': `Basic ${base64Client}`
        //     }
        // }) 
