import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from "../../App";
import { Dialog, Window, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import { Hint } from "@progress/kendo-react-labels";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { formatISOStringToShortDate, formatDatesToISO, formatDates, setLocal, formatLongDateToString } from '../../utils/formatDateUtil';
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { getRequest, put } from '../../services/api.request';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import DatePicker from "react-date-picker";
import "./CommodityCodeView.css";
import { debounce } from 'lodash';
import privateRequest from '../../services/api.config';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { Tooltip } from '@progress/kendo-react-tooltip';
import GridCellToolTip from '../../components/GridCellToolTip/GridCellToolTip';
import { Checkbox } from '@progress/kendo-react-inputs';

const CommodityCodeView = props => {
  const userContext = useContext(UserContext);
  const currentQuarter = userContext.curr_qrtr;
  const FLAG = props.addflag;
  const GCMobject = props.rowObject;
  const now = setLocal(new Date());
  const [commodityCodeData, setCommodityCodeData] = React.useState({
    "user_id": "", "gcg_id": "", "commodity_group": "", "gcg_start_active": "", "gcg_end_active": "", "commodity_code": [], 'current_quarter': currentQuarter
  });
  const [commoditycode, setCommodityCode] = useState([]);
  const [commodityCodeLine, setCommodityCodeLine] = React.useState([]);
  const [CommodityCodeLineObject, setCommodityCodeLineObject] = React.useState({
    "ccid": "", "gcc_start_active": "", "gcc_end_active": "", "commodity_code": "", 'flag': ""
  });
  const [visibleAlert, setVisibleAlert] = React.useState(false);
  const [comGroupChangeAlert,setComGroupChangeAlert] = React.useState(false)
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [visibleCMLinesDialog, setVisibleCMLinesDialog] = React.useState(false);
  const [visibleCMLinesDialogEdit, setVisibleCMLinesDialogEdit] = React.useState(false);
  const [data, setData] = useState([]);
  const [innerLoader, setInnerLoader] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingCGroup, setIsLoadingCGroup] = React.useState(false);
  const [isLoadingCCode, setIsLoadingCCode] = React.useState(false);
  const [gsmselected, setGsmSelected] = useState(GCMobject.user_id);
  const [commoditydata, setCommodityData] = useState([])
  const [commodityselected, setCommoditySelected] = useState(GCMobject.commodity_group);
  const [commodityGrpSelected, setCommodityGrpSelected] = useState();
  const [commodityGrpChngSelected, setCommodityChngGrpSelected] = useState();
  const [startDate1, setStartDate1] = useState(now);
  const [endDate1, setEndDate1] = useState();
  const [startDate, setStartDate] = useState(now);
  const [enddatecheckbox, setEnddatacheckbox] = React.useState(false);
  const [gccenddatecheckbox, setGCCEnddatacheckbox] = React.useState(false);
  const [endActiveDate, setEndActiveDate] = React.useState(null);
  const [gccendActiveDate, setGCCEndActiveDate] = React.useState(null);
  const [saveFlag, setSaveFlag] = useState(false);
  const [disCommCode, setDisCommCode] = React.useState(true); const limit = 10;
  const DATA_ITEM_KEY = "ccid";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({});
  const [disable, setDisable] = useState(true);
  const [dis, setDis] = useState(true);
  const [rowObject, setRowObject] = React.useState();
  const [rowObject2, setRowObject2] = useState();
  const [rowObject3, setRowObject3] = useState();
  const [rowObject4, setRowObject4] = useState();
  const [disableAdd, setdisableAdd] = useState(false);
  const toggleDialogForComLines = () => {
    setVisibleCMLinesDialog(!visibleCMLinesDialog);
    setCommodityCode([]);
  };
  const toggleDialogForComLinesEdit = () => {
    setVisibleCMLinesDialogEdit(!visibleCMLinesDialogEdit);
    setGCCEndActiveDate(null);
  };
const toggleDialogForComLinesEditCancel = ()=>{
  setVisibleCMLinesDialogEdit(!visibleCMLinesDialogEdit);
  setGCCEndActiveDate(null);
  setGCCEnddatacheckbox(false);
  setRowObject4(now);
  commodityCodeLine.forEach((value, index) => {
      if (value.ccid === rowObject2) {
          commodityCodeLine[index].gcc_end_active = "";
      }
  });
  CommodityCodeLineObject.gcc_end_active = "";
}
  const getMappedCommodityCodes = ({ data }, type) => {
    setCommodityCodeLine(
      data.items.map(dataItem => Object.assign({}, formatDates(["gcc_start_active", "gcc_end_active"], dataItem)))
    );
    setInnerLoader(false);
  };
  const fetchCommodityCodes = React.useCallback(
    () => {
      if (props.commodityGroupCode)
        getRequest('/gcm_group_ccode_edit?gcg_id='+props.commodityGroupCode +'&offset=0&limit=' + limit , getMappedCommodityCodes);
      else
        setCommodityCodeLine([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.commodityGroupCode]
  );
  useEffect(() => {
    if (FLAG === 'add') {
      setCommodityCodeData({});
      setEndActiveDate(null);
      setGCCEndActiveDate(null);
      GCMobject.gcg_start_active = now;
      GCMobject.gcg_end_active = null;
    }
    if (FLAG === 'edit') {
      setInnerLoader(true);
      setCommoditySelected(GCMobject.commodity_group);
      setGsmSelected(GCMobject.user_id);
      fetchCommodityCodes();
      setDisCommCode(false);
      setCommodityCodeData({
        ...commodityCodeData, user_id: GCMobject.user_id, commodity_group: GCMobject.commodity_group, gcg_id: GCMobject.gcg_id,
        gcg_start_active: GCMobject.gcg_start_active, gcg_end_active: GCMobject.gcg_end_active, commodity_code: commodityCodeLine,user_name:GCMobject.user_name
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterGCMData = (event) => {
   let obj = event.value;
  setGsmSelected(event.value.lookup_code);
    setCommodityCodeData({
      ...commodityCodeData, user_id: obj, current_quarter: currentQuarter, gcg_start_active: formatLongDateToString(now)
    });
  }
  const filterComGrp = (event) => {
    let obj = event.value;
    setCommoditySelected(obj.lookup_value);
    if (commodityselected == null || commodityselected === undefined) {
      setComGroupChangeAlert(false);
         setCommodityCodeData({
      ...commodityCodeData, commodity_group:obj, current_quarter: currentQuarter, gcg_start_active: formatLongDateToString(now)
    });
    setCommodityGrpSelected(obj)
    }  
    else if (commodityCodeLine.length !== 0) {
      setComGroupChangeAlert(true);
      setCommodityChngGrpSelected(obj);
    }
    else {
      setCommodityGrpSelected(obj);
      setCommodityCodeData({
        ...commodityCodeData, commodity_group:obj, current_quarter: currentQuarter, gcg_start_active: formatLongDateToString(now)
      });
    }
    setDisCommCode(false);
  }
 const toggleComGroupChangeAlert = (value) =>{
  if(value === "YES"){
    setCommodityCodeLine([]);
    setCommodityCodeData({
      ...commodityCodeData, commodity_group:commodityGrpChngSelected, current_quarter: currentQuarter, gcg_start_active: formatLongDateToString(now)
    });
    setCommodityGrpSelected(commodityGrpChngSelected);
  }
  else{
    setCommodityCodeData({
      ...commodityCodeData, commodity_group:commodityGrpSelected, current_quarter: currentQuarter, gcg_start_active: formatLongDateToString(now)
    });    
  }
  setComGroupChangeAlert(!comGroupChangeAlert);
 }
  const filterCommCodeData = (event)=>{
    let obj = event.value;
     setCommodityCodeLineObject({
        ...CommodityCodeLineObject, ccid: obj.lookup_code, commodity_code:obj.lookup_value, flag: 'N',gcc_start_active:now
      });
  }
  const handleSubmit = (event) => {
  };
  const filterCmatUsersChng = (dataset, field) => {
    let items = [];
    if (dataset && dataset.items.length > 0) {
      dataset.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value })
      })
    }
    if (field) {
      setData({ ...data, [field]: items });
    }
    setIsLoading(false);
  }
  const filterComGroupChng = (dataset, field)=>{
    let items = [];
    if (dataset && dataset.items.length > 0) {
      dataset.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value })
      })
    }
    if (field) {
      setCommodityData({ ...commoditydata, [field]: items });
    }
    setIsLoadingCGroup(false);
  }
  const getCommodityCodes = (dataset, field)=>{
    let items = [];
    if (dataset && dataset.items.length > 0) {
      dataset.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value })
      })
    }
    if (field) {
      setCommodityCode({ ...data, [field]: items });
    }
    setIsLoadingCCode(false);
  }
  const handleGsmselected =debounce( ({ filter }, param) => {
    setIsLoading(true)
    let url = `generic_lov_service/cmatavailableusers/?search_for=${filter.value}&user_id=${userContext.user_id}`;
    return privateRequest
    .get(url)
    .then(({ data }) => {
      filterCmatUsersChng(data, 'cmatavailableusers')
    })
    .catch((err) => {
      setIsLoading(false);
    });
  },1000)
  const handleCommoditySelected = debounce(({filter},param) => {
    setIsLoadingCGroup(true)
    let url = `generic_lov_service/gcmavailablecgroups/?search_for=${filter.value}&user_id=${userContext.user_id}`;
    return privateRequest.get(url)
    .then(({ data }) => {
      filterComGroupChng(data, 'gcmavailablecgroups')
    })
    .catch((err) => {
      setIsLoading(false);
    });
  },1000);
  const handleCommodityCode = debounce(({filter},param) => {
    setIsLoadingCCode(true);
    let url = `generic_lov_service/gcmavailableccodes/?search_for=${filter.value}&commgroup=${commodityselected}&user_id=${userContext.user_id}`
    return privateRequest
    .get(url)
    .then(({ data }) => {
      getCommodityCodes(data, 'gcmavailableccodes')
    })
    .catch((err) => {
      setIsLoading(false);
    });
  },1000);
  const handleStartDate = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setStartDate(e);
    GCMobject.gcg_start_active = value;
    setCommodityCodeData({
      ...commodityCodeData, gcg_start_active: value
    });
  }
  const handleEndDate = (event) => {
  let status = event.target.value;
        setEnddatacheckbox(status);
        if (status) {
            setEndActiveDate(now);
            setCommodityCodeData({
                  ...commodityCodeData, gcg_end_active: formatLongDateToString(now)
                });
        } else {
            setEndActiveDate(null);
        }
  }
  const handleStartDate1 = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setStartDate1(e);
    setCommodityCodeLineObject({
      ...CommodityCodeLineObject, gcc_start_active: formatISOStringToShortDate(value),
    });
  }
  const handleEndDate1 = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setEndDate1(e);
    setCommodityCodeLineObject({
      ...CommodityCodeLineObject, gcc_end_active: formatISOStringToShortDate(value),
    });
  }

  const handleStartDateEdit = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setRowObject3(e);
    CommodityCodeLineObject.gcc_start_active = value;
    setCommodityCodeLineObject({
      ...CommodityCodeLineObject, gcc_start_active: formatISOStringToShortDate(value),
    });
  }
  const handleEndDateEdit = (e) => {
    if (e.value === true) {
      setGCCEnddatacheckbox(true);
      let value = formatLongDateToString(now);
      setRowObject4(now);
      commodityCodeLine.forEach((value, index) => {
          if (value.ccid === rowObject2) {
              commodityCodeLine[index].gcc_end_active = formatISOStringToShortDate(formatLongDateToString(now));
              commodityCodeLine[index]['flag'] = 'U'
          }
      })
      CommodityCodeLineObject.gcc_end_active = value;
      setGCCEndActiveDate(now);
  } else {
      setGCCEnddatacheckbox(false);
      setRowObject4(null);
      commodityCodeLine.forEach((value, index) => {
          if (value.ccid === rowObject2) {
              commodityCodeLine[index].gcc_end_active = null;
          }
      })
      CommodityCodeLineObject.gcc_end_active = null;
      setGCCEndActiveDate(null);
  }
    setCommodityCodeLine(commodityCodeLine);
  };

  const submitCommodityCodeLinesData = () => {
    const commodCodeAlreadyAdded = commodityCodeLine.findIndex(
      (item) => item.commodity_code === CommodityCodeLineObject.commodity_code
    )
    if(commodCodeAlreadyAdded !== -1) {
      alert("Commodity Code already added!")
      return
    }
    setCommodityCodeLine([
      ...commodityCodeLine, {...CommodityCodeLineObject, gcc_start_active: formatISOStringToShortDate(formatLongDateToString(now)),gcc_end_active:null}
    ]);
    setVisibleCMLinesDialog(false);
    setEndDate1(null);
    setCommodityCodeLineObject({ commodity_code: "" });
    setDisable(true);
    setGCCEnddatacheckbox(false);
  }
  const submitCommodityCodeLinesDataEdit = () => {
    props.setLoading(true);
    setSelectedState({});
    setVisibleCMLinesDialogEdit(false);
    setdisableAdd(!disableAdd);
    setDis(true);
    setDisable(true);
    setGCCEnddatacheckbox(false);
    props.setLoading(false);
  }
  const saveGCMData = ({ data }, type) => {
    if (data.p_post_status === "Success") {
      alert("Record Added/Updated Successfully.")
    }
    else {
      alert(data.p_post_response)
    }
    props.setLoading(false);
    setCommodityCodeData({
      "user_id": "", "gcg_id": "", "commodity_group": "", "gcg_start_active": "", "gcg_end_active": "", "commodity_code": [],"user_name":''
    });
    setEndActiveDate(null);
    setGCCEndActiveDate(null);
    setSaveFlag(false);
    setVisibleDialog(!visibleDialog);
    props.reloadGrid();
  }
  const saveGCMCommodityData = () => {
    props.setLoading(true);
    commodityCodeData.commodity_group = commodityselected;
    commodityCodeData.user_id = gsmselected
    setCommodityCodeLine(
      commodityCodeLine.map(item => {
        return Object.assign({}, formatDatesToISO(["gcc_start_active", "gcc_end_active"], item));
      }));
      let filterArray =  commodityCodeLine.filter(element => {
      return element.flag !== "N"
      });
    commodityCodeData.commodity_code = commodityCodeData.gcg_end_active == null ? commodityCodeLine : filterArray;
    updateGCMData();
    setSaveFlag(false);
    props.reloadGrid();
    props.toggleDialog();
  }
  const updateGCMData = () => {
    setSaveFlag(true);
    put('/gcm_group_post', commodityCodeData, saveGCMData);
  }
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const handleRemoveFields = (index) => {
    const filters = commodityCodeLine.filter((item) => item.ccid !== rowObject2);
    const v = [...filters]
    setCommodityCodeLine(v);
    setDisable(true);
    setDis(true);
  }
  const handleRowClick = (e) => {
    setDis(false);
    setdisableAdd(!disableAdd);
    if ('flag' in e.dataItem) {
      e.dataItem.flag === 'N'? setDisable(false):setDisable(true);
      e.dataItem.flag === 'N'? setDis(true):setDis(false);
     }
    e.dataItem.flag === 'N'? setDisable(false):setDisable(true);
    setRowObject(e.dataItem.commodity_code);
    setRowObject2(e.dataItem.ccid);
    setRowObject3(e.dataItem.gcc_start_active);
    setRowObject4(e.dataItem.gcc_end_active);
    setGCCEnddatacheckbox(e.dataItem.gcc_end_active != null);
  };
  const toggleDialogAlert = (val) => {
    if (val === 'YES') {
      setVisibleAlert(!visibleAlert);
    }
    if (val === 'NO') {
      setVisibleAlert(!visibleAlert);
      props.toggleDialog();
    }
    else {
      setVisibleAlert(!visibleAlert);
    }
  }

  return (
    <div className='window-container'>
      <Window
        title={<WindowTitle title={FLAG === "add" ? "Add Commodity Code Assignment" : "Update Commodity Code Assignment"} icon="k-i-user" />}
        style={{ zIndex: "1", display: 'center' }}
        initialHeight={580}
        initialWidth={600}
        onClose={props.toggleDialog}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <div>
          <Form onSubmit={handleSubmit} render={() => (
            <FormElement >
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: "10px" }} >
                <div style={{ width: '40%' }}>
                  <label>GCM Name <sup style={{ color: "#f31700" }}>*</sup></label>
                  {
                    FLAG === 'add'? 
                    <DropDownList
                    style={{ width: '87%' }}
                    data={Object.keys(data).length > 0 ? data['cmatavailableusers'] : []}
                    filterable={true}
                    textField="lookup_value"
                    onFilterChange={handleGsmselected}
                    loading={isLoading}
                    onChange = {filterGCMData}
                  />:
                  <AutoComplete
                    disabled={FLAG === "add" ? false : true}
                    style={{ width: '87%' }}
                    value={gsmselected}
                    clearButton={false}
                     />
                  }
                </div>
                <div style={{ width: '40%' }}>
                  <label>Commodity Group <sup style={{ color: "#f31700" }}>*</sup></label>
                  {
                    FLAG ==='add'?<DropDownList
                    style={{ width: '87%' }}
                    data={Object.keys(commoditydata).length > 0 ? commoditydata['gcmavailablecgroups'] : []}
                    filterable={true}
                    textField="lookup_value"
                    onFilterChange={handleCommoditySelected}
                    loading={isLoadingCGroup}
                    onChange = {filterComGrp}
                    value = {commodityGrpSelected}
                  />:<AutoComplete
                  style={{ width: '87%' }}
                    disabled={FLAG === "add" ? false : true}
                   value={commodityselected}
                  />
                  }                   
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: "10px" }}>
                <div style={{ width: '40%' }}>
                  <div> <label>Start Date <sup style={{ color: "#f31700" }}>*</sup> </label> </div>
                  <DatePicker
                   style={{ width: '110%' }}
                    onChange={handleStartDate}
                    format="MM/dd/y"
                    minDate={FLAG === "add" ? startDate : (GCMobject.gcg_start_active !== null ? new Date(GCMobject.gcg_start_active) : "")}
                    value={GCMobject.gcg_start_active === undefined ? startDate : (GCMobject.gcg_start_active !== null ? new Date(GCMobject.gcg_start_active) : "")}
                    maxDate={FLAG === "add" && now}
                    required={true}
                    disabled = {true}
                    id='startDate'
                    dayPlaceholder='dd'
                    monthPlaceholder='mm'
                    yearPlaceholder='yyyy'
                  /><Hint>(PST)</Hint>
                </div>
                {FLAG === "add" ?"":<FieldWrapper style={{ width: '40%' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                            <Checkbox
                                value={enddatecheckbox}
                                label={"Mark as End dated"}
                                onChange={handleEndDate} />
                        </div>
                    </FieldWrapper>}
              </div>
              <div style={{ marginTop: "4px" }}>
                <Button disabled={disCommCode} onClick={toggleDialogForComLines} style={{ marginLeft: "1%" }}>  ➕ </Button>
                <Button onClick={toggleDialogForComLinesEdit} disabled={dis} style={{ marginLeft: "1%" }}> <span className="k-icon k-i-edit"></span> </Button>
                <Button onClick={() => handleRemoveFields()} disabled={disable} style={{ marginLeft: "1%" }}>❌</Button>
              </div>
              <hr />
              {
                FLAG === "add" ?'': innerLoader ? (
                  <ProgressBar />
                  ) : "" 
              } 
              <div>
              <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <Grid
                  style={{height:"250px"}}
                  className="innerGrid"
                  data={commodityCodeLine?.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
                  dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
                  selectable={{ enabled: true, drag: false, cell: false, mode: "single" }}
                  onSelectionChange={onSelectionChange}
                  onRowClick={(e) => { handleRowClick(e) }}>
                      <GridColumn field='commodity_code' title="Commodity Code"  width={'200px'} cell={(e) => GridCellToolTip(e, 'commodity_code')} />
                      <GridColumn field='gcc_start_active' title="Start Date"  width={'170px'} />
                      <GridColumn field='gcc_end_active' title="End Date"  width={'170px'} />
                </Grid>
                </Tooltip>
              </div>        
              <Button onClick={saveGCMCommodityData}
                icon="save"
                className='save-button'
                disabled={
                  GCMobject.gcg_start_active === null ||
                    commodityCodeData.user_id === undefined ||
                    commodityCodeData.commodity_group === undefined ||
                    saveFlag === true
                    ? true
                    : false
                }
              >Save</Button>
            </FormElement>
          )} />
        </div>
        <br />
      </Window>
      {visibleCMLinesDialog &&
        <div className='window-container'>
          <Window title={"Add Commodity Code"} style={{ zIndex: "1" }} onClose={toggleDialogForComLines}
            minimizeButton={() => null}
            maximizeButton={() => null}
            initialHeight={320}
            initialWidth={340}>
            <Form render={() => (
              <FormElement>
                <div>
                  <div>
                    <label>Commodity Code <sup style={{ color: "#f31700" }}>*</sup></label><br />
                    <DropDownList
                    style={{ width: 265}}
                    data={Object.keys(commoditycode).length > 0 ? commoditycode['gcmavailableccodes'] : []}
                    filterable={true}
                    textField="lookup_value"
                    onFilterChange={handleCommodityCode}
                    loading={isLoadingCCode}
                    onChange = {filterCommCodeData}
                  />
                  </div>
                  <br />
                  <div>
                    <label>Start Date <sup style={{ color: "#f31700" }}>*</sup></label>
                    <DatePicker
                      onChange={handleStartDate1}
                      dayPlaceholder='dd'
                      monthPlaceholder='mm'
                      yearPlaceholder='yyyy'
                      name="startDate"
                      format="MM/dd/y"
                      minDate={now}
                      value={startDate1 !== undefined ? startDate1 : now}
                      maxDate={now}
                      required={true}
                    />
                    <span><Hint>(PST)</Hint></span>
                  </div>
                  <br />
                </div>
                <br />
                <DialogActionsBar>
                  <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base save-button"
                    onClick={toggleDialogForComLines}> Cancel</button>
                  <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base save-button"
                    type='submit' onClick={submitCommodityCodeLinesData}
                    disabled={
                      startDate1 === null ||
                        CommodityCodeLineObject.commodity_code === ''
                        ? true
                        : false
                    }> Submit </button>
                </DialogActionsBar>
              </FormElement>)} />
          </Window>
        </div>}


      {/* -----------------------Selected Row Edit Form------------------------- */}
      {visibleCMLinesDialogEdit &&
        <div className='window-container'>
          <Window
            title={"Update Commodity Code "}
            style={{ zIndex: "1" }}
            onClose={toggleDialogForComLinesEdit}
            minimizeButton={() => null}
            maximizeButton={() => null}
            initialHeight={340}
            initialWidth={340}>
            <Form render={() => (
              <FormElement>
                <div>
                  <div>
                    <label>Commodity Code <sup style={{ color: "#f31700" }}>*</sup></label><br />
                    <AutoComplete
                      disabled={true}
                      style={{ width: "270px", }}
                      defaultValue={rowObject}
                      required={true}
                    />
                  </div>
                  <br />
                  <div>
                    <label>Start Date <sup style={{ color: "#f31700" }}>*</sup></label>
                    <DatePicker
                      format="MM/dd/y"
                      value={new Date(rowObject3)}
                      required={true}
                      disabled={true}
                      onChange={handleStartDateEdit}
                      dayPlaceholder='dd'
                      monthPlaceholder='mm'
                      yearPlaceholder='yyyy'
                    />
                    <span><Hint>(PST)</Hint></span>
                  </div>
                  <br />
                    <FieldWrapper style={{ width: '65%' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                            <Checkbox
                                value={gccenddatecheckbox}
                                label={"Mark as End dated"}
                                onChange={handleEndDateEdit}/>
                        </div>
                    </FieldWrapper>
                </div>
                <br />
                <DialogActionsBar>
                  <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleDialogForComLinesEditCancel}> Cancel</button>
                  <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    type='submit' onClick={submitCommodityCodeLinesDataEdit}> Submit </button>
                </DialogActionsBar>
              </FormElement>)} />
          </Window>
        </div>}
      {visibleAlert && (
        <Dialog title={"Alert"} onClose={toggleDialogAlert}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
              width: 400,
              height: 40
            }} >
            Data will be Saved only if End Date is entered as the commodity code is already assigned for Future Dates
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              value="NO"
              onClick={(e) => toggleDialogAlert(e.target.value)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              value="YES"
              onClick={(e) => toggleDialogAlert(e.target.value)}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
        {comGroupChangeAlert && (
        <Dialog title={"Alert"} onClose={toggleComGroupChangeAlert}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
              width: 400,
              height: 40
            }} >
            Selected Commodity Codes will be wiped out. Do you want to continue?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              value="NO"
              onClick={(e) => toggleComGroupChangeAlert(e.target.value)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              value="YES"
              onClick={(e) => toggleComGroupChangeAlert(e.target.value)}
            >
              YES
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  )
}
export default CommodityCodeView