import React, { useEffect, useState, useContext } from "react";
import {UserContext } from '../../App';
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getRequest } from "../../services/api.request";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { formatDates } from "../../utils/formatDateUtil";
import "./QuarterlyCostSubmission.css";
import EditQuarterDialog from "./EditQuarterDialog/EditQuarterDialog";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";

const initialDataState = {
  skip: 0,
  take: 10,
};
const limit = 10;
const QuaterlyCostSubmisson = () => {
  const FILTER_PARAMS = [
    {
      paramName: "quarters",
      displayValue: "Quarter",
      id_field: "quarter",
      api_field: "quarter",
    },
  ];
  const [dataState, setDataState] = React.useState();
  //const [dataStateResult, setDataStateResult] = React.useState();
  const [page, setPage] = React.useState(initialDataState);
  const [isDisabled, setIsDisabled] = useState(true)
  const userContext = useContext(UserContext);
  const user_id = userContext?.user_id;
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState([]);
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [freezeCoatSubmissionDate, setFreezeCoatSubmissionDate] = React.useState();
  const [minDate, setMinDate] = React.useState();
  const [maxDate, setMaxDate] = React.useState();
  //const [dis, setDis] = useState(true);
  const user = useContext(UserContext);
  const [show, setShow] = React.useState(false);
  const [submit, setSubmit] = useState(false);
  const anchor = React.useRef(null);
  const [ filterValue, setFilterValue ] = useState({});

  const getQuarterlyCostSubmissions = ({ data }, type) => {
   // setDataStateResult(data);
    setDataState(
      data.items.map((dataItem) => Object.assign({}, formatDates(["quarter_start_date",
      "quarter_end_date","quarter_freeze_start_date","quarter_freeze_end_date"
    ],dataItem)))
    );
    // setDataState(
    //   data.items.map((dataItem) => Object.assign({}, setLocalZone([
    //  "quarter_freeze_start_date",
    //  "quarter_freeze_end_date"
    // ],dataItem)))
    // );
    setLoading(false);
  };

  const reloadGrid = () => {
    setLoading(true)
    setPage({skip:0})
    getRequest("/qtr_sub_window?offset=0&limit=" + limit,getQuarterlyCostSubmissions);
    setSort([]);
    setSubmit(false);
    setSelectedState({});
  };

  useEffect(() => {
    reloadGrid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =  sort.length !== 0 &&
     '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if(submit === false){
      let urlWithoutFilter = sort.length === 0 ? "qtr_sub_window?offset=" : 
      "qtr_sub_window?q=" + encodeURIComponent(queryString) + "&offset=";
         getRequest(urlWithoutFilter + e.page.skip + "&limit=" + limit, getQuarterlyCostSubmissions);
        }
        if (submit === true){
         let urlWithFilter;
         let url = "/qtr_sub_window?user_id=" + user_id;
         FILTER_PARAMS.forEach((param)=>{
           if(filterValue.hasOwnProperty(param.id_field)){
               if(url.includes("?")){
                   url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
               }else{
                   url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
               }
           }
        })
      urlWithFilter = sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
       url = urlWithFilter + `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url = urlWithFilter +`?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, getQuarterlyCostSubmissions);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const DATA_ITEM_KEY = "quarter_id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = React.useCallback(
    (event) => {
      let { dataItems } = event;
      if(dataItems[event.endRowIndex].enable_flag === "N"){
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

   const handleSortChange = (e) => {
    setLoading(true)
    if (e.sort && e.sort.length > 0) {
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString ='{"$orderby": {"'+field+'":"'+sortingOrder+'"}}';
      getRequest("/qtr_sub_window?q="+encodeURIComponent(queryString)+ ` &offset=${page.skip}&limit=${limit}` ,getQuarterlyCostSubmissions);
      setSort(e.sort);
    } else {
      let dir = "asc";
      if (sort[0].dir === "asc") {
        dir="desc";
      }
      const queryString ='{"$orderby": {"'+sort[0].field+'":"'+dir+'"}}';
      getRequest("/qtr_sub_window?q="+encodeURIComponent(queryString) + `&offset=${page.skip}&limit=${limit}`,getQuarterlyCostSubmissions);
      setSort([{ "field": sort[0].field, 'dir': dir }]);
    }
  };

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
    setSelectedState({});
    setFreezeCoatSubmissionDate(null)
    setIsDisabled(true)
  };

 
  const rowClickApi = (e) => {
    if (visibleDialog !== true) {
    setFreezeCoatSubmissionDate(e.dataItem);
    //setDis(false);
    };
  };
  
  const enterEdit = (e) => {
    e.preventDefault();
    setVisibleDialog(!visibleDialog);
     setMinDate(
      (freezeCoatSubmissionDate.quarter_start_date)
    );
    setMaxDate(
      (freezeCoatSubmissionDate.quarter_end_date)
    );/*
    if (freezeCoatSubmissionDate.quarter_freeze_start_date != null) {
      freezeCoatSubmissionDate.quarter_freeze_start_date =
        formatISODateToShortDate(
          freezeCoatSubmissionDate.quarter_freeze_start_date
        );
    }
    if (freezeCoatSubmissionDate.quarter_freeze_end_date != null) {
      freezeCoatSubmissionDate.quarter_freeze_end_date =
        formatISODateToShortDate(
          freezeCoatSubmissionDate.quarter_freeze_end_date
        );
    } */
    setFreezeCoatSubmissionDate({
      ...freezeCoatSubmissionDate,
      user_id: user.user_id,
    });
  };
  const openPopover = (event) => {
    setShow(true);
  };
  const onCancelClick = (event) => {
    event.preventDefault();
    setSubmit(false);
    setShow(false);
  };

  const onFilterSubmit = () => {
    setPage({skip:0})
    setLoading(true);
    let url =
    "/qtr_sub_window?user_id=" +
    user_id;
    FILTER_PARAMS.forEach((param)=>{
      if(filterValue.hasOwnProperty(param.id_field)){
          if(url.includes("?")){
              url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
          }else{
              url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
          }
      }
  })
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getQuarterlyCostSubmissions);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
  }

  const renderQuarterScreenBody = dataState ? (
    <Tooltip openDelay={100} position="bottom" anchorElement="target">
    <Grid
      style={{ margin: 0 }}
      sortable={true}
      sort={sort}
      pageable={true}
      skip={page.skip}
      pageSize={limit}
      total={dataState.length > 0 ? dataState[0]?.resultset_size : 0}
      onPageChange={(e) => {handlePageChange(e)}}
      onSortChange={(e) => {handleSortChange(e)}}
      data={dataState?.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)]}))}
      //data={orderBy(dataState.map((item) => ({...item,[SELECTED_FIELD]: selectedState[idGetter(item)],})),sort)}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{ enabled: visibleDialog !== true ? true : false, drag: false, cell: false, mode: "single" }}
      onSelectionChange={onSelectionChange}
      className="tab"
      onRowClick={rowClickApi}
    >
      <GridColumn
        headerClassName="headerClass"
        field="fiscal_year"
        title="Year"
        width={70}
        resizable={false}
        cell={(e) => GridCellToolTip(e, 'fiscal_year')} 
      />
      <GridColumn
        headerClassName="headerClass"
        field="quarter_num"
        title="Quarter #"
        className="numberFields"
        width={100}
        cell={(e) => GridCellToolTip(e, 'quarter_num')} 
      />
       <GridColumn
        headerClassName="headerClass"
        field="quarter"
        title="Quarter"
        width={120}
        cell={(e) => GridCellToolTip(e, 'quarter')} 
      />
      <GridColumn headerClassName="headerClass" title="Quarter (MM/DD/YYYY)">
        <GridColumn
          headerClassName="headerClass"
          field="quarter_start_date"
          title="Start Date"
          cell={(e) => GridCellToolTip(e, 'quarter_start_date')} 
        />
        <GridColumn
          headerClassName="headerClass"
          field="quarter_end_date"
          title="End Date"
          cell={(e) => GridCellToolTip(e, 'quarter_end_date')} 
        />
      </GridColumn>
      <GridColumn
        headerClassName="headerClass"
        title="Cost Submission Window (MM/DD/YYYY)"
      >
        <GridColumn
          headerClassName="headerClass"
          field="quarter_freeze_start_date"
          title=" Start Date (PST)"
          cell={(e) => GridCellToolTip(e, 'quarter_freeze_start_date')} 
        />
        <GridColumn
          headerClassName="headerClass"
          field="quarter_freeze_end_date"
          title="End Date (PST)"
          cell={(e) => GridCellToolTip(e, 'quarter_freeze_end_date')} 
        />
      </GridColumn>
    </Grid>
    </Tooltip>
  ) : "";


  return (
    <div className="qtr_container">
      { loading ? (
                <ProgressBar />
            ) : "" } 
      <div>
          <Button
            size="small"
            onClick={reloadGrid}
            className="app-button" 
            title="Refresh"
          >
            <span
              className="k-icon k-i-reload"
            ></span>
          </Button>
          <Button
            size="small"
            onClick={enterEdit}
            className="app-button" 
            title="Edit"
            disabled={isDisabled}
          >
            <span
              className="k-icon k-i-edit"
            ></span>
          </Button>
          <Button
              size="small"
              onClick={openPopover}
              title="Filter By"
              className="app-button"
              ref={anchor}
            >
              <span
                className={
                  submit === true
                    ? "k-icon k-i-filter-clear"
                    : "k-icon k-i-filter"
                }
              ></span>
            </Button>
            {show && (
              <FilterPopUp
                params={FILTER_PARAMS}
                handleClose={onCancelClick}
                show={show}
                elementRef={anchor.current && anchor.current.element}
                handleFilterSubmit={onFilterSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                userId={user_id}
              />
            )}
        </div>
      <div>{renderQuarterScreenBody}</div>
      {visibleDialog ? <EditQuarterDialog 
        freezeCoatSubmissionDate={freezeCoatSubmissionDate}
        toggleDialog = {toggleDialog}
        minDate = {minDate}
        maxDate = {maxDate}
        reloadGrid = {reloadGrid}
        /> : ""}
    </div>
  );
};
export default QuaterlyCostSubmisson;
