import React, { useContext, useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { UserContext } from "../../../App";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getRequest, post } from "../../../services/api.request";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { orderBy } from "@progress/kendo-data-query";
import FilterPopUp from "../../../components/FilterPopUp/FilterPopUp";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import "../../shouldBeCost/components/UploadFileStatus.css";
import DownloadFileExtraction from "./DownloadFileExtraction";

const initialDataState = {
  skip: 0,
  take: 10,
};

const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
  return Math.round(gridWidth / 100) * percentage;
};

const CostSubmissionUploadViewStatus = ({ toggleDialog, params }) => {
  const FILTER_PARAMS = [
    {
      paramName: "csuploadparts",
      displayValue: "Part Number",
      id_field: "wd_part",
      api_field: "wd_part",
      filterable: true,
    },
    {
      paramName: "csuploadstatus",
      displayValue: "Status",
      id_field: "load_status",
      api_field: "load_status",
      filterable: false,
    },
  ];
  const [filterValue, setFilterValue] = useState({});
  const [data, setData] = useState([]);
  const [sort, setSort] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const userContext = useContext(UserContext);
  const gcm_append_yn = userContext.gcm_append_yn;
  const [width, setWidth] = React.useState(212);
  const anchor = React.useRef(null);
  const currentQuarter = userContext?.curr_qrtr;
  const [futureQuarters, setFutureQuarters] = useState([]);

  const limit = 10;
  const [page, setPage] = React.useState(initialDataState);

  let handleRequest = ({ data }) => {
    setData(data.items);
    setLoading(false);
  };

  const reloadGrid = () => {
    setLoading(true);
    setPage({ skip: 0 });
    let { media_id } = params;
    getRequest(
      `/costsubmission_upload_view/${media_id}/?offset=0&limit=` + limit,
      handleRequest
    );
    getRequest(
      `cost_submission_quarters/lov?p_current_quarter=${currentQuarter}`,
      getFuterQuarters
    );
  };
  const getFuterQuarters = ({ data }, type) => {
    setFutureQuarters(data.get_eight_quarter);
  };
  const handlePageChange = (e) => {
    setLoading(true);
    /*  if (sort.length === 0) {
       let { media_id } = params;
       let c = `/costsubmission_upload_view/${media_id}/?offset=`+ page.skip + "&limit=" + limit;
       getRequest(c,  handleRequest);
       setTimeout(() => {
         setPage(e.page);
       }, 100);
     } else {
       const queryString =
         '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
         let { media_id } = params;
         let c = `/costsubmission_upload_view/${media_id}/?offset=`+ 
         page.skip + 
         "&limit=" + limit+
         "&q=" +
         encodeURIComponent(queryString);
       getRequest(c, handleRequest);
       setTimeout(() => {
         setPage(e.page);
       }, 100);
     } */

    //submit true filter value
    if (submit === false) {
      let { media_id } = params;
      let c =
        `/costsubmission_upload_view/${media_id}/?offset=` +
        e.page.skip +
        "&limit=" +
        limit;
      getRequest(c, handleRequest);
    }

    //submit false filter value
    if (submit === true) {
      setLoading(true);
      let { media_id } = params;
      let url = `/cs_upload_filter?media_id=${media_id}&`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (url.includes("?")) {
            url += `&${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          } else {
            url += `?${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          }
        }
      });
      if (url.includes("?")) {
        url += `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, handleRequest);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  useEffect(() => {
    reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPopover = (event) => {
    setShow(!show);
  };
  const handleDownload = () => {
    setDownloadExcel(true);
  };

  const onSubmit = (event) => {
    setPage({ skip: 0 });
    setLoading(true);
    let { media_id } = params;
    let url = `/cs_upload_filter?media_id=${media_id}&`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        let urlAppendCharacter = url.includes("?") ? `&` : `?`;
        url +=
          filterValue[param.id_field][param.id_field] === "All"
            ? `${urlAppendCharacter}${param.api_field}=`
            : `${urlAppendCharacter}${param.api_field}=${
                filterValue[param.id_field][param.id_field]
              }`;
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, handleRequest);
    setSubmit(!submit);
    setShow(false);
  };

  let handleSave = ({ data }) => {
    if (data.p_out_status === "Success") {
      alert("Import Initiated");
    } else {
      alert(data.p_out_err_msg);
    }

    toggleDialog();
  };

  let saveChanges = () => {
    let { media_id } = params;
    setLoading(true);
    setDisable(true);
    post(
      `/costsubmission_upload_import/${media_id}/${userContext.user_id}/`,
      {},
      handleSave
    );
  };

  const handleMove = (e) => {
    if (e.end) {
      setWidth(e.left);
    }
  };

  return (
    <div className="window-shouldCostUpload">
      <Window
        title={`Cost Submission - Upload (${params.media_id})`}
        initialHeight={window.innerHeight * 0.96}
        initialWidth={window.innerWidth * 0.76}
        minimizeButton={() => null}
        maximizeButton={() => null}
        onMove={handleMove}
        onResize={handleMove}
        onClose={toggleDialog}
      >
        <div style={{ marginTop: -16 }}>{loading ? <ProgressBar /> : ""}</div>
        <div style={{ marginRight: 10, marginBottom: 5 }}>
          {/* <Button size="small" style={{marginLeft:5}} >
            <span style={{ paddingRight:5}} className="k-icon k-i-arrow-left"></span>
        </Button>
        <Button size="small" style={{marginLeft:5}}>
            <span style={{paddingRight:5}} className="k-icon k-i-home"></span>
        </Button> */}
          <Button
            size="small"
            style={{ marginLeft: 5 }}
            title="Refresh"
            onClick={reloadGrid}
            className="app-button"
          >
            <span
              style={{ paddingRight: 5 }}
              className="k-icon k-i-reload"
            ></span>
          </Button>
          <span title="Import">
            <Button
              size="small"
              style={{ marginLeft: 5 }}
              disabled={
                params.import_status !== null ||
                gcm_append_yn !== "Y" ||
                disable === true
                  ? true
                  : false
              }
              onClick={saveChanges}
            >
              <span
                style={{ paddingRight: 5 }}
                className="k-icon k-i-data"
              ></span>
            </Button>
          </span>
          <Button
            size="small"
            title="Filter By"
            className="app-button"
            onClick={openPopover}
            ref={anchor}
          >
            {/* disabled={(data.length === 0) ? true : disable} */}
            <span
              className={
                submit === true
                  ? "k-icon k-i-filter-clear"
                  : "k-icon k-i-filter"
              }
            ></span>
          </Button>
          <Button
            size="small"
            title="Download By"
            className="app-button"
            onClick={handleDownload}
            disabled = {data.length>0?false:true}
          >
            <span className="k-icon k-i-download"></span>
          </Button>
          {show && (
            <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={openPopover}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              mediaId={params.media_id}
              staticLOVFlag="CS"
            />
          )}
        </div>

        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <Grid
            style={{
              width: window.innerWidth * 0.73,
              height: window.innerHeight * 0.9,
            }}
            data={orderBy(data, sort)}
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            onPageChange={(e) => {
              handlePageChange(e);
            }}
            total={data ? (data.length > 0 ? data[0]?.resultset_size : 0) : 0}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            rowHeight={window.innerHeight * 0.065}
          >
            <GridColumn
              headerClassName="headerClass"
              title="Part Numbers (select from a list)"
              field="wd_part"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "wd_part")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[0]
                    ? futureQuarters[0]
                      ? futureQuarters[0]["quarter_name"] +
                        ` (${futureQuarters[0]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q1"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q1")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[1]
                    ? futureQuarters[1]
                      ? futureQuarters[1]["quarter_name"] +
                        ` (${futureQuarters[1]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q2"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q2")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[2]
                    ? futureQuarters[2]
                      ? futureQuarters[2]["quarter_name"] +
                        ` (${futureQuarters[2]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q3"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q3")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[3]
                    ? futureQuarters[3]
                      ? futureQuarters[3]["quarter_name"] +
                        ` (${futureQuarters[3]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q4"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q4")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[4]
                    ? futureQuarters[4]
                      ? futureQuarters[4]["quarter_name"] +
                        ` (${futureQuarters[4]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q5"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q5")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[5]
                    ? futureQuarters[5]
                      ? futureQuarters[5]["quarter_name"] +
                        ` (${futureQuarters[5]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q6"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q6")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[6]
                    ? futureQuarters[6]
                      ? futureQuarters[6]["quarter_name"] +
                        ` (${futureQuarters[6]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q7"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q7")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[7]
                    ? futureQuarters[7]
                      ? futureQuarters[7]["quarter_name"] +
                        ` (${futureQuarters[7]["quarter_label"]})`
                      : ""
                    : ""
                  : ""
              }
              field="q8"
              width={setPercentage(7)}
              cell={(e) => GridCellToolTip(e, "q8")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Status"
              field="load_status"
              width={setPercentage(8)}
              cell={(e) => GridCellToolTip(e, "load_status")}
            />
            <GridColumn
              headerClassName="headerClass"
              title="Reason"
              field="error_message"
              width={setPercentage(20)}
              cell={(e) => GridCellToolTip(e, "error_message")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Commodity Group"
              field="commodity_group"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "commodity_group")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Commodity Code"
              field="commodity_code"
              width={setPercentage(12)}
              cell={(e) => GridCellToolTip(e, "commodity_code")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Supplier Common Name"
              field="supplier_common_name"
              width={setPercentage(16)}
              cell={(e) => GridCellToolTip(e, "supplier_common_name")}
            />
            <GridColumn
              headerClassName="headerClass"
              className="cell"
              title="Manufacturer Name"
              field="manufacturer_name"
              width={setPercentage(15)}
              cell={(e) => GridCellToolTip(e, "manufacturer_name")}
            />
          </Grid>
        </Tooltip>
      </Window>
      {downloadExcel && (
        <DownloadFileExtraction source={"CS"}
        mediaId = {params.media_id}
          setDownloadExcel={setDownloadExcel}
          futureQuarters = {futureQuarters}
        ></DownloadFileExtraction>
      )}
    </div>
   
  );
};
export default CostSubmissionUploadViewStatus;
