import { Button } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useContext, useEffect, useState } from 'react';
import GridCellToolTip from '../../../components/GridCellToolTip/GridCellToolTip';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { orderBy } from "@progress/kendo-data-query";
import { UserContext } from '../../../App';
import { getRequest, post } from '../../../services/api.request';
import FilterPopUp from '../../../components/FilterPopUp/FilterPopUp';
import "./ReductionInitiativeUploadViewStatus.css"

const initialDataState = {
    skip: 0,
    take: 10,
  };
  
  const gridWidth = window.innerWidth * 0.85;
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };  
const limit = 10;
const ReductionInitiativeUploadViewStatus = ({ toggleDialog, params })=>{
  const FILTER_PARAMS = [
    {
      paramName: "ro_product",
      displayValue: "Product Name",
      id_field: "product_name",
      api_field: "product_name",
      filterable: true
    },
    {
      paramName: "ro_upload_commcodes",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
      filterable: true
    },
    {
      paramName: "ro_upload_items",
      displayValue: "Item Name",
      id_field: "item",
      api_field: "item",
      filterable: true
    },
    {
      paramName: "csuploadstatus",
      displayValue: "BU",
      id_field: "bu",
      api_field: "bu",
      filterable: false
    }
  ];
    const [filterValue, setFilterValue] = useState({});
    const anchor = React.useRef(null);
    const userContext = useContext(UserContext);
    const gcm_append_yn = userContext.gcm_append_yn;
    const currentQuarter = userContext?.curr_qrtr;
    const [futureQuarters, setFutureQuarters] = useState([]);
    const [disable, setDisable] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [page, setPage] = React.useState(initialDataState);
    const [data, setData] = useState([]);
    const [sort, setSort] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = React.useState(false);

    let handleRequest = ({ data }) => {
        setData(data.items);
        setLoading(false);
      }
      const getFuterQuarters = ({ data }, type) => {
        setFutureQuarters(data.get_eight_quarter);
      }
    const reloadGrid = () => {
        setLoading(true);
        setPage({ skip: 0 })
        let { upload_download_id } = params;
        let {media_id} = params;
        getRequest(`/rocogs_upload_view/${media_id}/?offset=0&limit=` + limit, handleRequest);
        getRequest(
          `cost_submission_quarters/lov?p_current_quarter=${currentQuarter}`,
          getFuterQuarters
        );
      }

      const saveChanges = () => {
        let { upload_download_id } = params;
        let {media_id} = params;
        setLoading(true)
        setDisable(true)
        post(`rocogs_upload_import/${media_id}/${userContext.user_id}/${userContext.user_name}`, {}, handleSave)
      }
     
      const handleSave = ({ data }) => {
        if (data.p_out_status === "Success") {
          alert("Import Initiated");
        } else {
          alert(data.p_out_err_msg);
        }    
        toggleDialog();    
      }

      const handlePageChange = (e) => {
        setLoading(true);
        if (submit === false) {
          let { upload_download_id } = params;
          let {media_id} = params;
          let c = `/rocogs_upload_view/${media_id}/?offset=` + e.page.skip + "&limit=" + limit;
          getRequest(c, handleRequest);
        }
        if (submit === true) {
          setLoading(true);
          let { upload_download_id } = params;
          let {media_id} = params;
          let url = `/rocogs_upload_view/${media_id}/?&`
          FILTER_PARAMS.forEach((param) => {
            if (filterValue.hasOwnProperty(param.id_field)) {
              if (url.includes("?")) {
                url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
              } else {
                url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
              }
            }
          });
          if (url.includes('?')) {
            url += `&offset=${e.page.skip}&limit=${limit}`
          } else {
            url += `?offset=${e.page.skip}&limit=${limit}`
          }
          getRequest(url, handleRequest);
        }
        setTimeout(() => {
          setPage(e.page);
    
        }, 100);
      }
    useEffect(() => {
        reloadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const openPopover = (event) => {
        setShow(!show);
      };
      const onSubmit = (event) => {
        setPage({ skip: 0 })
        setLoading(true);
        let { upload_download_id } = params;
        let {media_id} = params;
        let url = `/rocogs_upload_view_filter?media_id=${media_id}`
        FILTER_PARAMS.forEach((param) => {
          if (filterValue.hasOwnProperty(param.id_field)) {
            let urlAppendCharacter = url.includes("?") ? `&` : `?`
            url += (filterValue[param.id_field][param.id_field] === "All") ?
              `${urlAppendCharacter}${param.api_field}=` :
              `${urlAppendCharacter}${param.api_field}=${filterValue[param.id_field][param.id_field]}`
          }
        });
        if (url.includes('?')) {
          url += `&offset=0&limit=${limit}`
        } else {
          url += `?offset=0&limit=${limit}`
        }
        getRequest(url, handleRequest);
        setSubmit(!submit);
        setShow(false);
      };
      const gridColor =({selectionChange, dataItem}, field)=>{
       return(
        <td onClick={selectionChange} 
       className={params.upload_initiative === "N"?"greenClass":""} title={dataItem[field]}
       field='column'>{dataItem[field]}</td>
       )
      }
    return(
        <div className='window-shouldCostUpload'>
               <Window
        title={`Reduction Initiative - Upload (${params.upload_download_id})`}
        initialHeight={window.innerHeight * 0.96}
        initialWidth={window.innerWidth * 0.76}
        minimizeButton={() => null}
        maximizeButton={() => null}
        onClose={toggleDialog}>
        <div style={{ marginTop: -16 }}>
          {loading ? <ProgressBar /> : ""}
        </div>
        <div style={{ marginRight: 10, marginBottom: 5 }}>
          <Button size="small" style={{ marginLeft: 5 }} title="Refresh" onClick={reloadGrid} className="app-button">
            <span style={{ paddingRight: 5 }} className="k-icon k-i-reload"></span>
          </Button>
            <Button size="small" style={{ marginLeft: 5 }} title='Import' className='app-button'
            disabled={(params.import_status !== null ||  disable == true) ? true : false} 
            onClick={saveChanges}> <span  className="k-icon k-i-data"></span> </Button>
          <Button size="small" title="Filter By" className="app-button" 
          onClick={openPopover} ref={anchor}
          >
            <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
          </Button>
          {show && <FilterPopUp
            params={FILTER_PARAMS}
            handleClose={openPopover}
            show={show}
            elementRef={anchor.current && anchor.current.element}
            handleFilterSubmit={onSubmit}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            mediaId={params.media_id}
            staticLOVFlag = "RO"
          />}
        </div>

        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <Grid
            style={{ width: window.innerWidth * 0.73, height: window.innerHeight * 0.9 }}
            data={orderBy(data, sort)}
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            onPageChange={(e) => { handlePageChange(e); }}
            total={data ? data.length > 0 ? data[0]?.resultset_size : 0 : 0}
            sortable={true}
            sort={sort}
            onSortChange={(e) => { setSort(e.sort) }}
            rowHeight={window.innerHeight * 0.065}>
            <GridColumn headerClassName="headerClass" title="Product" field='product_name' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, "product_name")} />
            <GridColumn headerClassName="headerClass" title="Commodity Code" field='commodity_code' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, "commodity_code")} />
            <GridColumn headerClassName="headerClass" title="Item" field='item' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, "item")} />
            <GridColumn headerClassName="headerClass" title="Idea Name" field='idea_name' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, "idea_name")} />
            <GridColumn headerClassName="headerClass" title="BU" field='bu' width={setPercentage(6)} cell={(e) => GridCellToolTip(e, "bu")} />
            <GridColumn headerClassName="headerClass" title="Proposed Change" field='proposed_change' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, "proposed_change")} />
            <GridColumn headerClassName="headerClass" title="Initiative Type" field='initiative_type' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, "initiative_type")} />
            <GridColumn headerClassName="headerClass" title="Probability Level" field='probability_level' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, "probability_level")} />
            <GridColumn headerClassName="headerClass" title="Status Type" field='status_level' width={setPercentage(8)} cell = {(e) => gridColor(e, 'status_level')}/>
            <GridColumn headerClassName="headerClass" title="Remarks" field='remarks' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, "remarks")} />
            <GridColumn headerClassName="headerClass" title="Saving Type" field='saving_type' width={setPercentage(10)} cell={(e) => gridColor(e, "saving_type")} />
            <GridColumn headerClassName="headerClass" title="Status" field='load_status' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, "load_status")} />
            <GridColumn headerClassName="headerClass" title="Reason" field='error_message' width={setPercentage(20)} cell={(e) => GridCellToolTip(e, "error_message")} />
            <GridColumn headerClassName="headerClass" className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[0]
                    ? futureQuarters[0]
                      ? futureQuarters[0]["quarter_name"]
                      : ""
                    : ""
                  : ""
              }
              field='q1_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q1_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields"
              title={
                futureQuarters
                  ? futureQuarters[1]
                    ? futureQuarters[1]
                      ? futureQuarters[1]["quarter_name"]
                      : ""
                    : ""
                  : ""
              }
              field='q2_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q2_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[2]
                  ? futureQuarters[2]
                    ? futureQuarters[2]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q3_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q3_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[3]
                  ? futureQuarters[3]
                    ? futureQuarters[3]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q4_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q4_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[4]
                  ? futureQuarters[4]
                    ? futureQuarters[4]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q5_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q5_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[5]
                  ? futureQuarters[5]
                    ? futureQuarters[5]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q6_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q6_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[6]
                  ? futureQuarters[6]
                    ? futureQuarters[6]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q7_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q7_savings")} />
            <GridColumn headerClassName="headerClass" className="numberFields" title={
              futureQuarters
                ? futureQuarters[7]
                  ? futureQuarters[7]
                    ? futureQuarters[7]["quarter_name"]
                    : ""
                  : ""
                : ""
            } field='q8_savings' width={setPercentage(7)} cell={(e) => GridCellToolTip(e, "q8_savings")} />
            </Grid>
        </Tooltip>
      </Window>
        </div>
    )
}

export default ReductionInitiativeUploadViewStatus;