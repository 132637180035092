import React, { useEffect, useState, useContext } from "react";
import { Grid, GridColumn, getSelectedState, } from '@progress/kendo-react-grid'
import { Window } from '@progress/kendo-react-dialogs';
import WindowTitle from "../WindowTitle/WindowTitle";
import './LovDataPopUp.css';

import { getter } from "@progress/kendo-react-common";

const SELECTED_FIELD = "selected"; 


const LovDataPopUp = (props) => {

    const idGetter = getter(props.dataKey);

    return (
        <div>
            <Window
                className="lov-pop-up"
                title={<WindowTitle icon="k-i-search" />}
                minimizeButton={() => null}
                maximizeButton={() => null}
                closeButton={() => null}
            >

                <Grid
                    className="lov-pop-up-grid"
                    data={props.resultSet?.map((item) => ({ ...item, [SELECTED_FIELD]: props.selectedState[idGetter(item)] }))}
                    dataItemKey={props.dataKey}
                    selectedField={SELECTED_FIELD}
                    selectable={{ enabled: true, drag: false, cell: false, mode: "single", }}
                    pageable={{ buttonCount: 3, }}
                    skip={props.page.skip}
                    pageSize={props.limit}
                    total={props.resultSet?.length > 0 ? props.resultSet[0]?.resultset_size : 0}
                    onPageChange={props.handlePage}
                    onSelectionChange={props.onSelectionChange}
                    onRowClick={props.onRowClick}
                >
                    <GridColumn title="Search Items" field="part" />
                </Grid>

            </Window>
        </div>
    )
}

export default LovDataPopUp