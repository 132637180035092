import privateRequest from "./api.config";

const handleError = (error, type) => {
}

export const getRequest =  (resource, handleResponse) => {
    return privateRequest
    .get(resource)
    .then(handleResponse) 
    .catch(handleError); 

};

export const post = (resource, model, handleResponse) => { 
    return privateRequest 
      .post(resource, model) 
      .then(handleResponse) 
      .catch(handleError); 
  };
  
  /** @param {string} resource */ 
  /** @param {object} model */ 
export const put = (resource, model, handleResponse) => { 
    return privateRequest 
      .put(resource, model) 
      .then(handleResponse) 
      .catch(handleError); 
  }; 
  
  /** @param {string} resource */ 
  /** @param {object} model */ 
export const patch = (resource, model, handleResponse) => { 
    return privateRequest 
      .patch(resource, model) 
      .then(handleResponse) 
      .catch(handleError); 
  }; 
  
  /** @param {string} resource */ 
  /** @param {string} id */ 
export const remove = (resource, id, handleResponse) => { 
    return privateRequest 
      .delete(resource, id) 
      .then(handleResponse) 
      .catch(handleError); 
  }; 