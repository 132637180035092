import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../App";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { getRequest,put} from "../../../services/api.request";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import ReductionInitiativeUploadViewStatus from "./ReductionInitiativeUploadViewStatus";
import { Button } from "@progress/kendo-react-buttons";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from '@progress/kendo-data-query';
import { COGSInitiatveStatusStepper } from "./COGSInitiativeStatusStepper";
import BrowseUpdateInitiativeFile from "./BrowseUpdateInitiativeFile";

const initialDataState = {
    skip: 0,
    take: 10,
};
const limit = 10;
const initiativeType = ["New Initiative", "Update Initiative"];
const UpdateCOGSInitiativeUpload = ({toggleForViewDialog,labelOverride,view})=>{
    const anchor = React.useRef(null);
    const list = useRef([]);
    const dataList = useRef([]);
    const [uploadId, setUploadId] = React.useState("");
    const [mediaId,setMediaId]=React.useState("");
    const [downloadId, setDownloadId] = React.useState('');
    const [cancelFlag,setCancelFlag]=React.useState("");
    const [showImportDialog, setShowImportDialog] = React.useState(false);
    const [showBrowseDialog, setShowBrowseDialog] = React.useState(false);    
    const [importDialogData, setImportDialogData] = React.useState();
    const userContext = useContext(UserContext);
    const [width, setWidth] = React.useState(212);
    const [page, setPage] = React.useState(initialDataState);
    const [dataState, setDataState] = React.useState([]);
    const [loading,setLoading] = useState(true);
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = useState(false);
    const [uploadIdList, setUploadIdList] = useState({data:[],value:''});
    const [filterInitiativeType, setFilterInitiativeType] = useState('');
    const [uploadedFileDataFlag, setUploadFileDataFlag] = React.useState(false);
    const [uploadFileData, setUploadFileData] = React.useState({});
    const [uploadView,setUploadView] = useState (view);

    const getUploadedData = ({data},type) =>{
        setDataState( data.items );
        setUploadView(false);
        list.current = data.items.map((item) => {
            return item.upload_download_id;
        })
        setUploadIdList({ ...uploadIdList, data: list.current });
        setLoading(false);
    }
    let showUploadDetails = (data) => {
        setImportDialogData(data);
        if(data.upload_initiative == "U" && data.file_validation_status == null ||
        data.upload_initiative == "U" && data.file_validation_status == "Errored"){
            setShowBrowseDialog(true);
        }
        else {           
          setShowImportDialog(true);
        }       
    }
    const GetUploadedFileName = (props) => {
        let linkedTd = (props.dataItem?.file_validation_status?.toLowerCase() === "errored")
            ? (
                <td
                    onClick={(e) => showUploadedFileData(props.dataItem)}
                    className="fileName"
                    style={{ textDecoration: "underline", color: "blue" }}
                >
                    {props.dataItem.file_name}
                </td>
            ) : (
                <td className="fileName">{props.dataItem.file_name}</td>
            )
        return linkedTd;
    };
    const showUploadedFileData = (data) => {
        setUploadFileDataFlag(!uploadedFileDataFlag);
        setUploadFileData(data);
    }
    const UploadIdCell = (props) => {
        let linkedTd = (props.dataItem?.validation_status?.toLowerCase() === "complete") ||
            (props.dataItem?.validation_status?.toLowerCase() === "errored") || 
            (props.dataItem)?.upload_date === null || (props.dataItem?.file_validation_status === "Errored") ||
            (props.dataItem?.validation_status?.toLowerCase() === "warning")
            ? (
                <td
                    onClick={(e) => showUploadDetails(props.dataItem)}
                    className="col"
                    style={{ textDecoration: "underline", color: "blue" }}
                >
                    {props.dataItem.upload_download_id}
                </td>
            ) : (
                <td className="col">{props.dataItem.upload_download_id}</td>
            )
        return linkedTd;
    };
    const cellWithBackGround = (props) => {
        let iconAttention = props.dataItem.cancel_flg;
        let import_status = props.dataItem.import_status;
        let file_validation_status = props.dataItem.file_validation_status;
        let validation_status = props.dataItem.validation_status;
        let media_id = props.dataItem.media_id;
        let dis = (import_status === "Complete" || iconAttention === "Y" || file_validation_status === "Errored" ||
        validation_status === "Errored" ) ? true : false;
        let cancelIcon =
            (<td className="col">
                <Button title="Cancel Upload" className="app-button" disabled={dis} onClick={(e) => cancelFile(props.dataItem)}
                    style={{ color: dis === false ? "red" : "#F67280" }}
                > <span class="k-icon k-i-close-outline" ></span> </Button>
            </td>
            );
        return cancelIcon;
    };
    const cancelFileResponse =({data})=> {
        setLoading(false);
        alert(data.p_out_msg);
        reloadGrid();
    }
    const cancelFile = (e) => {
        
        if (typeof e === 'object') {
            setMediaId(e.media_id);
          setDownloadId(e.upload_download_id);
        }
        if (e === "YES") {
          let url ;
            setLoading(true);
          url = mediaId === null? `/upload_cancel_ro_cogs?user_id=${userContext.user_id}&download_id=${downloadId}`:`/upload_cancel_ro_cogs?media_id=${mediaId}&user_id=${userContext.user_id}&download_id=${downloadId}`
            put(url, '', cancelFileResponse);
        }
        setCancelFlag(!cancelFlag);
    }
    const onFormSubmit = (e) => {
        setLoading(true);
        setPage(initialDataState);
        let result;
        if(filterInitiativeType !== ''){
         result = filterInitiativeType === "Update Initiative"?"U" : "N"
        }
        else {
            result = filterInitiativeType;
        }
        getRequest( `rocogs_media_upld_id_filter?upload_download_id=${uploadId}&user_id=${userContext.user_id}&source=${"RO COGS REDUCTION"}&upload_initiative=${result}&offset=${page.skip}&limit=${limit}`, getUploadedData );
        setSubmit(true);
        setShow(!show)
    };
    const reloadGrid = () => {
        setLoading(true);
        setPage(initialDataState);
        setUploadId("");
        setUploadIdList({data:[],valu:''});
        setFilterInitiativeType('');
        if(uploadView == true){
         getRequest( `rocogs_media_upld_id_filter?upload_download_id=${""}&user_id=${userContext.user_id}&source=${"RO COGS REDUCTION"}&upload_initiative=${"U"}&offset=${page.skip}&limit=${limit}`, getUploadedData );
     }
        else {
        getRequest( `media_status_rocogs/${userContext.user_id}/${'RO COGS REDUCTION'}?offset=${page.skip}&limit=${limit}`, getUploadedData );
        }
        setSubmit(false);
    };
    useEffect(() => {
        reloadGrid();
    }, []);
    const openPopover = (event) => {
        setShow(true);
    };
    const onCancelClick = event => {
        event.preventDefault();
        setSubmit(false);
        setUploadId("");
        setUploadIdList({data:[],valu:''});
        setFilterInitiativeType('');
        reloadGrid();
        setShow(false);
    };
    const handleMove = (e) => {
        if (e.end) {
            setWidth(e.left)
        }
    };
   const onPageChange = (e) => {
   let status_api_path = `/media_status_rocogs/${userContext.user_id}/RO COGS REDUCTION?offset=${e.page.skip}&limit=${limit}`
    setLoading(true);
    getRequest(status_api_path, getUploadedData);

    setTimeout(() => {
        setPage(e.page);
    }, 200);
    }
    const handleUploadId = (e) => {
        setUploadId((e.target.value).toString());
        setUploadIdList({
            data: filterData(e.target.value.toString()),
            value: e.target.value.toString(),
        });
    };
    const filterData = (value) => {
        const data = list.current;
        const filter = {
            value: value,
            operator: 'startswith',
            ignoreCase: true,
        };
        return value ? filterBy(data, filter) : data;
    };
    const browseDialog = () => {
        setShowBrowseDialog(!showBrowseDialog);
        if (showBrowseDialog === true) {
            setLoading(true);
            getRequest(`media_status_rocogs/${userContext.user_id}/${'RO COGS REDUCTION'}?offset=${page.skip}&limit=${limit}`, getUploadedData);
        }
    }
    const importDialog = () => {
        if (showImportDialog === true) {
            setLoading(true);
            getRequest(`media_status_rocogs/${userContext.user_id}/${'RO COGS REDUCTION'}?offset=${page.skip}&limit=${limit}`, getUploadedData);
        }
        setShowImportDialog(!showImportDialog);
}
    return (
        <div className='window-shouldCostUpload'>
            <Window
                initialHeight={window.innerHeight * 0.95}
                initialWidth={1200}
                onClose={() => {
                    toggleForViewDialog();
                    labelOverride()
                }}
                title={"Update - Upload Initiative"}
                onMove={handleMove}
                onResize={handleMove}
                minimizeButton={() => null}
                maximizeButton={() => null}>
                <div style={{ marginTop: -15 }}> {loading ? <ProgressBar /> : ""}</div>
                <div>
                <Button onClick={reloadGrid} size="small" title="Refresh" className="app-button">
                        <span style={{ paddingRight: 5 }} className="k-icon k-i-reload" ></span> </Button>
                    <Button size="small" onClick={openPopover} title="Filter By" className="app-button" ref={anchor} >
                        <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
                    </Button>
                    {show && <Popover
                        show={show}
                        anchor={anchor.current && anchor.current.element}
                        position={"bottom"} >
                        <form>
                            <div style={{ width: 200 }} >
                                <label for="comm_code">Download/Upload Id</label>
                                <AutoComplete
                                    className="comm_code"
                                    id={"upload_id"}
                                    name={"upload_id"}
                                    value={uploadIdList.value}
                                    onChange={handleUploadId}
                                    style={{width: "100%", marginBottom: "10px" }}
                                    data={uploadIdList.data} />
                            </div>
                            <div style={{ width: 200 }} >
                                <label for="comm_code">Initiative Type</label>
                                <DropDownList
                                    style={{width: "100%", marginBottom: "10px" }}
                                    data={initiativeType} value={filterInitiativeType}
                                    onChange={(e) => setFilterInitiativeType(e.target.value)}/>
                            </div>
                            <PopoverActionsBar>
                                <Button className='searchBtn'
                                    onClick={onFormSubmit}
                                    primary="true"
                                disabled={uploadIdList.value !== '' || filterInitiativeType !=='' ? false : true}>Submit</Button>
                                <Button onClick={onCancelClick}>Cancel</Button>
                            </PopoverActionsBar>
                        </form>
                    </Popover>}              
                <Grid
                    className="uploadStatus"
                    onPageChange={onPageChange} 
                    total={dataState ? dataState.length > 0 ? dataState[0]?.resultset_size : 0 : 0}
                    sortable={true}
                    pageable={true}
                    skip={page.skip}
                    pageSize={limit}
                    data={dataState}
                    rowHeight={window.innerHeight * 0.068} >
                      <GridColumn
                        width={80}
                        cell={cellWithBackGround}
                        headerClassName="headerClass"
                        className="col"
                        field="cancel_flg"
                        key="cancel_flg"
                        title="Cancel"/>
                    <GridColumn
                        width={150}
                        cell={UploadIdCell}
                        className="col"
                        headerClassName="headerClass"
                        key="upload_download_id"
                        field="upload_download_id"
                        title="Download/Upload ID"/>
                    <GridColumn
                        width={290}
                        cell={GetUploadedFileName}
                        className="col"
                        headerClassName="headerClass"
                        key="file_name"
                        field="file_name"
                        title="File Name" />
                    <GridColumn
                        width={100}
                        className="col"
                        headerClassName="headerClass"
                        key="upload_date"
                        field="upload_date"
                        title="Upload Date"/>
                    <GridColumn
                        className="col"
                        headerClassName="headerClass"
                        key="status"
                        field="status"
                        cell={COGSInitiatveStatusStepper}
                        title="Status" />
                </Grid>
                </div>
                {showImportDialog && (<ReductionInitiativeUploadViewStatus
                    toggleDialog={importDialog} params={importDialogData} />)}
                {
                    showBrowseDialog && (<BrowseUpdateInitiativeFile
                        toggleDialog={browseDialog} params={importDialogData}>
                    </BrowseUpdateInitiativeFile>)
                }
                {uploadedFileDataFlag && (
                    <Dialog title={"Error Message "} onClose={showUploadedFileData}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                                width: 350,
                                height: 40,
                            }} >
                            {uploadFileData.file_validation_message}
                        </p>
                        <DialogActionsBar>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="YES"
                                onClick={(e) => showUploadedFileData(e.target.value)} > Ok  </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                               {cancelFlag && (
                    <Dialog title={"Please confirm "} onClose={cancelFile}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                                width: 400,
                                height: 40,
                            }}
                        >
                            {
                                "Uploaded file will be cancelled. Do you still want to continue?"
                            }
                        </p>
                        <DialogActionsBar>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="NO"
                                onClick={(e) => cancelFile(e.target.value)}
                            >
                                No
                            </button>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="YES"
                                onClick={(e) => cancelFile(e.target.value)}
                            >
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </Window>
        </div>
    )
}
export default UpdateCOGSInitiativeUpload;
