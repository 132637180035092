import React, { useContext, useEffect, useState, useCallback } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { getRequest, put } from "../../../services/api.request";
import { UserContext } from "../../../App";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import PreviousSubmissionView from "./previousSubmissionView";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import PreviousAllocationEditGrid from "./previousAllocationEditGrid";

const initialDataState = {
  skip: 0,
  take: 10,
};

const PreviousAllocation = ({
  componentCode,
  quarterNames,
  visibleDialog,
  setVisibleDialog,
  setLoading,
  rowClick,
  setRowClick,
  rightSort,
  setRightSort,
  allocationStatus,
  setAllocationStatus,
}) => {
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const limit = 10;
  const DATA_ITEM_KEY = "cam_id";
  const SELECTED_FIELD = "selected";
  const gridWidth = window.innerWidth * 0.65;
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };
  const fetchDetails =
    componentCode.gpn_id !== null
      ? `cost_allocation_vw_right/${user_id}/${currentQuarter}/${componentCode.cam_id}?gpn_id=${componentCode.gpn_id}&`
      : `cost_allocation_vw_right/${user_id}/${currentQuarter}/${componentCode.cam_id}?`;
  const idGetter = getter(DATA_ITEM_KEY);

  const [page, setPage] = useState(initialDataState);
  const [pageSizes, setPageSizes] = useState([10, 25, 50]);
  const [allocationDetails, setAllocationDetails] = useState([]);
  const [updateAllocations, setUpdateAllocations] = useState([]);
  const [saveFlag, setSaveFlag] = useState(false);
  const [currentInputBox, setCurrentInputBox] = useState(null);
  const [previousDetails, setPreviousDetails] = useState({
    user_id: "",
    gpn_id: null,
    ccid: null,
    current_quarter: "",
    cost_lines: [],
  });
  const [prevDialog, setPrevDialog] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [totalAlloc, setTotalAlloc] = useState(false);

  const getAllocationDetails = ({ data }, type) => {
    setAllocationDetails(data.items);
    if (data.items.length > 0) {
      if (data.items[0].resultset_size < 10) {
        setPageSizes([10]);
      } else if (data.items[0].resultset_size < 50) {
        setPageSizes([10, 25]);
      }
    }
    setAllocationStatus(
      data.items.some((item) => item.previous_allocation === "Y")
    );
    setCopyStatus(data.items.some((item) => item.copied_status === "Y"));
    getPayLoadDetails(data.items);
    setLoading(false);
    setLoadingDialog(false);
  };

  const getPayLoadDetails = (data) => {
    let costLineArray = [];
    data?.map((item, i) => {
      return costLineArray.push({
        part_id: item?.part_id,
        part: item?.part,
        mpn_id: item?.mpn_id,
        q1_alloc: item.q1_alloc,
        q2_alloc: item.q2_alloc,
        q3_alloc: item.q3_alloc,
        q4_alloc: item.q4_alloc,
        q5_alloc: item.q5_alloc,
        q6_alloc: item.q6_alloc,
        q7_alloc: item.q7_alloc,
        q8_alloc: item.q8_alloc,
        cad_id: item?.cad_id,
        supplier_id: item?.supplier_id,
        supplier_common_name: item?.supplier_common_name,
      });
    });
    data &&
      setPreviousDetails({
        ...previousDetails,
        user_id: user_id,
        gpn_id: data[0]?.gpn_id,
        ccid: componentCode.ccid,
        current_quarter: currentQuarter,
        cost_lines: costLineArray,
      });
  };

  const fetchCommodityCodes = useCallback(() => {
    if (componentCode.cam_id)
      getRequest(
        fetchDetails + "offset=" + page.skip + "&limit=" + limit,
        getAllocationDetails
      );
    else setAllocationDetails([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentCode.cam_id]);

  useEffect(() => {
    rowClick === true ? setLoading(true) : setLoadingDialog(true);
    if (rowClick === true || rightSort.length <= 0) {
      setAllocationDetails([]);
      setUpdateAllocations([]);
    }
    fetchCommodityCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommodityCodes]);

  const handleSortChange = (e) => {
    if (e.sort && e.sort.length > 0) {
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString =
        '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
      getRequest(
        fetchDetails +
          "q=" +
          encodeURIComponent(queryString) +
          `&offset=${page.skip}&limit=${page.take}`,
        getAllocationDetails
      );
      setRightSort(e.sort);
    } else {
      let dir = "asc";
      if (rightSort[0].dir === "asc") {
        dir = "desc";
      }
      const queryString =
        '{"$orderby": {"' + rightSort[0].field + '":"' + dir + '"}}';
      getRequest(
        fetchDetails +
          "q=" +
          encodeURIComponent(queryString) +
          `&offset=${page.skip}&limit=${page.take}`,
        getAllocationDetails
      );
      setRightSort([{ field: rightSort[0].field, dir: dir }]);
    }
  };

  const handlePageChange = (e) => {
    setLoading(true);
    if (rightSort.length === 0) {
      getRequest(
        fetchDetails + `offset=${e.page.skip}&limit=${e.page.take}`,
        getAllocationDetails
      );
      setTimeout(() => {
        setPage(e.page);
      }, 100);
    } else {
      const queryString =
        '{"$orderby": {"' +
        rightSort[0].field +
        '":"' +
        rightSort[0].dir +
        '"}}';
      getRequest(
        fetchDetails +
          "q=" +
          encodeURIComponent(queryString) +
          `&offset=${e.page.skip}&limit=${e.page.take}`,
        getAllocationDetails
      );
      setTimeout(() => {
        setPage(e.page);
      }, 100);
    }
  };

  const handleEdit = () => {
    setSaveFlag(false);
    setVisibleDialog(!visibleDialog);
    setRowClick(false);
  };

  useEffect(() => {
    if (visibleDialog === true) {
      setUpdateAllocations([...allocationDetails]);
      getPayLoadDetails(allocationDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationDetails, visibleDialog]);

  const handlePrevSubmission = () => {
    setPrevDialog(!prevDialog);
  };

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
    setUpdateAllocations([]);
  };

  const handleAllocationChange = (e, qtr, i) => {
    let data = [...updateAllocations];
    let qtrValues = [...quarterNames];
    let qtrIndex = qtrValues.findIndex((item) => item.quarter_name === qtr);
    let updatedAlloc = e.value !== "" ? Number(e.value) : null;
    const updateEditArr = data.map((obj) => {
      if (obj.cad_id === data[i].cad_id) {
        return { ...obj, [`q${qtrIndex + 1}_alloc`]: updatedAlloc };
      }
      return obj;
    });
    setUpdateAllocations(updateEditArr);
    let costlineArray = [...previousDetails.cost_lines];
    let finalArray = costlineArray.map((item) => {
      if (item.cad_id === costlineArray[i].cad_id) {
        return { ...item, [`q${qtrIndex + 1}_alloc`]: updatedAlloc };
      } else return item;
    });
    setPreviousDetails({ ...previousDetails, cost_lines: finalArray });
    setCurrentInputBox(`${i}_${qtr}`);
  };

  useEffect(() => {
    if (currentInputBox) {
      document?.getElementById(currentInputBox)?.focus();
    }
  });

  const reloadRightGrid = () => {
    setLoading(true);
    setAllocationDetails([]);
    setUpdateAllocations([]);
    setPreviousDetails({
      user_id: "",
      gpn_id: null,
      ccid: null,
      current_quarter: "",
      cost_lines: [],
    });
    setRightSort([]);
    getRequest(
      fetchDetails + "offset=" + page.skip + "&limit=" + page.take,
      getAllocationDetails
    );
  };

  const updatePreviousDetails = ({ data }, type) => {
    if (data.p_out_status === "Success") {
      alert("Cost allocation updated successfully!");
      reloadRightGrid();
      setSaveFlag(false);
    } else {
      alert(data.p_out_err_msg);
      setLoadingDialog(false);
    }
  };

  const handleSave = () => {
    setLoadingDialog(true);
    setSaveFlag(true);
    put("/cost_allocation_update", previousDetails, updatePreviousDetails);
  };

  return (
    <div>
      <span style={{ marginLeft: 10 }}>
        <Button
          size="small"
          onClick={handleEdit}
          className="app-button"
          title="Edit"
          disabled={componentCode.edit_yn !== "Y" ? true : false}
        >
          <span className="k-icon k-i-edit"></span>
        </Button>
        <span title="Prev. Allocation View">
          <Button
            size="small"
            onClick={handlePrevSubmission}
            className="app-button"
            disabled={!allocationStatus || componentCode.edit_yn !== "Y"}
          >
            <span className="k-icon k-i-table-column-insert-right"></span>
          </Button>
        </span>
      </span>
      <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <Grid
          sortable={true}
          pageable={{
            buttonCount: 5,
            pageSizes: allocationDetails?.length !== 0 ? pageSizes : "",
          }}
          skip={page.skip}
          pageSize={page.take}
          sort={rightSort}
          rowHeight={window.innerHeight * 0.067}
          data={orderBy(
            allocationDetails?.map((item) => ({
              ...item,
              [SELECTED_FIELD]: [idGetter(item)],
            })),
            rightSort
          )}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "single",
          }}
          total={
            allocationDetails
              ? allocationDetails.length > 0
                ? allocationDetails[0]?.resultset_size
                : 0
              : 0
          }
          onSortChange={(e) => {
            handleSortChange(e);
          }}
          onPageChange={(e) => {
            handlePageChange(e);
          }}
        >
          <GridColumn
            headerClassName="headerClass"
            field="part"
            title="Part Number"
            width={180}
            cell={(e) => GridCellToolTip(e, "part")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="supplier_common_name"
            title="Supplier Info"
            width={180}
            cell={(e) => GridCellToolTip(e, "supplier_common_name")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="manufacturer_name"
            title="Manufacturer Name"
            width={150}
            cell={(e) => GridCellToolTip(e, "manufacturer_name")}
          />
          <GridColumn
            headerClassName="headerClass"
            field="mpn"
            title="MPN"
            width={100}
            cell={(e) => GridCellToolTip(e, "mpn")}
          />
          {quarterNames.length > 0 &&
            [...quarterNames]?.map((name, i) => {
              return (
                <GridColumn
                  headerClassName="headerClass"
                  className="numberFields"
                  title={name.quarter_name + " (%)"}
                  width={90}
                  field={"q" + (i + 1) + "_alloc"}
                  cell={(e) => GridCellToolTip(e, "q" + (i + 1) + "_alloc")}
                />
              );
            })}
        </Grid>
      </Tooltip>
      {visibleDialog && (
        <div className="window-costAllocation">
          <Window
            initialHeight={window.innerHeight * 0.955}
            initialWidth={window.innerWidth * 0.9}
            style={{ zIndex: "1" }}
            title={
              <WindowTitle
                title={
                  updateAllocations[0]?.gpn_number
                    ? `Edit Allocation (GPN : ${updateAllocations[0]?.gpn_number})`
                    : "Edit Allocation"
                }
                icon="k-i-table k-i-table-unmerge"
              />
            }
            onClose={toggleDialog}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <div style={{ marginTop: "-15px" }}>
              {loadingDialog ? <ProgressBar /> : ""}
            </div>
            <PreviousAllocationEditGrid
              updateAllocations={updateAllocations}
              quarterNames={quarterNames}
              setPercentage={setPercentage}
              handleAllocationChange={handleAllocationChange}
              setTotalAlloc={setTotalAlloc}
              GridCellToolTip={GridCellToolTip}
            />
            <div>
              <Button
                icon="save"
                className="save-button"
                style={
                  totalAlloc === true
                    ? { marginTop: "-1px" }
                    : { float: "right" }
                }
                onClick={handleSave}
                disabled={
                  totalAlloc === true || saveFlag === true ? true : false
                }
              >
                Save
              </Button>
              {totalAlloc === true && (
                <p className="alloc-validation-msg">
                  Total Cost Allocation cannot exceed 100
                </p>
              )}
            </div>
          </Window>
        </div>
      )}
      {prevDialog && (
        <PreviousSubmissionView
          setPrevDialog={setPrevDialog}
          prevDialog={prevDialog}
          componentCode={componentCode}
          reloadRightGrid={reloadRightGrid}
          copyStatus={copyStatus}
          setCopyStatus={setCopyStatus}
        />
      )}
    </div>
  );
};

export default PreviousAllocation;
