import { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import FileValidator from "./fileValidator";
import {
  Popover,
  PopoverActionsBar,
  Tooltip,
} from "@progress/kendo-react-tooltip";
import FileUploader from "../../../components/FileUploader/FileUploader";
import { Button } from "@progress/kendo-react-buttons";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { getRequest, post } from "../../../services/api.request";
import "./uploadComponent.css";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { FormAutoComplete } from "../../commodity-code-view/FormComponent/form-components";
import ImportComponent from "./importComponent";

const paginationData = {
  skip: 0,
  take: 10,
};

const UploadComponent = ({
  user_id,
  currentQuarter,
  setUploadLoading,
  quarterNames,
  uploadWindow,
}) => {
  const limit = 10;
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUploadWindow, setFileUploadWindow] = useState(false);
  const [uploadDlgLoading, setUploadDlgLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [page, setPage] = useState(paginationData);
  const [showImportTable, setShowImportTable] = useState(false);
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [uploadTableDetails, setUploadTableDetails] = useState([]);
  const [downloadIdList, setDownloadIdList] = useState({ data: [], value: "" });
  const [importedFlg, setImportedFlag] = useState(false);
  const [isUploadFlag, setIsUploadFlag] = useState(false);
  const [showFileErrorMsg, setShowFileErrorMsg] = useState(false);
  const [actualDownloadID, setActualDownloadID] = useState();
  const [getError, setGetError] = useState(false);
  const [isCorrectFileName, setIsCorrectFileName] = useState("");
  const errorMsg = useRef();
  const media_ID = useRef();
  const anchor = useRef(null);
  const downloadList = useRef([]);
  const donwload_ID = useRef();

  const handleDownloadDetails = ({ data }) => {
    data && setUploadTableDetails(data.items);
    downloadList.current = data.items.map((item) => {
      return item.download_id;
    });
    setDownloadIdList({ ...downloadIdList, data: downloadList.current });
    isUploadFlag === true && checkData(data.items);
    setUploadLoading(false);
  };

  const checkData = (data) => {
    let reuiredActionData = data.filter((item) => {
      return item.download_id === actualDownloadID;
    });
    let checkProcessCompletion =
      reuiredActionData[0].upload_status === "Error" ||
      reuiredActionData[0].upload_status.extraction_status === "Errored" ||
      reuiredActionData[0].file_validation_status === "Error" ||
      reuiredActionData[0].validation_status === "Errored" ||
      "Warning"
        ? true
        : false;
    setGetError(checkProcessCompletion);
  };

  const fetchDownloadDetails = () => {
    setUploadLoading(true);
    getRequest(
      `ca_media_status/${user_id}/${currentQuarter}?offset=` +
        page.skip +
        "&limit=" +
        limit,
      handleDownloadDetails
    );
  };

  useEffect(() => {
    if (isUploadFlag && !getError) {
      const interval = setInterval(() => {
        fetchDownloadDetails();
      }, 4000);
      return () => {
        clearInterval(interval);
      };
    } else fetchDownloadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadWindow, importedFlg, isUploadFlag, getError]);

  const showFileValidationErrorMsg = (data) => {
    errorMsg.current = data;
    setShowFileErrorMsg(true);
  };

  const uploadFileName = (props) => {
    let linkedTd =
      props.dataItem?.file_validation_status?.toLowerCase() === "error" ? (
        <td
          onClick={(e) =>
            showFileValidationErrorMsg(props.dataItem.file_validation_message)
          }
          className="fileName"
          style={{ textDecoration: "underline", color: "blue" }}
          title={props.dataItem.file_name}
        >
          {props.dataItem.file_name}
        </td>
      ) : (
        <td className="fileName" title={props.dataItem.file_name}>
          {props.dataItem.file_name}
        </td>
      );
    return linkedTd;
  };

  const readUploadFile = (e) => {
    var fileInput = document.getElementById("upload");
    var filePath = e.target.value;
    var allowedExtensions = /(\.xlsx|\.xls)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type");
      fileInput.value = "";
      return false;
    } else {
      e.preventDefault();
      if (e.target.files && e.target.files?.length > 0) {
        let fileName = e.target.files[0].name;
        let uploadFile = fileName.substring(0, fileName.lastIndexOf("."));
        checkFileValidation(uploadFile);
        setFile(e.target.files[0]);
      }
      return true;
    }
  };

  const getUploadId = (id) => {
    setActualDownloadID(id);
    setFileUploadWindow(true);
  };

  const handleUpload = (props) => {
    return (
      <td className="col">
        <Button
          size="small"
          title="Upload File"
          className="app-button"
          onClick={(e) => getUploadId(props.dataItem.download_id)}
          icon="upload"
          disabled={props.dataItem.media_id !== null}
        />
      </td>
    );
  };

  const savedUploadedFile = ({ data }) => {
    if (data.p_out_status === "Success") {
      alert(data.p_out_err_msg);
      setFile(null);
      setFileName("");
      setIsEnabled(false);
      setFileUploadWindow(false);
      setIsUploadFlag(true);
    } else alert(data.p_out_status);
    setUploadDlgLoading(false);
  };

  const getUploadMessage = ({ data }) => {
    if (data?.allow_upload === "Y") {
      const formData = new FormData();
      formData.append("body", file);
      let url = `/media/?file_name=${fileName}&source_name=COST ALLOCATION&user_id=${user_id}&current_qtr=${currentQuarter}&attribute5_num=${actualDownloadID}`;
      post(url, formData, savedUploadedFile);
    }
  };

  const handleChosenFile = () => {
    setUploadDlgLoading(true);
    setIsEnabled(true);
    getRequest(
      `upload_allow_yn_cau?user_id=${user_id}&source_name=COST_ALLOCATION&attribute5_num=${actualDownloadID}`,
      getUploadMessage
    );
  };

  const reloadGrid = () => {
    fetchDownloadDetails();
    setIsSubmit(false);
    setSelectedId("");
    setShow(false);
  };

  const handlePageChange = (e) => {
    setUploadLoading(true);
    let url = `ca_media_status/${user_id}/${currentQuarter}?offset=`;
    getRequest(
      url + e.page.skip + "&limit=" + e.page.take,
      handleDownloadDetails
    );
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const showImportDetails = (data) => {
    media_ID.current = data.media_id;
    donwload_ID.current = data.download_id;
    setShowImportTable(true);
  };

  const viewImportedData = (props) => {
    let linkedTd =
      props.dataItem?.validation_status?.toLowerCase() === "complete" ||
      props.dataItem?.validation_status?.toLowerCase() === "errored" ||
      props.dataItem?.validation_status?.toLowerCase() === "warning" ? (
        <td
          onClick={(e) => showImportDetails(props.dataItem)}
          className="col"
          style={{ textDecoration: "underline", color: "blue" }}
          title={props.dataItem.download_id}
        >
          {props.dataItem.download_id}
        </td>
      ) : (
        <td className="col">{props.dataItem.download_id}</td>
      );
    return linkedTd;
  };

  const openPopover = () => {
    setShow(true);
  };

  const filterData = (value) => {
    const data = downloadList.current;
    const filter = {
      value: value,
      operator: "startswith",
      ignoreCase: true,
    };
    return value ? filterBy(data, filter) : data;
  };

  const handleUploadId = (e) => {
    setSelectedId(e.target.value.toString());
    setDownloadIdList({
      data: filterData(e.target.value.toString()),
      value: e.target.value.toString(),
    });
  };

  const onFormSubmit = (e) => {
    setUploadLoading(true);
    setPage(paginationData);
    getRequest(
      `ca_media_status/${user_id}/${currentQuarter}?download_id=` +
        selectedId +
        "&offset=" +
        page.skip +
        "&limit=" +
        limit,
      handleDownloadDetails
    );
    setIsSubmit(true);
    setShow(!show);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    setIsSubmit(false);
    setSelectedId("");
    reloadGrid();
    setShow(false);
  };

  const checkFileValidation = (file) => {
    let mockName = `CMATXWD_COST_ALLOCATION_${actualDownloadID}`;
    if (file === mockName) {
      setIsCorrectFileName(true);
      setFileName(file);
    } else {
      setIsCorrectFileName(false);
      setFileName(`Please upload file with the ID: ${actualDownloadID}`);
    }
  };

  return (
    <div>
      <Button
        onClick={reloadGrid}
        size="small"
        title="Refresh"
        className="app-button"
        iconClass="k-icon k-i-reload"
      ></Button>
      <Button
        size="small"
        onClick={openPopover}
        title="Filter By"
        className="app-button"
        ref={anchor}
      >
        <span
          className={
            isSubmit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"
          }
        ></span>
      </Button>
      {show && (
        <Popover
          show={show}
          anchor={anchor.current && anchor.current.element}
          position={"bottom"}
        >
          <form>
            <div
              style={{
                width: 200,
              }}
            >
              <label for="comm_code">ID</label>
              <AutoComplete
                className="comm_code"
                id="download_id"
                name="download_id"
                value={downloadIdList.value}
                onChange={handleUploadId}
                component={FormAutoComplete}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
                data={downloadIdList.data}
              />
            </div>
            <PopoverActionsBar>
              <Button
                className="searchBtn"
                onClick={onFormSubmit}
                primary="true"
                disabled={downloadIdList.value !== "" ? false : true}
              >
                Submit
              </Button>
              <Button onClick={onCancelClick}>Cancel</Button>
            </PopoverActionsBar>
          </form>
        </Popover>
      )}
      <div>
        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          <Grid
            className="uploadStatus"
            onPageChange={(e) => {
              handlePageChange(e);
            }}
            total={
              uploadTableDetails
                ? uploadTableDetails.length > 0
                  ? uploadTableDetails[0]?.resultset_size
                  : 0
                : 0
            }
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            data={uploadTableDetails}
            rowHeight={window.innerHeight * 0.06}
          >
            <GridColumn
              width={61}
              cell={handleUpload}
              headerClassName="headerClass"
              className="col"
              title="Upload"
            />
            <GridColumn
              width={80}
              className="col"
              headerClassName="headerClass"
              key="download_id"
              field="download_id"
              title="ID"
              cell={viewImportedData}
            />
            <GridColumn
              width={150}
              className="col"
              headerClassName="headerClass"
              key="category"
              field="category"
              title="Category"
              cell={(e) => GridCellToolTip(e, "category")}
            />

            <GridColumn
              className="col"
              headerClassName="headerClass"
              key="file_name"
              field="file_name"
              cell={uploadFileName}
              title="File Name"
              width={300}
            />

            <GridColumn
              className="col"
              headerClassName="headerClass"
              key="download_date"
              field="download_date"
              title="Download Date"
              width={115}
              cell={(e) => GridCellToolTip(e, "download_date")}
            />
            <GridColumn
              className="col"
              headerClassName="headerClass"
              key="status"
              field="status"
              cell={FileValidator}
              title="Status"
              width={372}
            />
          </Grid>
        </Tooltip>
      </div>
      {fileUploadWindow && (
        <div className="window-costAllocation">
          <Window
            style={{ height: "180px", width: "285px" }}
            title={<WindowTitle title="Upload" icon="k-i-upload" />}
            onClose={() => {
              setFile(null);
              setFileName("");
              setActualDownloadID();
              setFileUploadWindow(false);
            }}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <div>
              <div style={{ marginTop: "-15px" }}>
                {uploadDlgLoading ? <ProgressBar /> : ""}
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginTop: "15px",
                  alignItems: "center",
                }}
              >
                <label className="input-header-cell">Upload File:</label>
                <FileUploader onUpload={readUploadFile} />
              </div>
              <span className="uploadFileName">
                {fileName ? fileName : "No file selected"}
              </span>
            </div>
            <div>
              <Button
                size="small"
                iconClass="k-icon k-i-upload"
                title="Upload"
                className="save-button"
                onClick={handleChosenFile}
                disabled={
                  isEnabled || !file || isCorrectFileName === false
                    ? true
                    : false
                }
              >
                Upload
              </Button>
            </div>
          </Window>
        </div>
      )}
      {showImportTable && (
        <ImportComponent
          setShowImportTable={setShowImportTable}
          quarterNames={quarterNames}
          media_ID={media_ID.current}
          showImportTable={showImportTable}
          user_id={user_id}
          setImportedFlag={setImportedFlag}
          donwload_ID={donwload_ID.current}
        />
      )}
      {showFileErrorMsg && (
        <Dialog
          title={"File Validation Error"}
          onClose={() => setShowFileErrorMsg(false)}
          width={485}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            {errorMsg.current}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setShowFileErrorMsg(false)}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
export default UploadComponent;
