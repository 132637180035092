import React from 'react'
import { Button } from '@progress/kendo-react-buttons'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip'
import { debounce } from "lodash";
import privateRequest from '../../services/api.config';
import { COSTSUBMISSION_STATUS, ONETIMECHARGE_STATUS, PBL_STATUS, RO_STATUS, SHOULDCOST_STATUS,COSTALLOCATION_STATUS, ROCOGS_STATUS } from '../../pages/OneTimeCharge/status';
import { useEffect } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';

export default function FilterPopUp({
    params,
    show,
    elementRef,
    handleClose,
    handleFilterSubmit,
    filterValue,
    setFilterValue,
    userId = "",
    mediaId = "",
    staticLOVFlag = null,
    hmid = ''
}) {
    const [payload, setPayload] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        if (staticLOVFlag === "Y") {
            let items = [];
            let data = {
                items: ONETIMECHARGE_STATUS
            };
            data.items.forEach((item) => {
                items.push({ ...item, ['status']: item.lookup_value })
            })
            setPayload({ ...payload, ['status']: items })
        }
        else if (staticLOVFlag === "R") {
            let statusArray = [];
            let PBLevelArray = [];
            let statusData = {
                statusLevel: RO_STATUS,
                PBLevel: PBL_STATUS
            };
            statusData.statusLevel.forEach((item) => {
                statusArray.push({ ...item, ['status_level']: item.lookup_value })
            })
            statusData.PBLevel.forEach((k) => {
                PBLevelArray.push({ ...k, ['probability_level']: k.lookup_value })
            })
            setPayload({ ...payload, ['status_level']: statusArray, ['probability_level']: PBLevelArray });
        }
        else if(staticLOVFlag === "SC"){
            let items = [];
            let data = {
                items: SHOULDCOST_STATUS
            };
            data.items.forEach((item) => {
                items.push({ ...item, ['load_status']: item.lookup_value })
            })
            setPayload({ ...payload, ['load_status']: items })
        }
        else if (staticLOVFlag === "CS"){
            let items = [];
            let data = {
                items: COSTSUBMISSION_STATUS
            };
            data.items.forEach((item) => {
                items.push({ ...item, ['load_status']: item.lookup_value })
            })
            setPayload({ ...payload, ['load_status']: items })
        }
        else if(staticLOVFlag ==="RO"){
            let items = [];
            let data = {
                items:ROCOGS_STATUS
            };
            data.items.forEach((item)=>{
                items.push({...payload, ['bu']:item.lookup_value})
            });
            setPayload({...payload, ['bu']:items});
        }
        else if (staticLOVFlag === "CA"){
            let items = [];
            let data = {
                items: COSTALLOCATION_STATUS
            };
            data.items.forEach((item) => {
                items.push({ ...item, ['load_status']: item.lookup_value })
            })
            setPayload({ ...payload, ['load_status']: items })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staticLOVFlag]);
    const handleRequest = (data, field) => {
        let items = [];
        if (data && data.items.length > 0) {
            data.items.forEach((item) => {
                items.push({ ...item, [field]: item.lookup_value })
            })
        }
        if (field) {
            setPayload({ ...payload, [field]: items });
        }
        setIsLoading(false);
    }

    const filterChange = debounce(({ filter }, param) => {
        setIsLoading(true);
        let url = `/generic_lov_service/${param.paramName}/?search_for=${filter.value}`;
        if (param.paramName === "gcmparts" || param.paramName === "gpn" || param.paramName === "gcmcommcodes" || 
        param.paramName === "prefixval" || param.paramName === "gcmcommgroups" || param.paramName === "gcmparts_hierarchy") {
            url += `&user_id=${userId}`
        }
        if (param.paramName === 'scuploadparts' || param.paramName === 'csuploadparts' || param.paramName === 'ro_product'
        || param.paramName === 'ro_upload_commcodes' || param.paramName === 'ro_upload_items') {
            url += `&media_id=${mediaId}`;
        }
        if(param.paramName === 'hierarchyparts'){
           url +=`&user_id=${userId}&hmid=${hmid}`;
        }
        privateRequest
            .get(url)
            .then(({ data }) => {
                handleRequest(data, filter.field)
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, 1000)

    const handleChange = (field, val) => {
        setFilterValue({ ...filterValue, [field]: val })
    }

    const handleClear = () => {
        setFilterValue({})
    }
    return (    
        <Popover
            show={show}
            position={"bottom"}
            anchor={elementRef}
        >
                {isLoading ? (
        <ProgressBar />
      ) : ""}
            {params.map((param) => {
                return (
                    <div key={param.paramName} style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>{param.displayValue}</label>
                        <DropDownList
                            style={{ minWidth: '200px' }}
                            data={Object.keys(payload).length > 0 ? payload[param.id_field] : []}
                            filterable={param.filterable === false ? false : true}
                            textField={param.id_field}
                            dataItemKey={param.id_field}
                            onFilterChange={(e) => filterChange(e, param)}
                            // loading={isLoading}
                            value={filterValue[param.id_field]}
                            onChange={(e) => handleChange(param.id_field, e.target.value)}
                        />
                    </div>
                )
            })}
            <PopoverActionsBar>
                <Button onClick={handleFilterSubmit}>Filter</Button>
                <Button onClick={handleClear}>Clear</Button>
                <Button themeColor={"primary"} onClick={handleClose}>Close</Button>
            </PopoverActionsBar>
        </Popover>
    )
}
