import { useEffect, useContext, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { UserContext } from '../../../App'
import FileUploader from "../../../components/FileUploader/FileUploader";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { getRequest, post } from "../../../services/api.request";

const BrowseUpdateInitiativeFile=({ toggleDialog, params })=>{
    const userContext = useContext(UserContext);
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);     
    const [uploadDlgLoading, setUploadDlgLoading] = useState(false);
    const [isCorrectFileName, setIsCorrectFileName] = useState("");
    const [actualDownloadID, setActualDownloadID] = useState(params.upload_download_id);
    const [isEnabled, setIsEnabled] = useState(false);
    const [uploadMessageDialog, setUploadMessageDialog] = useState(false);
    const [saveFlag, setSaveFlag] = useState(false);
  
    const checkFileValidation = (file) => {
        let mockName = `CMATXWD_COGS_UPDATE_INITATIVE_TEMPLATE_${actualDownloadID}`;
        if (file === mockName) {
          setIsCorrectFileName(true);
          setFileName(file);
        } else {
          setIsCorrectFileName(false);
          setFileName(`Please upload file with the ID: ${actualDownloadID}`);
        }
      };
    const readUploadFile = (e) => {
        var fileInput = document.getElementById("upload");
        var filePath = e.target.value;
        var allowedExtensions = /(\.xlsx|\.xls)$/i;
        if (!allowedExtensions.exec(filePath)) {
          alert("Invalid file type");
          fileInput.value = "";
          return false;
        } else {
          e.preventDefault();
          if (e.target.files && e.target.files?.length > 0) {
            let fileName = e.target.files[0].name;
            let uploadFile = fileName.substring(0, fileName.lastIndexOf("."));
            checkFileValidation(uploadFile);
            setFile(e.target.files[0]);
          }
          return true;
        }
      };
      const handleChosenFile = () => {
        setUploadDlgLoading(true);
        setIsEnabled(true);
        const formData = new FormData();
        formData.append("body", file);
        let url = `media/?file_name=${fileName}&source_name=${"RO COGS REDUCTION"}&attribute5_num=${actualDownloadID}&user_id=${userContext.user_id}&current_qtr=${userContext.curr_qrtr}&upload_initiative=${'U'}`
       post(url, formData, savedUploadedFile);
        // getUploadMessage();
        // getRequest(
        //   `upload_allow_yn?user_id=${userContext?.user_id}&source_name=RO COGS REDUCTION`,
        //   getUploadMessage
        // );
      };
      const savedUploadedFile = ({ data }) => {
        if (data.p_out_status === "Success") {
          alert("File Upload started with id:" + actualDownloadID);
          setFile(null);
          setFileName("");
          setIsEnabled(false);
          toggleDialog();
        } else alert(data.p_out_status);
        setUploadDlgLoading(false);
      };
    
      const getUploadMessage = ({ data }) => {  
        if (data?.allow_upload === "N") {
          setUploadMessageDialog(true);
        } else {
          // setLoading(true);
          setSaveFlag(!saveFlag);
          const formData = new FormData();
          formData.append("body", file);
          let url = `media/?file_name=${fileName}&source_name=${"RO COGS REDUCTION"}&attribute5_num=${actualDownloadID}&user_id=${userContext.user_id}&current_qtr=${userContext.curr_qrtr}&upload_initiative=${'U'}`
         post(url, formData, savedUploadedFile);}
      };
      const toggleConfirmUpload = () => {
        setUploadMessageDialog(false);
        toggleDialog();
      };
      const handleConfirmUpload = () => {
        setUploadMessageDialog(false);
        toggleDialog();
      };
    return(
        <div className='window-shouldCostUpload'>
               <Window
            style={{ height: "250px", width: "400px" }}
            title={`Upload - ${params.upload_download_id}`}
            onClose={ () => {
                toggleDialog();
                setFile(null);
                setFileName("");
                setActualDownloadID();
            }}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <div>
              <div style={{ marginTop: "-15px" }}>
                {uploadDlgLoading ? <ProgressBar /> : ""}
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginTop: "15px",
                  alignItems: "center",
                }}
              >
                <label className="input-header-cell">Upload File:</label>
                <FileUploader onUpload={readUploadFile} />
              </div>
              <span className="uploadFileName">
                {fileName ? fileName : "No file selected"}
              </span>
            </div>
            <div>
              <Button
                size="small"
                iconClass="k-icon k-i-upload"
                title="Upload"
                className="save-button"
                onClick={handleChosenFile}
                disabled={
                  isEnabled || !file || isCorrectFileName === false || saveFlag === true
                    ? true
                    : false
                }
                style={{marginTop:'30px'}}
              >
                Upload
              </Button>
            </div>
            {uploadMessageDialog && (
        <Dialog
          title={"COGS Reduction - Upload"}
          onClose={toggleConfirmUpload}
          width={450}
          height = {200}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            Your Existing uploads awaiting import. Please import and try to
            upload again.
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmUpload}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
          </Window>
        </div>
    )

}
export default BrowseUpdateInitiativeFile;