import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import privateRequest from "../../../services/api.config";
import { getRequest, post, put } from "../../../services/api.request";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "../costSubmission.css";

const SubClosureExclusion = ({ setSearchWindow, user_id, currentQuarter }) => {
  const [commCodesList, setCommCodesList] = useState([]);
  const [commCodeLoading, setCommCodeLoading] = useState(false);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [partList, setPartList] = useState([]);
  const [partsLoading, setPartsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [excludeTableData, setExcludeTableData] = useState([]);
  const [excludeWindow, setExcludeWindow] = useState(false);
  const [excludeTableLoading, setExcludeTableLoading] = useState(false);
  const [alertPriorExclusion, setAlertPriorExclusion] = useState(false);
  const [saveData, setSaveData] = useState([]);
  const [saveParams, setSaveParams] = useState({ cost_lines: [] });
  const [isReIncludedFlag, setIsReIncludedFlag] = useState(false);
  const [excludedList, setExcludedList] = useState([]);
  const [excludedListLoading, setExcludedListLoading] = useState(false);
  const [copyData, setCopyData] = useState([]);
  const [copyRemainingParams, setCopyRemainingParams] = useState({
    cost_lines: [],
  });
  const duplicateArray = useRef([]);
  const copyRmngDuplicateArray = useRef([]);

  const getGCMCommCodes = debounce(({ filter }) => {
    setCommCodeLoading(true);
    let url = `/generic_lov_service/gcmcommcodes/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        setCommCodesList(data.items);
        setCommCodeLoading(false);
      })
      .catch((err) => {
        setCommCodeLoading(false);
      });
  }, 1000);

  const getSupplierName = debounce(({ filter }, param) => {
    setSupplierLoading(true);
    let url = `generic_lov_service/suppliers/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        data && setSupplierList(data.items);
        setSupplierLoading(false);
      })
      .catch((err) => {
        setSupplierLoading(false);
      });
  }, 1000);

  const getPartNumber = debounce(({ filter }, param) => {
    setPartsLoading(true);
    let url = `generic_lov_service/gcmparts_hierarchy/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        data && setPartList(data.items);
        setPartsLoading(false);
      })
      .catch((err) => {
        setPartsLoading(false);
      });
  }, 1000);

  const handleValues = (e) => {
    switch (e.value.lookup_type) {
      case "gcmcommcodes":
        setSelectedValue({ ...selectedValue, gcmcommcodes: e.value });
        break;
      case "gcmparts_hierarchy":
        setSelectedValue({ ...selectedValue, gcmparts_hierarchy: e.value });
        break;
      case "suppliers":
        setSelectedValue({ ...selectedValue, suppliers: e.value });
        break;
      default:
        break;
    }
  };

  const handleReload = () => {
    setExcludeTableLoading(true);
    setSaveData([]);
    setSaveParams({ cost_lines: [] });
    setIsReIncludedFlag(false);
    handleSearch();
  };

  const handleSearch = () => {
    setSearchLoading(true);
    setExcludeWindow(true);
    setExcludeTableLoading(true);
    getRequest(
      `copy_excl_search_vw?user_id=${user_id}&current_quarter=${currentQuarter}&exclude_flag=&ccid=${
        selectedValue.gcmcommcodes ? selectedValue.gcmcommcodes.attribute2 : ""
      }&part=${
        selectedValue.gcmparts_hierarchy
          ? selectedValue.gcmparts_hierarchy.lookup_value
          : ""
      }&supplier_id=${
        selectedValue.suppliers ? selectedValue.suppliers.lookup_code : ""
      }`,
      handleProceed
    );
  };

  const handleProceed = ({ data }) => {
    if (data.items.length > 0) {
      setExcludeTableData(data.items);
      getDuplicate(data.items);
      let isAllCopied = data.items.every((item) => item.cp_flag === "Y");
      setIsReIncludedFlag(isAllCopied);
    } else {
      setExcludeTableData([]);
      alert("There is no Prior submission for the combination.");
    }
    setSearchLoading(false);
    setExcludeTableLoading(false);
  };

  const getDuplicate = useCallback(
    (data) => {
      duplicateArray.current = JSON.parse(JSON.stringify(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveData]
  );

  const getCopyRmngDuplicate = useCallback(
    (data) => {
      copyRmngDuplicateArray.current = JSON.parse(JSON.stringify(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copyData]
  );

  const handleClear = () => {
    setSelectedValue({
      gcmcommcodes: null,
      gcmparts_hierarchy: null,
      suppliers: null,
    });
  };

  useEffect(() => {
    duplicateArray.current &&
      duplicateArray.current.forEach((data, index) => {
        saveData.forEach((item, i) => {
          if (item.csid === data.csid && item.exclude_yn === data.exclude_yn) {
            saveData.splice(i, 1);
          }
        });
      });
    setSaveParams({ cost_lines: saveData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const handleCheckBox = (e, index, Object) => {
    const list = [...excludeTableData];
    if (e.value === true) {
      saveData.forEach((item, i) => {
        if (item.csid === Object.csid) {
          saveData.splice(i, 1);
        }
      });
      list[index]["exclude_yn"] = "Y";
      setSaveData([...saveData, { csid: Object.csid, exclude_yn: "Y" }]);
    } else {
      saveData.forEach((item, i) => {
        if (item.csid === Object.csid) {
          saveData.splice(i, 1);
        }
      });
      checkPriorExclusion(Object);
      list[index]["exclude_yn"] = "N";
      setSaveData([...saveData, { csid: Object.csid, exclude_yn: "N" }]);
    }
    setExcludeTableData(list);
  };

  const checkPriorExclusion = (Object) => {
    if (Object.cp_flag === "Y") {
      setIsReIncludedFlag(true);
    } else setIsReIncludedFlag(false);
  };

  const handleSave = () => {
    if (saveParams?.cost_lines.length > 0) {
      setExcludeTableLoading(true);
      put(`exclude_records?p_user_id=${user_id}`, saveParams, getUpdatedList);
    } else {
      let allCopied = excludeTableData.some((item) => item.exclude_yn === "Y");
      allCopied === false && isReIncludedFlag === true
        ? alert(
            `${
              selectedValue.gcmcommcodes
                ? selectedValue.gcmcommcodes.lookup_value
                : ""
            }-${
              selectedValue.gcmparts_hierarchy
                ? selectedValue.gcmparts_hierarchy.lookup_value
                : ""
            }-${
              selectedValue.suppliers
                ? selectedValue.suppliers.lookup_value
                : ""
            } records copied from Previous Quarter to Current Quarter.`
          )
        : alert("No changes to save.");
    }
  };

  const getUpdatedList = ({ data }) => {
    if (data.p_out_status === "success") {
      alert(
        data.p_out_err_msg +
          ". Please correct Cost Allocations for these Parts, from the Cost Allocation Screen."
      );
      if (isReIncludedFlag === true) {
        let params = saveParams.cost_lines.filter(
          (item) => item.exclude_yn !== "Y"
        );
        params.length > 0 &&
          post(
            `copy_cs_inclusion_records?p_current_qtr=${currentQuarter}&p_user_id=${user_id}&p_cr_flag=N`,
            { cost_lines: params },
            handleCopy
          );
      }
    } else alert(data.p_out_err_msg);
    setExcludeTableLoading(false);
  };

  const handlePriorExclusion = (e) => {
    setExcludedListLoading(true);
    if (copyData.length > 0) {
      post(
        `copy_cs_inclusion_records?p_current_qtr=${currentQuarter}&p_user_id=${user_id}&p_cr_flag=Y`,
        copyRemainingParams,
        handleCopy
      );
    } else {
      alert("Select at least a Part to Copy.");
      setExcludedListLoading(false);
    }
  };

  const handleCopy = ({ data }) => {
    if (data.p_ret_status === "S") {
      alert(data.p_ret_msg);
      copyData.length > 0 ? handleReloadCopyWindow() : handleReload();
    } else alert(data.p_ret_msg);
    setExcludedListLoading(false);
  };

  const handleExcludedData = ({ data }) => {
    if (data.items.length > 0) {
      let arr = [...data.items];
      arr.forEach((val, index) => {
        if (val.cp_flag === "Y") {
          arr[index]["checked_flag"] = "Y";
        } else {
          arr[index]["checked_flag"] = "N";
        }
      });
      setExcludedList(arr);
      getCopyRmngDuplicate(arr);
    } else {
      setExcludedList([]);
      alert("There is no Parts to Copy.");
    }
    setExcludedListLoading(false);
  };

  const handleCopyRemaining = () => {
    setExcludeWindow(false);
    setAlertPriorExclusion(true);
    setExcludedListLoading(true);
    getRequest(
      `copy_remaining_vw?user_id=${user_id}&current_quarter=${currentQuarter}`,
      handleExcludedData
    );
  };

  useEffect(() => {
    copyRmngDuplicateArray.current &&
      copyRmngDuplicateArray.current.forEach((data, index) => {
        copyData.forEach((item, i) => {
          if (item.part_id === data.part_id && item.cp_flag === data.cp_flag) {
            copyData.splice(i, 1);
          }
        });
      });
    setCopyRemainingParams({ cost_lines: copyData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyData]);

  const handleCopyRemCheck = (e, index, Object) => {
    const list = [...excludedList];
    if (e.value === true) {
      copyData.forEach((item, i) => {
        if (item.part_id === Object.part_id) {
          copyData.splice(i, 1);
        }
      });
      list[index]["checked_flag"] = "Y";
      setCopyData([
        ...copyData,
        { ccid: Object.ccid, part_id: Object.part_id, cp_flag: "Y" },
      ]);
    } else {
      copyData.forEach((item, i) => {
        if (item.part_id === Object.part_id) {
          copyData.splice(i, 1);
        }
      });
      list[index]["checked_flag"] = "N";
      setCopyData([
        ...copyData,
        { ccid: Object.ccid, part_id: Object.part_id, cp_flag: "N" },
      ]);
    }
    setExcludedList(list);
  };

  const handleReloadCopyWindow = () => {
    setExcludedListLoading(true);
    setCopyData([]);
    setCopyRemainingParams({ cost_lines: [] });
    handleCopyRemaining();
  };

  return (
    <>
      <div className="window-costAllocation">
        <Window
          style={{ zIndex: "1", height: "295px", width: "300px" }}
          title={
            <WindowTitle
              title="Exclusion"
              icon="k-i-close-outline k-i-x-outline"
            />
          }
          onClose={() => setSearchWindow(false)}
          minimizeButton={() => null}
          maximizeButton={() => null}
        >
          <React.Fragment>
            <div style={{ marginTop: "-15px" }}>
              {searchLoading ? <ProgressBar /> : ""}
            </div>
            <div className="searchWindow">
              <div>
                <label className="input-header-cell">Commodity Code:</label>
                <DropDownList
                  data={commCodesList}
                  filterable={true}
                  textField="lookup_value"
                  dataItemKey="lookup_value"
                  onFilterChange={getGCMCommCodes}
                  loading={commCodeLoading}
                  value={selectedValue.gcmcommcodes}
                  onChange={handleValues}
                />
              </div>
              <div>
                <label className="input-header-cell">Part Number:</label>
                <DropDownList
                  data={partList}
                  filterable={true}
                  textField="lookup_value"
                  onFilterChange={getPartNumber}
                  loading={partsLoading}
                  value={selectedValue.gcmparts_hierarchy}
                  onChange={handleValues}
                />
              </div>
              <div>
                <label className="input-header-cell">Supplier Name:</label>
                <DropDownList
                  data={supplierList}
                  filterable={true}
                  textField="lookup_value"
                  onFilterChange={getSupplierName}
                  loading={supplierLoading}
                  value={selectedValue.suppliers}
                  onChange={handleValues}
                />
              </div>
            </div>
            <Button
              style={{ bottom: "-15px" }}
              size="small"
              title="Proceed"
              className="save-button"
              onClick={handleSearch}
              icon="k-i-zoom k-i-search"
              disabled={
                Object.values(selectedValue).length > 0 &&
                Object.values(selectedValue).filter(Boolean).length > 0
                  ? false
                  : true
              }
            >
              Search
            </Button>
            <Button
              style={{ bottom: "-15px" }}
              size="small"
              title="Clear"
              className="save-button"
              onClick={handleClear}
              disabled={
                Object.values(selectedValue).length > 0 &&
                Object.values(selectedValue).filter(Boolean).length > 0
                  ? false
                  : true
              }
            >
              Clear
            </Button>
          </React.Fragment>
        </Window>
      </div>
      {excludeWindow && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle
                title="Submission Exclusion"
                icon="k-i-close-outline k-i-x-outline"
              />
            }
            initialWidth={980}
            initialHeight={455}
            style={{ zIndex: "1" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
            onClose={() => {
              setExcludeTableData([]);
              setSaveData([]);
              setExcludeWindow(false);
            }}
          >
            <div style={{ marginTop: "-15px" }}>
              {excludeTableLoading ? <ProgressBar /> : ""}
            </div>
            <Button
              size="small"
              onClick={handleReload}
              className="app-button"
              title="Refresh"
              iconClass="k-icon k-i-reload"
            ></Button>
            <Button
              size="small"
              style={{ marginLeft: 5 }}
              className="app-button"
              onClick={handleCopyRemaining}
              iconClass="k-icon k-i-copy k-i-files"
              title="Copy Remaining"
            ></Button>
            <div>
              <div className="excludeTableHead">
                <div>Exclude</div>
                <div>Commodity Code</div>
                <div>Part Number</div>
                <div>Supplier Name</div>
                <div>Manufacturer Name</div>
                <div>Exclusion Date</div>
              </div>
            </div>
            <div className="excludeTableBody">
              {excludeTableData.length > 0 &&
                [...excludeTableData].map((item, i) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        className="example-col"
                      >
                        <Tooltip anchorElement="target" position="top">
                          <Checkbox
                            checked={item.exclude_yn === "Y" ? true : false}
                            style={{
                              borderColor: "#666666",
                              pointerEvents: "auto",
                              marginLeft: "14px",
                              justifyContent: "center",
                            }}
                            defaultChecked={
                              item.exclude_yn === "Y" ? true : false
                            }
                            value={item.exclude_yn}
                            onChange={(e) => handleCheckBox(e, i, item)}
                            id={i}
                            disabled={
                              item.sub_closure_status === "Y" ||
                              item.cs_exists_q0 === "Y"
                                ? true
                                : false
                            }
                            title={
                              item.sub_closure_status === "Y"
                                ? "Commodity Code already turned in"
                                : item.cs_exists_q0 === "Y"
                                ? "Part Supplier combination already exists in Current Quarter"
                                : item.sub_closure_status === "Y" &&
                                  item.cs_exists_q0 === "Y"
                                ? "Commodity Code already turned in and Part Supplier combination already exists in current quarter"
                                : ""
                            }
                          />
                        </Tooltip>
                        <label
                          style={{
                            width: "193px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.commodity_code}
                        </label>
                        <label
                          style={{
                            width: "180px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.part}
                        </label>
                        <label
                          style={{
                            width: "230px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.supplier_common_name}
                        </label>
                        <label
                          style={{
                            width: "180px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.manufacturer_name}
                        </label>
                        <label
                          style={{
                            width: "140px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {item.excluded_on}
                        </label>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            <Button
              style={{ bottom: "-15px" }}
              size="small"
              title="Proceed"
              className="save-button"
              onClick={handleSave}
              icon="save"
            >
              Save
            </Button>
          </Window>
        </div>
      )}
      {alertPriorExclusion && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle
                title="Copy Remaining (Submission Exclusion)"
                icon="k-i-close-outline k-i-x-outline"
              />
            }
            initialHeight={485}
            initialWidth={480}
            style={{ zIndex: "1" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
            onClose={() => {
              setExcludedList([]);
              setCopyData([]);
              setCopyRemainingParams({});
              setAlertPriorExclusion(false);
              setExcludeWindow(true);
              handleReload();
            }}
            draggable={false}
          >
            <div style={{ marginTop: "-15px" }}>
              {excludedListLoading ? <ProgressBar /> : ""}
            </div>
            <p style={{ marginBottom: "1px" }}>
              List of Parts available for Copy. Do you want to Copy?
            </p>
            <Button
              size="small"
              onClick={handleReloadCopyWindow}
              className="app-button"
              title="Refresh"
              iconClass="k-icon k-i-reload"
            ></Button>
            <div>
              <div className="crTableHead">
                <div>Copy</div>
                <div>Commodity Code</div>
                <div>Part Number</div>
              </div>
            </div>
            <div className="viewTable">
              {excludedList.length > 0 &&
                [...excludedList].map((item, i) => {
                  return (
                    <React.Fragment>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          marginLeft: "9px",
                        }}
                        className="example-col"
                      >
                        <Checkbox
                          checked={item.checked_flag === "Y" ? true : false}
                          style={{
                            borderColor: "#666666",
                            pointerEvents: "auto",
                            marginLeft: "25px",
                            justifyContent: "center",
                          }}
                          defaultChecked={
                            item.checked_flag === "Y" ? true : false
                          }
                          value={item.checked_flag}
                          onChange={(e) => handleCopyRemCheck(e, i, item)}
                          id={i}
                          disabled={item.cp_flag === "Y" ? true : false}
                          title={item.cp_flag === "Y" && "Copied"}
                        />
                        <label
                          style={{
                            width: "305px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {item.commodity_code}
                        </label>
                        <label
                          style={{
                            width: "240px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {item.part}
                        </label>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            <div style={{ float: "right" }}>
              <Button
                size="small"
                className="action-button"
                onClick={() => {
                  setExcludedList([]);
                  setCopyData([]);
                  setCopyRemainingParams({});
                  setAlertPriorExclusion(false);
                  setExcludeWindow(true);
                  handleReload();
                }}
              >
                No
              </Button>
              <Button
                size="small"
                className="action-button"
                onClick={handlePriorExclusion}
              >
                Yes
              </Button>
            </div>
          </Window>
        </div>
      )}
    </>
  );
};
export default SubClosureExclusion;
