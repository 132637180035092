import React from "react";
import "./QuarterValue.css";

const QuarterValue = (props) => {
  const prevQuarterKey = props.field.toString().replace("currq", "prevq");
  const currValue = props.dataItem[props.field];
  const prevValue = props.dataItem[prevQuarterKey];

  return (
    <td
      onClick={props.selectionChange}
      className={
        currValue && prevValue && currValue !== prevValue
          ? currValue < prevValue
            ? "redClass"
            : "greenClass"
          : undefined
      }
      title={props.dataItem[props.field]}
    >
   {props.dataItem[props.field]}
    </td>
  );
};

export default QuarterValue;
