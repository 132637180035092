import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Form, FormElement } from "@progress/kendo-react-form";
import "./costSubmission.css";
import { getRequest, put, remove } from "../../services/api.request";
import { orderBy } from "@progress/kendo-data-query";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { UserContext } from "../../App";
import { Input, NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import CostSubmissionUpload from "./components/CostSubmissionUpload";
import WindowTitle from "../../components/WindowTitle/WindowTitle";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {
  formatISOStringToShortDate,
  formatLongDateToString,
  setLocal,
} from "../../utils/formatDateUtil";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import { debounce } from "lodash";
import privateRequest from "../../services/api.config";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import SubmissionClosure from "./components/submissionClosure";
import CostSubmissionDownload from "./components/CostSubmissionDownload.jsx";
import CostSubmissionUploadFileStatus from "./components/CostSubmissionUploadFileStatus";
import SubClosureExclusion from "./components/subClosureExclusion";
import CostSubmissionDelete from "./components/costSubmissionDelete";

const initialDataState = {
  skip: 0,
  take: 10,
};

const styles = {
  fontSize: "max(16px, 1em)",
  fontFamily: "inherit",
  padding: " 0.25em 0.5em",
  backgroundColor: " #fff",
  border: "2px solid #f0f0f0",
  borderRadius: "4px",
  width: "150px",
  color: "#424242",
};

const CostSubmission = () => {
  const limit = 10;
  const DATA_ITEM_KEY = "csid";
  const SELECTED_FIELD = "selected";
  const FILTER_PARAMS = [
    {
      paramName: "gcmparts_hierarchy",
      displayValue: "Part Number",
      id_field: "part",
      api_field: "part",
    },
    {
      paramName: "suppliers",
      displayValue: "Supplier Common Name",
      id_field: "supplier_common_name",
      api_field: "supplier_id",
    },
    {
      paramName: "tpmanufacturer",
      displayValue: "Manufacturer",
      id_field: "Manufacturer",
      api_field: "Manufacturer",
      dependent: "part",
    },
    {
      paramName: "gcmcommcodes",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
    },
    {
      paramName: "gcmcommgroups",
      displayValue: "Commodity Group",
      id_field: "commodity_group",
      api_field: "commodity_group",
    },
  ];

  const user = useContext(UserContext);
  const userContext = useContext(UserContext);
  const user_id = userContext?.user_id;
  const currentQuarter = userContext?.curr_qrtr;
  const gcm_append_yn = userContext.gcm_append_yn;

  const gridWidth = window.innerWidth * 0.86;
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };

  const [filterValue, setFilterValue] = useState({});
  const [dataState, setDataState] = useState([]);
  const [pageSizes, setPageSizes] = useState([10, 25, 50]);
  const [dataLabel, setDataLabel] = useState();
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [componentCode, setComponentCode] = useState();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [sort, setSort] = useState([]);
  const [dataChg, setDataChg] = useState(false);
  const [exportCols, setExportCols] = useState([]);
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = useState({});
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(initialDataState);
  const [dis, setDis] = useState(true);
  const [disable, setDisable] = useState(false);
  const [vis, setVis] = useState(true);
  const [commodityCodeList, setCommodityCodeList] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [allproductData, setAllProductData] = useState([]);
  const [allsupplierData, setAllSupplierData] = useState([]);
  const [commodityCodeData, setCommodityCodeData] = useState({});
  const [manufacturerData, setManufacturerData] = useState([]);
  const [actualPrice, setActualPrice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewUploadStatus, setViewUploadStatus] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  const [uploadDialog, openUploadDialog] = useState(false);
  const [downloadDialog, openDownloadDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [isLoadingManufacturer, setIsLoadingManufacturer] = useState(false);
  const [headerClass, setHeaderClass] = useState({});
  const [submit, setSubmit] = useState(false);
  const [submissionClosureData, setSubmissionClosureData] = useState([{}]);
  const [manufacturerExists, setManufacturerExists] = useState(true);
  const [editFlag, setEditFlag] = useState(false);
  const [missingCostDialog, setMissingCostDialog] = useState(false);
  const [closureConfirmation, setClosureConfirmation] = useState(false);
  const [closureWindowLoading, setClosureWindowLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [allocCompleted, setAllocCompleted] = useState(false);
  const [filterURL, setFilterURL] = useState();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [getComCode, setComCode] = useState(0);
  const [searchWindow, setSearchWindow] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSearchWindow, setDeleteSearchWindow] = useState(false);
  const [prevQuarterNames, setPrevQuarterNames] = useState([]);
  const [enableCommCode, setEnableCommCode] = useState(false);
  const [checkedValues, setCheckedValues] = useState({
    ccid: undefined,
    commodity_code: undefined,
  });
  const [prefixSupplierData, setPrefixSupplierData] = useState([]);
  const [addCostSubmissionData, setAddCostSubmissionData] = useState({
    part: "",
    supplier_common_name: "",
    manufacturer_name: null,
    user_id: user_id,
    part_id: "",
    supplier_id: "",
    q0: "",
    commodity_code: "",
    comm_code: "",
  });
  const [enableSave, setEnableSave] = useState({
    parts: false,
    tdd: false,
    alloc: false,
    arrLen: false,
  });
  const anchor = useRef(null);
  const toggleAction = useRef("");

  const getHeaderClasses = (gridRow) => {
    let headerClass = {};
    try {
      for (let i = 1; i <= 9; i++) {
        let currentHeader = "q" + i + "_price";
        let col2 = gridRow[currentHeader]
          ? parseFloat(gridRow[currentHeader])
          : null;
        let col1 = gridRow["q" + (i - 1) + "_price"]
          ? parseFloat(gridRow["q" + (i - 1) + "_price"])
          : null;
        if (col2 && col1) {
          if (col2 > col1) {
            headerClass[currentHeader] = "red";
          } else if (col2 < col1) {
            headerClass[currentHeader] = "green";
          }
        }
      }
    } catch (e) {}
    setHeaderClass(headerClass);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      event.dataItems.filter(
        (dataItem) =>
          dataItem[DATA_ITEM_KEY] === Object.keys(newSelectedState)[0]
      );

      getHeaderClasses(event.dataItems[event.startRowIndex]);
    },
    [selectedState]
  );

  const getSrr = ({ data }, type) => {
    var cols = [];
    setDataLabel(data.get_eight_quarter);
    if (toggleAction.current === "add") {
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        q1: data.get_eight_quarter[0].quarter_name,
        q1_price: null,
        q2: data.get_eight_quarter[1].quarter_name,
        q2_price: null,
        q3: data.get_eight_quarter[2].quarter_name,
        q3_price: null,
        q4: data.get_eight_quarter[3].quarter_name,
        q4_price: null,
        q5: data.get_eight_quarter[4].quarter_name,
        q5_price: null,
        q6: data.get_eight_quarter[5].quarter_name,
        q6_price: null,
        q7: data.get_eight_quarter[6].quarter_name,
        q7_price: null,
        q8: data.get_eight_quarter[7].quarter_name,
        q8_price: null,
      });
    }
    data.get_eight_quarter.map((col) =>
      cols.push({ field: col.quarter_name, title: col.quarter_name })
    );
    setExportCols(cols);
  };

  const getPrevQuarterNames = ({ data }, type) => {
    setPrevQuarterNames(data.items);
  };

  const getSBC = ({ data }, type) => {
    setHeaderClass({});
    if (data.items.length > 0) {
      data.items.forEach((element) => {
        for (let i = 1; i < 9; i++) {
          element[`q${i}_price`] =
            element[`q${i}_price`] === null
              ? null
              : parseFloat(element[`q${i}_price`].replace(/,/g, ""));
        }
      });
      setDataState(data.items);
      if (data.items.length !== 0) {
        if (data.items[0].resultset_size < 50) {
          setPageSizes([10, 25]);
        } else if (data.items[0].resultset_size < 25) {
          setPageSizes([10]);
        }
      }
      let obj = [];
      let c = [];
      data.items.forEach((element) => {
        c = Object.keys(element);
      });
      c.forEach((element, index) => {
        if (
          element !== "q0" &&
          element !== "prev_q1" &&
          element !== "prev_q2" &&
          element !== "prev_q3" &&
          element !== "prev_q4" &&
          element !== "prev_q5" &&
          element !== "prev_q6" &&
          element !== "prev_q7"
        ) {
          obj.push({ title: element });
        }
      });
      Object.keys(obj[0]).filter((k) => obj[k] === "q0");
      for (var f in obj[0]) {
        if (obj[0][f] === "q0") {
          delete obj[0][f];
        }
      }
    } else setDataState([]);
    setLoading(false);
  };

  const reloadGrid = () => {
    setHeaderClass({});
    setLoading(true);
    setPage({ skip: 0 });
    getRequest(
      `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&offset=0&limit=` +
        limit,
      getSBC
    );
    setSubmit(false);
    setSort([]);
    setSelectedState({});
    setDataChg(false);
    setDis(true);
    setDisable(false);
  };

  const reloadGridWithPageSave = () => {
    setLoading(true);
    if (dataChg === true) {
      getRequest(
        filterURL + `&offset=${page.skip}&limit=${page.take} `,
        getSBC
      );
      setSubmit(true);
    } else {
      getRequest(
        `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&offset=${page.skip}&limit=${page.take}`,
        getSBC
      );
      setSubmit(false);
      setDataChg(false);
    }
    setSort([]);
    setSelectedState({});
    setDis(true);
    setDisable(false);
  };

  useEffect(() => {
    setLoading(true);
    getRequest(
      `cost_submission_quarters/lov?p_current_quarter=${currentQuarter}`,
      getSrr
    );
    getRequest(
      `cost_submission_prev_quarters/lov?current_quarter=${currentQuarter}`,
      getPrevQuarterNames
    );
    getRequest(
      `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&offset=` +
        page.skip +
        "&limit=" +
        page.take,
      getSBC
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const onCancelClick = (event) => {
    event.preventDefault();
    setShow(false);
  };

  const handleSortChange = (e) => {
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest(
          `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&q=` +
            encodeURIComponent(queryString) +
            "&current_quarter=" +
            currentQuarter +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getSBC
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&q=` +
            encodeURIComponent(queryString) +
            "&current_quarter=" +
            currentQuarter +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getSBC
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else if (dataChg === true) {
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          url =
            param.paramName === "gcmparts_hierarchy"
              ? `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_value"]
                }`
              : param.paramName === "gcmcommcodes" ||
                param.paramName === "gcmcommgroups"
              ? `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_value"]
                }`
              : `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_code"]
                }`;
        }
      });
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest(
          `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&q=` +
            encodeURIComponent(queryString) +
            "&current_quarter=" +
            currentQuarter +
            url +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getSBC
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}&q=` +
            url +
            encodeURIComponent(queryString) +
            "&current_quarter=" +
            currentQuarter +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getSBC
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else {
      setSort(e.sort);
    }
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (submit === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? "costsubmission_get?user_id=" +
            user_id +
            "&offset=" +
            e.page.skip +
            "&limit=" +
            e.page.take +
            "&current_quarter=" +
            currentQuarter
          : "costsubmission_get?user_id=" +
            user_id +
            "&offset=" +
            e.page.skip +
            "&limit=" +
            e.page.take +
            "&current_quarter=" +
            currentQuarter +
            "&q=" +
            encodeURIComponent(queryString);
      getRequest(urlWithoutFilter, getSBC);
    }
    if (submit === true) {
      let urlWithFilter;
      let url = `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          url +=
            param.paramName === "gcmparts_hierarchy"
              ? `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_value"]
                }`
              : param.paramName === "gcmcommcodes" ||
                param.paramName === "gcmcommgroups"
              ? `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_value"]
                }`
              : `&${param.api_field}=${
                  filterValue[param.id_field]["lookup_code"]
                }`;
        }
      });
      urlWithFilter =
        sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
        url = urlWithFilter + `&offset=${e.page.skip}&limit=${e.page.take}`;
      } else {
        url = urlWithFilter + `?offset=${e.page.skip}&limit=${e.page.take}`;
      }
      getRequest(url, getSBC);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const handleRowClick = (e) => {
    if (visibleDialog !== true) {
      setComponentCode(e.dataItem);
      setDisable(true);
      setDis(false);
    }
  };

  /* pop up code */
  const toggleDialog = (value) => {
    setShow(false);
    setVisibleDialog(!visibleDialog);
    if (value === "add") {
      toggleAction.current = "add";
      setFetchData(false);
      setSelectedState({});
      setDisable(false);
      setEditFlag(false);
    }
    if (value === "edit") {
      setEditFlag(true);
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        part: componentCode?.part,
        supplier_common_name: componentCode?.supplier_common_name,
        manufacturer_name: componentCode.manufacturer,
        user_id: user_id,
        part_id: componentCode.part_id,
        supplier_id: componentCode.supplier_id,
        q0: componentCode.q0,
        q1: dataLabel[0]?.quarter_name,
        q1_price: componentCode.q1_price,
        q2: dataLabel[1]?.quarter_name,
        q2_price: componentCode.q2_price,
        q3: dataLabel[2]?.quarter_name,
        q3_price: componentCode.q3_price,
        q4: dataLabel[3]?.quarter_name,
        q4_price: componentCode.q4_price,
        q5: dataLabel[4].quarter_name,
        q5_price: componentCode.q5_price,
        q6: dataLabel[5].quarter_name,
        q6_price: componentCode.q6_price,
        q7: dataLabel[6].quarter_name,
        q7_price: componentCode.q7_price,
        q8: dataLabel[7].quarter_name,
        q8_price: componentCode.q8_price,
        prev_q7_price: componentCode.prev_q7_price,
        prev_q6_price: componentCode.prev_q6_price,
        prev_q5_price: componentCode.prev_q5_price,
        prev_q4_price: componentCode.prev_q4_price,
        prev_q3_price: componentCode.prev_q3_price,
        prev_q2_price: componentCode.prev_q2_price,
        prev_q1_price: componentCode.prev_q1_price,
        prev_q0_price: componentCode.q0_price,
        q_1: componentCode["q-7"],
        q_2: componentCode["q-6"],
        q_3: componentCode["q-5"],
        q_4: componentCode["q-4"],
        q_5: componentCode["q-3"],
        q_6: componentCode["q-2"],
        q_7: componentCode["q-1"],
        q_8: componentCode["q0"],
        quarter_1: "Q-7",
        quarter_2: "Q-6",
        quarter_3: "Q-5",
        quarter_4: "Q-4",
        quarter_5: "Q-3",
        quarter_6: "Q-2",
        quarter_7: "Q-1",
        quarter_8: "Q0",
      });
      setFetchData(true);
    }
    if (value === "close") {
      setAddCostSubmissionData({});
      setAllProductData([]);
      setAllSupplierData([]);
      setManufacturerData([]);
      setManufacturerExists(true);
      setEditFlag(false);
      setFetchData(false);
      setActualPrice([]);
    }
  };

  const addCommodityCodes = (val) => {
    setShow(false);
    if (val === "add") {
      setVisiblePopup(!visiblePopup);
      fetchCommodityCodes();
      setClosureWindowLoading(true);
    } else {
      setVisiblePopup(!visiblePopup);
      setCommodityCodeList([]);
    }
  };

  const getCommodityCodes = ({ data }, type) => {
    let arr = data.items;
    arr.forEach((val, index) => {
      if (val.sub_closure_date != null) {
        arr[index]["flag"] = "old";
      } else {
        arr[index]["flag"] = "new";
      }
    });
    setCommodityCodeList(arr);
    setClosureWindowLoading(false);
  };

  const fetchCommodityCodes = () => {
    getRequest(
      `costsubmission_closure/${user_id}/${currentQuarter}/`,
      getCommodityCodes
    );
  };

  const saveCommodityCode = () => {
    setClosureWindowLoading(true);
    put(
      `costsubmission_closure_status_update?user_id=${user_id}&current_qtr=${currentQuarter}&ccid=${checkedValues.ccid}`,
      {
        user_id: user?.user_id,
        quarter: user?.curr_qrtr,
        items: submissionClosureData,
      },
      updateSubmissionClosureStatus
    );
  };

  const updateSubmissionClosureStatus = ({ data }, type) => {
    if (data.p_out_status === "S") {
      alert("Submission Closure Status Added/Updated Successfully.");
      setVisiblePopup(!visiblePopup);
      setCommodityCodeList([]);
      setEnableSave({
        parts: false,
        tdd: false,
        alloc: false,
        arrLen: false,
      });
    } else alert(data.p_out_err_msg);
    setClosureWindowLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    setSaveEnabled(
      Object.values(enableSave).every((item) => {
        return item === true;
      })
    );
  }, [enableSave]);

  useEffect(() => {
    if (getComCode === 0) {
      setEnableSave({
        parts: false,
        tdd: false,
        alloc: false,
        arrLen: false,
      });
      setSaveEnabled(false);
    }
  }, [getComCode]);

  const handleCheckBox = (e, index, Object) => {
    const list = [...commodityCodeList];
    if (e.value === true) {
      list[index]["sub_closure_status"] = "Y";
      list[index]["sub_closure_date"] = formatLongDateToString(
        setLocal(new Date())
      );
      list[index]["entry"] = "NEW";
      list[index]["commodity_code"] = Object.commodity_code;
      list[index]["closure_id"] = Object.closure_id;
      setComCode(Object.commodity_code);
      setCheckedValues({
        ccid: Object.ccid,
        commodity_code: Object.commodity_code,
      });
      setClosureConfirmation(true);
    }
    if (e.value === false) {
      list[index]["sub_closure_status"] = "N";
      list[index]["sub_closure_date"] = null;
      list[index]["entry"] = "";
      list[index]["commodity_code"] = Object.commodity_code;
      list[index]["closure_id"] = Object.closure_id;
      setComCode(0);
      setCheckedValues({ ccid: undefined, commodity_code: undefined });
    }
    var filArray = list.filter((key) => {
      return key.entry === "NEW";
    });
    let len = filArray.length <= 0 || filArray.length > 1 ? false : true;
    setEnableSave({ ...enableSave, arrLen: len });
    setCommodityCodeList(list);
    setSubmissionClosureData(filArray);
  };

  const getActualPrice = ({ data }, type) => {
    let response = data;
    if (data.hasOwnProperty("retmsg")) {
      alert(data.retmsg);
      setFetchData(false);
      setAddCostSubmissionData({});
      clearfilter();
    }
    if (data.hasOwnProperty("resultset")) {
      setFetchData(true);
      setActualPrice(response.resultset);
    }
    setAddLoading(false);
  };

  const fetchDetails = () => {
    setAddLoading(true);
    if (
      addCostSubmissionData.manufacturer_name === undefined ||
      addCostSubmissionData.manufacturer_name === null
    ) {
      getRequest(
        `cs_call_actual_prices/?quarter=${user?.curr_qrtr}&user_id=${user?.user_id}&part_id=${addCostSubmissionData.part_id}&supplier_id=${addCostSubmissionData.supplier_id}&commodity_code=${addCostSubmissionData.comm_code}`,
        getActualPrice
      );
    } else {
      if (addCostSubmissionData.manufacturer_name.lookup_value !== null) {
        getRequest(
          `cs_call_actual_prices/?quarter=${user?.curr_qrtr}&user_id=${user?.user_id}&part_id=${addCostSubmissionData.part_id}&supplier_id=${addCostSubmissionData.supplier_id}&manufacturer=${addCostSubmissionData.manufacturer_name.lookup_value}`,
          getActualPrice
        );
      }
      getRequest(
        `cs_call_actual_prices/?quarter=${user?.curr_qrtr}&user_id=${user?.user_id}&part_id=${addCostSubmissionData.part_id}&supplier_id=${addCostSubmissionData.supplier_id}`,
        getActualPrice
      );
    }
  };

  const closeFetchDetails = () => {
    setFetchData(false);
  };
  const getPrefixSupplierData = ({ data }) => {
    setPrefixSupplierData(data.items);
  };
  const filterCommodityCodeChange = (event) => {
    let obj = event.value;
    setAddCostSubmissionData({
      ...addCostSubmissionData,
      commodity_code: obj,
      user_id: user_id,
      q0: user?.curr_qrtr,
      ccid: obj.lookup_value,
      comm_code: obj.lookup_code,
    });
    let url = `generic_lov_service/prefix_supplier/?prefix=${addCostSubmissionData.prefix_part}&ccid=${obj.lookup_value}`;
    getRequest(url, getPrefixSupplierData);
  };

  const filterDataChng = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({
          ...item,
          [field]: item.lookup_value,
          lookUpCode: item.lookup_code,
        });
      });
    }

    if (field) {
      setAllProductData({ ...allproductData, [field]: items });
    }
    setIsLoading(false);
  };
  const getCommodityCodeData = ({ data }) => {
    setCommodityCodeData({ ...commodityCodeData, partsHierarchy: data.items });
  };
  const filterSupDataChng = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value });
      });
    }
    if (field) {
      setAllSupplierData({ ...allsupplierData, [field]: items });
    }
    setIsLoadingSupplier(false);
  };

  const filterManuDataChng = (data, field) => {
    let items = [];
    if (data && data.items.length > 0) {
      data.items.forEach((item) => {
        items.push({ ...item, [field]: item.lookup_value });
      });
    }
    if (field) {
      setManufacturerData({ ...manufacturerData, [field]: items });
    }
    setIsLoadingManufacturer(false);
  };

  const filterProductOnChange = (event) => {
    let obj = event.value;
    if (obj.lookup_code === "Active_Part") {
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        commodity_code: obj,
        part: obj,
        user_id: user_id,
        q0: user?.curr_qrtr,
        ccid: "",
        supplier_common_name: "",
        comm_code: obj.attribute3,
        part_type: "part",
        part_id: obj.attribute2,
      });
      getRequest(
        `cs_manufacturer_exists_yn/${obj.attribute2}`,
        getManufacturerExists
      );
      setEnableCommCode(false);
    } else {
      setManufacturerExists(true);
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        part: obj,
        user_id: user_id,
        q0: user?.curr_qrtr,
        prefix_part: obj.lookup_value,
        supplier_common_name: "",
        commodity_code: "",
        part_type: "prefix",
      });
      setCommodityCodeData({});
      let url = `/generic_lov_service/prefix_commcode/?prefix=${obj.lookup_value}&user_id=${user_id}`;
      getRequest(url, getCommodityCodeData);
      setEnableCommCode(true);
    }
    setAllSupplierData([]);
    setPrefixSupplierData([]);
  };

  const getManufacturerExists = ({ data }, type) => {
    let res = data.items;
    if (res[0]["manufacturer_exists"] === "N") {
      setManufacturerExists(true);
    } else {
      setManufacturerExists(false);
    }
  };

  const filterSupplierOnChange = (event) => {
    let obj = event.value;
    if (addCostSubmissionData.part_type === "prefix") {
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        supplier_id:
          addCostSubmissionData.part_type === "prefix"
            ? obj.lookup_value
            : obj.lookup_code,
        supplier_common_name: obj,
        user_id: user_id,
        q0: user?.curr_qrtr,
        part_id: obj.attribute1,
      });
    } else {
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        supplier_id:
          addCostSubmissionData.part_type === "prefix"
            ? obj.lookup_value
            : obj.lookup_code,
        supplier_common_name: obj,
        user_id: user_id,
        q0: user?.curr_qrtr,
      });
    }
  };

  const filterManufactOnChange = (event) => {
    setAddCostSubmissionData({
      ...addCostSubmissionData,
      manufacturer_name: event.value,
    });
  };

  const filterProduct = debounce(({ filter }, param) => {
    setIsLoading(true);
    let url = `generic_lov_service/gcmparts_hierarchy/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        filterDataChng(data, "gcmparts_hierarchy");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, 1000);

  const filterSupplier = debounce(({ filter }, param) => {
    setIsLoadingSupplier(true);
    let url = `generic_lov_service/suppliers/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        filterSupDataChng(data, "suppliers");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, 1000);

  const filterManufacturer = debounce(({ filter }, param) => {
    setIsLoadingManufacturer(true);
    let url = `generic_lov_service/tpmanufacturer/?search_for=${filter.value}&user_id=${user_id}&part_id=${addCostSubmissionData.part_id}&part=${addCostSubmissionData.part.lookup_value}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        filterManuDataChng(data, "tpmanufacturer");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, 1000);

  const clearfilter = () => {
    setAddCostSubmissionData({
      ...addCostSubmissionData,
      manufacturer_name: null,
      part: "",
      supplier_common_name: "",
      part_id: "",
      supplier_id: "",
      commodity_code: "",
      comm_code: "",
      user_id: user_id,
      q0: "",
    });
    setManufacturerExists(true);
    setEnableCommCode(false);
  };
  const handleNegotiatedPriceDetails = (e, i) => {
    const { name, value } = e.target;
    if (value > 0 && value <= 999999999) {
      setAddCostSubmissionData({ ...addCostSubmissionData, [name]: value });
    } else {
      setAddCostSubmissionData({ ...addCostSubmissionData, [name]: null });
    }
  };

  const updateCostSubmission = ({ data }, type) => {
    if (data.p_out_status === "Success") {
      setFetchData(false);
      if (toggleAction.current === "add") {
        alert("Cost submitted successfully.");
      } else {
        alert("Cost updated successfully.");
      }
      reloadGridWithPageSave();
      setSaveFlag(false);
      setAddCostSubmissionData({});
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        manufacturer_name: "",
        part: "",
        supplier_common_name: "",
        part_id: "",
        supplier_id: "",
        ccid: "",
        commodity_code: "",
        comm_code: "",
      });
      toggleAction.current = "";
      setVisibleDialog(!visibleDialog);
      setActualPrice([]);
    } else alert(data.p_out_err_msg);
    setSaveFlag(false);
    setAddLoading(false);
    setAddCostSubmissionData({});
    setActualPrice([]);
    setVisibleDialog(!visibleDialog);
  };

  const saveCostSubmission = () => {
    setAddLoading(true);
    setSaveFlag(true);
    if (editFlag === true) {
      setAddCostSubmissionData({
        ...addCostSubmissionData,
        part: componentCode?.part,
        supplier_common_name: componentCode?.supplier_common_name,
        manufacturer_name: componentCode.manufacturer,
      });
    } else {
      if (addCostSubmissionData.manufacturer_name != null) {
        addCostSubmissionData.manufacturer_name =
          addCostSubmissionData.manufacturer_name.lookup_value;
      }
      addCostSubmissionData.part = addCostSubmissionData.part.lookup_value;
      addCostSubmissionData.supplier_common_name =
        addCostSubmissionData.supplier_common_name.lookup_value;
    }
    put("/cost_submission_post", addCostSubmissionData, updateCostSubmission);
    setSaveFlag(!saveFlag);
  };

  const showHideColumn = (e) => {
    setVis(!vis);
  };

  const onFilterSubmit = () => {
    setPage({ skip: 0, take: 10 });
    setLoading(true);
    let url = `/costsubmission_get?user_id=${user_id}&current_quarter=${currentQuarter}`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        url +=
          param.paramName === "gcmparts_hierarchy"
            ? `&${param.api_field}=${
                filterValue[param.id_field]["lookup_value"]
              }`
            : param.paramName === "gcmcommcodes" ||
              param.paramName === "gcmcommgroups"
            ? `&${param.api_field}=${
                filterValue[param.id_field]["lookup_value"]
              }`
            : `&${param.api_field}=${
                filterValue[param.id_field]["lookup_code"]
              }`;
      }
    });
    setFilterURL(url);
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getSBC);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
    setDataChg(true);
  };

  const deleteRecord = ({ data }, type) => {
    if (data.p_out_status === "success") {
      alert("Cost Submission deleted successfully");
      setSelectedState({});
      reloadGrid();
    } else {
      alert(data.p_out_err_msg);
      setLoading(false);
    }
  };

  const handleProceed = () => {
    setLoading(true);
    setDeleteAlert(false);
    remove(
      `cs_delete?csid=${componentCode.csid}&user_id=${user_id}`,
      "",
      deleteRecord
    );
  };

  const handleDelete = (e) => {
    if (Object.keys(selectedState).length > 0) {
      setDeleteAlert(true);
    } else {
      setDeleteSearchWindow(true);
    }
  };

  const handleCancelClosure = () => {
    setClosureConfirmation(false);
    let deselectCheck = commodityCodeList.filter((item) => {
      return item.entry === "NEW";
    });
    handleCheckBox(
      { value: false },
      commodityCodeList.indexOf(deselectCheck[0]),
      deselectCheck[0]
    );
  };

  const handleConfirmClosure = () => {
    setClosureConfirmation(false);
    setMissingCostDialog(true);
  };

  const handleExclusion = () => {
    setSearchWindow(true);
  };

  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ width: "100%" }}>
          <div className="action-container">
            <Button
              size="small"
              onClick={reloadGrid}
              title="Refresh"
              className="app-button"
            >
              <span className="k-icon k-i-reload"></span>
            </Button>
            <Button
              size="small"
              onClick={() => toggleDialog("add")}
              title="Add"
              className="app-button"
              disabled={gcm_append_yn !== "Y" ? true : false}
            >
              <span className="k-icon k-i-plus"></span>
            </Button>
            <Button
              size="small"
              onClick={() => toggleDialog("edit")}
              title="Edit"
              className="app-button"
              disabled={dis === true || gcm_append_yn !== "Y" ? true : false}
            >
              <span className="k-icon k-i-edit"></span>
            </Button>
            <Button
              size="small"
              onClick={handleDelete}
              title="Delete"
              className="app-button"
              disabled={gcm_append_yn !== "Y" ? true : false}
              iconClass="k-icon k-i-delete"
            ></Button>
            <Button
              size="small"
              onClick={openPopover}
              ref={anchor}
              className="app-button"
              title="Filter By"
            >
              <span
                className={
                  submit === true
                    ? "k-icon k-i-filter-clear"
                    : "k-icon k-i-filter"
                }
              ></span>
            </Button>
            <Button
              size="small"
              title="Download Template"
              className="app-button"
              style={{ marginLeft: 5 }}
              onClick={(e) => {
                openDownloadDialog(true);
                setShow(false);
              }}
            >
              {/* <a
                style={{ color: "#424242", textDecoration: "none" }}
                href={CostSubmissionTemplate}
                download="CMATXWD_CS_UPLOAD_TEMPLATE.xlsx"
              > */}
              <span className="k-icon k-i-download"></span>
              {/* </a> */}
            </Button>
            <Button
              size="small"
              title="Upload"
              onClick={(e) => {
                openUploadDialog(true);
                setShow(false);
              }}
              className="app-button"
              disabled={gcm_append_yn !== "Y" ? true : false}
            >
              <span className="k-icon k-i-upload"></span>
            </Button>

            <Button
              className="app-button"
              size="small"
              title="View Upload Status"
              onClick={(e) => {
                setViewUploadStatus(!viewUploadStatus);
                setShow(false);
              }}
              style={{ marginLeft: 5 }}
            >
              <strong>
                {" "}
                <span
                  style={{ paddingRight: 5 }}
                  className="k-icon k-i-eye"
                ></span>
              </strong>
            </Button>
            <Button
              size="small"
              onClick={() => addCommodityCodes("add")}
              className="app-button"
              title="Closure Status"
            >
              <span className="k-icon k-i-check"></span>
            </Button>
            <Button
              size="small"
              onClick={handleExclusion}
              className="app-button"
              title="Copy Exclusion"
              disabled={
                (dataState !== undefined
                  ? dataState[0]?.copy_excl_flag === "N"
                  : "N") || loading
                  ? true
                  : false
              }
              icon="k-i-close-outline k-i-x-outline"
            ></Button>
            <span className="spacer"></span>
            <Button
              size="small"
              className="app-button"
              onClick={showHideColumn}
            >
              {vis === true ? (
                <span className="k-icon k-i-hyperlink-open-sm"></span>
              ) : (
                <span className="k-icon k-i-launch"></span>
              )}
            </Button>
          </div>
          {show && (
            <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={onCancelClick}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onFilterSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              userId={user.user_id}
            />
          )}
          <div style={{ position: "" }}>
            <Tooltip openDelay={100} position="bottom" anchorElement="target">
              <Grid
                sortable={true}
                pageable={{
                  buttonCount: 5,
                  pageSizes: dataState?.length !== 0 ? pageSizes : "",
                }}
                skip={page.skip}
                pageSize={page.take}
                className="test"
                sort={sort}
                onSortChange={(e) => {
                  handleSortChange(e);
                }}
                data={orderBy(
                  dataState?.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })),
                  sort
                )}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: visibleDialog !== true ? true : false,
                  drag: false,
                  cell: false,
                  mode: "single",
                }}
                onSelectionChange={onSelectionChange}
                total={
                  dataState
                    ? dataState.length > 0
                      ? dataState[0]?.resultset_size
                      : 0
                    : 0
                }
                onRowClick={(e) => {
                  handleRowClick(e);
                }}
                onPageChange={(e) => {
                  handlePageChange(e);
                }}
                rowHeight={window.innerHeight * 0.067}
              >
                <GridColumn
                  headerClassName="headerClass"
                  style={{
                    backgroundColor: "var(--dark-header)",
                    color: "white",
                  }}
                  title="Part Number"
                  field="part"
                  width={setPercentage(15)}
                />
                <GridColumn
                  headerClassName="headerClass"
                  title="Supplier Common Name"
                  field="supplier_common_name"
                  cell={(e) => GridCellToolTip(e, "supplier_common_name")}
                  width={setPercentage(20)}
                />
                <GridColumn
                  headerClassName="headerClass"
                  title="Manufacturer"
                  field="manufacturer"
                  cell={(e) => GridCellToolTip(e, "manufacturer")}
                  width={setPercentage(15)}
                />
                <GridColumn
                  headerClassName="headerClass"
                  title="Commodity Group"
                  className="cell"
                  field="commodity_group"
                  cell={(e) => GridCellToolTip(e, "commodity_group")}
                  width={setPercentage(15)}
                />
                <GridColumn
                  headerClassName="headerClass"
                  title="Commodity Code"
                  className="cell"
                  field="commodity_code"
                  cell={(e) => GridCellToolTip(e, "commodity_code")}
                  width={setPercentage(15)}
                />
                <GridColumn
                  headerClassName="headerClass"
                  title="MPN"
                  field="mpn"
                  className="cell"
                  cell={(e) => GridCellToolTip(e, "mpn")}
                  width={setPercentage(10)}
                />
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[6].quarter_name + " (Q-7) ($)"
                        : "(Q-7) ($)"
                    }
                    field="prev_q7_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q7_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[5].quarter_name + " (Q-6) ($)"
                        : " (Q-6) ($)"
                    }
                    field="prev_q6_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q6_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[4].quarter_name + " (Q-5) ($)"
                        : " (Q-6) ($)"
                    }
                    field="prev_q5_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q5_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[3].quarter_name + " (Q-4) ($)"
                        : " (Q-4) ($)"
                    }
                    field="prev_q4_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q4_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[2].quarter_name + " (Q-3) ($)"
                        : " (Q-3) ($)"
                    }
                    field="prev_q3_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q3_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[1].quarter_name + " (Q-2) ($)"
                        : " (Q-2) ($)"
                    }
                    field="prev_q2_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q2_price")}
                  />
                )}
                {vis && (
                  <GridColumn
                    headerClassName="headerClass"
                    title={
                      prevQuarterNames.length > 0
                        ? prevQuarterNames[0].quarter_name + " (Q-1) ($)"
                        : " (Q-1) ($)"
                    }
                    field="prev_q1_price"
                    className="cell numberFields"
                    width={setPercentage(8)}
                    cell={(e) => GridCellToolTip(e, "prev_q1_price")}
                  />
                )}
                <GridColumn
                  headerClassName="headerClass"
                  title={currentQuarter + " ($)"}
                  field="q0_price"
                  className="cell numberFields"
                  width={setPercentage(7)}
                  cell={(e) => GridCellToolTip(e, "q0_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q1_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[0]
                        ? dataLabel[0]["quarter_name"] +
                          ` (${dataLabel[0]["quarter_label"]}) ($)`
                        : "Q+1 ($)"
                      : "Q+1 ($)"
                  }
                  field="q1_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q1_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q2_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[1]
                        ? dataLabel[1]["quarter_name"] +
                          ` (${dataLabel[1]["quarter_label"]}) ($)`
                        : "Q+2 ($)"
                      : "Q+2 ($)"
                  }
                  field="q2_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q2_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q3_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[2]
                        ? dataLabel[2]["quarter_name"] +
                          ` (${dataLabel[2]["quarter_label"]}) ($)`
                        : "Q+3 ($)"
                      : "Q+3 ($)"
                  }
                  field="q3_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q3_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q4_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[3]
                        ? dataLabel[3]["quarter_name"] +
                          ` (${dataLabel[3]["quarter_label"]}) ($)`
                        : "Q+4 ($)"
                      : "Q+4 ($)"
                  }
                  field="q4_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q4_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q5_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[4]
                        ? dataLabel[4]["quarter_name"] +
                          ` (${dataLabel[4]["quarter_label"]}) ($)`
                        : "Q+5 ($)"
                      : "Q+5 ($)"
                  }
                  field="q5_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q5_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q6_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[5]
                        ? dataLabel[5]["quarter_name"] +
                          ` (${dataLabel[5]["quarter_label"]}) ($)`
                        : "Q+6 ($)"
                      : "Q+6 ($)"
                  }
                  field="q6_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q6_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q7_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[6]
                        ? dataLabel[6]["quarter_name"] +
                          ` (${dataLabel[6]["quarter_label"]}) ($)`
                        : "Q+7 ($)"
                      : "Q+7 ($)"
                  }
                  field="q7_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q7_price")}
                />
                <GridColumn
                  headerClassName={headerClass["q8_price"] ?? "headerClass"}
                  title={
                    dataLabel
                      ? dataLabel[7]
                        ? dataLabel[7]["quarter_name"] +
                          ` (${dataLabel[7]["quarter_label"]}) ($)`
                        : "Q+8 ($)"
                      : "Q+8 ($)"
                  }
                  field="q8_price"
                  className="numberFields"
                  width={setPercentage(8)}
                  cell={(e) => GridCellToolTip(e, "q8_price")}
                />
              </Grid>
            </Tooltip>
          </div>
        </div>
      </div>
      {deleteSearchWindow && (
        <CostSubmissionDelete
          setDeleteSearchWindow={setDeleteSearchWindow}
          user_id={user_id}
          currentQuarter={currentQuarter}
        />
      )}
      {deleteAlert && (
        <Dialog title="Delete Record" onClose={() => setDeleteAlert(false)}>
          <p
            style={{
              textAlign: "center",
              width: "464px",
            }}
          >
            Cost Submission entry and the associated Cost Allocations will be
            deleted. Do you want to proceed?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setDeleteAlert(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleProceed}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {uploadDialog && (
        <CostSubmissionUpload
          setLoading={setLoading}
          toggleWizard={(e) => {
            openUploadDialog(!uploadDialog);
          }}
        />
      )}
      {downloadDialog && (
        <CostSubmissionDownload
          toggleWizard={(e) => {
            openDownloadDialog(!downloadDialog);
          }}
        />
      )}

      {/* ADD COST SUBMISSION */}
      {visibleDialog && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle title="Cost Submission" icon="k-i-table-cell" />
            }
            initialHeight={650}
            onClose={() => toggleDialog("close")}
            style={{ zIndex: "1", height: "auto", display: "center" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
            initialWidth={700}
          >
            <div style={{ marginTop: -15 }}>
              {addLoading ? <ProgressBar /> : ""}
            </div>
            <Form
              render={() => (
                <FormElement>
                  <div className="container">
                    <div className="input-container input-first-cell">
                      <div className="input-first">
                        <label style={{marginTop: "10px"}} className="input-header-cell">
                          Part Number<sup style={{ color: "#f31700" }}>*</sup>:
                        </label>
                      </div>
                      <div className="input-second">
                        {disable === false ? (
                          <DropDownList
                            style={{ minWidth: "150px", marginTop: "10px" }}
                            data={
                              Object.keys(allproductData).length > 0
                                ? allproductData["gcmparts_hierarchy"]
                                : []
                            }
                            filterable={true}
                            textField="lookup_value"
                            onFilterChange={filterProduct}
                            disabled={fetchData === true ? true : false}
                            loading={isLoading}
                            value={addCostSubmissionData.part}
                            onChange={filterProductOnChange}
                          />
                        ) : (
                          <AutoComplete
                            style={{ width: "150px", marginTop: "10px" }}
                            clearButton={false}
                            value={addCostSubmissionData.part}
                            disabled={fetchData === true ? true : false}
                            onChange={filterProduct}
                          />
                        )}
                      </div>
                    </div>
                    <div className="input-container  input-second-cell ">
                      <div className="input-first">
                        <label style={{marginTop: "10px"}} className="input-header-cell">
                          Commodity Code
                          <sup style={{ color: "#f31700" }}>*</sup>:
                        </label>
                      </div>
                      <div className="input-second">
                        {disable === false ? (
                          <DropDownList
                            style={{ minWidth: "150px",marginTop: "10px" }}
                            data={
                              Object.keys(commodityCodeData).length > 0
                                ? commodityCodeData["partsHierarchy"]
                                : []
                            }
                            textField={
                              addCostSubmissionData.part_type === "part"
                                ? "attribute3"
                                : "lookup_code"
                            }
                            disabled={
                              enableCommCode === false || fetchData
                                ? true
                                : false
                            }
                            value={addCostSubmissionData.commodity_code}
                            onChange={filterCommodityCodeChange}
                          />
                        ) : (
                          <AutoComplete
                            style={{ width: "150px",marginTop: "10px" }}
                            value={addCostSubmissionData?.supplier_common_name}
                            disabled={fetchData === true ? true : false}
                            clearButton={false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="input-container  input-first-cell ">
                      <div className="input-first">
                        <label style={{marginTop: "10px"}} className="input-header-cell">
                          Manufacturer Name:
                        </label>
                      </div>
                      <div className="input-second">
                        {disable === false ? (
                          <DropDownList
                            style={{ minWidth: "150px",marginTop: "10px" }}
                            data={
                              Object.keys(manufacturerData).length > 0
                                ? manufacturerData["tpmanufacturer"]
                                : []
                            }
                            filterable={true}
                            textField="lookup_value"
                            disabled={
                              manufacturerExists === true
                                ? true
                                : false || fetchData === true
                                ? true
                                : false
                            }
                            onFilterChange={filterManufacturer}
                            loading={isLoadingManufacturer}
                            value={addCostSubmissionData.manufacturer_name}
                            onChange={filterManufactOnChange}
                          />
                        ) : (
                          <AutoComplete
                            style={{ width: "150px" }}
                            value={addCostSubmissionData?.manufacturer_name}
                            clearButton={false}
                            disabled={fetchData === true ? true : false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="input-container  input-second-cell ">
                      <div className="input-first">
                        <label style={{marginTop: "10px"}} className="input-header-cell">
                          Supplier Name<sup style={{ color: "#f31700" }}>*</sup>
                          :
                        </label>
                      </div>
                      <div className="input-second">
                        {disable === false ? (
                          <DropDownList
                            style={{ minWidth: "150px", marginTop: "10px" }}
                            data={
                              Object.keys(allsupplierData).length > 0
                                ? allsupplierData["suppliers"]
                                : prefixSupplierData.length > 0
                                ? prefixSupplierData
                                : []
                            }
                            filterable={
                              addCostSubmissionData.part_type === "part"
                                ? true
                                : false
                            }
                            disabled={fetchData ? true : false}
                            textField={
                              addCostSubmissionData.ccid === ""
                                ? "lookup_value"
                                : "lookup_code"
                            }
                            onFilterChange={filterSupplier}
                            loading={isLoadingSupplier}
                            value={addCostSubmissionData.supplier_common_name}
                            onChange={filterSupplierOnChange}
                          />
                        ) : (
                          <AutoComplete
                            style={{ width: "150px", marginTop: "10px" }}
                            value={addCostSubmissionData?.supplier_common_name}
                            disabled={fetchData === true ? true : false}
                            clearButton={false}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </FormElement>
              )}
            />
            <br></br>
            {!disable && (
              <div>
                <div className="cost_dialog_row">
                  <Button
                    onClick={fetchDetails}
                    className="save-button"
                    disabled={
                      !addCostSubmissionData.part_id ||
                      !addCostSubmissionData.supplier_id
                    }
                    style={{ marginLeft: "30px" }}
                  >
                    Fetch
                  </Button>
                  <Button
                    onClick={clearfilter}
                    className="save-button"
                    style={{ marginLeft: "30px" }}
                    disabled={fetchData === true ? true : false}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            )}
            {fetchData && (
              <div className="container-box">
                {!disable && (
                  <div
                    style={{
                      height: "20px",
                      width: "100%",
                      background: "#228DFF",
                    }}
                  >
                    <Button
                      style={{
                        float: "right",
                        height: "18px",
                        background: "#228DFF",
                        border: "none",
                      }}
                      onClick={closeFetchDetails}
                    >
                      <span
                        style={{
                          height: "18px",
                          background: "#228DFF",
                          color: "white",
                        }}
                        className="k-icon k-i-close"
                      ></span>
                    </Button>
                  </div>
                )}

                <div className="container">
                  <div
                    className="input-container input-first-cell"
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "right",
                      paddingBottom: "12px",
                    }}
                  >
                    <div className="input-first">
                      <label
                        style={{ textAlign: "center", margin: "0 auto" }}
                        className="input-header-cell"
                      >
                        Prev. Submitted Cost
                      </label>
                    </div>
                  </div>
                  <div
                    className="input-container input-second-cell"
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "right",
                      paddingBottom: "12px",
                    }}
                  >
                    <div className="input-first">
                      <label
                        style={{ textAlign: "center" }}
                        className="input-header-cell"
                      >
                        {" "}
                        Negotiated Price
                      </label>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="input-container input-first-cell">
                    {editFlag === true
                      ? dataLabel.map((element, i) => {
                          return (
                            <React.Fragment>
                              <div className="input-first">
                                <label className="input-header-cell">
                                  {addCostSubmissionData["quarter_" + (i + 1)] +
                                    "(" +
                                    addCostSubmissionData["q_" + (i + 1)] +
                                    ")"}
                                </label>
                              </div>
                              <div className="input-second">
                                <Input
                                  style={styles}
                                  min={1}
                                  value={
                                    addCostSubmissionData[
                                      "prev_q" +
                                        (dataLabel.length - (i + 1)) +
                                        "_price"
                                    ]
                                  }
                                  disabled={true}
                                />
                              </div>
                            </React.Fragment>
                          );
                        })
                      : actualPrice.map((element, i) => {
                          return (
                            <React.Fragment>
                              <div className="input-first">
                                <label className="input-header-cell">
                                  {"Q" +
                                    element.seq +
                                    "(" +
                                    element.quarter +
                                    ")"}{" "}
                                  :
                                </label>
                              </div>
                              <div className="input-second">
                                <Input
                                  style={styles}
                                  min={1}
                                  value={element.price}
                                  disabled={true}
                                />
                              </div>
                            </React.Fragment>
                          );
                        })}
                  </div>
                  <div className="input-container  input-second-cell ">
                    {dataLabel.map((element, i) => {
                      return (
                        <React.Fragment>
                          <div className="input-first">
                            <label className="input-header-cell">
                              {element.quarter_label +
                                "(" +
                                element.quarter_name +
                                ")"}{" "}
                              :{" "}
                            </label>
                          </div>
                          <div className="input-second">
                            <NumericTextBox
                              format="n5"
                              name={"q" + (i + 1) + "_price"}
                              max={999999999}
                              value={
                                addCostSubmissionData["q" + (i + 1) + "_price"]
                              }
                              onChange={(e) =>
                                handleNegotiatedPriceDetails(e, i)
                              }
                              min={0}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="cost_dialog_row" style={{ float: "right" }}>
                  <Button
                    onClick={saveCostSubmission}
                    icon="save"
                    className="save-button"
                    disabled={saveFlag === true ?? false}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Window>
        </div>
      )}
      {/* ADD COST SUBMISSION */}
      {visiblePopup && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle
                title="Submission closure Status"
                icon="k-i-check-outline"
              />
            }
            initialHeight={400}
            initialWidth={520}
            onClose={() => addCommodityCodes("close")}
            style={{ zIndex: "1", display: "center" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
            draggable={false}
          >
            <div style={{ marginTop: -15 }}>
              {closureWindowLoading ? <ProgressBar /> : ""}
            </div>
            <br />
            <div>
              <div className="headerAdd">
                <div>Status</div>
                <div>Commodity Code</div>
                <div>Closure Date</div>
              </div>
              <div className="scrAdd">
                {commodityCodeList.map((x, i) => {
                  return (
                    <div
                      style={{ marginTop: "10px", display: "flex" }}
                      className="example-col"
                    >
                      <Checkbox
                        style={{ marginLeft: "65px", borderColor: "#666666" }}
                        defaultChecked={x.flag === "old" ? true : false}
                        value={x.sub_closure_status}
                        disabled={x.flag === "old" ? true : false}
                        onChange={(e) => handleCheckBox(e, i, x)}
                        id={i}
                        checked={x.sub_closure_status === "Y" ? true : false}
                      />
                      <label
                        style={{
                          width: "100px",
                          marginLeft: "20%",
                          display: "flex",
                        }}
                      >
                        {x.commodity_code}
                      </label>
                      <label
                        style={{
                          width: "100px",
                          marginLeft: "13%",
                          display: "flex",
                        }}
                      >
                        {x.sub_closure_date === null
                          ? ""
                          : x.flag === "1"
                          ? formatISOStringToShortDate(setLocal(new Date()))
                          : x.sub_closure_date !== null
                          ? formatISOStringToShortDate(x.sub_closure_date)
                          : null}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              onClick={saveCommodityCode}
              className="save-button"
              style={{
                marginTop: "5%",
                marginLeft: "1%",
                marginBottom: "3%",
                float: "right",
              }}
              disabled={!saveEnabled}
            >
              {" "}
              Save{" "}
            </Button>
          </Window>
        </div>
      )}
      {viewUploadStatus && (
        <CostSubmissionUploadFileStatus
          from="COST_SUBMISSION"
          toggleWizard={(e) => setViewUploadStatus(!viewUploadStatus)}
        />
      )}
      {closureConfirmation && (
        <Dialog title={"Submission Closure"} closeIcon={false}>
          <p
            style={{
              textAlign: "center",
              height: 15,
              width: 445,
              color: "red",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            Submission Closure for Comm.code once initiated cannot be reverted,
            Do you want to Proceed ?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleCancelClosure}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmClosure}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {missingCostDialog && (
        <SubmissionClosure
          setMissingCostDialog={setMissingCostDialog}
          checkedValues={checkedValues}
          commodityCodeList={commodityCodeList}
          handleCheckBox={handleCheckBox}
          allocCompleted={allocCompleted}
          setAllocCompleted={setAllocCompleted}
          setEnableSave={setEnableSave}
          enableSave={enableSave}
        />
      )}
      {searchWindow && (
        <SubClosureExclusion
          setSearchWindow={setSearchWindow}
          user_id={user_id}
          currentQuarter={currentQuarter}
          searchWindow={searchWindow}
        />
      )}
    </div>
  );
};
export default CostSubmission;
