import React from "react";
import { Button } from "@progress/kendo-react-buttons";

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);
  const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      hiddenFileInput?.current?.click();
  };
  const handleChange=e=>{
    props.onUpload(e);
  };
  return (
    <>
      <Button className="upload" onClick={handleClick}>Browse File</Button>
      <input accept="" onChange={handleChange} ref={hiddenFileInput} type="file" style={{ display: "none" }} />
    </>
  );
};
export default FileUploader;
