import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { getRequest } from "./../../../services/api.request";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import { UserContext } from "../../../App.js";

const SavingsDetails = ({ ReductionInitativeId, ReductionInitatedQuarter, ReductionSavingType, ReductionGCMId, setLoading }) => {
    const initialDataState = {
        skip: 0,
        take: 1
    };
  const userContext = useContext(UserContext);
  const currentQuarter = userContext?.curr_qrtr;
    const limit = 10;
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = useState([]);
    const [dataIdState, setDataIdState] = React.useState([]);
    const [remarks, setRemarks] = React.useState('')
    const fetchSavingsDetails = React.useCallback(
        () => {
            if (ReductionInitativeId)
                getRequest(`/ro_cogs_vw_right?initiated_quarter=${ReductionInitatedQuarter}&roid=${ReductionInitativeId}&p_current_quarter=${currentQuarter}&gcm_id=${ReductionGCMId}&offset=${page.skip}&limit=${limit}`, getSavingsDetails)
            else
                setDataIdState([]);
            setRemarks('')
            setSort([]);
            setPage(initialDataState);
        },
        [ReductionInitativeId, ReductionInitatedQuarter]
    );

    useEffect(() => {
        fetchSavingsDetails();
    }, [fetchSavingsDetails]);

    const getSavingsDetails = ({ data }, type) => {
        setDataIdState(data.items);
        setRemarks(data.items[0].remarks);
        setLoading(false);
    }

    return (
        <div>
            <div className="pane-content">
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                    {<Grid 
                        total={dataIdState ? dataIdState.length > 0 ? dataIdState[0]?.resultset_size : 0 : 0}
                        data={orderBy(dataIdState, sort)} sortable={true} sort={sort} onSortChange={e => {setSort(e.sort);}}
                        rowHeight={window.innerHeight * 0.067}
                        style={{ minHeight: "200px", height: "auto" }} >
                        <GridColumn headerClassName="headerClass" field="q1" title="Future Quarters" />
                        <GridColumn headerClassName="headerClass" field="q1_rr"
                         title="Run Rate Dollar Saving" />
                        {ReductionSavingType == "Run Rate Unit Saving" ?
                            <GridColumn headerClassName="headerClass" field="q1_us" title="Run Rate Unit Saving" /> : ""
                        }
                    </Grid>}
                    <div className="grid">
                        <div>
                            <strong >
                                <label>Remarks: </label>  </strong> </div>
                        <div>
                            <TextArea disabled={true}
                                style={{ width: '95%', height: '190px', margin:"10px" }} maxlength={350}
                                id="remarks" name="remarks" value={remarks} />
                        </div>
                    </div>
                </Tooltip>
            </div>
        </div>
    );

}
export default SavingsDetails;