import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React, { useEffect, useState, useContext } from "react";
import { getRequest } from '../../../services/api.request';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { UserContext } from '../../../App';
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
const initialDataState = {
    skip: 0,
    take: 10,
  };

export default function PartNoRightSide({selectedData, setLoading, loading, rightGridTotal}) {
  const userContext = useContext(UserContext);
  const currentQuarter = userContext.curr_qrtr;
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(initialDataState);
  const [sort, setSort] = useState([]);
  const limit = 10;
  const [pageSizes, setPageSizes] = useState([10, 25, 50]);

  const handleResponse = ({data}) => {
      setData(data.items);
      if(data.items.length>0){
        rightGridTotal(data.items[0]?.resultset_size)
        if(data.items[0].resultset_size < 10 ){
          setPageSizes([10]);
        }
       else if(data.items[0].resultset_size < 50){
          setPageSizes([10,25]);
        }
      }
      setLoading(false);
  }

  useEffect(()=>{
    if(selectedData != null){
        setPage(initialDataState);
        setSort([])
        setLoading(true);
        getRequest(`/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?offset=${initialDataState.skip}&limit=${limit}`, handleResponse)
    }else{
        setData([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedData])

  const handleSortChange=(e)=>{
    setLoading(true)
    if(e.sort && e.sort.length>0){
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString='{"$orderby": {"'+field+'":"'+sortingOrder+'"}}';
        getRequest(`/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?q=${encodeURIComponent(queryString)}&offset=${page.skip}&limit=${page.take}`,handleResponse);
        setSort(e.sort);
    } else{
        let dir= "asc";
        if(sort[0].dir==='asc'){
            dir="desc";
        }
        const queryString='{"$orderby": {"'+sort[0].field+'":"'+dir+'"}}';
        getRequest(`/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?q=${encodeURIComponent(queryString)}&offset=${page.skip}&limit=${page.take}`,handleResponse);
        setSort([{"field": sort[0].field, "dir":dir}]);
    }
};

  const handlePageChange = (e) => {
    setLoading(true);
    if(sort.length===0){
        let c= `/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?offset=`+e.page.skip+"&limit="+e.page.take;
        getRequest(c, handleResponse);
        setTimeout(()=>{
            setPage(e.page);
        },100);         
    } else {
        const queryString='{"$orderby": {"'+sort[0].field+'":"'+sort[0].dir+'"}}';
        let c= `/gpn_right_view/${selectedData.gpn_id}/${currentQuarter}?offset=`+e.page.skip+"&limit="+e.page.take+'&q='+encodeURIComponent(queryString);
        getRequest(c, handleResponse);
        setTimeout(()=>{
            setPage(e.page);
        },100); 
    }
  };


  return (
      <>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <Grid 
            data={data} 
            style={{ marginTop:'1.4rem' }}
            pageable={{
                buttonCount: 5,
                pageSizes: data.length !== 0 ? pageSizes:""
              }}
              skip={page.skip}
              pageSize={page.take}
            total={data ? data.length > 0 ? data[0].resultset_size : 0 : 0}
            onPageChange={(e) => { handlePageChange(e) }}
            sortable={true}
            sort={sort}
            onSortChange={(e) =>{handleSortChange(e)}}
            rowHeight={window.innerHeight * 0.07}
            >
            <GridColumn headerClassName="headerClass" field="part" title="Part Number" cell={(e) => GridCellToolTip(e, 'part')} />
            <GridColumn headerClassName="headerClass" field="part_desc" title="Part Description" cell={(e) => GridCellToolTip(e, 'part_desc')} />
            <GridColumn headerClassName="headerClass" field="supplier" title="Supplier" cell={(e) => GridCellToolTip(e, 'supplier')} />
            <GridColumn headerClassName="headerClass" field="commodity_group" title="Commodity Group" cell={(e) => GridCellToolTip(e, 'commodity_group')} />
            <GridColumn headerClassName="headerClass" field="commodity_code" title="Commodity Code" cell={(e) => GridCellToolTip(e, 'commodity_code')}  />
        </Grid>
        </Tooltip>
      </>
  )
}
