import { Label, Hint } from "@progress/kendo-react-labels";
import { Window } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { put } from "../../../services/api.request";
import "./EditQuarterDialog.css";
import { Button } from "@progress/kendo-react-buttons";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import DateTimePicker from "react-datetime-picker";
import {
  formatLongDateToString,
  formatNewDate,
  setLocal,
} from "../../../utils/formatDateUtil";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import DatePicker from "react-date-picker";

export default function EditQuarterDialog({
  freezeCoatSubmissionDate,
  toggleDialog,
  minDate,
  maxDate,
  reloadGrid,
}) {
  let { quarter_freeze_start_date, quarter_freeze_end_date } =
    freezeCoatSubmissionDate;
  const now = setLocal(new Date());
  const [startDate, setStartDate] = useState(quarter_freeze_start_date);
  const [endDate, setEndDate] = useState(quarter_freeze_end_date);
  const [saveFlag, setSaveFlag] = useState(false);
  const [newEndDate, setNewEndDate] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setNewEndDate(new Date(maxDate));
  }, [maxDate]);

  const updatefreezeCoatSubmissionDate = ({ data }) => {
    if (data.p_out_status === "Success") {
      alert("Calender set Successfully!");
      reloadGrid();
      setSaveFlag(false);
      toggleDialog();
    } else alert(data.p_out_err_msg);
    setEditLoading(false);
  };

  const onSave = () => {
    setEditLoading(true);
    setSaveFlag(true);
    put(
      "/qtr_sub_window",
      Object.assign(freezeCoatSubmissionDate, {
        quarter_freeze_start_date: startDate,
        quarter_freeze_end_date: endDate,
      }),
      updatefreezeCoatSubmissionDate
    );
  };

  const handleStartDateChange = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setStartDate(value);
  };

  const handleEndDateChange = (e) => {
    let value = e === null ? e : formatLongDateToString(e);
    setEndDate(value);
  };

  const handleOnCalendarOpen = () => {
    setIsOpen(true);
  };

  const handleOnCalendarClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="window-container">
      <Window
        style={{ width: "500px", height: "auto" }}
        title={
          <WindowTitle title="Cost Submission Window" icon="k-i-calendar" />
        }
        onClose={toggleDialog}
        minimizeButton={() => null}
        maximizeButton={() => null}
      >
        <div style={{ marginTop: -15 }}>
          {editLoading ? <ProgressBar /> : ""}
        </div>
        <div className="quarter_dialog_row">
          <FieldWrapper>
            <Label>Year</Label>
            <Input
              style={{ maxWidth: "200px" }}
              type="text"
              value={freezeCoatSubmissionDate.fiscal_year}
              disabled
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>Quarter</Label>
            <Input
              style={{ maxWidth: "200px" }}
              type="text"
              value={freezeCoatSubmissionDate.quarter_num}
              disabled
            />
          </FieldWrapper>
        </div>
        <div className="quarter_dialog_row">
          <FieldWrapper>
            <Label>Start Date</Label>
            <Input
              style={{ maxWidth: "200px" }}
              type="text"
              value={freezeCoatSubmissionDate.quarter_start_date}
              disabled
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>End Date</Label>
            <Input
              style={{ maxWidth: "200px" }}
              type="text"
              value={freezeCoatSubmissionDate.quarter_end_date}
              disabled
            />
          </FieldWrapper>
        </div>
        <br />
        <div className="quarter_dialog_row">
          <Label>
            {" "}
            <span style={{ color: "red" }}>* </span>Submission Start Date
          </Label>
          <DatePicker
            className="dateTimepicker"
            style={{ marginLeft: "-5px !important" }}
            name="submission_startDate"
            minDate={isOpen === true ? now : new Date(minDate)}
            maxDate={new Date(maxDate)}
            format="MM/dd/y"
            value={startDate === null ? "" : formatNewDate(startDate)}
            onChange={handleStartDateChange}
            required={true}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            onCalendarOpen={handleOnCalendarOpen}
            onCalendarClose={handleOnCalendarClose}
          />
          <Hint>(PST)</Hint>
        </div>
        <div className="quarter_dialog_row">
          <Label>
            {" "}
            <span style={{ color: "red" }}>* </span>Submission End Date
          </Label>
          <DateTimePicker
            className="dateTimepicker"
            name="submission_endDate"
            minDate={startDate === null ? "" : formatNewDate(startDate)}
            maxDate={new Date(newEndDate)}
            format="MM/dd/y"
            value={endDate === null ? "" : formatNewDate(endDate)}
            onChange={handleEndDateChange}
            required={true}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
          />
          <Hint>(PST)</Hint>
        </div>
        <div className="quarter_dialog_row">
          {startDate === null
            ? ""
            : formatNewDate(startDate) >=
                (endDate === null ? "" : formatNewDate(endDate)) && (
                <p className="validation-msg">
                  Start and End date cannot be same / End date must be greater
                  than Start date{" "}
                </p>
              )}
          <Button
            onClick={onSave}
            icon="save"
            className="save-button"
            disabled={
              startDate === null ||
              endDate === null ||
              saveFlag === true ||
              formatNewDate(startDate) >= formatNewDate(endDate)
                ? true
                : false
            }
          >
            Save
          </Button>
        </div>
      </Window>
    </div>
  );
}
