import { Button } from "@progress/kendo-react-buttons";
import { Splitter } from "@progress/kendo-react-layout";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../App";
import { getter } from "@progress/kendo-react-common";
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import { Tooltip } from "@progress/kendo-react-tooltip";
import InvoiceDetails from "./components/Invoice";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import OneTimeChargePopup from './components/oneTimeDialog';
import { getRequest } from "../../services/api.request";
import { formatDates } from "../../utils/formatDateUtil";
import { orderBy } from "@progress/kendo-data-query";
import ProgressBar from "../../components/ProgressBar/ProgressBar";


const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };


const OneTimeCharge = () => {
  const FILTER_PARAMS = [
    {
      paramName: "cmatusers",
      displayValue: "GCM Name",
      id_field: "gcmname",
      api_field: "gcmname",
      filterable: true
    },
    {
      paramName: "otcstatus",
      displayValue: "Status",
      id_field: "status",
      api_field: "status",
      filterable: false
    },
    {
      paramName: "commoditycode",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code"
    }
  ];

  const userContext = useContext(UserContext);
  const anchor = React.useRef(null);
  const currentQuarter = userContext.curr_qrtr;
  const [panes, setPanes] = React.useState([
    { size: "70%", min: "50%", collapsible: true },
    { min: "25%", collapsible: true }
  ]);
  const DATA_ITEM_KEY = "otc_id";
  const SELECTED_FIELD = "selected";
  const [dataState, setDataState] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState(null);
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({}); const initialDataState = {
    skip: 0
  };
  const limit = 10;
  const [page, setPage] = React.useState(initialDataState);
  const [sort, setSort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [submit, setSubmit] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState("Add");
  const [oneTimeCharge, setOneTimeCharge] = React.useState({ name: undefined, id: undefined, charge_type: undefined });
  const [dataChg, setDataChg] = useState(false);
  const [filterURL, setFilterURL] = useState();
  const toggleDialog = (mode = null) => {
    setShow(false);
    if (mode != null) {
      setMode(mode)
    }
    setShowDialog(!showDialog);
  }

  const openPopover = (event) => {
    setShow(!show);
  };
  const getOneTimeChargeRecords = ({ data }, type) => {
    setOneTimeCharge({ ...oneTimeCharge, id: undefined, charge_type: undefined });
    setSelectedState({});
     setDataState(data.items.map(dataItem => Object.assign({}, formatDates(["expected_date"], dataItem))));
    setLoading(false);
  }
  const reloadGrid = () => {
    setLoading(true);
    setPage({ skip: 0 })
    getRequest('/otc_left_vw?offset=0&limit=' + limit, getOneTimeChargeRecords);
    setSubmit(false);
    setSort([]);
    setDataChg(false);
    setSelectedData(null);
  }
  const reloadGridWithPageSave = () => {
    if (dataChg === true) {
      getRequest(filterURL + `&offset=${page.skip}&limit=${limit} `, getOneTimeChargeRecords);
      setSubmit(true);
    }
    else {
      getRequest(`/otc_left_vw?offset=${page.skip}&limit=${limit}`, getOneTimeChargeRecords);
      setSubmit(false);
    }
    setSort([]);
    setSelectedData(null);
  }

  useEffect(() => {
    reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (event) => {
    setPanes(event.newState);
  };
  const handleRowClick = (e) => {
    if (e.dataItem.charge_type === "Debit Note") {
      setOneTimeCharge({ id: e.dataItem.po_number, charge_type: e.dataItem.charge_type })
    }
    else {
      setOneTimeCharge({ id: e.dataItem.invoice_number, charge_type: e.dataItem.charge_type })
    }
    setLoading(false);
  }
  const onSelectionChange = React.useCallback(
    (event) => {
      setSelectedData(event.dataItems[event.startRowIndex])
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const handleSortChange = (e) => {
    setLoading(true)
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString = '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest('/otc_left_vw?q=' + encodeURIComponent(queryString) + "&current_qtr=" + currentQuarter, getOneTimeChargeRecords);
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === 'asc') {
          dir = "desc";
        }
        const queryString = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest('/otc_left_vw?q=' + encodeURIComponent(queryString) + "&current_qtr=" + currentQuarter, getOneTimeChargeRecords);
        setSort([{ "field": sort[0].field, "dir": dir }]);
      }
    }
    else {
      setSort(e.sort);
      setLoading(false)
    }
  };
  const handlePageChange = (e) => {
    setLoading(true);
    //submit true filter value
    if (submit === false) {
      let c = 'otc_left_vw?offset=' + e.page.skip + "&limit=" + limit;
      getRequest(c, getOneTimeChargeRecords);
    }
    //submit false filter value
    if (submit === true) {
      let url = '/otc_gcm_filter'
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          let urlAppendCharacter = url.includes("?") ? `&` : `?`
          url += (filterValue[param.id_field][param.id_field] === "All") ?
            `${urlAppendCharacter}${param.api_field}=All` :
            `${urlAppendCharacter}${param.api_field}=${filterValue[param.id_field][param.id_field]}`
        }
      })
      if (url.includes('?')) {
        url += `&offset=${e.page.skip}&limit=${limit}`
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`
      }
      getRequest(url, getOneTimeChargeRecords);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);

  };
  const onFilterSubmit = () => {
    setPage({ skip: 0 })
    setLoading(true);
    let url = '/otc_gcm_filter'
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        let urlAppendCharacter = url.includes("?") ? `&` : `?`
        url += (filterValue[param.id_field][param.id_field] === "All") ?
          `${urlAppendCharacter}${param.api_field}=All` :
          `${urlAppendCharacter}${param.api_field}=${filterValue[param.id_field][param.id_field]}`;
      }
    })
    setFilterURL(url)
    if (url.includes('?')) {
      url += `&offset=0&limit=${limit}`
    } else {
      url += `?offset=0&limit=${limit}`
    }
   Object.keys(filterValue).length > 0 ? getRequest(url, getOneTimeChargeRecords):getRequest('/otc_left_vw?offset=0&limit=' + limit, getOneTimeChargeRecords);
   Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
    setDataChg(true);
    setOneTimeCharge({ ...oneTimeCharge, id: undefined });
  };
  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : ""}
      <Splitter panes={panes} onChange={onChange} className="splitBody">
        <div className="pane-content">
          <span>
            <Button size="small" className="app-button" onClick={reloadGrid} title="Refresh">
              <span className="k-icon k-i-reload"></span>
            </Button>
            {
              userContext.is_scm_admin == "Y"? "":<Button size="small" className="app-button" onClick={() => toggleDialog("Add")} title="Add">
              <span className="k-icon k-i-plus"></span>
            </Button>
            } 
            {     
              userContext.is_scm_admin == "Y"? "":<Button size="small" onClick={() => toggleDialog("Edit")} title="Edit" className="app-button"
              disabled={selectedData === null || selectedData.status === "Manually Closed" || selectedData.status === "Closed" || selectedData.status === "Cancelled" || selectedData.gcm !== userContext.user_name ? true : false}>           
              <span className="k-icon k-i-edit"></span>
            </Button>}
            <Button size="small" className="app-button" ref={anchor} title="Filter By" onClick={openPopover}>
              <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
            </Button>
            {show && <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={openPopover}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onFilterSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              staticLOVFlag="Y"
            />}
          </span>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              sort={sort}
              rowHeight={window.innerHeight * 0.067}
              onSortChange={(e) => { handleSortChange(e) }}
              onSelectionChange={onSelectionChange}
              total={dataState.length > 0 ? dataState[0]?.resultset_size : 0}
              onPageChange={(e) => { handlePageChange(e) }}
              data={orderBy(dataState.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] })), sort)}
              dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
              selectable={{ enabled: true, drag: false, cell: false, mode: "single" }}
              onRowClick={(e) => { handleRowClick(e) }}
            >
              <GridColumn headerClassName="headerClass" field='gcm' title="GCM" width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'gcm')} />
              <GridColumn headerClassName="headerClass" field='commodity_code' title='Commodity Code' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'commodity_code')} />
              <GridColumn headerClassName="headerClass" field='supplier_common_name' title='Supplier' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, 'supplier_common_name')} />
              <GridColumn headerClassName="headerClass" field='item_reference1' title='Item Reference' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, 'item_reference1')} />
              <GridColumn headerClassName="headerClass" field='bu' title='BU' width={setPercentage(8)} cell={(e) => GridCellToolTip(e, 'bu')} />
              <GridColumn headerClassName="headerClass" field='item_description' title='Item Description' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, 'item_description')} />
              <GridColumn headerClassName="headerClass" field='charge_type' title='Charge Type' width={setPercentage(8)} />
              <GridColumn headerClassName="headerClass" field='expected_amount' title='Expected Amount' width={setPercentage(10)} cell={(e) => GridCellToolTip(e, 'expected_amount')} />
              <GridColumn headerClassName="headerClass" field='expected_date' title='Expected Date' width={setPercentage(10)} />
              <GridColumn headerClassName="headerClass" field='status' title='Status' width={setPercentage(8)} />
              <GridColumn headerClassName="headerClass" field='po_number' title='Purchase Order(#)' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'po_number')} />
              <GridColumn headerClassName="headerClass" field='invoice_number' title='Invoice(#)' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'invoice_number')} />
              <GridColumn headerClassName="headerClass" field='invoice_amount' title='Total Actual Invoice Amount' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'invoice_amount')} />
              </Grid>
          </Tooltip>
        </div>
        <div className="pane-content">
          <InvoiceDetails OneTimeChargeId={oneTimeCharge.id} oneTimeChargeType={oneTimeCharge.charge_type} setLoading={setLoading} />
        </div>
      </Splitter>
      {
        showDialog && <OneTimeChargePopup
          mode={mode}
          selectedData={selectedData}
          toggleDialog={toggleDialog}
          reload={reloadGridWithPageSave} />
      }
    </div>
  );
}
export default OneTimeCharge;