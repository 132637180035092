import React from 'react';

const WindowTitle = (props) => {
    return (
        <div
          style={{
            lineHeight: "1.3em",
          }}
        >
          <span className={"k-icon "+props.icon} /> {props.title}
        </div>
      );
};

export default WindowTitle;