import React, { useEffect, useState } from 'react';
import { Stepper } from '@progress/kendo-react-layout';
import './../../shouldBeCost/components/StatusStepper.css'

  export const COGSInitiatveStatusStepper = (props) => {
    const [items,setItems] = useState(undefined);
    const [value,setValue]=React.useState();
    const determineStep=(dataItem)=>{
        let returnValue=0;
        if(["complete", "errored","cancelled"].includes(dataItem?.import_status?.toLowerCase())){    
            returnValue = 4;
        } else if(["complete", "errored","warning"].includes(dataItem?.validation_status?.toLowerCase())){  
          returnValue = 3;
        } else if(["complete", "errored"].includes(dataItem?.file_validation_status?.toLowerCase())){
          returnValue = 1
        }
        else if(["complete", "errored"].includes(dataItem?.extraction_status?.toLowerCase())){  
            returnValue=2;
        } else if(["complete", "errored"].includes(dataItem?.upload_status?.toLowerCase())){  
            returnValue=0;
        }
        setValue(returnValue);
    };
    const getIcons = (data) => {
      let itemsList=[];
      if(data?.upload_status){
        if(data?.upload_status?.toLowerCase()==="errored"){
          itemsList.push({
            label:(data?.upload_initiative) == "U" ? "Download":"Upload",
            icon: 'k-i-close'
          })
        } else {
          itemsList.push({
            label:(data?.upload_initiative) == "U" ? "Download":"Upload",
            icon: 'k-i-check'
          })
        }        
      } else {
        itemsList.push({
          label:(data?.upload_initiative) == "U" ? "Download":"Upload",
          icon: 'k-i-check'
        })
      }
      
      if(data?.file_validation_status){
        if(data?.file_validation_status?.toLowerCase()==="errored"){
          itemsList.push({
            label: 'File Validation',
            icon: 'k-i-close'       
          })
        } else {
          itemsList.push({
            label: 'File Validation',
            icon: 'k-i-check',
          })
        }        
      } 
      else {
        itemsList.push({
          label: 'File Validation',
          icon: 'k-i-check',
        })
      }
      
      if(data?.extraction_status){
        if(data?.extraction_status?.toLowerCase()==="errored"){
          itemsList.push({
            label: 'Extraction',
            icon: 'k-i-close'
          })
        } else {
          itemsList.push({
            label: 'Extraction',
            icon: 'k-i-check'
          })
        }        
      } else {
        itemsList.push({
          label: 'Extraction',
          icon: 'k-i-check'
        })
      }

      if(data?.validation_status){
        if(data?.validation_status?.toLowerCase()==="errored"){
          itemsList.push({
            label: 'Data Validation',
            icon: 'k-i-close'
          })
        } else if(data?.validation_status?.toLowerCase()==="warning"){
          itemsList.push({
            label: 'Data Validation',
            icon: 'k-i-warning'
          })
        }else {
          itemsList.push({
            label: 'Data Validation',
            icon: 'k-i-check',
          })
        }        
      } else {
        itemsList.push({
          label: 'Data Validation',
          icon: 'k-i-check'
        })
      }
      if(data?.import_status){
        if(data?.import_status?.toLowerCase()==="errored"){
          itemsList.push({
            label: 'Import',
            icon: 'k-i-close'
          })
        }
        else if(data?.import_status?.toLowerCase()=="cancelled"){
          itemsList.push({
            label: 'Import',
            icon: 'k-i-close'
          })
        } 
        else {
          itemsList.push({
            label: 'Import',
            icon: 'k-i-check'
          })
        }        
      } else {
        itemsList.push({
          label: 'Import',
          icon: 'k-i-check'
        })
      }
      
      setItems(itemsList)
    };
    
    
    useEffect(()=>{
      getIcons(props.dataItem);
      determineStep(props.dataItem)
    },[props.dataItem])
    
    const handleChange=(e)=>{
      setValue(value);
    }
    return items && 
    <Stepper  className="step" 
    disabled
    onChange={(e)=> { handleChange(e,items)}}
    value={value} 
     items={items} style={{marginTop: "7px"}} />
    ;
  };
