//fieldNames: array of field names whose date value needs to be formatted
//dataObj: Object having data values and field names
import { format } from "date-fns";
import moment from "moment-timezone";

export function formatDates(fieldNames, dataObj) {
  for (const property in dataObj) {
    if (fieldNames.includes(property)) {
      if (dataObj[property]) {
        dataObj[property] = formatISOStringToShortDate(
          formatLongDateToString(dataObj[property])
        );
      }
    }
  }
  return dataObj;
}

export function formatDatesToISO(fieldNames, dataObj) {
  for (const property in dataObj) {
    if (fieldNames.includes(property)) {
      if (dataObj[property]) {
        dataObj[property] = formatLongDateToString(new Date(dataObj[property]));
      }
    }
  }
  return dataObj;
}
// npm i --save date-fns --legacy-peer-deps
export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const formatDateFromString = (date, formats) => {
  const dateInFormat = format(new Date(date), formats);
  return dateInFormat;
};

// dd/mm/yyyy to yyyy-mm-dd
export const formatISODateToShortDate = (date) => {
  const split = date.split("/");
  const formatedDate = [
    split[2],
    (split[1] < 10 ? "0" : "") + parseInt(split[1]),
    (split[0] < 10 ? "0" : "") + parseInt(split[0]),
  ].join("-");
  return formatedDate;
};

/* Thu Apr 28 2022 00:00:00 GMT+0530 to ISO(2022-12-31T11:00:09Z) Standerd Time */
export const formatGMTToISOString = (date) => {
  const newdate = new Date(date);
  return newdate.toISOString();
};

/* 2022-12-31T11:00:09Z to  mm/dd/yyyy*/
export const formatISOStringToShortDate = (date) => {
  if (date !== null) {
    const isoDate = formatNewDate(date);
    let year = isoDate.getFullYear();
    let month = isoDate.getMonth() + 1;
    let dt = isoDate.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    const mmddyy = month + "/" + dt + "/" + year;
    return mmddyy;
  } else return null;
};

export const formatDateToStringDate = (date) => {
  var arrDate = date.split("/");
  var dateObject = new Date(+arrDate[2], arrDate[1] - 1, +arrDate[0]);
  const toStringdate = dateObject.toString();
  return toStringdate;
};
// 2022-05-10T18:30:00.000Z to DD/MM/YYYY
export const formatISOStringToShortDDDate = (date) => {
  const isoDate = new Date(date);
  const ddmmyy =
    isoDate.getDate() +
    "/" +
    (isoDate.getMonth() + 1) +
    "/" +
    isoDate.getFullYear();
  return ddmmyy;
};
// 2022-05-10T18:30:00.000Z to yyyy-mm-dd
export const formatISODateToShortDateDD = (date) => {
  const d = new Date(date);
  const formatedDate =
    d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  return formatedDate;
};

export function setLocalZone(fieldNames, dataObj) {
  for (const property in dataObj) {
    if (fieldNames.includes(property)) {
      if (dataObj[property]) {
        let b = dataObj[property].split("T")[1];
        let time = b.slice(0, 5);
        let dt = formatNewDate(dataObj[property]);
        let dd = dt.getDate();
        let mm = dt.getMonth() + 1;
        let yyyy = dt.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        dataObj[property] = mm + "/" + dd + "/" + yyyy + " " + time;
      }
    }
  }
  return dataObj;
}

//pst date formating
export const setLocal = (value) => {
  let a = value.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  let ist = new Date(a);
  return ist;
};

export const formatLongDateToString = (date) => {
  let m = moment(formatNewDate(date)).format();
  let finalDate = m.substring(0, 19).concat(".000Z");
  return finalDate;
};

//yyyy-mm-ddTHH:mm:ss.000Z to yyyy-mm-dd HH:mm
export const formatNewDateTime = (date) => {
  if (!isNaN(date.slice(0, 1))) {
    let d = date.split("T")[0] + " " + date.split("T")[1].slice(0, 5);
    return new Date(d);
  } else return new Date(date);
};

//yyyy-mm-ddTHH:mm:ss.000Z to long day
export const formatNewDate = (date) => {
  if (date !== null) {
    if (!isNaN(date.toString().slice(0, 1))) {
      return new Date(getDateFormat(date));
    } else return date;
  } else return null;
};

export const getDateFormat = (date) => {
  return date.split("T")[0].replace(/-/g, "/");
};
