import React, { useContext, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { UserContext } from "../../App";
import { getRequest, post, put } from "../../services/api.request";
import {
  formatISOStringToShortDate,
  formatLongDateToString,
  setLocal,
} from "../../utils/formatDateUtil";
import ProgressBar from "../ProgressBar/ProgressBar";
import WindowTitle from "../WindowTitle/WindowTitle";
import "./PageHeader.css";

export default function PageHeader({ selected }) {
  const user = useContext(UserContext);
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const [isActualization, setIsActualization] = useState(false);
  const [actualizationLoading, setActualizationLoading] = useState(false);
  const [actulizationData, setActulizationData] = useState([]);
  const [proceedData, setProceedData] = useState({});
  const [proceedPopUp, setProceedPopUp] = useState(false);
  const [subCheckWindow, setSubCheckWindow] = useState(false);
  const [subCheckForLastMnth, setSubCheckForLastMnth] = useState(false);
  const closureCheckMessage = useRef("");

  const getActualization = ({ data }) => {
    setActulizationData(data.items);
    setActualizationLoading(false);
  };

  const handleActualization = () => {
    setIsActualization(true);
    setActualizationLoading(true);
    getRequest(
      `actualization_dialogue?current_quarter=${currentQuarter}`,
      getActualization
    );
  };

  const handleCloseActualization = () => {
    handleCancel();
    setIsActualization(false);
  };

  const handleCheckBox = (e, index, Object) => {
    const list = [...actulizationData];
    if (e.value === true) {
      list[index]["submission_status"] = "Y";
      list[index]["submission_date"] = formatLongDateToString(
        setLocal(new Date())
      );
      setProceedData({
        ...proceedData,
        quarter: Object.quarter,
        p_month: Object.qtr_month,
        qtr_fiscal_month: Object.qtr_fiscal_month,
      });
    } else {
      list[index]["submission_date"] = null;
      list[index]["submission_status"] = "N";
      setProceedData({});
    }
    setActulizationData(list);
  };

  const getPostActualization = ({ data }) => {
    setActualizationLoading(false);
    data && data.p_ret_status === "S"
      ? alert(data.p_ret_msg)
      : alert(data.p_ret_msg);
    setProceedData({});
    setActulizationData([]);
    setIsActualization(false);
  };

  const handleProceed = () => {
    setActualizationLoading(true);
    put(
      `actualization_check_submission?current_quarter=${currentQuarter}`,
      {},
      getSubmissionCheckMsg
    );
  };

  const handleDiscardProceed = () => {
    setProceedPopUp(false);
    handleCancel();
  };

  const getSubmissionCheckMsg = ({ data }) => {
    if (data.proceed_actualization === "Y") {
      setProceedPopUp(true);
    } else {
      closureCheckMessage.current = data.message;
      if (proceedData?.p_month === 3) {
        setSubCheckForLastMnth(true);
      } else setSubCheckWindow(true);
    }
    setActualizationLoading(false);
  };

  const handleConfirmPopUp = () => {
    setProceedPopUp(false);
    setActualizationLoading(true);
    post(
      `actualization_dialogue?quarter=${proceedData?.quarter}&p_month=${proceedData?.p_month}&user_id=${user_id}`,
      {},
      getPostActualization
    );
  };

  const handleCancel = () => {
    if (Object.keys(proceedData).length > 0) {
      let deselectCheck = actulizationData.filter((item) => {
        return item.submission_date !== null && item.enabled_flag === "Y";
      });
      handleCheckBox(
        { value: false },
        actulizationData.indexOf(deselectCheck[0]),
        deselectCheck
      );
    }
  };

  const handleDiscardCheckWindow = () => {
    setSubCheckWindow(false);
    handleCancel();
  };

  const handleDiscardLstMnthCheck = () => {
    setSubCheckForLastMnth(false);
    handleCancel();
  };

  const handleSubCheckProceed = () => {
    setSubCheckWindow(false);
    setProceedPopUp(true);
  };

  const handleClosePopUp = () => {
    setProceedPopUp(false);
    handleCancel();
  };

  return (
    <div className="page-header-container">
      <p>{selected}</p>
      {user.is_scm_admin === "Y" && selected === "Home" && (
        <Button
          size="small"
          onClick={handleActualization}
          className="save-button"
          title="Actualization"
          style={{ margin: 0, marginLeft: "1%", borderRadius: "13px" }}
        >
          A
        </Button>
      )}
      <span className="spacer"></span>
      {selected === "One Time Charge" ||
      selected === "R&O COGS Reduction Initative" ? (
        ""
      ) : (
        <span className="k-icon k-i-information"></span>
      )}
      <p className="days-left-msg">
        {selected === "One Time Charge" ||
        selected === "R&O COGS Reduction Initative"
          ? ""
          : user.days_left_for_cost_submission}
      </p>
      {isActualization && (
        <div className="window-container">
          <Window
            title={
              <WindowTitle
                title="Actualization Trigger"
                icon="k-i-check-outline"
              />
            }
            initialHeight={335}
            initialWidth={520}
            onClose={handleCloseActualization}
            style={{ zIndex: "1" }}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <div style={{ marginTop: -15 }}>
              {actualizationLoading ? <ProgressBar /> : ""}
            </div>
            <br />
            <div>
              <div className="actulizationHeader">
                <div></div>
                <div>Quarter</div>
                <div>Month</div>
                <div>Submitted Date</div>
              </div>
              <div className="actBody">
                {actulizationData.length > 0 &&
                  actulizationData.map((item, i) => {
                    return (
                      <div className="actContent">
                        <Checkbox
                          style={
                            item.submission_status === "I"
                              ? {
                                  marginLeft: "65px",
                                  color: "white",
                                  backgroundColor: "rgb(255, 123, 0)",
                                  borderColor: "rgb(255, 123, 0)",
                                }
                              : item.submission_status === "Y"
                              ? {
                                  marginLeft: "65px",
                                  color: "white",
                                  backgroundColor: "#228dff",
                                  borderColor: "#228dff",
                                }
                              : item.submission_status === "E"
                              ? {
                                  marginLeft: "65px",
                                  color: "white",
                                  backgroundColor: "red",
                                  borderColor: "red",
                                }
                              : { marginLeft: "65px", borderColor: "#666666" }
                          }
                          defaultChecked={
                            item.enabled_flag === "N" ? true : false
                          }
                          value={item.submission_status}
                          disabled={
                            item.enabled_flag === "N" ||
                            actualizationLoading === true
                              ? true
                              : false
                          }
                          onChange={(e) => handleCheckBox(e, i, item)}
                          checked={
                            item.submission_status === "N" ? false : true
                          }
                        />
                        <label>{item.quarter}</label>
                        <label>{item.qtr_fiscal_month}</label>
                        <label>
                          {item.submission_date === null
                            ? ""
                            : item.flag === "1"
                            ? formatISOStringToShortDate(setLocal(new Date()))
                            : item.submission_date !== null
                            ? formatISOStringToShortDate(item.submission_date)
                            : null}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleCancel}
                className="actbutton"
                iconClass="k-icon k-i-close"
                style={{ marginRight: "4px" }}
                disabled={Object.keys(proceedData).length <= 0 ? true : false}
              ></Button>
              <Button
                onClick={handleProceed}
                className="actbutton"
                iconClass="k-icon k-i-arrow-chevron-right"
                disabled={Object.keys(proceedData).length <= 0 ? true : false}
              ></Button>
            </div>
          </Window>
        </div>
      )}
      {proceedPopUp && (
        <Dialog title={"Actualization"} onClose={handleDiscardProceed}>
          <p
            style={{
              textAlign: "center",
              height: 15,
              width: "472px",
            }}
          >
            {`Actualization for the month ${proceedData?.qtr_fiscal_month}, in the quarter ${proceedData?.quarter}, is being initiated. Do you want to proceed ?`}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleClosePopUp}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleConfirmPopUp}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {subCheckWindow && (
        <Dialog
          title={"Actualization"}
          onClose={() => setSubCheckWindow(false)}
        >
          <p
            style={{
              textAlign: "center",
              height: "20px",
              width: "516px",
            }}
          >
            {`${closureCheckMessage.current}. Do you want to proceed?`}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleDiscardCheckWindow}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleSubCheckProceed}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {subCheckForLastMnth && (
        <Dialog
          title={"Actualization"}
          onClose={() => setSubCheckForLastMnth(false)}
          width={548}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            {`${closureCheckMessage.current}. Cannot Proceed with the Actualization.`}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleDiscardLstMnthCheck}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
}
