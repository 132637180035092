import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import React, { useContext, useEffect } from "react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../components/WindowTitle/WindowTitle";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { debounce } from "lodash";
import privateRequest from "../../services/api.config";
import { UserContext } from "../../App";
import { setLocal } from "../../utils/formatDateUtil";
import { post } from "../../services/api.request";
import AddParts from "./AddParts";

const AddHeirarchyGroup = ({addToggleDialog,reloadGrid})=>{
    const userContext = useContext(UserContext);
    const user_id = userContext.user_id;
    const [loading, setLoading] = React.useState(false);
    const [heirarchyName,setHeirarchyName] = React.useState();
    const [payload, setPayload] = React.useState({});
    const [supPayload, setSupPayload] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [supLoading, setSupLoading] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState();
    const [supplierFilterValue, setSupplierFilterValue] = React.useState();
    const [addPartsDialog, setAddPartsDialog] = React.useState(false);
    const [getHeirarchyName, setGetHeirachyName] = React.useState();
    const [commodityCodeId,setCommodityCodeId] = React.useState();
    const [HMID,setHMID] = React.useState();    
    const [alertDialog, setAlertDialog] = React.useState(false);

    const supplierFilterChange = debounce(({ filter }) => {
        setSupLoading(true)
        let url = `generic_lov_service/suppliers/?search_for=${filter.value}&user_id=${user_id}`

        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleSupplierFilterRequest(data)
            })
            .catch((err) => {
                setSupLoading(false);
            });
    }, 1000)
    const filterChange = debounce(({ filter }) => {
        setIsLoading(true)
        let url = `/generic_lov_service/gcmcommcodes/?search_for=${filter.value}&user_id=${user_id}`

        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest(data)
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, 1000)
    const handleFilterRequest = (data) => {
        setPayload(data.items);
        setIsLoading(false);
    }
    const handleSupplierFilterRequest = (data) =>{
        setSupPayload(data.items);
        setSupLoading(false)
    }
    const commCodeChg = (e) => {
        setFilterValue(e.target.value)
    }
    const supplierOnChange = (e)=>{
        setSupplierFilterValue(e.target.value)
    }
    useEffect(() => {        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const openParts = () =>{
        setLoading(true);
        post(`/part_hierarchy?p_user_id=${user_id}` ,{prefix:heirarchyName,commodity_code:filterValue.lookup_value,
            supplier_name:supplierFilterValue.lookup_value,effective_start_date:setLocal(new Date())},({data})=>{
                if(data.p_out_status === "Success"){
                    setGetHeirachyName(data);
                    setCommodityCodeId(filterValue.attribute2);
                    setHMID(data.p_hmid);
                    setLoading(false);
                    setAddPartsDialog(!addPartsDialog);
                }
                else {
                    setAlertDialog(true);
                    setLoading(false);
                    setFilterValue('');
                    setSupplierFilterValue('');
                    setHeirarchyName('');
                }           
        });
    }
  const  clearAddHierarchy = () =>{
        setFilterValue('');
        setSupplierFilterValue('');
        setHeirarchyName('');
    }
return (
    <div>
    <div className="window-container">
       
            <Window title={"Create Hierarchy"}
                onClose={addToggleDialog}
                minimizeButton={() => null}
                maximizeButton={() => null}
                initialHeight={300}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={280} >
                <div style={{ marginTop: -15 }}>{loading ? (
                    <ProgressBar />
                ) : ""}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                    <FieldWrapper style={{ width: '100%' }}>
                        <Label editorId={"heirarchy_name"} optional={false}>Prefix:<sup style={{ color: "#f31700" }}>*</sup></Label>
                        <div className={"k-form-field-wrap"}>
                            <Input
                                style={{ width: '100%' }}
                                id="heirarchy_name"
                                onChange={(e) => { setHeirarchyName(e.target.value) }}
                                name="heirarchy_name"
                                value={heirarchyName}
                            />
                             </div>
                    </FieldWrapper>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <FieldWrapper style={{ width: '100%' }}>
                        <Label editorId={"commodity_code"} optional={false}>Commodity Code:<sup style={{ color: "#f31700" }}>*</sup></Label>
                        <DropDownList
                            style={{ width: '100%' }}
                            data={Object.keys(payload).length > 0 ? payload : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={filterChange}
                            loading={isLoading}
                            value={filterValue}
                            onChange={commCodeChg}
                        />
                    </FieldWrapper>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <FieldWrapper style={{ width: '100%' }}>
                        <Label editorId={"commodity_code"} optional={false}>Supplier Common Name:<sup style={{ color: "#f31700" }}>*</sup></Label>
                        <DropDownList
                            style={{ width: '100%' }}
                            data={Object.keys(supPayload).length > 0 ? supPayload : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={supplierFilterChange}
                            loading={supLoading}
                            value={supplierFilterValue}
                            onChange={supplierOnChange}
                        />
                    </FieldWrapper>
                </div>
                <div style={{ marginTop: 15, width: "100%", textAlign: "right" }}>
                <Button
                    size="small" className='save-button'
                    disabled={!heirarchyName || !filterValue || !supplierFilterValue}
                    onClick={openParts} >
                    <span >Save</span>
                </Button>
                <Button  size="small" 
                            onClick={clearAddHierarchy}
                            className="save-button"
                            disabled={!heirarchyName && !filterValue & !supplierFilterValue}
                        ><span > </span> Clear
                        </Button>
                </div>
            </Window>
            {
                alertDialog &&  ( <Dialog height='150px' ><p
                  style={{
                    textAlign: "center",
                    width: "400px"
                  }}
                >
                 You can not create the duplicate entry for Hierarchy.
                </p>
                <DialogActionsBar>
                  <button style={{ backgroundColor: "#228dff", color: "white",width:'100%'}}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={()=>setAlertDialog(false)} > Ok </button>
                </DialogActionsBar>
              </Dialog>)
            }
               
    </div>
     {
        addPartsDialog && <AddParts  setGetHeirachyName = {getHeirarchyName} hmid = {HMID} ccId = {commodityCodeId}
        toggleDialog={(e) => setAddPartsDialog(!addPartsDialog)} addToggleDialog = {addToggleDialog} reloadGrid ={reloadGrid}>

        </AddParts>
    }
    </div>
)
}
export default AddHeirarchyGroup;