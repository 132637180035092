import React from 'react'

import './ProgressBar.css'

export default function ProgressBar() {
  return (
    <div className="progress-bar">
        <div className="progress-bar-value"></div>
    </div>
  )
}
