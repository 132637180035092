import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import "./costAllocation.css";
import { UserContext } from "../../App";
import { getRequest } from "../../services/api.request";
import PreviousAllocation from "./previousAllocation/previousAllocation";
import { orderBy } from "@progress/kendo-data-query";
import { Splitter } from "@progress/kendo-react-layout";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { Button } from "@progress/kendo-react-buttons";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import ExportComponent from "./massUploadComponents/exportComponent";
import UploadComponent from "./massUploadComponents/uploadComponent";
import { Window } from "@progress/kendo-react-dialogs";
import WindowTitle from "../../components/WindowTitle/WindowTitle";

const initialData = {
  skip: 0,
  take: 10,
};

const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
  return Math.round(gridWidth / 100) * percentage;
};

const CostAllocation = () => {
  const limit = 10;
  const FILTER_PARAMS = [
    {
      paramName: "gcmparts_hierarchy",
      displayValue: "Part Number",
      id_field: "part",
      api_field: "part",
    },
    {
      paramName: "gpn",
      displayValue: "Group Part Number",
      id_field: "gpn_id",
      api_field: "gpn_id",
    },
    {
      paramName: "gcmcommcodes",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
    },
  ];
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const DATA_ITEM_KEY = "cam_id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const fetchDetails = `/cost_allocation_vw_left/${user_id}/${currentQuarter}?offset=`;

  const [allocationData, setAllocationData] = useState();
  const [filterValue, setFilterValue] = useState({});
  const [componentCode, setComponentCode] = useState({
    cam_id: undefined,
    gpn_id: undefined,
    part_id: undefined,
    ccid: undefined,
    edit_yn: "",
  });
  const [sort, setSort] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [page, setPage] = useState(initialData);
  const [pageSizes, setPageSizes] = useState([10, 25, 50]);
  const [quarterNames, setQuarterNames] = useState([]);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataChg, setDataChg] = useState(false);
  const [panes, setPanes] = useState([
    {
      size: "47.3%",
      min: "25%",
      collapsible: true,
    },
    {
      min: "52%",
      collapsible: true,
    },
  ]);
  const [rightSort, setRightSort] = useState([]);
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [rowClick, setRowClick] = useState(false);
  const [allocationStatus, setAllocationStatus] = useState(false);
  const [downloadWindow, setDownloadWindow] = useState(false);
  const [uploadWindow, setUploadWindow] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const anchor = useRef(null);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const getCostAllocationDetails = ({ data }, type) => {
    setAllocationData(data.items);
    if (data.items.length > 0) {
      if (data.items[0].resultset_size < 50) {
        setPageSizes([10, 25]);
      } else if (data.items[0].resultset_size < 25) {
        setPageSizes([10]);
      }
    }
    setLoading(false);
  };

  const getQuarterNames = ({ data }, type) => {
    setQuarterNames(data.get_eight_quarter);
  };

  useEffect(() => {
    setLoading(true);
    getRequest(
      `cost_submission_quarters/lov?p_current_quarter=${currentQuarter}`,
      getQuarterNames
    );
    getRequest(
      fetchDetails + page.skip + "&limit=" + limit,
      getCostAllocationDetails
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cellWithBackGround = (props) => {
    let iconAttention = props.dataItem.requires_action_yn;
    const icon =
      iconAttention === "Y" ? (
        <span className="k-icon k-i-exception"></span>
      ) : (
        ""
      );
    const style = { color: iconAttention ? "red" : "" };
    return <td style={style}>{icon}</td>;
  };

  const handleSortChange = (e) => {
    setLoading(true);
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          `/cost_allocation_vw_left/${user_id}/${currentQuarter}?q=` +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getCostAllocationDetails
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `/cost_allocation_vw_left/${user_id}/${currentQuarter}?q=` +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getCostAllocationDetails
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else if (dataChg === true) {
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "gcmparts_hierarchy") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gcmcommcodes") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_code"]
            }`;
          }
        }
      });
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          `/cost_allocation_vw_left/${user_id}/${currentQuarter}?q=` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getCostAllocationDetails
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `/cost_allocation_vw_left/${user_id}/${currentQuarter}?q=` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
          getCostAllocationDetails
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else {
      setSort(e.sort);
    }
  };

  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (submit === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? fetchDetails + e.page.skip + "&limit=" + e.page.take
          : fetchDetails +
            e.page.skip +
            "&q=" +
            encodeURIComponent(queryString) +
            "&limit=" +
            e.page.take;
      getRequest(urlWithoutFilter, getCostAllocationDetails);
    }
    if (submit === true) {
      let urlWithFilter;
      let url = `/costalloc_left_filter/?user_id=${user_id}&current_quarter=${currentQuarter}`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "gcmparts_hierarchy") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else if (param.paramName === "gcmcommcodes") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          } else {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_code"]
            }`;
          }
        }
      });
      urlWithFilter =
        sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
        url = urlWithFilter + `&offset=${e.page.skip}&limit=${e.page.take}`;
      } else {
        url = urlWithFilter + `?offset=${e.page.skip}&limit=${e.page.take}`;
      }
      getRequest(url, getCostAllocationDetails);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const handleRowClick = (e) => {
    if (e.dataItem?.cam_id) {
      setComponentCode({
        ...componentCode,
        cam_id: e.dataItem.cam_id,
        gpn_id: e.dataItem.gpn_id,
        ccid: e.dataItem.ccid,
        part_id: e.dataItem.part_id,
        edit_yn: e.dataItem.edit_yn,
      });
    }
    setRowClick(true);
  };

  const onPaneChange = (event) => {
    setPanes(event.newState);
  };

  const reloadGrid = () => {
    setLoading(true);
    setPage(initialData);
    setComponentCode({});
    setSubmit(false);
    setSort([]);
    setRightSort([]);
    setSelectedState({});
    setAllocationStatus(false);
    setRowClick(false);
    setDataChg(false);
    getRequest(fetchDetails + "0&limit=" + limit, getCostAllocationDetails);
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const onFilterSubmit = () => {
    setPage({ skip: 0, take: 10 });
    setLoading(true);
    let url = `/costalloc_left_filter/?user_id=${user_id}&current_quarter=${currentQuarter}`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        if (param.paramName === "gcmparts_hierarchy") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        } else if (param.paramName === "gcmcommcodes") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        } else {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_code"]
          }`;
        }
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getCostAllocationDetails);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setComponentCode({});
    setRightSort([]);
    setSelectedState({});
    setShow(false);
    setDataChg(true);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    setSubmit(false);
    setShow(false);
  };

  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <Splitter panes={panes} onChange={onPaneChange} className="splitBody">
        <div className="pane-content">
          <span>
            <Button
              size="small"
              onClick={reloadGrid}
              className="app-button"
              title="Refresh"
            >
              <span className="k-icon k-i-reload"></span>
            </Button>
            <Button
              size="small"
              className="app-button"
              onClick={openPopover}
              ref={anchor}
              title="Filter By"
            >
              <span
                className={
                  submit === true
                    ? "k-icon k-i-filter-clear"
                    : "k-icon k-i-filter"
                }
              ></span>
            </Button>
            <Button
              size="small"
              iconClass="k-icon k-i-download"
              title="Allocation Download"
              className="app-button"
              onClick={() => setDownloadWindow(true)}
            ></Button>
            <Button
              size="small"
              iconClass="k-icon k-i-upload"
              title="Allocation Upload"
              className="app-button"
              onClick={() => setUploadWindow(true)}
            ></Button>
            {show && (
              <FilterPopUp
                params={FILTER_PARAMS}
                handleClose={onCancelClick}
                show={show}
                elementRef={anchor.current && anchor.current.element}
                handleFilterSubmit={onFilterSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                userId={user_id}
              />
            )}
          </span>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={{
                buttonCount: 5,
                pageSizes: allocationData?.length !== 0 ? pageSizes : "",
              }}
              skip={page.skip}
              pageSize={page.take}
              sort={sort}
              rowHeight={window.innerHeight * 0.067}
              data={orderBy(
                allocationData?.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })),
                sort
              )}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
              }}
              onSelectionChange={onSelectionChange}
              total={
                allocationData
                  ? allocationData.length > 0
                    ? allocationData[0]?.resultset_size
                    : 0
                  : 0
              }
              onRowClick={(e) => {
                handleRowClick(e);
              }}
              onSortChange={(e) => {
                handleSortChange(e);
              }}
              onPageChange={(e) => {
                handlePageChange(e);
              }}
            >
              <GridColumn
                headerClassName="headerClass"
                field="requires_action_yn"
                title="!"
                width={setPercentage(6)}
                cell={cellWithBackGround}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Part Number"
                field="part"
                width={setPercentage(15)}
                cell={(e) => GridCellToolTip(e, "part")}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Commodity Group"
                field="commodity_group"
                width={setPercentage(14)}
                cell={(e) => GridCellToolTip(e, "commodity_group")}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Commodity Code"
                field="commodity_code"
                width={setPercentage(14)}
                cell={(e) => GridCellToolTip(e, "commodity_code")}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Group Part Number"
                field="gpn_number"
                width={setPercentage(14)}
                cell={(e) => GridCellToolTip(e, "gpn_number")}
              />
            </Grid>
          </Tooltip>
        </div>
        <div className="pane-content">
          <div>
            <PreviousAllocation
              componentCode={componentCode}
              quarterNames={quarterNames}
              visibleDialog={visibleDialog}
              setVisibleDialog={setVisibleDialog}
              setLoading={setLoading}
              rowClick={rowClick}
              setRightSort={setRightSort}
              rightSort={rightSort}
              setRowClick={setRowClick}
              setAllocationStatus={setAllocationStatus}
              allocationStatus={allocationStatus}
            />
          </div>
        </div>
      </Splitter>
      {downloadWindow && (
        <div className="window-costAllocation">
          <Window
            style={{ zIndex: "1", height: "265px", width: "515px" }}
            title={<WindowTitle title={"Download By"} icon="k-i-download" />}
            onClose={() => setDownloadWindow(false)}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <ExportComponent
              quarterNames={quarterNames}
              user_id={user_id}
              currentQuarter={currentQuarter}
            />
          </Window>
        </div>
      )}
      {uploadWindow && (
        <div className="window-container">
          <Window
            initialHeight={window.innerHeight * 0.96}
            initialWidth={1130}
            title={
              <WindowTitle
                title="Upload of Mass Allocation"
                icon="k-i-calculator"
              />
            }
            onClose={() => setUploadWindow(false)}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <div style={{ marginTop: "-15px" }}>
              {" "}
              {uploadLoading ? <ProgressBar /> : ""}
            </div>
            <UploadComponent
              user_id={user_id}
              currentQuarter={currentQuarter}
              setUploadLoading={setUploadLoading}
              quarterNames={quarterNames}
              uploadWindow={uploadWindow}
            />
          </Window>
        </div>
      )}
    </div>
  );
};

export default CostAllocation;
