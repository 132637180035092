import React, { useEffect, useState } from "react";
import "./commodityView.css"
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { getRequest } from "./../../../services/api.request";
import { Button } from "@progress/kendo-react-buttons";
import { orderBy } from "@progress/kendo-data-query";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
const CommodityView = ({componentCode, setLoading}) => {
    const initialDataState = {
        skip: 0,
        take: 1
    };
    const limit = 10;
    const gridWidth = window.innerWidth * 0.85;
    const setPercentage = (percentage) => {
        return Math.round(gridWidth / 100) * percentage;
      };
    const [page, setPage] = React.useState(initialDataState);
    const [sort, setSort] = useState([]);
    const [dataIdState, setDataIdState] = React.useState();

    const formatDates = (dataItem) => {
        for (const property in dataItem) {
            if (['start_date_active', 'end_date_active'].includes(property)) {
                if (dataItem[property]) {
                    var d = new Date(dataItem[property].toString())
                    dataItem[property] = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
                }
            }
        }
        return dataItem;
    };
    const getGCV1 = ({ data }, type) => {
        setDataIdState(
            data.items.map(dataItem => Object.assign({}, formatDates(dataItem)))
        );
        setLoading(false);
    }

    const fetchCommodityCodes = React.useCallback(
        () => {
            if (componentCode)

                getRequest('comm_code_vw_right/' + componentCode+ '?offset=' + page.skip + "&limit=" + limit, getGCV1);
            else
                setDataIdState([]);
            setSort([]);
            setPage(initialDataState);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [componentCode]
    );

    useEffect(() => {
        fetchCommodityCodes();
    }, [fetchCommodityCodes]);
    const handlePageChange = (e) => {
        setLoading(true);
        let c = 'comm_code_vw_right'+ componentCode+'?offset=' + e.page.skip + "&limit=" + limit;
        getRequest(c, getGCV1);
        setTimeout(() => {
          setPage(e.page);
    
        }, 100);
      };

    const reloadGrid1 = () => {
        setLoading(true);
        setSort([]);
        setDataIdState([]);
    }
    return (
        <div>
            <div className="pane-content">
                <span className="fil">
                    <Button className="app-button"  size="small" title="Refresh" onClick={reloadGrid1} >
                        <span className="k-icon k-i-reload"></span>
                    </Button>
                </span>
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                {<Grid  total={dataIdState?dataIdState.length > 0 ? dataIdState[0]?.resultset_size : 0:0} data={orderBy(dataIdState, sort)} sortable={true} sort={sort} onSortChange={e => {
                    setSort(e.sort);
                }} pageable={true}
                onPageChange={(e) => { handlePageChange(e) }}
                rowHeight={window.innerHeight * 0.065}
                >

                    <GridColumn headerClassName="headerClass" field="user_name" title="GCM Name" cell={(e) => GridCellToolTip(e, 'user_name')} />
                    <GridColumn headerClassName="headerClass"  field="start_date_active" title="Start Date Active" cell={(e) => GridCellToolTip(e, 'start_date_active')}/>
                    <GridColumn headerClassName="headerClass" field="end_date_active" title="End Date Active" cell={(e) => GridCellToolTip(e, 'end_date_active')}/>
                </Grid>}
                </Tooltip>
            </div>
        </div>
    );

}
export default CommodityView;