import React, { useEffect, useState } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import "./fileValidator.css";

const FileValidator = (props) => {
  const [items, setItems] = useState(undefined);
  const [value, setValue] = useState();

  const determineStep = (dataItem) => {
    let returnValue = 0;
    if (
      ["complete", "error"].includes(dataItem?.import_status?.toLowerCase())
    ) {
      returnValue = 4;
    } else if (
      ["complete", "errored", "warning"].includes(
        dataItem?.validation_status?.toLowerCase()
      )
    ) {
      returnValue = 3;
    } else if (
      ["complete", "error", "warning"].includes(
        dataItem?.file_validation_status?.toLowerCase()
      )
    ) {
      returnValue = 2;
    } else if (
      ["complete", "errored"].includes(
        dataItem?.extraction_status?.toLowerCase()
      )
    ) {
      returnValue = 1;
    } else if (
      ["complete", "error"].includes(dataItem?.upload_status?.toLowerCase())
    ) {
      returnValue = 0;
    }
    setValue(returnValue);
  };

  const getIcons = (data) => {
    let itemsList = [];
    if (data?.upload_status) {
      if (data?.upload_status?.toLowerCase() === "error") {
        itemsList.push({
          label: "Upload",
          icon: "k-i-close",
        });
      } else {
        itemsList.push({
          label: "Upload",
          icon: "k-i-check",
        });
      }
    } else {
      itemsList.push({
        label: "Upload",
        icon: "k-i-close",
      });
    }
    if (data?.extraction_status) {
      if (data?.extraction_status?.toLowerCase() === "errored") {
        itemsList.push({
          label: "Extraction",
          icon: "k-i-close",
        });
      } else {
        itemsList.push({
          label: "Extraction",
          icon: "k-i-check",
        });
      }
    } else {
      itemsList.push({
        label: "Extraction",
        icon: "k-i-check",
      });
    }
    if (data?.file_validation_status) {
      if (data?.file_validation_status?.toLowerCase() === "error") {
        itemsList.push({
          label: "File Validation",
          icon: "k-i-close",
        });
      } else if (data?.file_validation_status?.toLowerCase() === "warning") {
        itemsList.push({
          label: "File Validation",
          icon: "k-i-warning",
        });
      } else {
        itemsList.push({
          label: "File Validation",
          icon: "k-i-check",
        });
      }
    } else {
      itemsList.push({
        label: "File Validation",
        icon: "k-i-check",
      });
    }
    if (data?.validation_status) {
      if (data?.validation_status?.toLowerCase() === "errored") {
        itemsList.push({
          label: "Data Validation",
          icon: "k-i-close",
        });
      } else if (data?.validation_status?.toLowerCase() === "warning") {
        itemsList.push({
          label: "Data Validation",
          icon: "k-i-warning",
        });
      } else {
        itemsList.push({
          label: "Data Validation",
          icon: "k-i-check",
        });
      }
    } else {
      itemsList.push({
        label: "Data Validation",
        icon: "k-i-check",
      });
    }
    if (data?.import_status) {
      if (data?.import_status?.toLowerCase() === "error") {
        itemsList.push({
          label: "Import",
          icon: "k-i-close",
        });
      } else if (data?.import_status?.toLowerCase() === "cancelled") {
        itemsList.push({
          label: "Import",
          icon: "k-i-close",
        });
      } else {
        itemsList.push({
          label: "Import",
          icon: "k-i-check",
        });
      }
    } else {
      itemsList.push({
        label: "Import",
        icon: "k-i-check",
      });
    }
    setItems(itemsList);
  };

  useEffect(() => {
    getIcons(props.dataItem);
    determineStep(props.dataItem);
  }, [props.dataItem]);

  const handleChange = (e) => {
    setValue(value);
  };

  return (
    items && (
      <Stepper
        disabled
        className="step"
        onClick={handleChange}
        value={value}
        items={items}
        style={{ marginTop: "7px" }}
      />
    )
  );
};

export default FileValidator;
