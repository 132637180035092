import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import './Header.css';
import SVGLogo from '../../assets/logo.svg'
import { UserContext } from '../../App'
import { ssoUrl } from '../../environment/environment.prod';

export default function Header({handleClick}) {
  const user = useContext(UserContext);

  const handleLogout = () => {
    localStorage.clear()
    window.location.replace(ssoUrl,{replace:true})
  }
  return (
      <div className='header-container'>
        <button onClick={handleClick} className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
           <span className="k-icon k-i-menu" />
        </button>
        <img className="logo-image" src={SVGLogo} alt="" />
        <span className='header-saperator'>|</span>
        <Link to="/" style={{textDecoration:'none', color: '#000'}}>
            <h1 className="brand-title">CMAT</h1>
        </Link>
        <span className='spacer'></span>
        <div className="user-container">
            <strong><span className='brand-title'>Welcome, { user.user_name }</span></strong>
            <span>Current Quarter: <b style={{ color: '#53A5FF' }}>{user.curr_qrtr}</b></span>
        </div>
        <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
             <Link style={{textDecoration:'none', color: '#000'}} to="/"><span className="k-icon k-i-home" /></Link> 
        </button>
        <button onClick={handleLogout} title='logout' className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
          <span className="k-icon k-i-logout"></span>
        </button>
      </div>
  )
}
