import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { UserContext } from "../../../App";
import {
  Dialog,
  DialogActionsBar,
  Window,
} from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { getRequest, post, put } from "../../../services/api.request";

const SubmissionClosure = ({
  setMissingCostDialog,
  checkedValues,
  allocCompleted,
  setAllocCompleted,
  setEnableSave,
  enableSave,
}) => {
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const [loadingCS, setLoadingCS] = useState(false);
  const [copyQtrDialog, setCopyQtrDialog] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [missedParts, setMissedParts] = useState([]);
  const [confirmCostSub, setConfirmCostSub] = useState(false);
  const [tDDMessage, setTDDMessage] = useState("");
  const [confirmTDD, setConfirmTDD] = useState(false);
  const [showTDDSetUpMsg, setShowTDDSetUpMsg] = useState(false);
  const [loadingTddSetUpMsg, setLoadingTddSetUpMsg] = useState(false);
  const [tddSetUpMsg, setTddSetUpMsg] = useState("");
  const [allocDetails, setAllocDetails] = useState([]);
  const [loadingCA, setLoadingCA] = useState(false);
  const [incompleteAllocDialog, setIncompleteAllocDialog] = useState(false);
  const [isCopyAlloc, setIsCopyAlloc] = useState(false);
  const [checkStatusWindow, setCheckStatusWindow] = useState(false);
  const [copyCSDetails, setCopyCSDetails] = useState({
    copyCSID: null,
    copyCsStatus: "",
  });
  const [copyCADetails, setCopyCADetails] = useState({
    copyCAID: null,
    copyCAMsg: "",
    copyCAStatus: "",
  });
  const quarterLabels = useRef([]);

  const fetchMissedParts = useCallback(() => {
    setLoadingCS(true);
    if (checkedValues.ccid) {
      getRequest(
        `partsmissingcs/?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}`,
        getMissedParts
      );
    }
  }, [checkedValues, currentQuarter]);

  const getMissedParts = ({ data }) => {
    if (data.items.length <= 0) {
      setMissedParts([]);
      alert(
        "All the part costs under this code has been submitted. Please click on Proceed icon."
      );
    } else setMissedParts(data.items);
    setLoadingCS(false);
  };

  useEffect(() => {
    fetchMissedParts();
  }, [fetchMissedParts]);

  const handleCloseMissingParts = () => {
    setMissingCostDialog(false);
  };

  const handleCopy = () => {
    setCopyQtrDialog(true);
  };

  const handleReload = () => {
    setLoadingCS(true);
    getRequest(
      `partsmissingcs/?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}`,
      getMissedParts
    );
  };

  const handleProceed = () => {
    setConfirmCostSub(true);
    setEnableSave({ ...enableSave, parts: true });
    setCheckStatusWindow(false);
  };

  const handleCloseConfirmCopy = () => {
    setCopyQtrDialog(false);
  };

  const handleConfirmCopy = () => {
    setLoadingCS(true);
    setCopyQtrDialog(false);
    post(
      `copycsfrompreviousquarter/?p_current_qtr=${currentQuarter}&p_user_id=${user_id}&ccid=${checkedValues.ccid}`,
      {},
      getCopiedData
    );
  };

  const getCopiedData = ({ data }) => {
    if (data.ret_status === "I") {
      setIsCopy(true);
      alert(data.ret_msg + ". Please Check the Copy Status.");
    } else alert(data.ret_msg);
    setLoadingCS(false);
    setCopyCSDetails({ ...copyCSDetails, copyCSID: data.copy_cs_id });
    setCopyQtrDialog(false);
    setLoadingCS(false);
  };

  const handleCheckStatus = () => {
    setLoadingCS(true);
    getRequest(
      `copy_cs_status?copy_cs_id=${copyCSDetails.copyCSID}&ccid=${checkedValues.ccid}&current_quarter=${currentQuarter}`,
      getStatus
    );
  };

  const handleCheckCAStatus = () => {
    setLoadingCA(true);
    getRequest(
      `copy_ca_status?copy_ca_id=${copyCADetails.copyCAID}&ccid=${checkedValues.ccid}&current_quarter=${currentQuarter}`,
      getCAStatus
    );
  };

  const getStatus = ({ data }) => {
    data && data.copy_cs_status === "I"
      ? setCopyCSDetails({ ...copyCSDetails, copyCsStatus: "I" })
      : setCopyCSDetails({ ...copyCSDetails, copyCsStatus: "S" });
    setCheckStatusWindow(true);
  };

  const getCAStatus = ({ data }) => {
    data && data.copy_ca_status === "I"
      ? setCopyCADetails({ ...copyCADetails, copyCAMsg: "I" })
      : setCopyCADetails({
          ...copyCADetails,
          copyCAMsg: data.copy_ca_msg,
          copyCAStatus: "S",
        });
    setCheckStatusWindow(true);
  };

  const toggleConfirmCostSub = () => {
    setConfirmCostSub(false);
  };

  const handleConfirmCostSub = () => {
    setLoadingCS(true);
    getRequest(
      `tdforcommcode/?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}`,
      getTDDMessage
    );
    setConfirmCostSub(false);
  };

  const getTDDMessage = ({ data }, type) => {
    if (data.items[0].call_response !== null) {
      setConfirmTDD(true);
      setTDDMessage(data.items[0].call_response);
      setLoadingCS(false);
    } else {
      put(
        `cs_closure_topdown_default?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}`,
        {},
        getTDDSetupMsgHidden
      );
      setConfirmCostSub(false);
    }
  };

  const toggleConfirmTDD = () => {
    setConfirmTDD(false);
  };

  const handleConfirmTDD = (value) => {
    setLoadingCS(true);
    if (value === "YES") {
      put(
        `cs_closure_topdown_default?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}`,
        {},
        getTDDSetupMsg
      );
      setShowTDDSetUpMsg(true);
      setLoadingTddSetUpMsg(true);
    }
    setConfirmTDD(false);
    setLoadingCS(false);
  };

  const getTDDSetupMsgHidden = ({ data }) => {
    if (data.ret_status === "S") {
      setEnableSave({ ...enableSave, tdd: true });
      handleConfirmTDDSetUpMsg();
    } else alert(data.ret_msg);
  };

  const getTDDSetupMsg = ({ data }) => {
    if (data.ret_status === "S") {
      setEnableSave({ ...enableSave, tdd: true });
      setTddSetUpMsg(data.ret_msg);
    } else {
      alert(data.ret_msg);
      setTddSetUpMsg("");
    }
    setLoadingTddSetUpMsg(false);
  };

  const toggleTDDSetUpMsg = () => {
    setShowTDDSetUpMsg(false);
  };

  const handleConfirmTDDSetUpMsg = () => {
    setIncompleteAllocDialog(true);
    setLoadingCS(false);
    setLoadingCA(true);
    getRequest(
      `viewincompletecostallocations/?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}&gcm=${user_id}`,
      getAllocDetails
    );
    setShowTDDSetUpMsg(false);
  };

  const handleDataLabels = () => {
    getRequest(
      `cost_submission_quarters/lov?p_current_quarter=${currentQuarter}`,
      getQtrNames
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    allocDetails.length <= 0 && handleDataLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocDetails]);

  const getQtrNames = ({ data }) => {
    let col = [];
    data.get_eight_quarter.map((item) => col.push(item.quarter_name));
    quarterLabels.current = col;
  };

  const getAllocDetails = ({ data }, type) => {
    if (data.items.length > 0) {
      setAllocDetails(data.items);
      setAllocCompleted(
        data.items.every((item) => {
          return (
            item.q1_total_alloc === 100 &&
            item.q2_total_alloc === 100 &&
            item.q3_total_alloc === 100 &&
            item.q4_total_alloc === 100 &&
            item.q5_total_alloc === 100 &&
            item.q6_total_alloc === 100 &&
            item.q7_total_alloc === 100 &&
            item.q8_total_alloc === 100
          );
        })
      );
      getQuarterLabels(data.items[0]);
    } else {
      setAllocDetails([]);
      handleDataLabels();
      alert("No incomplete cost allocations. Please click on Proceed icon.");
    }
    setLoadingCA(false);
  };

  const getQuarterLabels = (item) => {
    let col = [];
    for (let key in item) {
      if (key.toString().charAt(0) === "q" && key.length === 2) {
        col.push(item[key]);
      }
    }
    quarterLabels.current = col;
  };

  const handleClose = () => {
    setIncompleteAllocDialog(false);
  };

  const handleCopyAlloc = () => {
    setLoadingCA(true);
    post(
      `copyca_sw_closure/?current_qtr=${currentQuarter}&user_id=${user_id}&ccid=${checkedValues.ccid}`,
      {},
      getCopiedAllocationData
    );
  };

  const reloadTable = () => {
    setLoadingCA(true);
    getRequest(
      `viewincompletecostallocations/?current_quarter=${currentQuarter}&ccid=${checkedValues.ccid}&gcm=${user_id}`,
      getAllocDetails
    );
  };

  const getCopiedAllocationData = ({ data }) => {
    if (data.ret_status === "I") {
      setIsCopyAlloc(true);
      alert(data.ret_msg + ". Please Check the Copy Status.");
    } else alert(data.ret_msg);
    setLoadingCA(false);
    setCopyCADetails({ ...copyCADetails, copyCAID: data.copy_ca_id });
  };

  const handleAllocCancel = () => {
    setIncompleteAllocDialog(false);
    setMissingCostDialog(false);
  };

  const handleCostAlloc = () => {
    setEnableSave({ ...enableSave, alloc: true });
    setIncompleteAllocDialog(false);
    setConfirmTDD(false);
    setMissingCostDialog(false);
  };

  return (
    <>
      <div className="window-container">
        <Window
          title={
            <WindowTitle
              title={`Parts with missing/partial Cost submission: ${checkedValues.commodity_code}`}
              icon="k-i-check-outline"
            />
          }
          initialHeight={430}
          initialWidth={520}
          style={{ zIndex: "1" }}
          minimizeButton={() => null}
          maximizeButton={() => null}
          onClose={handleCloseMissingParts}
          draggable={false}
          closeButton={
            copyCSDetails && copyCSDetails.copyCsStatus === "I"
              ? () => null
              : ""
          }
        >
          <div style={{ marginTop: "-15px" }}>
            {loadingCS ? <ProgressBar /> : ""}
            <span>
              <Button
                size="small"
                onClick={handleCopy}
                className="app-button"
                title="Copy from Prev. Quarters"
                iconClass="k-icon k-i-copy k-i-files"
                disabled={
                  isCopy === true || missedParts.length <= 0 ? true : false
                }
              ></Button>
              <Button
                size="small"
                onClick={handleReload}
                className="app-button"
                title="Refresh"
                iconClass="k-icon k-i-reload"
              ></Button>
              <span
                title={
                  copyCSDetails.copyCsStatus === "S"
                    ? "Copied Successfully"
                    : "Check Copy Status"
                }
              >
                <Button
                  className="app-button"
                  size="small"
                  onClick={handleCheckStatus}
                  iconClass="k-icon k-i-eye"
                  style={{
                    color: copyCSDetails.copyCsStatus === "S" && "green",
                  }}
                  disabled={
                    !isCopy ||
                    (missedParts.length <= 0 && !loadingCS) ||
                    copyCSDetails.copyCsStatus === "S"
                  }
                ></Button>
              </span>
            </span>
            <span style={{ float: "right" }}>
              <Button
                size="small"
                onClick={handleProceed}
                className="app-button"
                title="Proceed"
                iconClass="k-icon k-i-check-outline"
                disabled={
                  copyCSDetails.copyCsStatus === "S" ||
                  (missedParts.length <= 0 && !loadingCS)
                    ? false
                    : true
                }
              ></Button>
            </span>
          </div>
          <div>
            <div className="dialogHeader">
              <div>Part Number</div>
              <div>Description</div>
            </div>
          </div>
          <div className="dialogBody">
            {missedParts.length > 0 &&
              [...missedParts].map((item, i) => {
                return (
                  <React.Fragment>
                    <div className="dialogContent">
                      <label>{item.part}</label>
                    </div>
                    <div className="dialogContent">
                      <label>{item.part_desc}</label>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
          {copyQtrDialog && (
            <Dialog
              title={"Parts with missing Cost submission"}
              onClose={handleCloseConfirmCopy}
            >
              <p
                style={{
                  textAlign: "center",
                  height: 15,
                }}
              >
                Do you want to copy the missing Cost Submission from prior
                submissions?
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={handleCloseConfirmCopy}
                >
                  No
                </button>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={handleConfirmCopy}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {confirmCostSub && (
            <Dialog
              title={"Parts with missing Cost submission"}
              onClose={toggleConfirmCostSub}
            >
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Do you want to proceed?
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  value="NO"
                  onClick={toggleConfirmCostSub}
                >
                  No
                </button>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={handleConfirmCostSub}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {confirmTDD && tDDMessage?.length > 0 && (
            <Dialog
              title={"Top-Down discount % not setup"}
              onClose={toggleConfirmTDD}
              width={510}
            >
              <p
                style={{
                  textAlign: "center",
                  height: 40,
                }}
              >
                {tDDMessage}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={toggleConfirmTDD}
                  value="NO"
                >
                  No
                </button>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  value="YES"
                  onClick={(e) => handleConfirmTDD(e.target.value)}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {showTDDSetUpMsg && (
            <Dialog
              title={"Top-Down discount % "}
              onClose={toggleTDDSetUpMsg}
              width={300}
            >
              <div style={{ marginTop: -15 }}>
                {loadingTddSetUpMsg ? <ProgressBar /> : ""}
              </div>
              <p
                style={{
                  textAlign: "center",
                  height: 40,
                }}
              >
                {tddSetUpMsg}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={handleConfirmTDDSetUpMsg}
                >
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {incompleteAllocDialog && (
            <div>
              <Window
                title={
                  <WindowTitle
                    title="Incomplete Cost Allocation"
                    icon="k-i-check-outline"
                  />
                }
                initialHeight={430}
                initialWidth={950}
                style={{ zIndex: "1" }}
                minimizeButton={() => null}
                maximizeButton={() => null}
                onClose={handleClose}
                draggable={false}
                closeButton={
                  copyCADetails && copyCADetails.copyCAStatus === "I"
                    ? () => null
                    : ""
                }
              >
                <div style={{ marginTop: "-15px" }}>
                  {loadingCA ? <ProgressBar /> : ""}
                  <span style={{ marginLeft: 10 }}>
                    <Button
                      size="small"
                      onClick={handleCopyAlloc}
                      className="app-button"
                      title="Copy from Prev. Quarters"
                      iconClass="k-icon k-i-copy k-i-files"
                      disabled={
                        isCopyAlloc === true || allocDetails.length <= 0
                          ? true
                          : false
                      }
                    ></Button>
                    <Button
                      size="small"
                      onClick={reloadTable}
                      className="app-button"
                      title="Refresh"
                      iconClass="k-icon k-i-reload"
                    ></Button>
                    <Button
                      size="small"
                      onClick={handleAllocCancel}
                      className="app-button"
                      title="Cancel"
                      iconClass="k-icon k-i-close"
                    ></Button>
                    <span
                      title={
                        copyCSDetails.copyCsStatus === "S"
                          ? "Copied Successfully"
                          : "Check Copy Status"
                      }
                    >
                      <Button
                        className="app-button"
                        size="small"
                        title={
                          copyCADetails.copyCAStatus === "S"
                            ? "Copied Successfully"
                            : "Check Copy Status"
                        }
                        onClick={handleCheckCAStatus}
                        iconClass="k-icon k-i-eye"
                        style={{
                          color: copyCADetails.copyCAStatus === "S" && "green",
                        }}
                        disabled={
                          !isCopyAlloc ||
                          (missedParts.length <= 0 && !loadingCS) ||
                          copyCADetails.copyCAStatus === "S"
                        }
                      ></Button>
                    </span>
                  </span>
                  <span style={{ float: "right" }}>
                    <Button
                      size="small"
                      onClick={handleCostAlloc}
                      className="app-button"
                      title="Proceed"
                      iconClass="k-icon k-i-check-outline"
                      disabled={
                        (isCopyAlloc && allocCompleted) ||
                        (allocDetails.length <= 0 && !loadingCA)
                          ? false
                          : true
                      }
                    ></Button>
                  </span>
                </div>
                <div>
                  <div className="allocHeader">
                    <div className="dialogheaderContent">
                      Allocation Category
                    </div>
                    <div className="dialogheaderContent">GPN or Part</div>
                    {[...quarterLabels.current].map((item) => {
                      return (
                        <div className="dialogheaderContent">
                          {item + " % "}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="allocBody">
                  {allocDetails.length > 0 &&
                    [...allocDetails].map((item) => {
                      return (
                        <React.Fragment>
                          <div className="dialogContent">
                            <label>{item.allocation_category}</label>
                          </div>
                          <div className="dialogContent">
                            <label>{item.gpn_or_part}</label>
                          </div>
                          {quarterLabels.current &&
                            quarterLabels.current.map((data, i) => {
                              return (
                                <div className="dialogContent">
                                  <label>{item[`q${i + 1}_total_alloc`]}</label>
                                </div>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </div>
              </Window>
            </div>
          )}
        </Window>
        {checkStatusWindow && (
          <Dialog
            title={"Copy Status"}
            onClose={() => setCheckStatusWindow(false)}
            width={300}
          >
            <p
              style={{
                textAlign: "center",
              }}
            >
              {loadingCS === true
                ? copyCSDetails.copyCsStatus === "I"
                  ? "Copy in Progress."
                  : "Copied Successfully."
                : copyCADetails.copyCAMsg}
            </p>
            <DialogActionsBar>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => {
                  setCheckStatusWindow(false);
                  loadingCS && setLoadingCS(false);
                  setLoadingCA(false);
                }}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default SubmissionClosure;
