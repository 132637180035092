import React, { useEffect, useState, useContext } from "react";
import { Window } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import './oneTimeDialog.css'
import { put, post } from '../../../services/api.request';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { UserContext } from "../../../App";
import WindowTitle from '../../../components/WindowTitle/WindowTitle';
import { Label, Hint } from "@progress/kendo-react-labels";
import DatePicker from 'react-date-picker';
import { debounce } from 'lodash';
import privateRequest from '../../../services/api.config';
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { formatLongDateToString } from '../../../utils/formatDateUtil';

const OnTimeChargePopup = ({ toggleDialog, mode, selectedData, reload }) => {
    const [saveBtn, setSaveBtn] = useState(true);
    const [reference, setReference] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [itemDescription, setItemDescription] = React.useState("");
    const [chargeTypeVal, setChargeTypeVal] = React.useState("");
    const [expectedAmount, setExpectedAmount] = useState(null);
    const [payload, setPayload] = React.useState({});
    const [payload1, setPayload1] = React.useState({});
    const [payload2, setPayload2] = React.useState({});
    const [payload3, setPayload3] = React.useState({});
    const [expectedDate, setExpectedDate] = React.useState(new Date());
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoading1, setIsLoading1] = React.useState(false);
    const [isLoading2, setIsLoading2] = React.useState(false);
    const [isLoading3, setIsLoading3] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState({});
    const [filterValue1, setFilterValue1] = React.useState({});
    const [filterValue2, setFilterValue2] = React.useState({});
    const [filterValue3, setFilterValue3] = React.useState({});
    const [BuValue, setBuValue] = React.useState("");
    const userContext = useContext(UserContext);
    const user_name = userContext.user_name;
    const user_id = userContext.user_id;
    const chargeType = ['Credit Note', 'Debit Note']
    const BU_val = ["HDD", "Flash"]
    useEffect(() => {
        if (selectedData !== null) {
            setChargeTypeVal(selectedData.charge_type)
            setItemDescription(selectedData.item_description)
            setReference(selectedData.item_reference1)
            setBuValue(selectedData.bu)
            setFilterValue({ lookup_value: selectedData.commodity_code })
            setFilterValue1({ lookup_value: selectedData.supplier_common_name })
            setExpectedAmount(selectedData.expected_amount)
            setExpectedDate(selectedData.expected_date === null ? null : new Date(selectedData.expected_date))
            setFilterValue2({ lookup_value: selectedData.po_number })
            setFilterValue3({ lookup_value: selectedData.invoice_number })
        }
    }, [selectedData])
    useEffect(() => {
        if (mode === 'Add') {
            setChargeTypeVal('')
            setItemDescription('')
            setReference('')
            setFilterValue({ lookup_value: '' })
            setFilterValue1({ lookup_value: '' })
            setExpectedAmount('')
            setBuValue('')
            setExpectedDate(new Date())
        }
    }, [mode])
    const handleExpDateChange = (e) => {
        let value = e === null ? e : formatLongDateToString(e);
        setExpectedDate(value);
    };
    const handleFilterRequest = (data) => {
        setPayload(data.items);
        setIsLoading(false);
    }
    const handleFilterRequest1 = (data) => {
        setPayload1(data.items);
        setIsLoading1(false);
    }
    const handleFilterRequest2 = (data) => {
        setPayload2(data.items);
        setIsLoading2(false);
    }
    const handleFilterRequest3 = (data) => {
        setPayload3(data.items);
        setIsLoading3(false);
    }
    const PoNumberChange = debounce(({ filter }) => {
        setIsLoading2(true);
        let url = `/generic_lov_service/ponumber/?search_for=${filter.value}&lookup_type=ponumber`
        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest2(data)
            })
            .catch((err) => {
                setIsLoading2(false);
            });
    }, 1000)
    const InvoiceNumberChange = debounce(({ filter }) => {
        setIsLoading3(true)
        let url = `/generic_lov_service/invoicenumber/?search_for=${filter.value}&look_type=invoicenumber`
        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest3(data)
            })
            .catch((err) => {
                setIsLoading3(false);
            });
    }, 1000)
    const filterChange = debounce(({ filter }) => {
        setIsLoading(true)
        let url = `/generic_lov_service/gcmcommcodes/?search_for=${filter.value}&user_id=${user_id}`

        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest(data)
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, 1000)
    const supplierChange = debounce(({ filter }) => {
        setIsLoading1(true)
        let url = `/generic_lov_service/suppliers/?search_for=${filter.value}&user_id=${user_id}`
        return privateRequest
            .get(url)
            .then(({ data }) => {
                handleFilterRequest1(data)
            })
            .catch((err) => {
                setIsLoading1(false);
            });
    }, 1000)

    const onSave = () => {
        setLoading(true)
        setSaveBtn(false)
        let payload;
        if (mode === 'Add') {
            payload = {
                gcm: user_name,
                gcm_id: user_id,
                bu: BuValue,
                commodity_code: filterValue.lookup_value,
                supplier_common_name: filterValue1.lookup_value,
                item_reference1: reference,
                item_description: itemDescription,
                charge_type: chargeTypeVal,
                expected_amount: expectedAmount,
                expected_date: expectedDate,
                status: 'Open'
            }
            post(`/otc_post_put`, payload, ({ data }) => {
                if (data.hasOwnProperty("p_out_err_msg")) {
                    setLoading(false)
                    alert(data.p_out_err_msg);
                    reload();
                    toggleDialog();
                }

            })
        }
        if (mode === 'Edit') {

            payload = {
                otc_id: selectedData.otc_id,
                gcm: user_name,
                gcm_id: user_id,
                bu: BuValue,
                status: null,
                po_number: filterValue2.lookup_value,
                invoice_number: filterValue3.lookup_value,
                expected_amount: expectedAmount,

            }
            put(`/otc_post_put`, payload, ({ data }) => {
                if (data.hasOwnProperty("p_out_err_msg")) {
                    setLoading(false)
                    alert(data.p_out_err_msg);
                    reload();
                    toggleDialog();
                }

            })
        }
    }

    const onManualClose = () => {
        setLoading(true)
        let payload = {
            otc_id: selectedData.otc_id,
            ccid: selectedData.ccid,
            gcm: user_name,
            gcm_id: user_id,
            commodity_code: filterValue.lookup_value,
            supplier_common_name: filterValue1.lookup_value,
            item_reference1: reference,
            item_description: itemDescription,
            charge_type: chargeTypeVal,
            expected_amount: expectedAmount,
            expected_date: expectedDate,
            status: 'MC',
            bu: BuValue,
            po_number: filterValue2.lookup_value,
            invoice_number: filterValue3.lookup_value2
        }

        put(`/otc_post_put`, payload, ({ data }) => {
            if (data.hasOwnProperty("p_out_err_msg")) {
                setLoading(false)
                alert(data.p_out_err_msg);
                toggleDialog();
                reload();

            }

        })
    }
    const onCancel = () => {
        setLoading(true)
        let payload = {
            otc_id: selectedData.otc_id,
            ccid: selectedData.ccid,
            gcm: user_name,
            gcm_id: user_id,
            commodity_code: filterValue.lookup_value,
            supplier_common_name: filterValue1.lookup_value,
            item_reference1: reference,
            item_description: itemDescription,
            charge_type: chargeTypeVal,
            expected_amount: expectedAmount,
            expected_date: expectedDate,
            bu: BuValue,
            status: 'C',
            po_number: filterValue2.lookup_value,
            invoice_number: filterValue3.lookup_value2
        }
        put(`/otc_post_put`, payload, ({ data }) => {
            if (data.hasOwnProperty("p_out_err_msg")) {
                setLoading(false)
                alert(data.p_out_err_msg);
                toggleDialog();
                reload();
            }

        })
    }

    const handleChargeType = (e) => {
       setChargeTypeVal(e.value);
       setExpectedAmount(null);
    };

    const handleExpectedAmnt = (e) => {
        setExpectedAmount(e.value === -0 ? 0 : e.value);
    };

    return (
        <div className="window-container">
            <Window title={<WindowTitle title={mode + "  One Time Charge"} icon="k-i-star" />}
                minimizeButton={() => null}
                maximizeButton={() => null}
                onClose={toggleDialog}
                initialHeight={mode === 'Edit' ? 545 : 450}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={600} >
                    <div style={{marginTop: "-15px"}}>
                    {loading ? (
                        <ProgressBar />
                        ) : ""}
                        </div>
                     <div>
                    <div className="" style={{ display: 'flex', marginTop: "15px" }}>
                        {mode === 'Edit' ? <strong><span className='brand-title' style={{ marginLeft: '27px' }}>ID: {selectedData.otc_id}</span></strong> : ''}
                        {mode === 'Edit' ? <strong><span className='brand-title' style={{ marginLeft: '130px' }}>Status: {selectedData.status}</span></strong> : ''}
                        {mode === 'Edit' ? <strong><span className='brand-title' style={{ marginLeft: '85px', float: 'right' }}>GCM Name: {user_name}</span></strong> : ''}
                        {mode === 'Add' ? <strong><span className='brand-title gcmClass' style={{ float: 'left', marginRight: '18px' }}>GCM Name: {user_name}</span></strong> : ''}
                    </div>
                    <div style={{ marginTop: '15px' }} className='quarter_dialog_row marRight' >
                        <Label editorId={"commodity_code"} className='marginLeftBox' optional={false}>Commodity Code<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <DropDownList
                            style={{ width: '44%', marginRight: '110px' }}
                            data={Object.keys(payload).length > 0 ? payload : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={filterChange}
                            loading={isLoading}
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            required={true}
                            disabled={mode === 'Edit'} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"common_supplier_name"} className='marginLeftBox' optional={false}>Supplier Common Name<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <DropDownList
                            style={{ width: '44%', marginRight: '110px' }}
                            data={Object.keys(payload1).length > 0 ? payload1 : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={supplierChange}
                            loading={isLoading1}
                            value={filterValue1}
                            onChange={(e) => setFilterValue1(e.target.value)}
                            disabled={mode === 'Edit'} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"Reference1"} className='marginLeftBox'>Item Reference:</Label>
                        <Input style={{ width: '44%', marginRight: '110px' }}
                            id="reference1"
                            onChange={(e) => { setReference(e.target.value) }}
                            disabled={mode === 'Edit'}
                            name="reference1"
                            value={reference}
                            validationMessage={"This field is required"} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"BU"} className='marginLeftBox'>BU:</Label>
                        <DropDownList
                            data={BU_val}
                            name="BU"
                            id="BU"
                            value={BuValue}
                            //required={true}
                            // disabled={mode === 'Edit'}
                            onChange={(e) => setBuValue(e.target.value)}
                            style={{ width: '44%', marginRight: '110px' }} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"Item_Description"} className='marginLeftBox'> Item Description<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <Input
                            style={{ width: '44%', marginRight: '110px' }}
                            id="itemDescription"
                            onChange={(e) => { setItemDescription(e.target.value) }}
                            disabled={mode === 'Edit'}
                            name="itemDescription"
                            value={itemDescription}
                            required={true}
                            validationMessage={"This field is required"} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"Charge Type"} className='marginLeftBox'>Charge Type<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <DropDownList
                            data={chargeType}
                            name="chargeType"
                            id="chargeType"
                            value={chargeTypeVal}
                            required={true}
                            disabled={mode === 'Edit'}
                            onChange={handleChargeType}
                            style={{ width: '44%', marginRight: '110px' }} />
                    </div>
                    <div className='quarter_dialog_row marRight' >
                        <Label editorId={"Reference1"} className='marginLeftBox'>Expected Amount<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <NumericTextBox style={{ width: '44%', marginRight: '110px' }}
                            name="ExpectedAmount"
                            format={"n4"}
                            id="ExpectedAmount"
                            value={expectedAmount}
                            required={true}
                            min={chargeTypeVal === "Debit Note" ? 0 : Number.NEGATIVE_INFINITY}
                            onChange={handleExpectedAmnt}
                            validationMessage={"This field is required"} />
                    </div>
                    <div className='quarter_dialog_row marRight'>
                        <Label style={{ marginRight: '60px' }} className='marginLeftBox'> Expected Date<sup style={{ color: "#f31700" }}>*</sup>:</Label>
                        <DatePicker style={mode === 'Edit' ? { opacity: '0.1' } : ''}
                            className={mode === 'Edit' ? 'dateTimepicker2' : 'dateTimepicker1'}
                            name="submission_endDate"
                            minDate={new Date()}
                            format="MM/dd/y"
                            value={expectedDate === null ? "" : new Date(expectedDate)}
                            clearIcon={null}
                            onChange={handleExpDateChange}
                            required={true}
                            dayPlaceholder='dd'
                            monthPlaceholder='mm'
                            yearPlaceholder='yyyy'
                            disabled={mode === 'Edit'}
                            onKeyDown={(e) => {
                                e.preventDefault();
                             }}
                        /><Hint style={{ margin: 0 }}></Hint>
                    </div>
                    {mode === 'Edit' ? <div className='quarter_dialog_row marRight' >
                        <Label editorId={"po_code"} optional={false} className='marginLeftBox'> Purchase Order:</Label>
                        <DropDownList
                            style={{
                                width: '44%',
                                marginRight: '108px'
                            }}
                            data={Object.keys(payload2).length > 0 ? payload2 : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={PoNumberChange}
                            loading={isLoading2}
                            value={filterValue2}
                            onChange={(e) => setFilterValue2(e.target.value)}
                            disabled={chargeTypeVal === 'Credit Note' || selectedData.po_number != null}
                        />
                    </div> : ''}
                    {mode === 'Edit' ? <div className='quarter_dialog_row marRight'  >
                        <Label editorId={"invoice"} optional={false} className='marginLeftBox'> Invoice :</Label>
                        <DropDownList
                            style={{
                                width: '44%',
                                marginRight: '108px'
                            }}
                            data={Object.keys(payload3).length > 0 ? payload3 : []}
                            filterable={true}
                            textField="lookup_value"
                            dataItemKey="lookup_value"
                            onFilterChange={InvoiceNumberChange}
                            loading={isLoading3}
                            value={filterValue3}
                            onChange={(e) => setFilterValue3(e.target.value)}
                            disabled={chargeTypeVal === 'Debit Note' || selectedData.invoice_number != null}
                        />
                    </div> : ''}
                    <div>
                        <div style={{ display: 'flex', float: 'left' }}>
                            {mode === 'Edit' ? <Button className='save-button' title="Cancel"
                                onClick={onCancel}

                            ><span className="k-icon k-i-cancel-outline"></span></Button> : ''}

                            {mode === 'Edit' ? <Button className='save-button' title="Manually Close"
                                onClick={onManualClose}
                            ><span class="k-icon k-i-hand"></span></Button> : ''}
                        </div>
                        <Button onClick={onSave} icon="save" className='save-button app-button'
                            disabled={
                                filterValue.lookup_value === '' ||
                                    expectedDate === '' ||
                                    expectedAmount === null ||
                                    filterValue1.lookup_value === '' ||
                                    itemDescription === '' ||
                                    chargeTypeVal === '' || saveBtn ===false? true
                                    : false
                            }
                        >Save</Button>
                    </div>
                </div>
            </Window>
        </div>
    )
}
export default OnTimeChargePopup;
