import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { getRequest, put } from "../../../services/api.request";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { UserContext } from "../../../App";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import "../components/ShouldBeCostUploadWizard.css";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

const initialDataState = {
  skip: 0,
};

const inde = {
  fontSize: "max(16px, 1em)",
  fontFamily: "inherit",
  padding: " 0.25em 0.5em",
  backgroundColor: " #fff",
  border: "2px solid #f0f0f0",
  borderRadius: "4px",
  width: "150px",
  color: "#424242",
};

const QuaterCost = ({
  componentCode,
  setVisibleDialog,
  visibleDialog,
  setLoading,
}) => {
  const limit = 10;
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const [dataState, setDataState] = React.useState([]);
  const [sort, setSort] = useState([]);
  const [page, setPage] = React.useState(initialDataState);
  const [visible, setVisible] = React.useState(false);
  const [updateSBC, setupdateSBC] = React.useState();
  const [saveFlag, setSaveFlag] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [dis, setDis] = useState(true);

  const getMappedCommodityCodes = ({ data }, type) => {
    data.items.forEach((element) => {        
        element["should_cost"] = (element["should_cost"] === null) ? null : parseFloat((element["should_cost"]).replace(/,/g, ''));
      });
      setDataState(data.items);
    setDis(false);
    setLoading(false);
  };

  const fetchCommodityCodes = React.useCallback(
    () => {
      if (componentCode)
        getRequest(
          `should_be_cost_right/${currentQuarter}/${componentCode}`,
          getMappedCommodityCodes
        );
      else setDataState([]);
      setSort([]);
      setPage(initialDataState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [componentCode]
  );

  useEffect(() => {
    setLoading(true);
    fetchCommodityCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommodityCodes]);

  const handleSortChange = (e) => {
    if (e.sort && e.sort.length > 0) {
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString =
        '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
      getRequest(
        `should_be_cost_right/${currentQuarter}/${componentCode}?q=` +
          encodeURIComponent(queryString),
        getMappedCommodityCodes
      );
      setSort(e.sort);
      setPage(initialDataState);
    } else {
      let dir = "asc";
      if (sort[0].dir === "asc") {
        dir = "desc";
      }
      const queryString =
        '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
      getRequest(
        `should_be_cost_right/${currentQuarter}/${componentCode}?q=` +
          encodeURIComponent(queryString),
        getMappedCommodityCodes
      );
      setSort([{ field: sort[0].field, dir: dir }]);
      setPage(initialDataState);
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
    setVisibleDialog(false);
  };

  const enterEdit = (item) => {
    setVisibleDialog(!visibleDialog);
    setupdateSBC({
      ...updateSBC,
      user_id: user_id,
      part_id: componentCode,
      cost_lines: dataState,
    });
  };

  const handleChange = (e, i) => {
    const { value } = e.target;
    const list = [...dataState];
    if (value > 0 && value <= 999999999) {
      list[i]["should_cost"] = value;
    } else {
      list[i]["should_cost"] = null;
    }
    setDataState(list);
  };

  const handleQuarterSate = (e, i) => {
    const { value } = e.target;
    const list = [...dataState];
    list[i]["qtr_state"] = value;
    setDataState(list);
  };

  const updateShouldBeCost = () => {
    alert("Should Cost updated successfully.");
    getRequest(
      `should_be_cost_right/${currentQuarter}/${componentCode}`,
      getMappedCommodityCodes
    );
    setSaveFlag(false);
    setVisibleDialog(false);
    setEditLoading(false);
  };

  const saveShouldBeCost = () => {
    setEditLoading(true);
    setSaveFlag(true);
    put("/should_be_cost_manual", updateSBC, updateShouldBeCost);
  };

  return (
    <div>
      <div style={{ marginLeft: -2 }}>
        <Button
          className="app-button"
          size="small"
          onClick={enterEdit}
          title="Edit"
          disabled={dis}
        >
          <span className="k-icon k-i-edit"></span>
        </Button>
      </div>
      <Grid
        sortable={true}
        pageable={true}
        skip={page.skip}
        pageSize={limit}
        sort={sort}
        data={dataState}
        rowHeight={window.innerHeight * 0.065}
        onSortChange={(e) => {
          handleSortChange(e);
        }}
      >
        <GridColumn
          headerClassName="headerClass"
          title="Quarter"
          field="quarter"
        />
        <GridColumn
          headerClassName="headerClass"
          title="Should Cost ($)"
          field="should_cost"
          className="numberFields"
        />
        <GridColumn
          headerClassName="headerClass"
          title="Quarter State"
          field="qtr_state"
        />
      </Grid>
      {visibleDialog && (
        <div className="window-shouldCostUpload">
          <Window
            minimizeButton={() => null}
            maximizeButton={() => null}
            title={
              <WindowTitle
                title="Update Should-be Cost For Upcomming Quarters"
                icon="k-i-calendar"
              />
            }
            onClose={toggleDialog}
            initialHeight={510}
            initialWidth={710}
            style={{ zIndex: "1" }}
          >
            <div style={{ marginTop: -15 }}>
              {editLoading ? <ProgressBar /> : ""}
            </div>
            <div class="container">
              <div className="input-first-cell">
                <Input className="input-header-cell" value={"Quarter"} />
              </div>
              <div className="input-second-cell">
                <Input className="input-header-cell" value={"Should Cost"} />
              </div>
              <div className="input-third-cell">
                <Input className="input-header-cell" value={"Quarter State"} />
              </div>
              {dataState.map((element, i) => {
                return (
                  <React.Fragment>
                    <div className="input-first-cell">
                      <Input value={element.quarter} disabled={true} />{" "}
                    </div>
                    <div className="input-second-cell">
                      <NumericTextBox
                        format="n4"
                        min={0}
                        value={element.should_cost}
                        max={999999999}
                        onChange={(e) => handleChange(e, i)}
                      />
                    </div>
                    <div className="input-third-cell">
                      <select
                        style={inde}
                        id="state"
                        onChange={(e) => handleQuarterSate(e, i)}
                        value={element.qtr_state}
                      >
                        <option value=""></option>
                        <option value="MP">MP</option>
                        <option value="NPI">NPI</option>
                      </select>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <br></br>
            <Button
              onClick={saveShouldBeCost}
              icon="save"
              className="save-button"
              style={{ float: "right" }}
              disabled={saveFlag === true ?? false}
            >
              Save
            </Button>
          </Window>
        </div>
      )}
    </div>
  );
};

export default QuaterCost;
