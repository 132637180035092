import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { UserContext } from "../../App";
import "./CommodityCodeScreen.css";
import { Splitter } from "@progress/kendo-react-layout";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { getRequest } from "./../../services/api.request";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CommodityView from "./components/commodityView";
import { orderBy } from "@progress/kendo-data-query";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { formatDates } from "../../utils/formatDateUtil";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";

const CommodityCodeScreen = () => {
  const FILTER_PARAMS = [
    {
      paramName: "commoditycode",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
    },
    {
      paramName: "commoditygroup",
      displayValue: "Commodity Group",
      id_field: "commodity_group",
      api_field: "commodity_group",
    },
    {
      paramName: "sub_closure_status",
      displayValue: "Submission Status",
      id_field: "current_qtr_submission_status",
      api_field: "current_qtr_submission_status",
    },
  ];

  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const userContext = useContext(UserContext);
  const currentQuarter = userContext.curr_qrtr;
  const gridWidth = window.innerWidth * 0.825;
  const limit = 10;
  const DATA_ITEM_KEY = "ccid";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(initialDataState);
  const [componentCode, setcomponentCode] = useState({ id: undefined });
  const [sort, setSort] = useState([]);
  const [dataChg, setDataChg] = useState(false);
  const [dataState, setDataState] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [show, setShow] = React.useState(false);
  const anchor = useRef(null);
  const [panes, setPanes] = React.useState([
    { size: "60%", min: "49%", collapsible: true },
    { min: "40%", collapsible: true },
  ]);
  const [filterValue, setFilterValue] = useState({});
  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };

  const onChange = (event) => {
    setPanes(event.newState);
  };

  const handleSortChange = (e) => {
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        setLoading(true);
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest(
          "/comm_code_vw_left?q=" +
            encodeURIComponent(queryString) +
            "&current_qtr=" +
            currentQuarter +
            "&offset=" +
            page.skip +
            "&limit=" +
            limit,
          getGCV
        );
        setSort(e.sort);
      }
       else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        setLoading(true);
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          "/comm_code_vw_left?q=" +
            encodeURIComponent(queryString) +
            "&current_qtr=" +
            currentQuarter +
            "&offset=" +
            page.skip +
            "&limit=" +
            limit,
          getGCV
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    }
    else if(dataChg === true){
     let url ;
     FILTER_PARAMS.forEach((param) => {
       if (filterValue.hasOwnProperty(param.id_field)) {
          url = `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`;
       }
     });
     if (e.sort && e.sort.length > 0) {
      setLoading(true);
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString = '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
      getRequest( "/comm_code_vw_left?q=" +  encodeURIComponent(queryString) +  "&current_qtr=" +  currentQuarter + url +
       "&offset=" + page.skip +"&limit=" +  limit, getGCV
      );
      setSort(e.sort);
    } 
    else {
      let dir = "asc";
      if (sort[0].dir === "asc") {
        dir = "desc";
      }
      setLoading(true);
      const queryString =
        '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
      getRequest( "/comm_code_vw_left?q=" + encodeURIComponent(queryString) + "&current_qtr=" + currentQuarter + url +
          "&offset=" +  page.skip + "&limit=" +  limit, getGCV
      );
      setSort([{ field: sort[0].field, dir: dir }]);
    }
    } 
    else {
      setSort(e.sort);
    }
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    setSubmit(false);
    setShow(false);
  };

  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (submit === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? "comm_code_vw_left?offset=" +
            e.page.skip +
            "&limit=" +
            limit +
            "&current_qtr=" +
            currentQuarter
          : "comm_code_vw_left?offset=" +
            e.page.skip +
            "&limit=" +
            limit +
            "&current_qtr=" +
            currentQuarter +
            "&q=" +
            encodeURIComponent(queryString);
      getRequest(urlWithoutFilter, getGCV);
    }
    if (submit === true) {
      let urlWithFilter;
      let url = `/comm_code_vw_left?&current_qtr=${currentQuarter}`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (url.includes("?")) {
            url += `&${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          } else {
            url += `?${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          }
        }
      });
      urlWithFilter =
        sort.length !== 0 ? url + "&q=" + encodeURIComponent(queryString) : url;
      if (url.includes("?")) {
        url = urlWithFilter + `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url = urlWithFilter + `?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, getGCV);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const rowClickApi = (e) => {
    if (e.dataItem.ccid) {
      setcomponentCode({ id: e.dataItem.ccid });
      setLoading(true);
    }
  };

  const getGCV = ({ data }, type) => {
    setDataState(
      data.items.map((dataItem) =>
        Object.assign(
          {},
          formatDates(["start_date_active", "end_date_active"], dataItem)
        )
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getRequest(
      `comm_code_vw_left?offset=${page.skip}&limit=${limit}&current_qtr=${currentQuarter}`,
      getGCV
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadGrid = () => {
    setLoading(true);
    setPage({ skip: 0 });
    getRequest(
      `comm_code_vw_left?offset=0&limit=${limit}&current_qtr=${currentQuarter}`,
      getGCV
    );
    setcomponentCode({ ...componentCode, id: undefined });
    setSubmit(false);
    setSort([]);
    setSelectedState({});
    setDataChg(false);
  };

  const cellWithBackGround = (props) => {
    let iconAttention = props.dataItem.needs_attention;
    const icon =
      iconAttention === "Y" ? <span class="k-icon k-i-exception"></span> : "";
    const style = { color: iconAttention ? "red" : "" };
    return <td style={style}>{icon}</td>;
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onFilterSubmit = () => {
    setPage({ skip: 0 });
    setLoading(true);
    let url = `/comm_code_vw_left?&current_qtr=${currentQuarter}`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        if (url.includes("?")) {
          url += `&${param.api_field}=${
            filterValue[param.id_field][param.id_field]
          }`;
        } else {
          url += `?${param.api_field}=${
            filterValue[param.id_field][param.id_field]
          }`;
        }
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getGCV);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
    setDataChg(true);
    setcomponentCode({ ...componentCode, id: undefined });
  };

  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <Splitter panes={panes} onChange={onChange} className="splitBody">
        <div className="pane-content">
          <span>
            <Button
              size="small"
              onClick={reloadGrid}
              className="app-button"
              title="Refresh"
            >
              <span className="k-icon k-i-reload"></span>
            </Button>

            <Button
              size="small"
              className="app-button"
              onClick={openPopover}
              ref={anchor}
              title="Filter By"
            >
              <span
                className={
                  submit === true
                    ? "k-icon k-i-filter-clear"
                    : "k-icon k-i-filter"
                }
              ></span>
            </Button>

            {show && (
              <FilterPopUp
                params={FILTER_PARAMS}
                handleClose={onCancelClick}
                show={show}
                elementRef={anchor.current && anchor.current.element}
                handleFilterSubmit={onFilterSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
              />
            )}
          </span>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              sort={sort}
              onSortChange={(e) => {
                handleSortChange(e);
              }}
              total={dataState.length > 0 ? dataState[0]?.resultset_size : 0}
              onPageChange={(e) => {
                handlePageChange(e);
              }}
              data={orderBy(
                dataState.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })),
                sort
              )}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
              }}
              onSelectionChange={onSelectionChange}
              onRowClick={rowClickApi}
              rowHeight={window.innerHeight * 0.065}
            >
              <GridColumn
                headerClassName="headerClass"
                field="needs_attention"
                title="!"
                cell={cellWithBackGround}
                width={setPercentage(5)}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Commodity Code"
                field="commodity_code"
                width={setPercentage(20)}
                cell={(e) => GridCellToolTip(e, "commodity_code")}
              />
              <GridColumn
                headerClassName="headerClass"
                title="Commodity Group"
                field="commodity_group"
                width={setPercentage(20)}
                cell={(e) => GridCellToolTip(e, "commodity_group")}
              />
              <GridColumn
                headerClassName="headerClass"
                className="cell-Allign"
                title="Submission Status"
                field="current_qtr_submission_status"
                width={setPercentage(15)}
                cell={(e) =>
                  GridCellToolTip(e, "current_qtr_submission_status")
                }
              />
              <GridColumn
                headerClassName="headerClass"
                title="Submission Date"
                field="submission_date"
                width={setPercentage(15)}
                cell={(e) => GridCellToolTip(e, "submission_date")}
              />
            </Grid>
          </Tooltip>
        </div>
        <div className="pane-content">
          <CommodityView
            componentCode={componentCode.id}
            setLoading={setLoading}
          />
        </div>
      </Splitter>
    </div>
  );
};
export default CommodityCodeScreen;
