import React, { useEffect, useState } from 'react';
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { Splitter } from "@progress/kendo-react-layout";

import { getter } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import './shouldBeCost.css';
import { getRequest} from '../../services/api.request';
import QuaterCost from './quaterCost/quaterCost';
import { orderBy } from "@progress/kendo-data-query";
import ShouldBeCostUploadWizard from './components/ShouldBeCostUploadWizard';
import UploadFileStatus from './components/UploadFileStatus';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import FilterPopUp from '../../components/FilterPopUp/FilterPopUp';
import GridCellToolTip from '../../components/GridCellToolTip/GridCellToolTip';
import ShouldCostTemplate from '../../assets/templates/ShouldCostTemplate.xlsx'

export const DialogContext = React.createContext(() => {/** noop */});
const columnDef = [
  {
    field: 'commodity_group',
    title: 'Commodity Group'
  },
  {
    field: 'commodity_code',
    title: 'Commodity Code'

  },
  {
    field: 'part',
    title: 'Part Numbers'
  },
  {
    field: 'part_desc',
    title: 'Description'
  },


];


const initialDataState = {
  skip: 0,
  take: 10
};
const ShouldBeCost = () => {
  const FILTER_PARAMS = [
    {
      paramName:"parts",
      displayValue:"Part Number",
      id_field:"part",
      api_field:"part"
    },
    {
      paramName:"commoditycode",
      displayValue:"Commodity Code",
      id_field:"commodity_code",
      api_field:"commodity_code"
    },
    {
      paramName:"commoditygroup",
      displayValue:"Commodity Group",
      id_field:"commodity_group",
      api_field:"commodity_group"
    }
];
  const limit = 10;
  const [wizardVisible, setWizardVisible]= useState(false);
  //const userContext = useContext(UserContext);
  // const user_id = userContext.user_id;
  // const currentQuarter = userContext.curr_qrtr;
  const [loading, setLoading] = useState(false);
  //const [uploadDetails,setUploadDetails]= React.useState(false);
  //const [uploadDetailsParams, setUploadDetailsParams] = React.useState();
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [submit, setSubmit] = useState(false);
  const [ filterValue, setFilterValue ] = useState({});

  // const showUploadDetails=(value)=>{
  //   setUploadDetailsParams(value);
  //   setUploadDetails(true);
    
  // }
  const [dataState, setDataState] = React.useState();
  const [componentCode, setcomponentCode] = React.useState({ id: undefined });
  const anchor = React.useRef(null);
  const filterAnchor = React.useRef(null);
  const [sort, setSort] = useState([]);
  // const _exporter = React.createRef();

  // const exportExcel = () => {
  //   // if (_exporter.current) {
  //   //   _exporter.current.save();
  //   // }
  // };
  //const [count, setCount] = useState();
  //const [query, setquery] = useState();
  const [dataChg, setDataChg] = useState(false);
  const DATA_ITEM_KEY = "part";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [uploadStatusPopup, setUploadStatusPopup] = React.useState(false);
  const [page, setPage] = React.useState(initialDataState);
 // const [dataIdState, setDataIdState] = React.useState(); 
  const [panes, setPanes] = React.useState([
    { size: "60%", min: "49%", collapsible: true },
    { min: "40%", collapsible: true }
  ]);
  const toggleUploadStatusPopup = ()=>{
    setShow(false)
    setUploadStatusPopup(!uploadStatusPopup)
  };
  const onChange = (event) => {
    setPanes(event.newState);
  };
  const toggleWizard=()=>{
    setShow(false)
      setWizardVisible(!wizardVisible);
  }
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
 
  // const getGCV1 = ({ data }, type) => {
  //       setDataIdState(
  //           data.items
  //       );
  //       setLoading(false);
  //   }

  const getSBC = ({ data }, type) => {
    setDataState(data.items)
    //setCount(data.count);
    setLoading(false);
  }

  const reloadGrid = () => {
    setLoading(true);
    setPage({skip:0})
    getRequest('/should_be_cost_left?offset=0&limit=' + limit, getSBC);
    setSort([]);
    setSelectedState({});
    setDataChg(false);
    setSubmit(false);
    setcomponentCode({ ...componentCode, id: undefined });
  }
  useEffect(() => {
    setLoading(true);
    getRequest('/should_be_cost_left?offset='+page.skip+'&limit='+limit, getSBC);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelClick = event => {
    event.preventDefault();
    setSubmit(false);
    setShow(false);
    // reloadGrid();
    //etLoading(true)
  };

  const handleSortChange = (e) => {
    if (dataChg === false) {
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString1 = '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest('/should_be_cost_left?offset=' + page.skip + "&limit=" + limit + '&q=' + encodeURIComponent(queryString1), getSBC);
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === 'asc') {
          dir = "desc";
        }
        const queryString1 = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest('/should_be_cost_left?offset=' + e.page.skip + "&limit=" + limit + '&q=' + encodeURIComponent(queryString1), getSBC);
        setSort([{ "field": sort[0].field, "dir": dir }]);
      }
    }
    else if(dataChg === true){
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
        }
      })
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString1 = '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest('/should_be_cost_left?offset=' + page.skip + "&limit=" + limit + '&q=' + url + encodeURIComponent(queryString1), getSBC);
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === 'asc') {
          dir = "desc";
        }
        const queryString1 = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest('/should_be_cost_left?offset=' + e.page.skip + "&limit=" + limit + '&q=' + url +encodeURIComponent(queryString1), getSBC);
        setSort([{ "field": sort[0].field, "dir": dir }]);
      }
    }
    else {
      setSort(e.sort);
       }
  }

  const openPopover = (event) => {
    setShow(!show);

  };

  const handlePageChange = (e) => {
    setLoading(true);
      //submit true filter value
    if(submit === false){
      let c = 'should_be_cost_left?offset=' + e.page.skip + "&limit=" + limit;
      getRequest(c, getSBC);
    }
    //submit false filter value
    if (submit === true){
      setLoading(true);
      let url = '/should_be_cost_left'
      FILTER_PARAMS.forEach((param)=>{
          if(filterValue.hasOwnProperty(param.id_field)){
              if(url.includes("?")){
                  url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
              }else{
                  url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
              }
          }
      })
      if(url.includes('?')){
          url += `&offset=${e.page.skip}&limit=${limit}`
      }else{
          url += `?offset=${e.page.skip}&limit=${limit}`
      }
      getRequest(url, getSBC);
    }
    setTimeout(() => {
      setPage(e.page);

    }, 100);

  };

//   const handleChange = (e, i) => {
//     const { should_be_cost, value } = e.target;
//     const list = [...dataIdState];
//     list[i]['should_be_cost'] = value;
//     setDataIdState(list);
// }
// const handleQuarterSate = (e, i) => {
//     const { quarter_state, value } = e.target;
//     const list = [...dataIdState];
//     list[i]['quarter_state'] = value;
//     setDataIdState(list);
// }

  const handleRowClick = (e) => {
    if (e.dataItem.part_id) {
      setcomponentCode({ id: e.dataItem.part_id})
    }
  };

  const onFilterSubmit = () => {
    setPage({skip:0})
    setLoading(true);
    let url = '/should_be_cost_left'
    FILTER_PARAMS.forEach((param)=>{
        if(filterValue.hasOwnProperty(param.id_field)){
            if(url.includes("?")){
                url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
            }else{
                url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
            }
        }
    })
    if(url.includes('?')){
        url += `&offset=0&limit=${limit}`
    }else{
        url += `?offset=0&limit=${limit}`
    }
    getRequest(url, getSBC);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false)
    setShow(false);
    setDataChg(true);
    setcomponentCode({ id: undefined})
  };

  return (
    <div>
      { loading ? (
                <ProgressBar />
            ) : "" } 
               <Splitter panes={panes} onChange={onChange} className="splitBody">
      {/* <div  style={{ display: 'flex', flexWrap: 'wrap'}}> */}

      <div className="pane-content">

          <div className='buttonContainer'>
            <Button className="app-button" size="small" title="Refresh" onClick={reloadGrid} style={{ marginLeft: 5 }} >
              <span  className="k-icon k-i-reload"></span>
            </Button>
            <Button className="app-button" size="small" title="Filter By" onClick={openPopover} ref={filterAnchor}  >
              <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
            </Button>
            <Button className="app-button" size="small" title="Download" style={{ marginLeft: 5 }} >
              <a
                style={{ color: '#424242', textDecoration: 'none' }}
                href={ShouldCostTemplate}
                download="CMATXWD_SC_UPLOAD_TEMPLATE.xlsx"
              >
                <span className="k-icon k-i-download"></span>
              </a>
            </Button>
            <Button className="app-button" size="small" title="Upload" onClick={toggleWizard} ref={anchor} style={{ marginLeft: 5 }} >
              <span className="k-icon k-i-upload"></span>
            </Button>
            <Button className="app-button" size="small" title="View Upload Status" onClick={toggleUploadStatusPopup} ref={anchor} style={{ marginLeft: 5 }} >
              <strong>  <span className="k-icon k-i-eye"></span>
              </strong>
            </Button>
            
          </div>
          {show && <FilterPopUp 
              params={FILTER_PARAMS}
              handleClose={onCancelClick} 
              show={show} 
              elementRef={filterAnchor.current && filterAnchor.current.element} 
              handleFilterSubmit={onFilterSubmit}
              filterValue = { filterValue }
              setFilterValue = { setFilterValue }
              />} 
          {/* <DialogContext.Provider value={showUploadDetails}> */}
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
            sortable={true}
            pageable={true}
            skip={page.skip}
            pageSize={limit}
            sort={sort}
            onDataStateChange={(e) => {
              setSort(e.sort);
            }}
            data={orderBy(dataState?.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] })),sort)}
            dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
            selectable={{ enabled: visibleDialog !== true ? true : false, drag: false, cell: false, mode: "single" }}
            onSelectionChange={onSelectionChange}
            total={dataState?dataState.length>0 ? dataState[0]?.resultset_size : 0:0}
            onRowClick={(e) => { handleRowClick(e) }}
            onSortChange={(e) => { handleSortChange(e) }}
            onPageChange={(e) => { handlePageChange(e) }}
            rowHeight={window.innerHeight * 0.065}>
            {columnDef.map(colDef =>
              <GridColumn headerClassName="headerClass" key={colDef.field} field={colDef.field} title={colDef.title} width={colDef.width ?? undefined} 
              cell={(e) => GridCellToolTip(e, colDef.field)}/>
            )}
          </Grid>
          </Tooltip>
          {wizardVisible && <ShouldBeCostUploadWizard toggleWizard={toggleWizard}/>}
      
      {uploadStatusPopup && <UploadFileStatus from='SHOULD_COST' toggleWizard={toggleUploadStatusPopup}/>}
      {/* {uploadDetails && <UploadDetails toggleWizard={(e)=> setUploadDetails(!uploadDetails)} params={uploadDetailsParams}/>} */}
          {/* </DialogContext.Provider> */}
        </div>
        <div className="pane-content">
          <QuaterCost componentCode={componentCode.id} setLoading={setLoading} setVisibleDialog={setVisibleDialog}
          visibleDialog={visibleDialog} />
        </div>
      {/* </div> */}
      

      {/* <ExcelExport
        data={[]}
        collapsible={true}
        fileName="Should Cost Template.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="wdPartNo" title="Part Number" width={200} />
        <ExcelExportColumn field="quarter" title="Quarter(1-4) QFY(YY)" />
        <ExcelExportColumn field="shouldBeCost" title="Should Cost"/>
        <ExcelExportColumn field="quarterState" title="Quarter State"/>
      </ExcelExport> */}
      </Splitter>
    </div>

  );
};

export default ShouldBeCost;