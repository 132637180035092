import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { Splitter } from '@progress/kendo-react-layout';
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { getRequest } from '../../services/api.request'
import { formatDates } from '../../utils/formatDateUtil';
import { getter } from "@progress/kendo-react-common";
import { Button } from '@progress/kendo-react-buttons';
import PartNoRightSide from './components/PartNoRightSide';
import FilterPopUp from '../../components/FilterPopUp/FilterPopUp';
import GroupPartDialog from './components/GroupPartDialog';
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";

const initialDataState = {
  skip: 0,
  take: 10,
};

export default function PartNumTOGrp() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataChg, setDataChg] = useState(false);
  const userContext = useContext(UserContext);
  const [filterURL, setFilterURL] = useState();
  const [rightGridDataLength, setRightGridDataLength] = useState(null)
  const user_id = userContext.user_id;
  const gcm_append_yn = userContext.gcm_append_yn;
  const [panes, setPanes] = React.useState([
    { size: "40%", min: "30%", collapsible: true },
    { min: "60%", collapsible: true }
  ]);

  const FILTER_PARAMS = [
    {
      paramName: "gpn",
      displayValue: "GPN number",
      id_field: "gpn_id",
      api_field: "gpn_number"
    },
    {
      paramName: `gcmcommcodes`,
      displayValue: "Commodity Code",
      id_field: `commodity_code`,
      api_field: `commodity_code`,
    }
  ];
  const DATA_ITEM_KEY = "gpn_number";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = React.useState({});
  const [selectedData, setSelectedData] = React.useState(null);
  const [page, setPage] = React.useState(initialDataState);
  const [pageSizes, setPageSizes] = useState([10, 25, 50]);
  const [sort, setSort] = useState([]);
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [submit, setSubmit] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState("Add");
  const limit = 10;
  const handleAPIData = ({ data }) => {
    setData(data.items.map(dataItem => Object.assign({}, formatDates(["start_date_active", "end_date_active"], dataItem))));
    if (data.items.length !== 0) {
      if (data.items[0].resultset_size < 50) {
        setPageSizes([10, 25]);
      }
      else if (data.items[0].resultset_size < 25) {
        setPageSizes([10]);
      }
    }
    setLoading(false)
  }

  const onLoad = () => {
    setLoading(true)
    getRequest(`/gpn_left_view/${user_id}?offset=0&limit=${limit}`, handleAPIData)
  }

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPaneChange = (event) => {
    setPanes(event.newState);
  };

  const onSelectionChange = (event) => {
    setRightGridDataLength(null)
    setSelectedData(event.dataItems[event.startRowIndex])
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const handleSortChange = (e) => {

    if (dataChg != true) {
      setLoading(true)
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString = '{"$orderby": {"' + field + '":"' + sortingOrder + '"}}';
        getRequest(`/gpn_left_view/${user_id}?q=${encodeURIComponent(queryString)}&offset=${page.skip}&limit=${page.take}`, handleAPIData);
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === 'asc') {
          dir = "desc";
        }
        const queryString = '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(`/gpn_left_view/${user_id}?q=${encodeURIComponent(queryString)}&offset=${page.skip}&limit=${page.take}`, handleAPIData);
        setSort([{ "field": sort[0].field, "dir": dir }]);
      }
    }
  };

  const handlePageChange = (e) => {
    setLoading(true);

    //submit true filter value
    if (submit === false) {
      let c = `/gpn_left_view/${user_id}?offset=` + e.page.skip + "&limit=" + e.page.take;
      getRequest(c, handleAPIData);
    }

    //submit false filter value
    if (submit === true) {
      let url = ""
      if (Object.keys(filterValue).length === 0) {
        url += `/gpn_left_view/${user_id}`
      } else {
        url += `/gpn_left_view/${user_id}?`
        FILTER_PARAMS.forEach((param) => {
          if (filterValue.hasOwnProperty(param.id_field)) {
            url += `${param.api_field}=${filterValue[param.id_field]["lookup_value"]
              }`
          }
        })
      }
      if (url.includes('?')) {
        url += `&offset=${e.page.skip}&limit=${e.page.take}`
      } else {
        url += `?offset=${e.page.skip}&limit=${e.page.take}`
      }
      getRequest(url, handleAPIData);
    }

    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const reloadGrid = () => {
    onLoad();
    setPage({ skip: 0, take: page.take })
    setSelectedState({});
    setSelectedData(null)
    setSort([]);
    setDataChg(false);
    setSubmit(false);
  }

  const reloadGridWithPageSave = () => {
    if (dataChg == true) {
      getRequest(filterURL + `&offset=${page.skip}&limit=${page.take} `, handleAPIData);
      setSubmit(true);
    }
    else {
      getRequest(`/gpn_left_view/${user_id}?offset=${page.skip}&limit=${page.take}`, handleAPIData)
      setSubmit(false);
      setDataChg(false);
    }
    setSelectedState({});
    setSelectedData(null)
    setSort([]);
  }


  const openPopover = (event) => {
    setShow(!show);
  };

  const onFilterSubmit = () => {
    setPage({ skip: 0, take: page.take })
    setLoading(true);
    let url = ""
    if (Object.keys(filterValue).length === 0) {
      url += `/gpn_left_view/${user_id}`
    } else {
      url += `/gpn_left_view/${user_id}`
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (url.includes("?")) {
            url += `&${param.api_field}=${filterValue[param.id_field]["lookup_value"]
              }`;
          } else {
            url += `?${param.api_field}=${filterValue[param.id_field]["lookup_value"]
              }`;
          }
        }
      })
    }

    if (url.includes('?')) {
      url += `&offset=0&limit=${page.take}`
    } else {
      url += `?offset=0&limit=${page.take}`
    }
    setFilterURL(url);
    getRequest(url, handleAPIData);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false)
    setShow(false);
    setDataChg(true)
    setSelectedData(null)
  };

  const toggleDialog = (mode = null) => {
    setShow(false)
    if (mode != null) {
      setMode(mode)
    }
    setShowDialog(!showDialog);
  }

  const rightGridTotal = (rightGridTotal) => {
    setRightGridDataLength(rightGridTotal)
  }

  return (
    <div>
      {loading ? (
        <ProgressBar />
      ) : ""}
      <Splitter panes={panes} onChange={onPaneChange} className="splitBody">
        <div className='pane-content'>
          <Button size="small" className='app-button' onClick={reloadGrid} title="Refresh">
            <span className="k-icon k-i-reload"></span>
          </Button>
          <Button size="small" onClick={openPopover} title="Filter By" className="app-button" ref={anchor} >
            <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
          </Button>
          <Button size="small" className="app-button" onClick={() => toggleDialog("Add")} title="Add" disabled={gcm_append_yn === "N" ? true : false}>
            <span className="k-icon k-i-plus"  ></span>
          </Button>
          <Button size="small" className="app-button" onClick={() => toggleDialog("Edit")} title="Edit" disabled={(selectedData === null || gcm_append_yn !== "Y" || selectedData.end_date_active || !rightGridDataLength)}>
            <span className="k-icon k-i-edit"></span>
          </Button>
          {show && <FilterPopUp
            params={FILTER_PARAMS}
            handleClose={openPopover}
            show={show}
            elementRef={anchor.current && anchor.current.element}
            handleFilterSubmit={onFilterSubmit}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            userId={user_id}
          />}
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              pageable={{
                buttonCount: 4,
                pageSizes: data.length !== 0?pageSizes: ""
              }}
              skip={page.skip}
              pageSize={page.take}
              data={data.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              onSelectionChange={onSelectionChange}
              total={data ? data.length > 0 ? data[0].resultset_size : 0 : 0}
              onPageChange={(e) => { handlePageChange(e) }}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
              }}
              sortable={true}
              sort={sort}
              onSortChange={(e) => { handleSortChange(e) }}
              rowHeight={window.innerHeight * 0.07}
            >
              <GridColumn headerClassName="headerClass" field="gpn_number" title="Group Part Number" width={224} cell={(e) => GridCellToolTip(e, 'gpn_number')} />
              <GridColumn headerClassName="headerClass" field="start_date_active" title="Start Date Active" width={125}/>
              <GridColumn headerClassName="headerClass" field="end_date_active" title="End Date Active" width={125} />
            </Grid>
          </Tooltip>
        </div>
        <div className='pane-content'>
          <PartNoRightSide selectedData={selectedData} setLoading={setLoading} rightGridTotal={rightGridTotal} loading={loading} />
        </div>
      </Splitter>
      {
        showDialog && <GroupPartDialog
          mode={mode}
          selectedData={selectedData}
          toggleDialog={toggleDialog}
          reloadGrid={reloadGridWithPageSave}
          dataLimit={rightGridDataLength}
          />
      }
    </div>
  )
}