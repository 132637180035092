import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../App";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../components/WindowTitle/WindowTitle";
import { getRequest, put } from "../../services/api.request";

const limit = 10;
const initialDataState = {
    skip: 0
};
const ExcudePriorQuarterParts = ({addToggleDialog,ccId,hmid,reloadGrid,setGetHeirachyName}) =>{
    const userContext = useContext(UserContext);
    const user_id = userContext.user_id;
    const curr_qrtr = userContext.curr_qrtr;
    const [loading, setLoading] = React.useState(false);
    const [dataState, setDataState] = React.useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [response, setResponse] = React.useState()
    const onClickComplete = () =>{  
        setLoading(true);       
        put(`hierarchy_update_exclude_status?p_user_id=${user_id}&p_current_quarter=${curr_qrtr}&p_hmid=${hmid}`
        ,{"ccid":ccId} , ({data})=>{
            setLoading(false);
            if(data.p_out_status === "Success"){
                setResponse('Hierarchy Created Successfully.')
                setAlertDialog(true);
                addToggleDialog();
                reloadGrid();
            }
            else{
                setAlertDialog(true);
                setResponse(data.p_out_err_msg);
                addToggleDialog();
                reloadGrid();
            }
        });
    }
    const getExcludedParts = ({ data }) => {
        setDataState(data.items);
        setLoading(false);
    }
    useEffect(() => {
        setLoading(true);
       getRequest(`/hierarchy_cs_exclude_vw?user_id=${user_id}&ccid=${ccId}&current_quarter=${curr_qrtr}&offset=0&limit=${limit}`, getExcludedParts);
    }, []);
    const onPageChange = (e) =>{
        setLoading(true)
        getRequest(`/hierarchy_cs_exclude_vw?user_id=${user_id}&ccid=${ccId}&current_quarter=${curr_qrtr}&offset=${e.page.skip}&limit=${limit}`, getExcludedParts);
        setTimeout(()=>{
            setPage(e.page);
        },100); 
        }
return(
    <div className="window-container">
         <Window title = {"Cost Submission Exclusions"}
                closeButton={() => null}
                minimizeButton={() => null}
                maximizeButton={() => null}
                initialHeight={window.innerHeight * 0.95}
                style={{ zIndex: "1", display: "center" }}
                initialWidth={1200} >
                <div style={{ marginTop: -15 }}>{loading ? (
                    <ProgressBar />
                ) : ""}
                </div>
                <div className="wizard-page-hedding">
                    <p style={{ width: '150px'}}>Prior Quarter : {setGetHeirachyName.p_prior_qrtr}</p>
                    <span className="spacer"></span>
                    <p className="over-flow2">{setGetHeirachyName.p_hierarchy_name}</p>
                    <span className="spacer"></span>
                    <p className="next-steps"> Step 3 of 3
                        <Button size="small" style={{ backgroundColor: "#228dff", color: "white" ,marginBottom:'3px',marginLeft:'5px'}}
                            onClick={onClickComplete}>
                            <span >OK</span>
                        </Button>
                    </p>
                </div>
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <Grid onPageChange={(e) => { onPageChange(e) }} pageable={true}
                total={dataState ? dataState.length > 0 ? dataState[0]?.resultset_size : 0 : 0}
                sortable={true} skip={page.skip} pageSize={limit} data={dataState} rowHeight={window.innerHeight * 0.065}>
                <GridNoRecords>No Submission entries found for exclusion</GridNoRecords>
                <GridColumn className="col" headerClassName="headerClass" field="part" title="Parts" width={140} cell={(e) => GridCellToolTip(e, 'part')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="supplier_common_name" title="Supplier" cell={(e) => GridCellToolTip(e, 'supplier_common_name')} />
                    <GridColumn className="col" headerClassName="headerClass" field="manufacturer_name" width={170} title="Manufacturer" cell={(e) => GridCellToolTip(e, 'manufacturer_name')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="commodity_code" title="Commodity Code" width={150} cell={(e) => GridCellToolTip(e, 'commodity_code')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="mpn" title="MPN" cell={(e) => GridCellToolTip(e, 'mpn')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q1_price" title="Q+1 ($)" cell={(e) => GridCellToolTip(e, 'q1_price')} />
                    <GridColumn className="col" headerClassName="headerClass" field="q2_price" title="Q+2 ($)" cell={(e) => GridCellToolTip(e, 'q2_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q3_price" title="Q+3 ($)" cell={(e) => GridCellToolTip(e, 'q3_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q4_price" title="Q+4 ($)" cell={(e) => GridCellToolTip(e, 'q4_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q5_price" title="Q+5 ($)" cell={(e) => GridCellToolTip(e, 'q5_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q6_price" title="Q+6 ($)" cell={(e) => GridCellToolTip(e, 'q6_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q7_price" title="Q+7 ($)" cell={(e) => GridCellToolTip(e, 'q7_price')}/>
                    <GridColumn className="col" headerClassName="headerClass" field="q8_price" title="Q+8 ($)" cell={(e) => GridCellToolTip(e, 'q8_price')}/>
                </Grid>
                </Tooltip>
                {
                alertDialog &&  ( <Dialog height='150px' ><p
                  style={{ textAlign: "center", width: "400px" }} >
                 {response}
                </p> <DialogActionsBar>
                  <button style={{ backgroundColor: "#228dff", color: "white",width:'100%'}}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={()=> setAlertDialog(false)} >OK </button> </DialogActionsBar>
              </Dialog>)
            }
            </Window>
    </div>
)
}
export default ExcudePriorQuarterParts;