import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import "./TopDownDiscount.css";
import { getter } from "@progress/kendo-react-common";
import { getRequest, put } from "../../services/api.request";
import { UserContext } from "../../App";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import QuarterValue from "./components/QuarterValue";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../components/WindowTitle/WindowTitle";
import { orderBy } from "@progress/kendo-react-data-tools";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";

const initialDataState = {
  skip: 0,
  take: 10,
};

const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };


const TopDownDiscount = () => {
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const currentQuarter = userContext.curr_qrtr;
  const gcm_append_yn = userContext.gcm_append_yn;
  const limit = 10;
  const FILTER_PARAMS = [
    {
      paramName: "commoditycode",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "comm_code",
    },
    {
      paramName: "quarters",
      displayValue: "Quarter",
      id_field: "quarter",
      api_field: "quarter",
    },
  ];
  const DATA_ITEM_KEY = "tdid";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const fetchDetails = `/td_discount_get?user_id=${user_id}&current_quarter=${currentQuarter}`;
  const fetchQtrFilterDetails = `/td_discount_get?user_id=${user_id}&current_quarter=${currentQuarter}`;

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(initialDataState);
  const [gridCols, setGridCols] = useState([]);
  const [dataState, setDataState] = useState([]);
  const [sort, setSort] = useState([]);
  const [saveFlag, setSaveFlag] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [filterValue, setFilterValue] = useState({
    quarter: {
      lookup_type: "quarters",
      lookup_code: "9",
      lookup_value: currentQuarter,
      start_active: null,
      end_active: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      quarter: currentQuarter,
    },
  });
  const [selectedData, setSelectedData] = useState({
    current_quarter: undefined,
    commodity_group: undefined,
  });
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [discountValues, setDiscountValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const anchor = useRef(null);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      let gridRow = event.dataItems.filter(
        (dataItem) =>
          dataItem[DATA_ITEM_KEY] === Object.keys(newSelectedState)[0]
      );
      handleGridColumns(gridRow);
    },
    [selectedState]
  );

  const handleGridColumns = (item) => {
    var k = [];
    for (var key in item) {
      let char1 = key.toString().charAt(1);
      if (key.toString().charAt(0) === "q" && char1 !== "0") {
        let className = "parentHeaderClass";
        if (
          item["currq" + char1] &&
          item["prevq" + char1] &&
          item["currq" + char1] > item["prevq" + char1]
        ) {
          className = "greenHeader";
        } else if (
          item["currq" + char1] &&
          item["prevq" + char1] &&
          item["currq" + char1] < item["prevq" + char1]
        ) {
          className = "redHeader";
        }
        k.push({ class: className, col: item[key] });
      }
    }
    if (k.length > 0) {
      setGridCols(k);
    }
  };

  const handleGridData = ({ data }, type) => {
    handleGridColumns(data.items[0]);
    setDataState(data.items);
    setSelectedState({ [data.items[0]?.tdid]: true });
    setSelectedData({
      ...selectedData,
      commodity_group: data.items[0]?.commodity_group,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getRequest(
      fetchQtrFilterDetails +
        "&quarter=" +
        currentQuarter +
        "&offset=" +
        page.skip +
        "&limit=" +
        limit,
      handleGridData
    );
    setSubmit(true);
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const handleRowClick = (e) => {
    if (visibleDialog !== true) {
      handleGridColumns(e.dataItem);
      setDiscountValues(e.dataItem);
    }
  };

  const handlePageChange = (e) => {
    setLoading(true);
    let url =
      submit !== true
        ? fetchDetails + "?"
        : fetchQtrFilterDetails + "&quarter=" + currentQuarter + "&";
    if (submit === false) {
      getRequest(
        url + "offset=" + e.page.skip + "&limit=" + limit,
        handleGridData
      );
    }
    if (submit === true) {
      let url =
        "/td_discount_get?user_id=" +
        user_id +
        "&current_quarter=" +
        currentQuarter;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (param.paramName === "gcmparts") {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["attribute2"]
            }`;
          } else {
            url += `&${param.api_field}=${
              filterValue[param.id_field]["lookup_value"]
            }`;
          }
        }
      });
      if (url.includes("?")) {
        url += `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, handleGridData);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const handleSortChange = (e) => {
    setLoading(true);
    let url =
      submit !== true
        ? fetchDetails + "?"
        : fetchQtrFilterDetails + "&quarter=" + currentQuarter;
    if (e.sort && e.sort.length > 0) {
      let field = e.sort[0].field;
      let sortingOrder = e.sort[0].dir;
      const queryString =
        '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
      getRequest(
        url +
          "&q=" +
          encodeURIComponent(queryString) +
          "&offset=" +
          page.skip +
          "&limit=" +
          limit,
        handleGridData
      );
      setSort(e.sort);
    } else {
      let dir = "asc";
      if (sort[0].dir === "asc") {
        dir = "desc";
      }
      const queryString =
        '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
      getRequest(
        url +
          "&q=" +
          encodeURIComponent(queryString) +
          "&offset=" +
          page.skip +
          "&limit=" +
          limit,
        handleGridData
      );
      setSort([{ field: sort[0].field, dir: dir }]);
    }
  };

  const handleEdit = () => {
    setVisibleDialog(!visibleDialog);
  };

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const handleQuarterDetails = (e) => {
    const { name, value } = e.target;
    setDiscountValues({
      ...discountValues,
      [name]: value,
    });
  };

  const openPopover = (event) => {
    setShow(!show);
  };

  const reloadGrid = () => {
    setLoading(true);
    setSelectedState({});
    setDiscountValues({});
    setPage({ skip: 0 });
    getRequest(fetchDetails + "?offset=0&limit=" + limit, handleGridData);
    setSubmit(false);
    setSort([]);
  };

  const updateDiscountValues = ({ data }, type) => {
    if (data.p_out_status === "Success") {
      alert("Top Down Discount % Updated Successfully.");
      submit !== true ? reloadGrid() : onFilterSubmit();
      setVisibleDialog(false);
      setSaveFlag(false);
    } else alert(data.p_out_err_msg);
    setEditLoading(false);
  };

  const handleSave = () => {
    setEditLoading(true);
    setSaveFlag(true);
    put("/td_update_disc", discountValues, updateDiscountValues);
  };

  const onFilterSubmit = () => {
    setPage({ skip: 0 });
    setLoading(true);
    let url =
      "/td_discount_get?user_id=" +
      user_id +
      "&current_quarter=" +
      currentQuarter;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        if (param.paramName === "gcmparts") {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["attribute2"]
          }`;
        } else {
          url += `&${param.api_field}=${
            filterValue[param.id_field]["lookup_value"]
          }`;
        }
      }
    });
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, handleGridData);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    setSubmit(false);
    setShow(false);
  };

  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <div>
        <Button
          size="small"
          onClick={reloadGrid}
          className="app-button"
          title="Refresh"
        >
          <span className="k-icon k-i-reload"></span>
        </Button>
        <Button
          size="small"
          title="Edit"
          onClick={handleEdit}
          className="app-button"
          disabled={
            discountValues.update_flag === "Y" && gcm_append_yn === "Y"
              ? false
              : true
          }
        >
          <span className="k-icon k-i-edit"></span>
        </Button>
        <Button
          size="small"
          onClick={openPopover}
          title="Filter By"
          className="app-button"
          ref={anchor}
        >
          <span
            className={
              submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"
            }
          ></span>
        </Button>
        {show && (
          <FilterPopUp
            params={FILTER_PARAMS}
            handleClose={onCancelClick}
            show={show}
            elementRef={anchor.current && anchor.current.element}
            handleFilterSubmit={onFilterSubmit}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            userId={user_id}
          />
        )}
        {selectedData.commodity_group && (
          <div className="commoditygroup">
            <strong>
              <span>Commodity Group:</span> &nbsp;{" "}
              <span>{selectedData.commodity_group}</span>
            </strong>
          </div>
        )}
        {gridCols.length > 0 ? (
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              className="grid"
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              rowHeight={window.innerHeight * 0.062}
              data={orderBy(
                dataState.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })),
                sort
              )}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              sort={sort}
              total={dataState[0]?.resultset_size}
              onRowClick={(e) => {
                handleRowClick(e);
              }}
              onSortChange={(e) => {
                handleSortChange(e);
              }}
              onPageChange={(e) => {
                handlePageChange(e);
              }}
              onSelectionChange={(e) => onSelectionChange(e)}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
              }}
            >
              <GridColumn
                headerClassName="headerClass"
                field="commodity_code"
                title="Commodity Code"
                width={165}
                cell={(e) => GridCellToolTip(e, "commodity_code")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="q0"
                title="Fiscal Quarter"
                width={165}
                cell={(e) => GridCellToolTip(e, "q0")}
              />
              {gridCols.map((gridCol, index) => {
                return (
                  <GridColumn
                    headerClassName={gridCol.class}
                    key={gridCol.col}
                    title={gridCol.col}
                  >
                    <GridColumn
                      headerClassName="headerClass"
                      className="numberFields"
                      cell={QuarterValue}
                      field={"currq" + (index + 1)}
                      title={`Curr Q${index + 1}(%)`}                     
                      width={setPercentage(6)}
                    />
                    <GridColumn
                      className="numberFields"
                      headerClassName="headerClass"
                      field={"prevq" + (index + 1)}
                      title={`Prev Q${index + 1}(%)`}                      
                      width={setPercentage(6)}
                      cell={(e) => GridCellToolTip(e, "prevq" + (index + 1))}
                    />
                  </GridColumn>
                );
              })}
            </Grid>
          </Tooltip>
        ) : (
          ""
        )}
        {visibleDialog && (
          <div className="window-container">
            <Window
              title={
                <WindowTitle title="Top Down Discount" icon="k-i-percent" />
              }
              minimizeButton={() => null}
              maximizeButton={() => null}
              onClose={toggleDialog}
              initialHeight={530}
              style={{ zIndex: "1", display: "center" }}
              initialWidth={500}
            >
              <div style={{ marginTop: -15 }}>
                {editLoading ? <ProgressBar /> : ""}
              </div>
              <div className="popUpFields">
                <div className="individualFields">
                  <label className="labels">Year</label>
                  <span>
                    <Input
                      type="text"
                      value={discountValues?.year}
                      className="numberFields"
                      disabled
                    />
                  </span>
                </div>
                <div className="individualFields">
                  <label className="labels">Current Quarter </label>
                  <Input
                    type="text"
                    value={discountValues?.q0}
                    disabled={discountValues?.q0 ? true : false}
                  />
                </div>
                <div className="individualFields">
                  <label className="labels">Commodity Code </label>
                  <Input
                    type="text"
                    value={discountValues?.commodity_group}
                    disabled={discountValues?.commodity_group ? true : false}
                  />
                </div>
              </div>
              <div className="discount-container">
                <div>
                  <label>Quarter</label>
                </div>
                <div>
                  <label>Discount %</label>
                </div>
              </div>
              <div className="discountFieldContainer">
                {gridCols.map((gridCol, index) => {
                  return (
                    <React.Fragment>
                      <div style={{ marginBottom: "5px" }}>
                        <Input value={gridCol.col} disabled={true} />
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <NumericTextBox
                          format="n4"
                          className="numberFields"
                          value={discountValues["currq" + (index + 1)]}
                          name={"currq" + (index + 1)}
                          onChange={(e) => handleQuarterDetails(e)}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <Button
                onClick={handleSave}
                icon="save"
                className="save-button"
                style={{ float: "right" }}
                disabled={saveFlag === true ?? false}
              >
                Save
              </Button>
            </Window>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopDownDiscount;
