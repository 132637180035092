import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "../../shouldBeCost/components/UploadFileStatus.css";
import { Button } from "@progress/kendo-react-buttons";
import { getRequest, put } from "../../../services/api.request";
import { UserContext } from "../../../App";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { formatDates } from "../../../utils/formatDateUtil";
import CostSubmissionUploadViewStatus from "../../CostSubmissionView/components/CostSubmissionUploadViewStatus";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { FormAutoComplete } from "../../commodity-code-view/FormComponent/form-components";
import { filterBy } from '@progress/kendo-data-query';
import { CostSubmissionStatusStepper } from "./CostSubmissionStatusStepper";

const initialDataState = {
    skip: 0,
    take: 10,
};
const limit = 10;
const CostSubmissionUploadFileStatus = (props) => {
    const userContext = useContext(UserContext);
    const [dataState, setDataState] = React.useState([]);
    const [width, setWidth] = React.useState(212);
    const [cancelFlag, setCancelFlag] = useState(false);
    const [mediaId, setMediaId] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(false)
    const [page, setPage] = React.useState(initialDataState);
    const [showImportDialog, setShowImportDialog] = React.useState(false);
    const [importDialogData, setImportDialogData] = React.useState();
    const [uploadIdList, setUploadIdList] = useState({
        data: [],
        value: ''
    });
    const [submit, setSubmit] = useState(false);
    const list = useRef([]);
    const [uploadedFileDataFlag, setUploadFileDataFlag] = React.useState(false);
    const [uploadFileData, setUploadFileData] = React.useState({})
    let status_api_path = `/media_status_costsubmission/${userContext.user_id}/COST SUBMISSION?offset=${page.skip}&limit=${limit}`

    const getUploadTableRows = ({ data }) => {
        setDataState(
            data.items.map(dataItem => Object.assign({}, formatDates(["upload_date"], dataItem)))
        );
        list.current = data.items.map((item) => {
            return item.media_id;
        })
        setUploadIdList({ ...uploadIdList, data: list.current })
        setLoading(false);
    };
    const anchor = React.useRef(null);
    const [uploadId, setUploadId] = React.useState("");
    const reloadGrid = () => {
        setLoading(true);
        setPage(initialDataState);
        setUploadIdList({});
        getRequest(status_api_path, getUploadTableRows
        );
        setSubmit(false);
    };
    const onPageChange = (e) => {
        status_api_path =
            `/media_status_costsubmission/${userContext.user_id}/COST SUBMISSION?offset=${e.page.skip}&limit=${limit}`
        setLoading(true)
        getRequest(status_api_path, getUploadTableRows);

        setTimeout(() => {
            setPage(e.page);
        }, 200);

    };
    useEffect(() => {
        reloadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormSubmit = (e) => {
        setLoading(true);
        setPage(initialDataState)
        getRequest(
            "should_cost_upload_id_filter?media_id=" + uploadId + "&offset=" +
            page.skip +
            "&limit=" +
            limit,
            getUploadTableRows
        );
        setSubmit(true);
        setShow(!show)
    };
    const openPopover = (event) => {
        setShow(true);
    };
    const onCancelClick = event => {
        event.preventDefault();
        setSubmit(false);
        setUploadId("");
        reloadGrid();
        setShow(false);
    };

    let showUploadDetails = (data) => {
        setImportDialogData(data);
        setShowImportDialog(true);
    }

    const UploadIdCell = (props) => {
        let linkedTd = (props.dataItem?.validation_status?.toLowerCase() === "complete") ||
            (props.dataItem?.validation_status?.toLowerCase() === "errored") ||
            (props.dataItem?.validation_status?.toLowerCase() === "warning")
            ? (
                <td
                    onClick={(e) => showUploadDetails(props.dataItem)}
                    className="col"
                    style={{ textDecoration: "underline", color: "blue" }}
                >
                    {props.dataItem.media_id}
                </td>
            ) : (
                <td className="col">{props.dataItem.media_id}</td>
            )
        return linkedTd;
    };
    const showUploadedFileData = (data) => {
        setUploadFileDataFlag(!uploadedFileDataFlag);
        setUploadFileData(data)
    }
    const GetUploadedFileName = (props) => {
        let linkedTd = (props.dataItem?.file_validation_status?.toLowerCase() === "errored")
            ? (
                <td
                    onClick={(e) => showUploadedFileData(props.dataItem)}
                    className="fileName"
                    style={{ textDecoration: "underline", color: "blue" }}
                >
                    {props.dataItem.file_name}
                </td>
            ) : (
                <td className="fileName">{props.dataItem.file_name}</td>
            )
        return linkedTd;
    };

    const filterData = (value) => {
        const data = list.current;
        const filter = {
            value: value,
            operator: 'startswith',
            ignoreCase: true,
        };
        return value ? filterBy(data, filter) : data;
    };
    const handleUploadId = (e) => {
        setUploadId((e.target.value).toString());
        setUploadIdList({
            data: filterData(e.target.value.toString()),
            value: e.target.value.toString(),
        });
    };

    const handleMove = (e) => {
        if (e.end) {
            setWidth(e.left)
        }
    };
    const cancelFileResponse = ({ data }) => {
        setLoading(false);
        alert(data.p_out_msg);
        reloadGrid();
    }
    const cancelFile = (e) => {
        if (typeof e === 'object') {
            setMediaId(e.media_id);
        }
        if (e === "YES") {
            setLoading(true);
            put(`/upload_cancel?media_id=${mediaId}&user_id=${userContext.user_id}`, { media_id: e.media_id, user_id: userContext.user_id }, cancelFileResponse);
        }
        setCancelFlag(!cancelFlag);
    }
    const cellWithBackGround = (props) => {
        let iconAttention = props.dataItem.cancel_flg;
        let import_status = props.dataItem.import_status;
        let file_validation_status = props.dataItem.file_validation_status;
        let validation_status = props.dataItem.validation_status
        let dis = (import_status === "Complete" || iconAttention === "Y" || file_validation_status === "Errored" ||
        validation_status === "Errored") ? true : false;
        let cancelIcon =
            (<td className="col">
                <Button title="Cancel Upload" className="app-button" disabled={dis} onClick={(e) => cancelFile(props.dataItem)}
                    style={{ color: dis === false ? "red" : "#F67280" }}
                > <span class="k-icon k-i-close-outline" ></span> </Button>
            </td>
            );
        return cancelIcon;
    };
    return (
        <div className='window-shouldCostUpload'>
            <Window
                initialHeight={window.innerHeight * 0.95}
                initialWidth={1000}
                title={<WindowTitle title="Upload File Status" icon="k-i-calculator" />}
                onClose={props.toggleWizard}
                onMove={handleMove}
                onResize={handleMove}
                minimizeButton={() => null}
                maximizeButton={() => null}
            >

                <div style={{ marginTop: -16 }}>
                    {loading ? <ProgressBar /> : ""}
                </div>

                <div>

                    <Button onClick={reloadGrid} size="small" title="Refresh" className="app-button">
                        <span
                            style={{ paddingRight: 5 }}
                            className="k-icon k-i-reload"
                        ></span>
                    </Button>
                    <Button size="small" onClick={openPopover} title="Filter By" className="app-button" ref={anchor} >
                        <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
                    </Button>
                    {show && <Popover
                        show={show}
                        anchor={anchor.current && anchor.current.element}
                        position={"bottom"}
                    >
                        <form>
                            <div
                                style={{
                                    width: 200,
                                }}
                            >
                                <label for="comm_code">Upload Id</label>
                                <AutoComplete
                                    className="comm_code"
                                    id={"upload_id"}
                                    name={"upload_id"}
                                    value={uploadIdList.value}
                                    onChange={handleUploadId}
                                    component={FormAutoComplete}
                                    style={{
                                        width: "100%",
                                        marginBottom: "10px"
                                    }}
                                    data={uploadIdList.data}
                                />

                            </div>
                            <PopoverActionsBar>
                                <Button className='searchBtn'
                                    onClick={onFormSubmit}
                                    primary="true"
                                    disabled={uploadIdList.value !== '' ? false : true}
                                >
                                    Submit
                                </Button>
                                <Button onClick={onCancelClick}>Cancel</Button>
                            </PopoverActionsBar>
                        </form>
                    </Popover>}

                    <Grid
                        className="uploadStatus"
                        onPageChange={onPageChange}
                        total={
                            dataState
                                ? dataState.length > 0
                                    ? dataState[0]?.resultset_size
                                    : 0
                                : 0
                        }
                        sortable={true}
                        pageable={true}
                        skip={page.skip}
                        pageSize={limit}
                        data={dataState}
                        rowHeight={window.innerHeight * 0.068}
                    >
                        <GridColumn
                            width={60}
                            cell={cellWithBackGround}
                            headerClassName="headerClass"
                            className="col"
                            field="cancel_flg"
                            key="cancel_flg"
                            title="Cancel"
                        />
                        <GridColumn
                            width={80}
                            cell={UploadIdCell}
                            className="col"
                            headerClassName="headerClass"
                            key="media_id"
                            field="media_id"
                            title="Upload ID"
                        />
                        <GridColumn
                            width={270}
                            cell={GetUploadedFileName}
                            className="col"
                            headerClassName="headerClass"
                            key="file_name"
                            field="file_name"
                            title="File Name"
                        />
                        <GridColumn
                            width={100}
                            className="col"
                            headerClassName="headerClass"
                            key="upload_date"
                            field="upload_date"
                            title="Upload Date"
                        />
                        <GridColumn
                            className="col"
                            headerClassName="headerClass"
                            key="status"
                            field="status"
                            cell={CostSubmissionStatusStepper}
                            title="Status"
                        />
                    </Grid>
                </div>
                {showImportDialog && (<CostSubmissionUploadViewStatus toggleDialog={(e) => setShowImportDialog(!showImportDialog)} params={importDialogData} />)}
                {cancelFlag && (
                    <Dialog title={"Please confirm "} onClose={cancelFile}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                                width: 400,
                                height: 40,
                            }}
                        >
                            {
                                "Uploaded file will be cancelled. Do you still want to continue?"
                            }
                        </p>
                        <DialogActionsBar>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="NO"
                                onClick={(e) => cancelFile(e.target.value)}
                            >
                                No
                            </button>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="YES"
                                onClick={(e) => cancelFile(e.target.value)}
                            >
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {uploadedFileDataFlag && (
                    <Dialog title={"Error Message "} onClose={showUploadedFileData}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                                width: 350,
                                height: 40,
                            }} >
                                 {  uploadFileData.file_validation_message  }
                        </p>
                        <DialogActionsBar>
                            <button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                value="YES"
                                onClick={(e) => showUploadedFileData(e.target.value)} > Ok  </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

            </Window>
        </div>
    );
};

export default CostSubmissionUploadFileStatus;
