import React, { useEffect, useState,useCallback } from 'react';
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { getter } from "@progress/kendo-react-common";
import {formatDates} from '../../utils/formatDateUtil';
import { Button } from "@progress/kendo-react-buttons";
import { Splitter } from '@progress/kendo-react-layout';
import  './GcmToCommodityCodeMapping.css';
import { getRequest } from '../../services/api.request';
import MappedCommodityCodes from './components/MappedCommodityCodes';
import CommodityCodeView from '../commodity-code-view/CommodityCodeView';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { Tooltip } from "@progress/kendo-react-tooltip";
import FilterPopUp from '../../components/FilterPopUp/FilterPopUp';
import GridCellToolTip from '../../components/GridCellToolTip/GridCellToolTip';

const gridWidth = window.innerWidth * 0.85;
const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
  };

const initialDataState = {
    skip: 0,
    take: 10
};

const GcmToCommodityCodeMapping = () => {
    const FILTER_PARAMS = [
        {
            paramName:"cmatusers",
            displayValue:"GCM Name",
            id_field:"user_name",
            api_field:"user_name"
        },
        {
          paramName:"commoditygroup",
          displayValue:"Commodity Group",
          id_field:"commodity_group",
          api_field:"comm_grp"
        }
    ];
    const limit = 10;
    const [ filterValue, setFilterValue ] = useState({});
    const [dataState, setDataState] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [commodityGroupCode, setCommodityGroupCode] = React.useState({name: undefined, id: undefined});
    const anchor = React.useRef(null);
    const [sort, setSort]= useState([]);
    const [panes, setPanes] = React.useState([
        { size: "60%", min: "42.8%", collapsible: true },
        { min: "40%", collapsible: true }
    ]);
    const DATA_ITEM_KEY = "gcg_id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedState, setSelectedState] = React.useState({});
    const [dis,setDis]=useState(true);
    const [addflag, setAddFlag] = React.useState();
    const [rowObject, setRowObject]=React.useState({});
    const [show, setShow] = React.useState(false);
    const [page, setPage] = React.useState(initialDataState);
      const [submit, setSubmit] = useState(false);

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );
    const onPaneChange =(event) =>{
        setPanes(event.newState);
    }; 
    const [visible, setVisible] = React.useState(false);
    const toggleDialog = () => {
        setShow(false);
        setVisible(!visible);
        setAddFlag("add")
    };   
    const toggleEditDialog =()=>{
        setShow(false);
        setVisible(!visible);
        setAddFlag("edit")
    }
    const getGcms = ({ data },type)=>{
        setCommodityGroupCode({...commodityGroupCode, id: undefined});
        setSelectedState({});
        setDataState(
            data.items.map(dataItem => Object.assign({}, formatDates(["gcg_start_active","gcg_end_active"],dataItem)))
        );
    setLoading(false);
    };


    const reloadGrid=()=>{
        setLoading(true);
        setPage({skip:0})
        getRequest('/gcm_group/?offset=0&limit='+limit,getGcms); 
        setSubmit(false);
        setSort([]);
        setDis(true);
    }
    useEffect(()=>{
        reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleSortChange=(e)=>{
        setLoading(true)
        if(e.sort && e.sort.length>0){
            let field = e.sort[0].field;
            let sortingOrder = e.sort[0].dir;
            const queryString='{"$orderby": {"'+field+'":"'+sortingOrder+'"}}';
            getRequest('/gcm_group/?q='+encodeURIComponent(queryString)
            + "&offset=" +
            page.skip +
            "&limit=" +
            limit,getGcms);
            setSort(e.sort);
        } else{
            let dir= "asc";
            if(sort[0].dir==='asc'){
                dir="desc";
            }
            const queryString='{"$orderby": {"'+sort[0].field+'":"'+dir+'"}}';
            getRequest('/gcm_group/?q='+encodeURIComponent(queryString)  + "&offset=" +
            page.skip +
            "&limit=" +
            limit,getGcms);
            setSort([{"field": sort[0].field, "dir":dir}]);
        }
        setCommodityGroupCode({...commodityGroupCode, id: undefined})
    };

    const openPopover=(event)=>{
        setShow(!show);
    };

    const handlePageChange=(e)=>{
        setLoading(true);
        let queryString = sort.length !== 0 && '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
        if(submit === false){
            let urlWithoutFilter =
            sort.length === 0
              ? "gcm_group/?offset=" +
                e.page.skip +
                "&limit=" +
                limit 
              : "gcm_group/?offset=" +
                e.page.skip +
                "&limit=" +
                limit  +
                "&q=" +
                encodeURIComponent(queryString);
          getRequest(urlWithoutFilter, getGcms);
          setRowObject({});
          setDis(true);
        }
        if (submit === true){
            let url = '/gcm_group/'
            FILTER_PARAMS.forEach((param)=>{
                if(filterValue.hasOwnProperty(param.id_field)){
                    if(url.includes("?")){
                        url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
                    }else{
                        url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
                    }
                }
            })
            if(url.includes('?')){
                url += `&offset=${e.page.skip}&limit=${limit}`
            }else{
                url += `?offset=${e.page.skip}&limit=${limit}`
            }
            getRequest(url, getGcms);
            setRowObject({});
            setDis(true);
        }

        setTimeout(() => {
            setPage(e.page);
          }, 100);
        
    };

    const onFilterSubmit = () => {
        setPage({skip:0})
        setLoading(true);
        let url = '/gcm_group/'
        FILTER_PARAMS.forEach((param)=>{
            if(filterValue.hasOwnProperty(param.id_field)){
                if(url.includes("?")){
                    url += `&${param.api_field}=${filterValue[param.id_field][param.id_field]}`
                }else{
                    url += `?${param.api_field}=${filterValue[param.id_field][param.id_field]}`
                }
            }
        })
        if(url.includes('?')){
            url += `&offset=0&limit=${limit}`
        }else{
            url += `?offset=0&limit=${limit}`
        }
        getRequest(url, getGcms);
        Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false)
        setShow(false);
      };
    
    const handleRowClick=(e)=>{
        if (visible !== true) {
        if(e.dataItem.gcg_id){
            setCommodityGroupCode({id:e.dataItem.gcg_id, name: e.dataItem.commodity_group})
        }
        setDis(false);
        setRowObject(e.dataItem);
        setLoading(true);
    }
    if(e.dataItem.active_flag === 'N'){
        setDis(true);
    }
    };

    return (
        <div>
            { loading ? (
                <ProgressBar />
            ) : "" }           
            <Splitter panes={panes} onChange={onPaneChange} className="splitBody">
                <div className="pane-content">
                <span>
                    <Button size="small" onClick={reloadGrid} className="app-button" title="Refresh">
                        <span className="k-icon k-i-reload"></span>
                    </Button>
                    <Button size="small" onClick={toggleDialog} className="app-button" title="Add" >
                        <span className="k-icon k-i-plus"></span>
                    </Button>
                    <Button onClick={toggleEditDialog} size="small" className="app-button" title="Edit" disabled={dis}>
                        <span className="k-icon k-i-edit"></span>
                    </Button>
                    <Button size="small" onClick={openPopover} title="Filter By" className="app-button" ref={anchor} >
                    <span className={submit === true ? "k-icon k-i-filter-clear" : "k-icon k-i-filter"}></span>
                    </Button>
                    {show && <FilterPopUp 
                    params={FILTER_PARAMS}
                    handleClose={openPopover} 
                    show={show} 
                    elementRef={anchor.current && anchor.current.element} 
                    handleFilterSubmit={onFilterSubmit}
                    filterValue = { filterValue }
                    setFilterValue = { setFilterValue }
                    />}
                </span>
                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                        <Grid 
                        pageable={true}
                        skip={page.skip}
                        pageSize={limit}
                        data={dataState?.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
                        dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
                        selectable={{ enabled: visible !== true ? true : false, drag: false, cell: false, mode: "single" }}
                        onSelectionChange={onSelectionChange}
                        total={dataState?.length>0 ? dataState[0]?.resultset_size : 0}
                        onRowClick={(e)=> { handleRowClick(e)}}
                        onPageChange={(e)=> { handlePageChange(e)}}
                        rowHeight={window.innerHeight * 0.065}
                        >           
                        <GridColumn headerClassName="headerClass" field='user_name' title="Name" width={setPercentage(18)} cell={(e) => GridCellToolTip(e, 'user_name')}/>
                        <GridColumn headerClassName="headerClass" field='user_id' title="Email"  width={setPercentage(25)} cell={(e) => GridCellToolTip(e, 'user_id')} />
                        <GridColumn headerClassName="headerClass" field='commodity_group' title='Commodity Group' width={setPercentage(15)} cell={(e) => GridCellToolTip(e, 'commodity_group')} />
                        <GridColumn headerClassName="headerClass" field='gcg_start_active' title='Start Date Active' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'gcg_start_active')}/>
                        <GridColumn headerClassName="headerClass" field='gcg_end_active' title='End Date Active' width={setPercentage(12)} cell={(e) => GridCellToolTip(e, 'gcg_end_active')}/>
                        </Grid>
                        </Tooltip>
                </div>

                <div className="pane-content">
                   <div className='commodity-views-header'>    
                       <span>Commodity Group:</span> &nbsp;
                        {commodityGroupCode.id ? <strong><span>{commodityGroupCode.name}</span></strong> : ''}
                   </div>
                    <MappedCommodityCodes commodityGroupCode={commodityGroupCode.id} setLoading={setLoading}/>
                </div>
            </Splitter>
            {
                visible && <CommodityCodeView commodityGroupCode={commodityGroupCode.id} toggleDialog={toggleDialog} addflag={addflag} rowObject={rowObject} setLoading={setLoading} reloadGrid={reloadGrid} />
            }
           
        </div>        
    );
};

export default GcmToCommodityCodeMapping;