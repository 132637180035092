import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import GridCellToolTip from "../../../components/GridCellToolTip/GridCellToolTip";
import { Tooltip } from "@progress/kendo-react-tooltip";

const PreviousAllocationEditGrid = ({
  updateAllocations,
  quarterNames,
  setPercentage,
  handleAllocationChange,
  setTotalAlloc,
}) => {
  let totalArr = [];

  const cellTotal = (props) => {
    const field = props.field || "";
    const total = updateAllocations.reduce(
      (acc, current) => acc + Number(current[field]),
      0
    );
    totalArr.push(total);
    let largeVal = totalArr.some((item) => item > 100);
    setTotalAlloc(largeVal);
    return (
      <p style={{ textAlign: "center", margin: 0 }}>
        {props.title}
        <br />
        <span
          style={{
            color: total <= 100 ? "white" : "red",
            fontWeight: "bolder",
            fontSize: "medium",
          }}
        >
          {total}%
        </span>
      </p>
    );
  };

  const inputCellData = ({ dataItem }, field, count, event, title) => {
    return (
      <td title={dataItem[field]}>
        <Input
          style={{ margin: 0 }}
          defaultValue={dataItem[`q${count}_alloc`]}
          type="number"
          onChange={(e) => handleAllocationChange(e, title, event.dataIndex)}
          id={`${event.dataIndex}_${title}`}
          min={0}
        />
      </td>
    );
  };

  return (
    <Tooltip openDelay={100} position="bottom" anchorElement="target">
      <Grid data={[...updateAllocations]}>
        <GridColumn
          headerClassName="headerClass"
          field="part"
          title="Part Number"
          width={setPercentage(19)}
          cell={(e) => GridCellToolTip(e, "part")}
        />
        <GridColumn
          headerClassName="headerClass"
          field="manufacturer_name"
          title="Manufacturer Name"
          width={setPercentage(18)}
          cell={(e) => GridCellToolTip(e, "manufacturer_name")}
        />
        <GridColumn
          headerClassName="headerClass"
          field="supplier_common_name"
          title="Supplier Info"
          width={setPercentage(24)}
          cell={(e) => GridCellToolTip(e, "supplier_common_name")}
        />
        <GridColumn headerClassName="headerClass" title="Allocation %">
          {quarterNames.length > 0 &&
            [...quarterNames]?.map((name, i) => {
              return (
                <GridColumn
                  headerClassName="headerClass"
                  title={name.quarter_name}
                  width={setPercentage(14)}
                  field={"q" + (i + 1) + "_alloc"}
                  cell={(e) =>
                    inputCellData(
                      e,
                      "q" + (i + 1) + "_alloc",
                      i + 1,
                      e,
                      name.quarter_name
                    )
                  }
                  headerCell={cellTotal}
                />
              );
            })}
        </GridColumn>
      </Grid>
    </Tooltip>
  );
};

export default PreviousAllocationEditGrid;
