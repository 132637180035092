import { createRef, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { getRequest, put } from "../../../services/api.request";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import "./exportComponent.css";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import privateRequest from "../../../services/api.config";
import { debounce } from "lodash";
import { Hint } from "@progress/kendo-react-labels";

const category = [
  {
    label: "Commodity Code",
    value: "COMMODITY_CODE",
  },
  {
    label: "GPN",
    value: "GPN",
  },
  {
    label: "Part",
    value: "PART",
  },
];

const ExportComponent = ({ quarterNames, currentQuarter, user_id }) => {
  const [categoryName, setCategoryName] = useState(category[0].value);
  const [loading, setLoading] = useState(false);
  const [downloadParams, setDownloadParams] = useState({
    user_id: user_id,
    category: "",
    current_quarter: currentQuarter,
    commodity_code: "",
    incl_100ca_gpn: "N",
  });
  const [exportDetails, setExportDetails] = useState([]);
  const [downloadID, setDownloadID] = useState();
  const [commCodesList, setCommCodesList] = useState([]);
  const [commCodeLoading, setCommCodeLoading] = useState(false);
  const [selectedCommCode, setSelectedCommCode] = useState({});
  const [checked, setChecked] = useState(false);
  const _exporter = createRef();

  useEffect(() => {
    setDownloadParams({ ...downloadParams, category: categoryName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  useEffect(() => {
    exportDetails.length > 0 && handleSaveExcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDetails]);

  const handleCategoryChange = (e) => {
    setCategoryName(e.value);
  };

  const handleExport = () => {
    setLoading(true);
    put("/ca_dwnld_put", downloadParams, getDownloadId);
  };

  const getDownloadId = ({ data }) => {
    if (data.p_out_status === "success") {
      setDownloadID(data.p_out_id);
      getRequest(`ca_get_dwnld_extract/${data.p_out_id}`, handleDownload);
    } else {
      alert(data.p_out_err_msg);
      setLoading(false);
    }
  };

  const handleDownload = ({ data }) => {
    data.items.length > 0
      ? setExportDetails(data.items)
      : alert("There is no data combinations");
    setLoading(false);
  };

  const handleSaveExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const getGCMCommCodes = debounce(({ filter }) => {
    setCommCodeLoading(true);
    let url = `/generic_lov_service/gcmcommcodes/?search_for=${filter.value}&user_id=${user_id}`;
    return privateRequest
      .get(url)
      .then(({ data }) => {
        setCommCodesList(data.items);
        setCommCodeLoading(false);
      })
      .catch((err) => {
        setCommCodeLoading(false);
      });
  }, 1000);

  const handleCommCode = (e) => {
    setDownloadParams({
      ...downloadParams,
      commodity_code: e.value.lookup_value,
    });
    setSelectedCommCode(e.value);
  };

  const handleChange = (event) => {
    setChecked(event.value);
    setDownloadParams({
      ...downloadParams,
      incl_100ca_gpn: event.value === true ? "Y" : "N",
    });
  };

  return (
    <div>
      <div style={{ marginTop: "-15px" }}>{loading ? <ProgressBar /> : ""}</div>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1.2fr 1fr 0.3fr",
            marginTop: "15px",
            alignItems: "center",
          }}
        >
          <label className="input-header-cell">
            GCM Managed Commodity Code:{" "}
          </label>
          <DropDownList
            style={{ width: "175px" }}
            data={commCodesList}
            filterable={true}
            textField="lookup_value"
            dataItemKey="lookup_value"
            onFilterChange={getGCMCommCodes}
            loading={commCodeLoading}
            value={selectedCommCode}
            onChange={handleCommCode}
          />
          <Button
            size="small"
            title="Clear"
            className="save-button"
            onClick={() => {
              setDownloadParams({
                ...downloadParams,
                commodity_code: "",
              });
              setSelectedCommCode({});
            }}
            disabled={Object.keys(selectedCommCode).length > 0 ? false : true}
          >
            Clear
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginTop: "15px",
            alignItems: "baseline",
            marginBottom: "5px",
          }}
        >
          <label className="input-header-cell">Download By: </label>
          <RadioGroup
            data={category}
            value={categoryName}
            onChange={handleCategoryChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "381px",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={handleChange}
            label={"Include 100% allocation"}
            style={{ fontWeight: "500", borderColor: "#666666" }}
          />
          {checked && (
            <Hint>(Parts with a single supplier will be excluded.)</Hint>
          )}
        </div>
      </div>
      <Button
        size="small"
        iconClass="k-icon k-i-download"
        title="Download"
        className="save-button"
        onClick={handleExport}
        disabled={loading ? true : false}
      >
        Download
      </Button>
      <ExcelExport
        data={exportDetails}
        fileName={`CMATXWD_COST_ALLOCATION_${downloadID}.xlsx`}
        ref={_exporter}
      >
        <ExcelExportColumn
          field="commodity_code"
          title="COMMODITY_CODE"
          width={150}
        />
        <ExcelExportColumn field="gpn" title="GPN" width={150} />
        <ExcelExportColumn field="part" title="PART_NUMBER" width={150} />
        <ExcelExportColumn field="supplier" title="SUPPLIER_NAME" width={160} />
        <ExcelExportColumn
          field="manufacturer"
          title="MANUFACTURER_NAME"
          width={150}
        />
        {quarterNames.length > 0 &&
          [...quarterNames]?.map((name, i) => {
            return (
              <ExcelExportColumn
                className="numberFields"
                title={"Q" + (i + 1) + "Pcent"}
                field={"q" + (i + 1) + "_alloc"}
                cellOptions={{
                  format: "#,###",
                }}
                width={75}
              />
            );
          })}
      </ExcelExport>
    </div>
  );
};

export default ExportComponent;
