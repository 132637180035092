import { Button } from "@progress/kendo-react-buttons";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Splitter } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import GridCellToolTip from "../../components/GridCellToolTip/GridCellToolTip";
import PartAssociation from "./PartAssociation";
import AddHeirarchyGroup from "./AddHeirarchyGroup";
import { getRequest, put, remove } from "../../services/api.request";
import { formatDates } from "../../utils/formatDateUtil";
import { UserContext } from "../../App";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import FilterPopUp from "../../components/FilterPopUp/FilterPopUp";

const HeirarchyGroupParts = () => {
  const FILTER_PARAMS = [
    {
      paramName: "prefixval",
      displayValue: "Prefix",
      id_field: "prefixval",
      api_field: "prefixval",
    },
    {
      paramName: "gcmcommcodes",
      displayValue: "Commodity Code",
      id_field: "commodity_code",
      api_field: "commodity_code",
    },
    {
      paramName: "suppliers",
      displayValue: "Supplier Common Name",
      id_field: "supplier_name",
      api_field: "supplier_name",
    },
  ];
  const userContext = useContext(UserContext);
  const user_id = userContext.user_id;
  const [loading, setLoading] = useState(false);
  const DATA_ITEM_KEY = "hmid";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const limit = 10;
  const [page, setPage] = React.useState(initialDataState);
  const [sort, setSort] = useState([]);
  const [panes, setPanes] = React.useState([
    { size: "65%", min: "50%", collapsible: true },
    { min: "30%", collapsible: true },
  ]);
  const [dataState, setDataState] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState({});
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [submit, setSubmit] = useState(false);
  const [dis, setDis] = useState(true);
  const [visible, setVisible] = React.useState(false);
  const [rowObject, setRowObject] = React.useState({});
  const [heirarchyCode, setHeirarchyCode] = React.useState();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [hmid, setHMID] = React.useState();
  const [disabledeleteFlag, setDisableDeleteFlag] = React.useState(true);
  const [editAlert, setEditAlert] = useState(false);
  const anchor = React.useRef(null);
  const [show, setShow] = useState(false);
  const [dataChg, setDataChg] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [filterURL, setFilterURL] = useState();
  const [alertDialogForEdit, setAlertDialogForEdit] = React.useState(false);
  const [response, setResponse] = React.useState();

  const onChange = (event) => {
    setPanes(event.newState);
  };
  const openPopover = (event) => {
    setShow(!show);
  };
  const getHeirarchyData = ({ data }) => {
    setHeirarchyCode();
    setSelectedData(null);
    setDataState(
      data.items.map((dataItem) =>
        Object.assign(
          {},
          formatDates(["effective_end_date", "effective_start_date"], dataItem)
        )
      )
    );
    setLoading(false);
  };
  const reloadGrid = () => {
    setLoading(true);
    setPage(initialDataState);
    getRequest(
      `/part_hierarchy?user_id=${user_id}&offset=0&limit=${limit}`,
      getHeirarchyData
    );
    setSubmit(false);
    setSort([]);
    setDataChg(false);
    setHeirarchyCode();
    setSelectedData(null);
    setDisableDeleteFlag(true);
    setSelectedState({});
  };
  const handleDelete = () => {
    setDeleteAlert(true);
  };
  const handleProceed = () => {
    setLoading(true);
    setDeleteAlert(false);
    remove(
      `part_hierarchy?p_user_id=${user_id}&p_hmid=${hmid}`,
      "",
      deleteRecord
    );
  };
  const deleteRecord = ({ data }, type) => {
    if (data.p_out_status === "Success") {
      setHeirarchyCode();
      setResponse("Heirarchy deleted successfully");
      setAlertDialogForEdit(true);
    } else {
      setResponse(data.p_out_err_msg);
      setAlertDialogForEdit(true);
      setLoading(false);
      setDisableDeleteFlag(true);
    }
    reloadGrid();
  };
  const editHierarchyRecord = () => {
    setLoading(true);
    setEditAlert(false);
    put(`part_hierarchy?p_user_id=${user_id}&p_hmid=${hmid}`, "", editRecord);
  };
  const editRecord = ({ data }) => {
    if (data.p_out_status === "Success") {
      setHeirarchyCode();
      setResponse("Record Updated Successfully");
      setAlertDialogForEdit(true);
    } else {
      setResponse(data.p_out_err_msg);
      setAlertDialogForEdit(true);
      setLoading(false);
    }
    reloadGrid();
  };
  useEffect(() => {
    reloadGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addToggleDialog = () => {
    setOpenAddDialog(!openAddDialog);
  };
  const onSelectionChange = useCallback(
    (event) => {
      setSelectedData(event.dataItems[event.startRowIndex]);
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const handlePageChange = (e) => {
    setLoading(true);
    let queryString =
      sort.length !== 0 &&
      '{"$orderby": {"' + sort[0].field + '":"' + sort[0].dir + '"}}';
    if (submit === false) {
      let urlWithoutFilter =
        sort.length === 0
          ? "/part_hierarchy?user_id=" +
            user_id +
            "&offset=" +
            e.page.skip +
            "&limit=" +
            limit
          : "/part_hierarchy?user_id=" +
            user_id +
            "&offset=" +
            e.page.skip +
            "&limit=" +
            limit +
            "&q=" +
            encodeURIComponent(queryString);
      getRequest(urlWithoutFilter, getHeirarchyData);
      setRowObject({});
      setDis(true);
    }
    if (submit === true) {
      let url = `/part_hierarchy?user_id=${user_id}`;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          if (url.includes("?")) {
            url += `&${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          } else {
            url += `?${param.api_field}=${
              filterValue[param.id_field][param.id_field]
            }`;
          }
        }
      });
      if (url.includes("?")) {
        url += `&offset=${e.page.skip}&limit=${limit}`;
      } else {
        url += `?offset=${e.page.skip}&limit=${limit}`;
      }
      getRequest(url, getHeirarchyData);
      setRowObject({});
      setDis(true);
    }
    setTimeout(() => {
      setPage(e.page);
    }, 100);
  };

  const handleSortChange = (e) => {
    setLoading(true);
    if (dataChg === false) {
      let url = `/part_hierarchy?user_id=${user_id}`;
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          url + '&q=' +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
            getHeirarchyData
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          url + '&q=' +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
            getHeirarchyData
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else if (dataChg === true) {
      let url;
      FILTER_PARAMS.forEach((param) => {
        if (filterValue.hasOwnProperty(param.id_field)) {
          url += `&${param.api_field}=${
            filterValue[param.id_field][param.id_field]
          }`;
        }
      });
      if (e.sort && e.sort.length > 0) {
        let field = e.sort[0].field;
        let sortingOrder = e.sort[0].dir;
        const queryString =
          '{"$orderby": {"' + field + '":"' + sortingOrder + '"} }';
        getRequest(
          `/part_hierarchy?user_id=${user_id}&q` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
            getHeirarchyData
        );
        setSort(e.sort);
      } else {
        let dir = "asc";
        if (sort[0].dir === "asc") {
          dir = "desc";
        }
        const queryString =
          '{"$orderby": {"' + sort[0].field + '":"' + dir + '"}}';
        getRequest(
          `/part_hierarchy?user_id=${user_id}&q` +
            url +
            encodeURIComponent(queryString) +
            "&offset=" +
            page.skip +
            "&limit=" +
            page.take,
            getHeirarchyData
        );
        setSort([{ field: sort[0].field, dir: dir }]);
      }
    } else {
      setSort(e.sort);
    }
  };

  const handleRowClick = (e) => {
    if (visible !== true) {
      if (e.dataItem.hmid) {
        setHMID(e.dataItem.hmid);
        setHeirarchyCode(e.dataItem.hmid);
      }
      if(e.dataItem.status === "Inactive"){
        setDisableDeleteFlag(false);
      }
      setDis(false);
      setRowObject(e.dataItem);
      setLoading(false);
    }
  };
  const EditHeirarchy = () => {
    setEditAlert(true);
  };
  const onFilterSubmit = () => {
    setPage({ skip: 0, take: 10 });
    setLoading(true);
    let url = `/part_hierarchy?user_id=${user_id}`;
    FILTER_PARAMS.forEach((param) => {
      if (filterValue.hasOwnProperty(param.id_field)) {
        url += `&${param.api_field}=${
          filterValue[param.id_field][param.id_field]
        }`;
      }
    });
    setFilterURL(url);
    if (url.includes("?")) {
      url += `&offset=0&limit=${limit}`;
    } else {
      url += `?offset=0&limit=${limit}`;
    }
    getRequest(url, getHeirarchyData);
    Object.keys(filterValue).length > 0 ? setSubmit(true) : setSubmit(false);
    setShow(false);
    setDataChg(true);
  };
  return (
    <div>
      {loading ? <ProgressBar /> : ""}
      <Splitter panes={panes} onChange={onChange} className="splitBody">
        <div className="pane-content">
          <span>
            <Button
              size="small"
              className="app-button"
              onClick={reloadGrid}
              title="Refresh"
            >
              <span className="k-icon k-i-reload"></span>
            </Button>
            <Button
              size="small"
              className="app-button"
              ref={anchor}
              title="Filter By"
              onClick={openPopover}
            >
              <span
                className={
                  submit === true
                    ? "k-icon k-i-filter-clear"
                    : "k-icon k-i-filter"
                }
              ></span>
            </Button>
            <Button
              size="small"
              className="app-button"
              onClick={() => addToggleDialog("Add")}
              title="Add"
            >
              <span className="k-icon k-i-plus"></span>
            </Button>
            { userContext.is_gcm_admin === "Y"?
              <Button size="small" onClick={EditHeirarchy} title="Edit" className="app-button" disabled={selectedData === null}
              >
                <span className="k-icon k-i-edit"></span>
              </Button>:""
            }
            {show && <FilterPopUp
              params={FILTER_PARAMS}
              handleClose={openPopover}
              show={show}
              elementRef={anchor.current && anchor.current.element}
              handleFilterSubmit={onFilterSubmit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              userId={user_id}
            />}
             <Button
              size="small"
              onClick={handleDelete}
              title="Delete"
              className="app-button"
              disabled={disabledeleteFlag}
              iconClass="k-icon k-i-delete"
            ></Button>
          </span>
          <Tooltip openDelay={100} position="bottom" anchorElement="target">
            <Grid
              sortable={true}
              pageable={true}
              skip={page.skip}
              pageSize={limit}
              sort={sort}
              rowHeight={window.innerHeight * 0.067}
              onSelectionChange={onSelectionChange}
              total={dataState.length > 0 ? dataState[0]?.resultset_size : 0}
              onPageChange={(e) => {
                handlePageChange(e);
              }}
              data={orderBy(
                dataState.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })),
                sort
              )}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
              }}
              onRowClick={(e) => {
                handleRowClick(e);
              }}
              onSortChange={(e) => {
                handleSortChange(e);
              }}
            >
              <GridColumn
                headerClassName="headerClass"
                field="prefix"
                title="Prefix"
                cell={(e) => GridCellToolTip(e, "prefix")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="commodity_code"
                title="Commodity Code"
                cell={(e) => GridCellToolTip(e, "commodity_code")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="supplier_name"
                title="Supplier"
                cell={(e) => GridCellToolTip(e, "supplier_name")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="status"
                title="Status"
                cell={(e) => GridCellToolTip(e, "status")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="effective_start_date"
                title="Start Date Active"
                cell={(e) => GridCellToolTip(e, "effective_start_date")}
              />
              <GridColumn
                headerClassName="headerClass"
                field="effective_end_date"
                title="End Date Active"
                cell={(e) => GridCellToolTip(e, "effective_end_date")}
              />
            </Grid>
          </Tooltip>
        </div>
        <div className="pane-content">
          <PartAssociation
            heirarchyCode={heirarchyCode}
            setLoading={setLoading}
          ></PartAssociation>
        </div>
      </Splitter>
      {openAddDialog && (
        <AddHeirarchyGroup
          addToggleDialog={addToggleDialog}
          setOpenAddDialog={setOpenAddDialog}
          openAddDialog={openAddDialog}
          reloadGrid={reloadGrid}
        ></AddHeirarchyGroup>
      )}
      {deleteAlert && (
        <Dialog title="Delete Record" onClose={() => setDeleteAlert(false)}>
          <p
            style={{
              textAlign: "center",
              width: "464px",
            }}
          >
            Heirarchy will be deleted along with the associated parts. Do you
            want to proceed?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setDeleteAlert(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleProceed}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {editAlert && (
        <Dialog title="Edit Record" onClose={() => setEditAlert(false)}>
          <p
            style={{
              textAlign: "center",
              width: "464px",
            }}
          >
            Heirarchy will be end dated along with the associated parts. Do you
            want to proceed?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setEditAlert(false)}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={editHierarchyRecord}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {alertDialogForEdit && (
        <Dialog height="150px">
          <p style={{ textAlign: "center", width: "400px" }}>{response}.</p>
          <DialogActionsBar>
            <button
              style={{
                backgroundColor: "#228dff",
                color: "white",
                width: "100%",
              }}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => {
                setAlertDialogForEdit(false);
                setResponse("");
              }}
            >
              OK
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
export default HeirarchyGroupParts;
