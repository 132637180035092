import React, { useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import {
    Window,
} from "@progress/kendo-react-dialogs";
import "./CostSubmissionUpload.css";
import WindowTitle from "../../../components/WindowTitle/WindowTitle";
import { UserContext } from "../../../App";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CostSubmissionTemplate from "../../../assets/templates/CostSubmissionTemplate.xlsx";
import CostSubmissionMultiSupplierTemplate from "../../../assets/templates/CostSubmissionMultiSupplierTemplate.xlsx";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

const CostSubmissionDownload = ({ toggleWizard }) => {
    const [templateType, seTemplateType] = useState("");
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [uploadLoading, setuploadLoading] = useState(false);

    const templateTypeOnChange = (event) => {
        let obj = event.value;
        seTemplateType(obj);
    }
   
    const costSubmissionDownload = () => {
        setuploadLoading(false);
        setDisabledBtn(true);
    };

    const clearCostSubmissionDownload = ()=>{
        seTemplateType("");
    }

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="window-container">
                <Window
                    width={486}
                    height={210}
                    minimizeButton={() => null}
                    maximizeButton={() => null}
                    title={
                        <WindowTitle
                            title="Cost Submission - Download"
                            icon="k-i-table-cell"
                        />
                    }
                    onClose={toggleWizard}
                >
                    <div
                        style={{
                            margin: "25px 12px 12px 15px",
                        }}
                    >
                        <div style={{ marginTop: "-39px", marginBottom: "9px" }}>
                            {uploadLoading ? <ProgressBar /> : ""}
                        </div>
                        <br></br>
                        <FormElement>
                            <div className="form-element">
                                <label className="input-header-cell">
                                    Select Template Type* :
                                </label>
                                <DropDownList
                                    style={{
                                        width: "200px",
                                    }}
                                    value={templateType}
                                    data={["Multiple Supplier Template", "Single Supplier Template"]}
                                    onChange={templateTypeOnChange}
                                />
                            </div>
                        </FormElement>
                    </div>
                    <div style={{ marginTop: 15, width: "100%", textAlign: "right" }}>
                        <Button
                            onClick={costSubmissionDownload}
                            className="save-button"
                            disabled={templateType === "" ? true : false}
                        >
                        <a 
                style={{ color: "white", textDecoration: "none" }}
                href= {templateType == "Single Supplier Template"?CostSubmissionTemplate:CostSubmissionMultiSupplierTemplate}
                download= {templateType == "Single Supplier Template"?"CMATXWD_CS_UPLOAD_TEMPLATE.xlsx":"CMATXWD_CS_UPLOAD_MULTI_SUPLIER_TEMPLATE.xlsx"}>
                          <span className="k-icon k-i-download"> </span> Download
                            </a>
                        </Button>
                        <Button
                            onClick={clearCostSubmissionDownload}
                            className="save-button"
                            disabled={templateType === "" ? true : false}
                        >
                            <span > </span> Clear
                        </Button>
                    </div>
                </Window>
            </div>
        </div>
    );
};

export default CostSubmissionDownload;
